import React from "react";
import moment from "moment";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import API from "API";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import Tile from "Components/Common/Dashboard/Tile";
import { COLOR_MAIN_CONNECTWISE } from "ColorSchemes";

const initialState = {
    isLoading: true,
    charts: {
        import: {
            companies: [],
        },
        sync: {
            companies: [],
            additions: [],
        },
    },
    insight: {},
    syncIssues: false,
};

class ConnectWiseDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.props.pageTitle([1, "ConnectWise", "Dashboard"]);
        this.loadComponentData();
    };

    loadComponentData = () => {
        Promise.all([API.get("/connectwise/insight"), API.get("/connectwise/insight/charts")]).then(([insight, charts]) => {
            let labels = [];
            let newCompanies = [];
            let activeCompanies = [];
            let syncAdditions = [];

            for (let i = 0, j = 11; i <= j; i++) {
                newCompanies.push(parseInt(charts.data[i].companies.count));
                activeCompanies.push(parseInt(charts.data[i].active.count));
                syncAdditions.push(parseInt(charts.data[i].sync.additions));
                labels.push(charts.data[i].label);
            }

            const chartNewCompanies = {
                labels: labels,
                datasets: [
                    {
                        lineTension: 0.1,
                        backgroundColor: "rgba(13,71,161,0.5)",
                        borderColor: "#0D47A1",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#0D47A1",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#0D47A1",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        fill: true,
                        data: newCompanies,
                    },
                ],
            };

            const chartActiveCompanies = {
                labels: labels,
                datasets: [
                    {
                        lineTension: 0.1,
                        backgroundColor: "rgba(255,87,34,0.5)",
                        borderColor: "#FF5722",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#FF5722",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#FF5722",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        fill: true,
                        data: activeCompanies,
                    },
                ],
            };

            const chartSyncAdditions = {
                labels: labels,
                datasets: [
                    {
                        lineTension: 0.1,
                        backgroundColor: "rgba(156,39,176,0.5)",
                        borderColor: "#9C27B0",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#9C27B0",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#9C27B0",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        fill: true,
                        data: syncAdditions,
                    },
                ],
            };

            this.setState({
                isLoading: false,
                charts: {
                    import: {
                        companies: chartNewCompanies,
                    },
                    sync: {
                        companies: chartActiveCompanies,
                        additions: chartSyncAdditions,
                    },
                },
                insight: insight.data.insight,
                syncIssues: insight.data.insight.sync_issues > 0 ? true : false,
            });
        });
    };

    render() {
        const { charts, isLoading, insight } = this.state;
        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={8} xl={9}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={6}>
                                            <Typography variant={"h4"} paragraph>
                                                New Companies Imported
                                            </Typography>
                                            <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                <Box p={3}>
                                                    <Chart
                                                        type="line"
                                                        data={charts.import.companies}
                                                        options={{
                                                            plugins: {
                                                                legend: {
                                                                    display: false,
                                                                },
                                                            },
                                                            scales: {
                                                                xAxes: [
                                                                    {
                                                                        gridLines: {
                                                                            color: "rgba(0,0,0,.03)",
                                                                        },
                                                                    },
                                                                ],
                                                                yAxes: [
                                                                    {
                                                                        gridLines: {
                                                                            color: "rgba(0,0,0,.03)",
                                                                        },
                                                                        ticks: {
                                                                            beginAtZero: true,
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <Typography variant={"h4"} paragraph>
                                                Active Companies
                                            </Typography>
                                            <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                <Box p={3}>
                                                    <Chart
                                                        type="line"
                                                        data={charts.sync.companies}
                                                        options={{
                                                            plugins: {
                                                                legend: {
                                                                    display: false,
                                                                },
                                                            },
                                                            scales: {
                                                                xAxes: [
                                                                    {
                                                                        gridLines: {
                                                                            color: "rgba(0,0,0,.03)",
                                                                        },
                                                                    },
                                                                ],
                                                                yAxes: [
                                                                    {
                                                                        gridLines: {
                                                                            color: "rgba(0,0,0,.03)",
                                                                        },
                                                                        ticks: {
                                                                            beginAtZero: true,
                                                                            userCallback: function (label, index, labels) {
                                                                                if (Math.floor(label) === label) {
                                                                                    return label;
                                                                                }
                                                                            },
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Synchronized Additions
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.sync.additions}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                    userCallback: function (label, index, labels) {
                                                                                        if (Math.floor(label) === label) {
                                                                                            return label;
                                                                                        }
                                                                                    },
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={4} xl={3}>
                                    <Grid container spacing={3}>
                                        <Tile
                                            dashboard
                                            icon="exchange"
                                            color={COLOR_MAIN_CONNECTWISE}
                                            backgroundColor="#fff"
                                            data={
                                                insight && insight.last_import && insight.last_import.icw_created_datetime
                                                    ? moment(insight.last_import.icw_created_datetime).format("DD/MM/YYYY")
                                                    : "Never"
                                            }
                                            title="Last ConnectWise Data Import"
                                        />
                                        <Tile
                                            dashboard
                                            icon="exchange"
                                            color={COLOR_MAIN_CONNECTWISE}
                                            backgroundColor="#fff"
                                            data={
                                                insight && insight.last_sync && insight.last_sync.icws_created_datetime
                                                    ? moment(insight.last_sync.icws_created_datetime).format("DD/MM/YYYY")
                                                    : "Never"
                                            }
                                            title="Last ConnectWise Synchronization"
                                        />
                                        <Tile
                                            dashboard
                                            icon="landmark"
                                            color={COLOR_MAIN_CONNECTWISE}
                                            backgroundColor="#fff"
                                            data={insight ? insight.companies : 0}
                                            title="Companies"
                                        />
                                        <Tile
                                            dashboard
                                            icon="handshake"
                                            color={COLOR_MAIN_CONNECTWISE}
                                            backgroundColor="#fff"
                                            data={insight ? insight.agreements : 0}
                                            title="Active Agreements"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

export default ConnectWiseDashboard;
