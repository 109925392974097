import React from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import API from "API";
import Tile from "Components/Common/Dashboard/Tile";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";

const initialState = {
    dataLoading: true,
    charts: {
        cost: [],
        price: [],
        profit: [],
        margin: [],
    },
    counts: {},
};

class VoipDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.props.pageTitle([1, "VOIP and Connectivity", "Dashboard"]);
        this.loadComponentData();
    };

    loadComponentData = () => {
        Promise.all([API.get("/voip/insight"), API.get("/voip/insight/charts")]).then(([counts, insight]) => {
            let historyCost = [];
            let historyPrice = [];
            let historyProfit = [];
            let historyMargin = [];
            let historyLabels = [];

            for (let i = 0, j = 11; i <= j; i++) {
                historyCost.push(parseInt(insight.data[i].cost));
                historyPrice.push(parseInt(insight.data[i].price));
                historyProfit.push(parseInt(insight.data[i].profit));
                historyMargin.push(parseInt(insight.data[i].margin));
                historyLabels.push(insight.data[i].label);
            }

            const chartCost = {
                labels: historyLabels,
                datasets: [
                    {
                        lineTension: 0.1,
                        backgroundColor: "rgba(244,67,54,0.5)",
                        borderColor: "#f44336",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#f44336",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#f44336",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        fill: true,
                        data: historyCost,
                    },
                ],
            };

            const chartPrice = {
                labels: historyLabels,
                datasets: [
                    {
                        lineTension: 0.1,
                        backgroundColor: "#81C784",
                        borderColor: "#4CAF50",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#4CAF50",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#4CAF50",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        fill: true,
                        data: historyPrice,
                    },
                ],
            };

            const chartProfit = {
                labels: historyLabels,
                datasets: [
                    {
                        lineTension: 0.1,
                        backgroundColor: "rgba(171,71,188,0.5)",
                        borderColor: "#AB47BC",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#AB47BC",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#AB47BC",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        fill: true,
                        data: historyProfit,
                    },
                ],
            };

            const chartMargin = {
                labels: historyLabels,
                datasets: [
                    {
                        lineTension: 0.1,
                        backgroundColor: "rgba(255,152,0,0.5)",
                        borderColor: "#FF9800",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#FF9800",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#FF9800",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        fill: true,
                        data: historyMargin,
                    },
                ],
            };
            this.setState({
                dataLoading: false,
                charts: {
                    cost: chartCost,
                    price: chartPrice,
                    profit: chartProfit,
                    margin: chartMargin,
                },
                counts: counts.data,
            });
        });
    };

    render() {
        const { charts, counts, dataLoading } = this.state;
        return (
            <Grid container spacing={3}>
                {(dataLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={8} xl={9}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Service Sales
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.price}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Service Cost
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.cost}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Service Profit
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.profit}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Service Margin
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.margin}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={4} xl={3}>
                                    <Grid container spacing={3}>
                                        <Tile
                                            dashboard
                                            icon="phone"
                                            color="#6A1B9A"
                                            backgroundColor="#fff"
                                            data={counts ? counts.total_services_active : 0}
                                            title="Active Services"
                                        />
                                        <Tile
                                            dashboard
                                            icon="phone-slash"
                                            color="#6A1B9A"
                                            backgroundColor="#fff"
                                            data={counts ? counts.total_services_terminated : 0}
                                            title="Terminated Services"
                                        />
                                        <Tile
                                            dashboard
                                            icon="ballot"
                                            color="#6A1B9A"
                                            backgroundColor="#fff"
                                            data={counts ? counts.total_service_charges : 0}
                                            title="Imported Service Charges"
                                        />
                                        <Tile
                                            dashboard
                                            icon="ballot"
                                            color="#6A1B9A"
                                            backgroundColor="#fff"
                                            data={counts ? counts.total_call_charges : 0}
                                            title="Imported Call Charges"
                                        />
                                        <Tile
                                            dashboard
                                            icon="exclamation-circle"
                                            color="#6A1B9A"
                                            backgroundColor="#fff"
                                            data={counts ? counts.total_call_charges_pending : 0}
                                            title="Service Charges Awaiting Billing"
                                        />
                                        <Tile
                                            dashboard
                                            icon="exclamation-circle"
                                            color="#6A1B9A"
                                            backgroundColor="#fff"
                                            data={counts ? counts.total_call_charges_pending : 0}
                                            title="Call Charges Awaiting Billing"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

export default VoipDashboard;
