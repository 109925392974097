import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';

import AdminNavItem from './AdminNavItem';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const styles = theme => ({
    drawerPaper: {
        position: 'fixed',
        whiteSpace: 'nowrap',
    },
    drawerPaperStandard: {
        width: 235,
    },
    drawerPaperSlim: {
        width: 195,
    },
    drawerPaperClose: {
        width: theme.spacing(6.5),  
        overflowX: 'hidden',
    },
    notchMenuFix: {
        paddingTop: 0,
        paddingBottom:0
    },    
    [theme.breakpoints.down('sm')]: {
        drawerPaper: {
            position: 'fixed',
            width: '100%',
            height: '100%',
            minHeight: '100%',
            borderRight: 0,
        },
        drawerPaperClose: {
            width: theme.spacing(6.5),
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        },
    },
    [theme.breakpoints.down('xs')]: {
        drawerPaper: {
            borderRight: 0,
        },
        drawerPaperClose: {
            width: 0,
        },
    },
    // ['@media (orientation: landscape) and (max-height: 767px)']: { // eslint-disable-line no-useless-computed-key
    //     drawerPaperClose: {
    //         width: 0,
    //     },
    //     notchMenuFix: {
    //         marginLeft: 'env(safe-area-inset-left)',
    //         marginRight: 'env(safe-area-inset-right)',
    //     },
    // },
});

const initialState = {
    navItems: {},
    navLoading: true,
    selectedNavId: false,
    scrollHeight: window.innerHeight,
}

class AdminNav extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        API.get('/staff/my/navigation')
        .then(result => {
            if(result?.data){
                this.setState({
                    ...this.state,
                    navItems: result.data,
                    navLoading: false,
                    scrollHeight: this.props.scrollHeight,
                })
            }
        })
    }
    
    componentDidUpdate(prevProps){
        if(this.props.scrollHeight !== prevProps.scrollHeight){
            this.setState({
                scrollHeight: this.props.scrollHeight,
            });
        }
    }

    handleClick = (navId) => {
        if(this.state.selectedNavId !== navId){
            this.setState({
                selectedNavId: navId,
            });
        } else {
            this.setState({
                selectedNavId: false,
            });
        }
    }

    render() {
        const { navLoading, scrollHeight } = this.state;
        const { classes, ui } = this.props;
        const isTablet = ui?.device?.isTablet ?? false;
        return (
            <Drawer
                classes={{
                    paper: classNames(classes.drawerPaper, !this.props.navOpen && classes.drawerPaperClose, !this.props.navOpen && classes.drawerPaperClose, isTablet ? classes.drawerPaperSlim : classes.drawerPaperStandard),
                }}
                open={this.props.navOpen}
                style={{height: window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${scrollHeight}px)` : `${scrollHeight}px`}}
                variant="permanent"
            >
                <div style={{minHeight: `calc(env(safe-area-inset-top) + 50px)`}} />
                {(navLoading && (
                    <div style={{textAlign: 'center', paddingTop: 32}}>
                        <LoadingCircle 
                            padding={!this.props.navOpen ? 'xs' : false} 
                            size={this.props.navOpen ? 'large' : 'small'} 
                        />
                    </div>
                )) || (
                    <List 
                        onClick={() => !this.props.navOpen && this.props.toggleDrawer('nav')} 
                        className={classes.notchMenuFix}
                    >
                        {!_.isEmpty(this.state.navItems) && this.state.navItems.map(mainNav => {
                            return (
                                <React.Fragment key={mainNav.access_id}>
                                    {(typeof(mainNav.children) === "undefined" && mainNav.access_display_on_nav === 1 && (                                               
                                        <AdminNavItem 
                                            access_display_divider={mainNav.access_display_divider} 
                                            history={this.props.history} 
                                            icon={mainNav.access_icon} 
                                            reference={mainNav.access_reference} 
                                            navOpen={this.props.navOpen} 
                                            link={mainNav.access_route} 
                                            navId={mainNav.access_id} 
                                            name={mainNav.access_name} 
                                            notifications={this.props.notifications}
                                            onClick={this.handleClick} 
                                            pagePath={this.props.pagePath} 
                                            selectedNavId={this.state.selectedNavId}
                                            color={mainNav.access_nav_color}
                                        /> 
                                    )) || (
                                        mainNav.access_display_on_nav === 1 && (
                                            (_.some(mainNav.children, function (nv) { return nv.access_display_on_nav === 1 }) && (
                                                <AdminNavItem 
                                                    access_display_divider={mainNav.access_display_divider} 
                                                    history={this.props.history} 
                                                    icon={mainNav.access_icon} 
                                                    reference={mainNav.access_reference} 
                                                    name={mainNav.access_name} 
                                                    navId={mainNav.access_id} 
                                                    navOpen={this.props.navOpen} 
                                                    notifications={this.props.notifications}
                                                    onClick={this.handleClick} 
                                                    pagePath={this.props.pagePath} 
                                                    selectedNavId={this.state.selectedNavId} 
                                                    submenu={mainNav.children} 
                                                    color={mainNav.access_nav_color}
                                                />
                                            )) || (
                                                <AdminNavItem 
                                                    access_display_divider={mainNav.access_display_divider} 
                                                    history={this.props.history} 
                                                    icon={mainNav.access_icon}
                                                    reference={mainNav.access_reference} 
                                                    navOpen={this.props.navOpen} 
                                                    link={mainNav.access_route} 
                                                    name={mainNav.access_name}
                                                    navId={mainNav.access_id} 
                                                    notifications={this.props.notifications}
                                                    onClick={this.handleClick} 
                                                    pagePath={this.props.pagePath} 
                                                    selectedNavId={this.state.selectedNavId} 
                                                    color={mainNav.access_nav_color}
                                                />    
                                            )
                                        )
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </List>
                )}
            </Drawer>
        );
    }
}

export default withStyles(styles)(AdminNav);