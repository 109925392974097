import * as ServiceWorkerTypes from 'Redux/Actions/ServiceWorker/Types/Types';

 const initialState = {
    installed: false
};

 function ServiceWorkerReducer(state = initialState, action) {
    switch (action.type) {
        case ServiceWorkerTypes.STATE_CHANGE:
            return {
                ...state,
                installed: action.payload
            };
        default:
            return state;
    }
}

 export default ServiceWorkerReducer;