import React from 'react';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';

const initialState = {
    currentProfilePhoto: null,
    formData: {},
    formErrors: {},
    confirmationOpen: false,
}

class UploadStaffProfilePhotoForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.loadStaffProfilePhoto();
    }

    loadStaffProfilePhoto = () => {
        API.get('/staff/' + this.props.staffId + '/profilePhoto').then(result => {
            this.setState({
                currentProfilePhoto: result.data
            });
        });
    }

    clearNewStaffProfilePhoto = () => {
        this.setState({
            formData: initialState.formData
        });
    }

    handleSubmit = () => {
        let formData = new FormData();
        formData.append('photo', this.state.formData.photo);
        API.post('/staff/' + this.props.staffId + '/profilePhoto', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(result => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                let newPhoto = this.state.formData.photo;
                this.setState(initialState,
                () => {
                    this.setState({
                        ...initialState,
                        currentProfilePhoto: URL.createObjectURL(newPhoto)
                    }, () => {
                        this.props.deploySnackBar("success", "The system user's avatar has been changed")
                        this.props.toggleDialog()
                    })
                })
            }
        })
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }

    render() {
        const { formErrors, formData, currentProfilePhoto } = this.state;
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} align='center'>
                        <Avatar alt="Current Profile Photo" src={(formData.photo && URL.createObjectURL(formData.photo)) || currentProfilePhoto} style={{height: 150, width: 150, margin: 0}} />
                    </Grid>
                    <Grid item xs={12}>
                        <DragFileInput
                            id="photoInput"
                            name="photo"
                            label="Choose Profile Photo (.png, .jpg, .jpeg, .bmp, .gif) *"
                            file={formData.photo}
                            errorText={formErrors && formErrors['generic']}
                            onChange={this.handleFileChange}
                            cancelOnClick={this.clearNewStaffProfilePhoto}
                            emptyText='No photo selected'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs />
                            <Grid item>
                                <Button onClick={() => this.props.toggleDialog()}
                                        variant="text"
                                >
                                    <FAIcon icon="times" size={15} button />
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                        disabled={!(formData.photo && formData.photo.name)}>
                                    <FAIcon icon="check" size={15} buttonPrimary disabled={!(formData.photo && formData.photo.name)} />
                                    Change Avatar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Upload new avatar?" 
                    message="Are you sure you want to upload a new avatar?"
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(UploadStaffProfilePhotoForm);