import React from 'react';
import { useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';

const UserDetails = ({device, small, textDefault}) => {
    const loggedInStaff = useSelector(state => state.staffAuth.staff)
    let name = loggedInStaff?.name;
     if(small && name) {
        name = name.split(' ');
        if(name.length > 1) {
            name = `${name[0]} ${name[1].charAt(0)}.`
        } else {
            name = name[0]
        }
    }
    return (
        (loggedInStaff && (
            <>
                <Typography
                    align='left'
                    variant="body2"
                    noWrap
                    className={`${!small ? 'not-xs' : undefined} fw-400`}
                    style={{color: !textDefault ? '#fff' : undefined}}
                > 
                    {name}
                </Typography>
                {!small && !device?.isTablet && (             
                    <Typography
                        align='left'
                        variant="caption"
                        className={`${!small ? 'not-xs' : undefined}`}
                        style={{color: '#fff'}}
                    > 
                        {loggedInStaff.email}
                    </Typography>
                )}
            </>
        )) || (
            null
        )
    )
}

export default UserDetails;