import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import API from "API";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import Tile from "Components/Common/Dashboard/Tile";
import { Button, Divider } from "@material-ui/core";
import { COLOR_MAIN_CONNECTWISE, COLOR_MAIN_ACCOUNTS, COLOR_MAIN_MYCLOUD, COLOR_MAIN_MS365, COLOR_MAIN_SUPPORT_CONTRACTS, COLOR_MAIN_VOIP } from "ColorSchemes";
import { hex2rgba } from "Functions/MiscFunctions";

const initialState = {
    charts: {
        sales: [],
    },
    insight: {},
    insightAcc: {},
    isLoading: true,
    syncIssues: false,
    sageCloudConnected: true,
    overview: {},
    cancellingSync: false,
    cancellingImport: false,
    cancellingAccImport: false,
};

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.overviewInterval = null;
    }

    componentDidMount = () => {
        this.props.pageTitle([0, "Dashboard"]);
        this.loadComponentData();
    };

    loadComponentData = () => {
        Promise.all([
            API.get("/bluesync/accounts/status"),
            API.get("/connectwise/insight"),
            API.get("/connectwise/insight/dashboard"),
            API.get("/bluesync/accounts/batches/insight"),
            API.get("/bluesync/overview"),
        ]).then(([sageConnector, insight, dashboard, insightAcc, overview]) => {
            let historySales = [];
            let historyMyCloud = [];
            let historyMs365 = [];
            let historyVoip = [];
            let historyLabels = [];

            for (let i = 0, j = 11; i <= j; i++) {
                historySales.push(dashboard.data[i].in.total);
                historyMyCloud.push(dashboard.data[i].my.total);
                historyMs365.push(dashboard.data[i].ms.total);
                historyVoip.push(dashboard.data[i].vp.total);
                historyLabels.push(dashboard.data[i].label);
            }

            const chartSales = {
                labels: historyLabels,
                datasets: [
                    {
                        backgroundColor: hex2rgba(COLOR_MAIN_MYCLOUD, 0.8),
                        data: historyMyCloud,
                        label: "MyCloud",
                    },
                    {
                        backgroundColor: hex2rgba(COLOR_MAIN_VOIP, 0.8),
                        data: historyVoip,
                        label: "VOIP",
                    },
                    {
                        backgroundColor: hex2rgba(COLOR_MAIN_MS365, 0.8),
                        data: historyMs365,
                        label: "MS365",
                    },
                    {
                        backgroundColor: hex2rgba(COLOR_MAIN_ACCOUNTS, 0.8),
                        data: historySales,
                        label: "Invoices",
                    },
                ],
            };

            this.setState(
                {
                    charts: {
                        sales: chartSales,
                    },
                    isLoading: false,
                    dashboard: dashboard.data,
                    insight: insight.data.insight,
                    insightAcc: insightAcc.data.insight,
                    sageCloudConnected: (sageConnector.data && sageConnector.data.status === 1) || false,
                    syncIssues: insight.data.insight.sync_issues > 0 ? true : false,
                    overview: overview.data,
                },
                () => {
                    this.overviewInterval = setInterval(this.refreshOverview, 3000);
                }
            );
        });
    };

    componentWillUnmount = () => {
        if (this.overviewInterval) {
            clearInterval(this.overviewInterval);
        }
    };

    refreshOverview = () => {
        API.get("/bluesync/overview", { props: { noLoading: true } }).then((overview) => {
            this.setState({
                overview: overview.data,
            });
        });
    };

    cancelSync = () => {
        this.setState(
            {
                cancellingSync: true,
            },
            () => {
                API.put("/bluesync/synchronization/cancel")
                    .then((res) => {
                        if (res.data?.success) {
                            API.get("/bluesync/overview")
                                .then((overview) => {
                                    this.setState({
                                        overview: overview.data,
                                        cancellingSync: false,
                                    });
                                })
                                .catch((err) => {
                                    this.setState({
                                        cancellingSync: false,
                                    });
                                });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            cancellingSync: false,
                        });
                    });
            }
        );
    };

    cancelImport = () => {
        this.setState(
            {
                cancellingImport: true,
            },
            () => {
                API.put("/bluesync/import/cancel")
                    .then((res) => {
                        if (res.data?.success) {
                            API.get("/bluesync/overview")
                                .then((overview) => {
                                    this.setState({
                                        overview: overview.data,
                                        cancellingImport: false,
                                    });
                                })
                                .catch((err) => {
                                    this.setState({
                                        cancellingImport: false,
                                    });
                                });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            cancellingImport: false,
                        });
                    });
            }
        );
    };

    cancelAccImport = () => {
        this.setState(
            {
                cancellingImport: true,
            },
            () => {
                API.put("/bluesync/accounts/cancel")
                    .then((res) => {
                        if (res.data?.success) {
                            API.get("/bluesync/overview")
                                .then((overview) => {
                                    this.setState({
                                        overview: overview.data,
                                        cancellingImport: false,
                                    });
                                })
                                .catch((err) => {
                                    this.setState({
                                        cancellingImport: false,
                                    });
                                });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            cancellingImport: false,
                        });
                    });
            }
        );
    };

    render() {
        const { charts, isLoading, insight, insightAcc, sageCloudConnected, syncIssues, overview, cancellingSync, cancellingImport, cancellingAccImport } = this.state;
        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12} lg={5}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant={"h4"} paragraph>
                                        Net Sales
                                    </Typography>
                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                        <Box p={3}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Box p={3}>
                                                        <Chart
                                                            type="bar"
                                                            data={charts.sales}
                                                            options={{
                                                                plugins: {
                                                                    legend: {
                                                                        display: true,
                                                                        position: "bottom",
                                                                    },
                                                                },
                                                                scales: {
                                                                    xAxes: [
                                                                        {
                                                                            gridLines: {
                                                                                color: "rgba(0,0,0,.03)",
                                                                            },
                                                                        },
                                                                    ],
                                                                    yAxes: [
                                                                        {
                                                                            gridLines: {
                                                                                color: "rgba(0,0,0,.03)",
                                                                            },
                                                                            ticks: {
                                                                                beginAtZero: true,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={6}>
                                            <Typography variant="h4" paragraph>
                                                ConnectWise
                                            </Typography>
                                            <Grid container spacing={3}>
                                                <Tile
                                                    dashboard
                                                    icon="landmark"
                                                    color={COLOR_MAIN_CONNECTWISE}
                                                    backgroundColor="#fff"
                                                    data={insight.companies ?? 0}
                                                    title="Active Companies"
                                                />
                                                <Tile
                                                    dashboard
                                                    icon="handshake"
                                                    color={COLOR_MAIN_CONNECTWISE}
                                                    backgroundColor="#fff"
                                                    data={insight.agreements ?? 0}
                                                    title="Agreements"
                                                />
                                                <Tile
                                                    dashboard
                                                    icon="file-contract"
                                                    color={COLOR_MAIN_CONNECTWISE}
                                                    backgroundColor="#fff"
                                                    data={insight.additions ?? 0}
                                                    title="Additions"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <Typography variant="h4" paragraph>
                                                System Status
                                            </Typography>
                                            <Grid container spacing={3}>
                                                <Tile
                                                    dashboard
                                                    icon="thermometer-half"
                                                    color={syncIssues ? "#d9534f" : "#4CAF50"}
                                                    backgroundColor="#fff"
                                                    data={syncIssues ? "Impacted" : "Good"}
                                                    title="Synchronization Status"
                                                />
                                                <Tile
                                                    dashboard
                                                    icon={insightAcc.awaitingBe + insightAcc.awaitingBi !== 0 ? "exclamation-triangle" : "check"}
                                                    color={insightAcc.awaitingBe + insightAcc.awaitingBi !== 0 ? "#d9534f" : "#4CAF50"}
                                                    backgroundColor="#fff"
                                                    data={insightAcc.awaitingBe + insightAcc.awaitingBi}
                                                    title="Oustanding Accounts Batches"
                                                />
                                                <Tile
                                                    dashboard
                                                    icon="server"
                                                    color={!sageCloudConnected ? "#d9534f" : "#4CAF50"}
                                                    backgroundColor="#fff"
                                                    data={sageCloudConnected ? "Connected" : "Not Authorised"}
                                                    title="Sage Business Cloud Accounting"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Box pb={3}>
                                        <Typography variant="h4">Service Imports Overview</Typography>
                                    </Box>
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item>
                                            <FAIcon type="thin" icon="cloud-download-alt" color={COLOR_MAIN_CONNECTWISE} size={40} noMargin />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant={"h6"}>ConnectWise Import</Typography>
                                            {(overview.import.CW?.import_queue_status && (
                                                <React.Fragment>
                                                    <Typography variant="body2" className="fw-400" gutterBottom>
                                                        {moment(overview.import.CW.import_queue_created_datetime).format("DD/MM/YYYY")}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Started at {moment(overview.import.CW.import_queue_created_datetime).format("HH:mm:ss")} |{" "}
                                                        {overview.import.CW.import_queue_status === "Cancelled"
                                                            ? `Cancelled at ${moment(overview.import.CW.import_queue_updated_datetime).format("HH:mm:ss")}`
                                                            : overview.import.CW.import_queue_status === "Completed"
                                                            ? `Finished at ${moment(overview.import.CW.import_queue_updated_datetime).format("HH:mm:ss")}`
                                                            : overview.import.CW.import_queue_status === "Queued"
                                                            ? "Queued for " + moment(overview.import.CW.import_queue_created_datetime).fromNow(true)
                                                            : "Processing for " + moment(overview.import.CW.import_queue_updated_datetime).fromNow(true)}
                                                    </Typography>
                                                </React.Fragment>
                                            )) || (
                                                <Typography variant="body2" className="fw-400">
                                                    <em>Never Imported</em>
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            {(overview.import.CW &&
                                                ((overview.import.CW?.import_queue_status === "Completed" && (
                                                    <FAIcon icon="check" size={40} style={{ color: "#4CAF50" }} noMargin />
                                                )) ||
                                                    (overview.import.CW?.import_queue_status === "Queued" && <FAIcon icon="hourglass-start" size={40} noMargin spin />) ||
                                                    (overview.import.CW.import_queue_status === "Cancelled" && (
                                                        <FAIcon icon="times" size={40} style={{ color: "#d9534f" }} noMargin />
                                                    )) ||
                                                    (overview.import.CW?.import_queue_status === "Processing" && (
                                                        <LoadingCircle
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                color:
                                                                    moment.duration(moment().diff(overview.import.CW?.import_queue_updated_datetime)).asMinutes() > 15
                                                                        ? "#d9534f"
                                                                        : moment.duration(moment().diff(overview.import.CW?.import_queue_updated_datetime)).asMinutes() > 5
                                                                        ? "#FB8C00"
                                                                        : "#4CAF50",
                                                            }}
                                                        />
                                                    )))) || <FAIcon icon="question-circle" size={40} noMargin />}
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item>
                                            <FAIcon type="thin" icon="cloud" size={40} color={COLOR_MAIN_MYCLOUD} noMargin />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant={"h6"}>MyCloud Import</Typography>
                                            {(overview.import.MYCLOUD?.import_queue_status && (
                                                <React.Fragment>
                                                    <Typography variant="body2" className="fw-400" gutterBottom>
                                                        {moment(overview.import.MYCLOUD.import_queue_created_datetime).format("DD/MM/YYYY")}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Started at {moment(overview.import.MYCLOUD.import_queue_created_datetime).format("HH:mm:ss")} |{" "}
                                                        {overview.import.MYCLOUD.import_queue_status === "Cancelled"
                                                            ? `Cancelled at ${moment(overview.import.MYCLOUD.import_queue_updated_datetime).format("HH:mm:ss")}`
                                                            : overview.import.MYCLOUD.import_queue_status === "Completed"
                                                            ? `Finished at ${moment(overview.import.MYCLOUD.import_queue_updated_datetime).format("HH:mm:ss")}`
                                                            : overview.import.MYCLOUD.import_queue_status === "Queued"
                                                            ? "Queued for " + moment(overview.import.MYCLOUD.import_queue_created_datetime).fromNow(true)
                                                            : "Processing for " + moment(overview.import.MYCLOUD.import_queue_updated_datetime).fromNow(true)}
                                                    </Typography>
                                                    {/* {overview.import.MYCLOUD?.import_queue_status === 'Processing' && moment.duration(moment().diff(overview.import.MYCLOUD.import_queue_updated_datetime)).asMinutes() > 10 && (
                                                        <React.Fragment>
                                                            <Box mt={1}>
                                                                <Typography variant="caption" className="textError">
                                                                    This seems to be taking too long...
                                                                </Typography>
                                                                <Box mt={2}>
                                                                    <Button variant="outlined" size="small"><FAIcon icon="times" size="small" />Cancel Sync</Button>
                                                                </Box>
                                                            </Box>
                                                        </React.Fragment>
                                                    )} */}
                                                </React.Fragment>
                                            )) || (
                                                <Typography variant="caption">
                                                    <em>Never Imported</em>
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            {(overview.import.MYCLOUD &&
                                                ((overview.import.MYCLOUD?.import_queue_status === "Completed" && (
                                                    <FAIcon icon="check" size={40} style={{ color: "#4CAF50" }} noMargin />
                                                )) ||
                                                    (overview.import.MYCLOUD?.import_queue_status === "Queued" && <FAIcon icon="hourglass-start" size={40} noMargin spin />) ||
                                                    (overview.import.MYCLOUD.import_queue_status === "Cancelled" && (
                                                        <FAIcon icon="times" size={40} style={{ color: "#d9534f" }} noMargin />
                                                    )) ||
                                                    (overview.import.MYCLOUD?.import_queue_status === "Processing" && (
                                                        <LoadingCircle
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                color:
                                                                    moment.duration(moment().diff(overview.import.MYCLOUD?.import_queue_updated_datetime)).asMinutes() > 15
                                                                        ? "#d9534f"
                                                                        : moment.duration(moment().diff(overview.import.MYCLOUD?.import_queue_updated_datetime)).asMinutes() >
                                                                          5
                                                                        ? "#FB8C00"
                                                                        : "#4CAF50",
                                                            }}
                                                        />
                                                    )))) || <FAIcon icon="question-circle" size={40} noMargin />}
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item>
                                            <FAIcon type="thin" icon="th-large" size={40} color={COLOR_MAIN_MS365} noMargin />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant={"h6"}>Microsoft 365 Import</Typography>
                                            {(overview.import.MS365?.import_queue_status && (
                                                <React.Fragment>
                                                    <Typography variant="body2" className="fw-400" gutterBottom>
                                                        {moment(overview.import.MS365.import_queue_created_datetime).format("DD/MM/YYYY")}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Started at {moment(overview.import.MS365.import_queue_created_datetime).format("HH:mm:ss")} |{" "}
                                                        {overview.import.MS365.import_queue_status === "Cancelled"
                                                            ? `Cancelled at ${moment(overview.import.MS365.import_queue_updated_datetime).format("HH:mm:ss")}`
                                                            : overview.import.MS365.import_queue_status === "Completed"
                                                            ? `Finished at ${moment(overview.import.MS365.import_queue_updated_datetime).format("HH:mm:ss")}`
                                                            : overview.import.MS365.import_queue_status === "Queued"
                                                            ? "Queued for " + moment(overview.import.MS365.import_queue_created_datetime).fromNow(true)
                                                            : "Processing for " + moment(overview.import.MS365.import_queue_updated_datetime).fromNow(true)}
                                                    </Typography>
                                                    {/* {overview.import.MS365?.import_queue_status === 'Processing' && moment.duration(moment().diff(overview.import.MS365.import_queue_updated_datetime)).asMinutes() > 10 && (
                                                        <React.Fragment>
                                                            <Box mt={1}>
                                                                <Typography variant="caption" className="textError">
                                                                    This seems to be taking too long...
                                                                </Typography>
                                                                <Box mt={2}>
                                                                    <Button variant="outlined" size="small"><FAIcon icon="times" size="small" />Cancel Sync</Button>
                                                                </Box>
                                                            </Box>
                                                        </React.Fragment>
                                                    )} */}
                                                </React.Fragment>
                                            )) || (
                                                <Typography variant="caption">
                                                    <em>Never Imported</em>
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            {(overview.import.MS365 &&
                                                ((overview.import.MS365?.import_queue_status === "Completed" && (
                                                    <FAIcon icon="check" size={40} style={{ color: "#4CAF50" }} noMargin />
                                                )) ||
                                                    (overview.import.MS365?.import_queue_status === "Queued" && <FAIcon icon="hourglass-start" size={40} noMargin spin />) ||
                                                    (overview.import.MS365.import_queue_status === "Cancelled" && (
                                                        <FAIcon icon="times" size={40} style={{ color: "#d9534f" }} noMargin />
                                                    )) ||
                                                    (overview.import.MS365?.import_queue_status === "Processing" && (
                                                        <LoadingCircle
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                color:
                                                                    moment.duration(moment().diff(overview.import.MS365?.import_queue_updated_datetime)).asMinutes() > 15
                                                                        ? "#d9534f"
                                                                        : moment.duration(moment().diff(overview.import.MS365?.import_queue_updated_datetime)).asMinutes() > 5
                                                                        ? "#FB8C00"
                                                                        : "#4CAF50",
                                                            }}
                                                        />
                                                    )))) || <FAIcon icon="question-circle" size={40} noMargin />}
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item>
                                            <FAIcon type="thin" icon="desktop" size={40} color={COLOR_MAIN_SUPPORT_CONTRACTS} noMargin />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant={"h6"}>Support Contracts Import</Typography>
                                            {(overview.import.SC?.import_queue_status && (
                                                <React.Fragment>
                                                    <Typography variant="body2" className="fw-400" gutterBottom>
                                                        {moment(overview.import.SC.import_queue_created_datetime).format("DD/MM/YYYY")}
                                                    </Typography>
                                                    <Typography variant="body2          ">
                                                        Started at {moment(overview.import.SC.import_queue_created_datetime).format("HH:mm:ss")} |{" "}
                                                        {overview.import.SC.import_queue_status === "Cancelled"
                                                            ? `Cancelled at ${moment(overview.import.SC.import_queue_updated_datetime).format("HH:mm:ss")}`
                                                            : overview.import.SC.import_queue_status === "Completed"
                                                            ? `Finished at ${moment(overview.import.SC.import_queue_updated_datetime).format("HH:mm:ss")}`
                                                            : overview.import.SC.import_queue_status === "Queued"
                                                            ? "Queued for " + moment(overview.import.SC.import_queue_created_datetime).fromNow(true)
                                                            : "Processing for " + moment(overview.import.SC.import_queue_updated_datetime).fromNow(true)}
                                                    </Typography>
                                                    {/* {overview.import.SC?.import_queue_status === 'Processing' && moment.duration(moment().diff(overview.import.SC.import_queue_updated_datetime)).asMinutes() > 10 && (
                                                        <React.Fragment>
                                                            <Box mt={1}>
                                                                <Typography variant="caption" className="textError">
                                                                    This seems to be taking too long...
                                                                </Typography>
                                                                <Box mt={2}>
                                                                    <Button variant="outlined" size="small"><FAIcon icon="times" size="small" />Cancel Sync</Button>
                                                                </Box>
                                                            </Box>
                                                        </React.Fragment>
                                                    )} */}
                                                </React.Fragment>
                                            )) || (
                                                <Typography variant="caption">
                                                    <em>Never Imported</em>
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            {(overview.import.SC &&
                                                ((overview.import.SC?.import_queue_status === "Completed" && (
                                                    <FAIcon icon="check" size={40} style={{ color: "#4CAF50" }} noMargin />
                                                )) ||
                                                    (overview.import.SC?.import_queue_status === "Queued" && <FAIcon icon="hourglass-start" size={40} noMargin spin />) ||
                                                    (overview.import.SC.import_queue_status === "Cancelled" && (
                                                        <FAIcon icon="times" size={40} style={{ color: "#d9534f" }} noMargin />
                                                    )) ||
                                                    (overview.import.SC?.import_queue_status === "Processing" && (
                                                        <LoadingCircle
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                color:
                                                                    moment.duration(moment().diff(overview.import.SC?.import_queue_updated_datetime)).asMinutes() > 15
                                                                        ? "#d9534f"
                                                                        : moment.duration(moment().diff(overview.import.SC?.import_queue_updated_datetime)).asMinutes() > 5
                                                                        ? "#FB8C00"
                                                                        : "#4CAF50",
                                                            }}
                                                        />
                                                    )))) || <FAIcon icon="question-circle" size={40} noMargin />}
                                        </Grid>
                                    </Grid>
                                    {_.find(overview.import, (i) => i?.import_queue_status === "Processing") &&
                                        moment
                                            .duration(
                                                moment().diff(
                                                    _.find(
                                                        overview.import,
                                                        (i) =>
                                                            i &&
                                                            i.import_queue_id &&
                                                            (i.import_queue_task === "ConnectWise" ||
                                                                i.import_queue_task === "MS365" ||
                                                                i.import_queue_task === "MyCloud" ||
                                                                i.import_queue_task === "SC") &&
                                                            i.import_queue_status === "Processing"
                                                    ).import_queue_updated_datetime
                                                )
                                            )
                                            .asMinutes() > 10 && (
                                            <Grid item xs>
                                                <Box textAlign="center" pt={2}>
                                                    <Typography variant="subtitle1" className="fw-400 textError">
                                                        The service import(s) seem to be taking a while...
                                                    </Typography>
                                                    <Box mt={1}>
                                                        <Button
                                                            variant="contained"
                                                            className="btn btn-error"
                                                            size="small"
                                                            disabled={cancellingImport}
                                                            onClick={this.cancelImport}
                                                        >
                                                            <FAIcon icon="times" size={15} buttonPrimary />
                                                            Cancel Import(s)
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pb={3}>
                                        <Typography variant="h4">Accounting Imports</Typography>
                                    </Box>
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item>
                                            <FAIcon type="thin" icon="funnel-dollar" color={COLOR_MAIN_ACCOUNTS} size={40} noMargin />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant={"h6"}>Invoices Import</Typography>
                                            {(overview.import.INV?.acc_queue_status && (
                                                <>
                                                    <Typography variant="body2" className="fw-400" gutterBottom>
                                                        {moment(overview.import.INV.acc_queue_created_datetime).format("DD/MM/YYYY")}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Started at {moment(overview.import.INV.acc_queue_created_datetime).format("HH:mm:ss")} |{" "}
                                                        {overview.import.INV.acc_queue_status === "Cancelled"
                                                            ? `Cancelled at ${moment(overview.import.INV.acc_queue_updated_datetime).format("HH:mm:ss")}`
                                                            : overview.import.INV.acc_queue_status === "Completed"
                                                            ? `Finished at ${moment(overview.import.INV.acc_queue_updated_datetime).format("HH:mm:ss")}`
                                                            : overview.import.INV.acc_queue_status === "Queued"
                                                            ? "Queued for " + moment(overview.import.INV.acc_queue_created_datetime).fromNow(true)
                                                            : "Processing for " + moment(overview.import.INV.acc_queue_updated_datetime).fromNow(true)}
                                                    </Typography>
                                                </>
                                            )) || (
                                                <Typography variant="body2" className="fw-400">
                                                    <em>Never Imported</em>
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            {(overview.import.INV &&
                                                ((overview.import.INV?.acc_queue_status === "Completed" && (
                                                    <FAIcon icon="check" size={40} style={{ color: "#4CAF50" }} noMargin />
                                                )) ||
                                                    (overview.import.INV?.acc_queue_status === "Queued" && <FAIcon icon="hourglass-start" size={40} noMargin spin />) ||
                                                    (overview.import.INV.acc_queue_status === "Cancelled" && (
                                                        <FAIcon icon="times" size={40} style={{ color: "#d9534f" }} noMargin />
                                                    )) ||
                                                    (overview.import.INV?.acc_queue_status === "Processing" && (
                                                        <LoadingCircle
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                color:
                                                                    moment.duration(moment().diff(overview.import.INV?.acc_queue_updated_datetime)).asMinutes() > 15
                                                                        ? "#d9534f"
                                                                        : moment.duration(moment().diff(overview.import.INV?.acc_queue_updated_datetime)).asMinutes() > 5
                                                                        ? "#FB8C00"
                                                                        : "#4CAF50",
                                                            }}
                                                        />
                                                    )))) || <FAIcon icon="question-circle" size={40} noMargin />}
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item>
                                            <FAIcon type="thin" icon="funnel-dollar" color={COLOR_MAIN_ACCOUNTS} size={40} noMargin />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant={"h6"}>Expenses Import</Typography>
                                            {(overview.import.EXP?.acc_queue_status && (
                                                <>
                                                    <Typography variant="body2" className="fw-400" gutterBottom>
                                                        {moment(overview.import.EXP.acc_queue_created_datetime).format("DD/MM/YYYY")}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Started at {moment(overview.import.EXP.acc_queue_created_datetime).format("HH:mm:ss")} |{" "}
                                                        {overview.import.EXP.acc_queue_status === "Cancelled"
                                                            ? `Cancelled at ${moment(overview.import.EXP.acc_queue_updated_datetime).format("HH:mm:ss")}`
                                                            : overview.import.EXP.acc_queue_status === "Completed"
                                                            ? `Finished at ${moment(overview.import.EXP.acc_queue_updated_datetime).format("HH:mm:ss")}`
                                                            : overview.import.EXP.acc_queue_status === "Queued"
                                                            ? "Queued for " + moment(overview.import.EXP.acc_queue_created_datetime).fromNow(true)
                                                            : "Processing for " + moment(overview.import.EXP.acc_queue_updated_datetime).fromNow(true)}
                                                    </Typography>
                                                </>
                                            )) || (
                                                <Typography variant="body2" className="fw-400">
                                                    <em>Never Imported</em>
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            {(overview.import.EXP &&
                                                ((overview.import.EXP?.acc_queue_status === "Completed" && (
                                                    <FAIcon icon="check" size={40} style={{ color: "#4CAF50" }} noMargin />
                                                )) ||
                                                    (overview.import.EXP?.acc_queue_status === "Queued" && <FAIcon icon="hourglass-start" size={40} noMargin spin />) ||
                                                    (overview.import.EXP.acc_queue_status === "Cancelled" && (
                                                        <FAIcon icon="times" size={40} style={{ color: "#d9534f" }} noMargin />
                                                    )) ||
                                                    (overview.import.EXP?.acc_queue_status === "Processing" && (
                                                        <LoadingCircle
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                color:
                                                                    moment.duration(moment().diff(overview.import.EXP?.acc_queue_updated_datetime)).asMinutes() > 15
                                                                        ? "#d9534f"
                                                                        : moment.duration(moment().diff(overview.import.EXP?.acc_queue_updated_datetime)).asMinutes() > 5
                                                                        ? "#FB8C00"
                                                                        : "#4CAF50",
                                                            }}
                                                        />
                                                    )))) || <FAIcon icon="question-circle" size={40} noMargin />}
                                        </Grid>
                                    </Grid>
                                    {_.find(overview.import, (i) => i?.acc_queue_status === "Processing") &&
                                        moment
                                            .duration(
                                                moment().diff(
                                                    _.find(
                                                        overview.import,
                                                        (i) =>
                                                            i &&
                                                            i.acc_queue_id &&
                                                            (i.acc_queue_task === "Invoices" || i.acc_queue_task === "Expenses") &&
                                                            i.acc_queue_status === "Processing"
                                                    ).acc_queue_updated_datetime
                                                )
                                            )
                                            .asMinutes() > 10 && (
                                            <Grid item xs>
                                                <Box textAlign="center" mt={2} pt={2}>
                                                    <Typography variant="subtitle1" className="fw-400 textError">
                                                        The account import(s) seems to be taking a while...
                                                    </Typography>
                                                    <Box mt={1}>
                                                        <Button
                                                            variant="contained"
                                                            className="btn btn-error"
                                                            size="small"
                                                            disabled={cancellingAccImport}
                                                            onClick={this.cancelAccImport}
                                                        >
                                                            <FAIcon icon="times" size={15} buttonPrimary />
                                                            Cancel Import(s)
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Box pb={3}>
                                        <Typography variant="h4">Synchronization Overview</Typography>
                                    </Box>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <Box pt={1}>
                                                <FAIcon type="thin" icon="cloud-upload-alt" size={40} noMargin />
                                            </Box>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant={"h6"}>ConnectWise Sync</Typography>
                                            {(overview.sync.CW.sync_status && (
                                                <React.Fragment>
                                                    <Typography variant="body2" className="fw-400" gutterBottom>
                                                        {moment(overview.sync.CW.sync_created_datetime).format("DD/MM/YYYY")}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Started at {moment(overview.sync.CW.sync_created_datetime).format("HH:mm:ss")} |{" "}
                                                        {overview.sync.CW.sync_status === "Cancelled"
                                                            ? `Cancelled at ${moment(overview.sync.CW.sync_updated_datetime).format("HH:mm:ss")}`
                                                            : overview.sync.CW.sync_updated_datetime !== "0000-00-00 00:00:00"
                                                            ? `Finished at ${moment(overview.sync.CW.sync_updated_datetime).format("HH:mm:ss")}`
                                                            : "Processing for " + moment(overview.sync.CW.sync_created_datetime).fromNow(true)}
                                                    </Typography>
                                                    <Box mt={2}>
                                                        <Grid container spacing={3}>
                                                            {_.map(overview.sync.CW.queue, (q) => {
                                                                let qTitle = "";
                                                                switch (q.sync_queue_task) {
                                                                    case "ConnectWise":
                                                                        qTitle = "ConnectWise Data";
                                                                        break;
                                                                    case "MyCloud":
                                                                        qTitle = "MyCloud Data";
                                                                        break;
                                                                    case "VOIP":
                                                                        qTitle = "VOIP & Connectivity Data";
                                                                        break;
                                                                    case "MS365":
                                                                        qTitle = "Microsoft 365 Data";
                                                                        break;
                                                                    case "SC":
                                                                        qTitle = "Support Contracts Data";
                                                                        break;
                                                                    default:
                                                                        qTitle = "Unknown";
                                                                        break;
                                                                }
                                                                return (
                                                                    <Grid item xs={12}>
                                                                        <Grid container spacing={1}>
                                                                            <Grid item>
                                                                                <Box pt={1} width={32} minWidth={32} component="div" display="inline-block" textAlign="center">
                                                                                    {(q.sync_queue_status === "Completed" && (
                                                                                        <FAIcon icon="check" size={25} style={{ color: "#4CAF50" }} />
                                                                                    )) ||
                                                                                        (q.sync_queue_status === "Cancelled" && (
                                                                                            <FAIcon icon="times" size={25} style={{ color: "#d9534f" }} />
                                                                                        )) ||
                                                                                        (q.sync_queue_status === "Queued" && <FAIcon icon="hourglass-start" size={25} fw />) ||
                                                                                        (q.sync_queue_status === "Processing" && (
                                                                                            <FAIcon icon="spinner" style={{ color: "#4CAF50" }} spin />
                                                                                        )) || <FAIcon icon="question-circle" size={25} />}
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs>
                                                                                <Typography variant="h6" component="span">
                                                                                    {qTitle}
                                                                                </Typography>
                                                                                {(q.sync_queue_status === "Completed" && (
                                                                                    <Typography variant="body2" component="div">
                                                                                        Completed at {moment(q.sync_queue_updated_datetime).format("HH:mm:ss")}
                                                                                    </Typography>
                                                                                )) ||
                                                                                    (q.sync_queue_status === "Cancelled" && (
                                                                                        <Typography variant="body2" component="div">
                                                                                            Cancelled at {moment(q.sync_queue_updated_datetime).format("HH:mm:ss")}
                                                                                        </Typography>
                                                                                    )) ||
                                                                                    (q.sync_queue_status === "Queued" && (
                                                                                        <Typography variant="body2" component="div">
                                                                                            Queued since {moment(q.sync_queue_created_datetime).format("HH:mm:ss")}
                                                                                        </Typography>
                                                                                    )) ||
                                                                                    (q.sync_queue_status === "Processing" && (
                                                                                        <Typography variant="body2" component="div">
                                                                                            Processing since {moment(q.sync_queue_updated_datetime).format("HH:mm:ss")}
                                                                                        </Typography>
                                                                                    ))}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </Box>
                                                </React.Fragment>
                                            )) || (
                                                <Typography variant="caption">
                                                    <em>Never Synchronized</em>
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <React.Fragment>
                                                {(overview.sync.CW.sync_status === "Completed" && <FAIcon icon="check" size={40} style={{ color: "#4CAF50" }} noMargin />) ||
                                                    (overview.sync.CW.sync_status === "Processing" && (
                                                        <LoadingCircle
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                color:
                                                                    moment.duration(moment().diff(overview.sync.CW.sync_created_datetime)).asMinutes() > 15
                                                                        ? "#d9534f"
                                                                        : moment.duration(moment().diff(overview.sync.CW.sync_created_datetime)).asMinutes() > 5
                                                                        ? "#FB8C00"
                                                                        : "#4CAF50",
                                                            }}
                                                        />
                                                    )) ||
                                                    (overview.sync.CW.sync_status === "Cancelled" && (
                                                        <FAIcon icon="times" size={40} style={{ color: "#d9534f" }} noMargin />
                                                    )) || <FAIcon icon="question-circle" size={40} noMargin />}
                                            </React.Fragment>
                                        </Grid>
                                    </Grid>
                                    {overview.sync.CW.sync_status === "Processing" &&
                                        moment.duration(moment().diff(overview.sync.CW.sync_created_datetime)).asMinutes() > 10 && (
                                            <Grid item xs>
                                                <Box textAlign="center" mt={2} pt={2}>
                                                    <Typography variant="subtitle1" className="fw-400 textError">
                                                        The ConnectWise Sync seems to be taking a while...
                                                    </Typography>
                                                    <Box mt={1}>
                                                        <Button variant="contained" className="btn btn-error" size="small" disabled={cancellingSync} onClick={this.cancelSync}>
                                                            <FAIcon icon="times" size={15} buttonPrimary />
                                                            Cancel Sync
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        loggedInStaff: state.staffAuth.staff,
    };
}

export default connect(mapStateToProps)(Dashboard);
