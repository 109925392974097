import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import AdminRouteSwitcher from '../../Routes/AdminRoutes/AdminRouteSwitcher';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { setLastActivePage } from 'Functions/AuthFunctions';
import { handleAppError } from 'Redux/Actions/UI/UI';
import { isTablet } from 'Functions/MiscFunctions';
import { 
    COLOR_MAIN_DASHBOARD,
    COLOR_MAIN_ACCOUNTS,
    COLOR_MAIN_ACTION_CENTER,
    COLOR_MAIN_CONNECTWISE,
    COLOR_MAIN_MS365,
    COLOR_MAIN_MYCLOUD,
    COLOR_MAIN_SETTINGS,
    COLOR_MAIN_SUPPORT_CONTRACTS,
    COLOR_MAIN_VOIP
} from 'ColorSchemes';

const offset = isTablet() ? 45 : 50;

const styles = theme => ({
    pwaBarSpacer: {
        minHeight: 'env(safe-area-inset-top)',
    },
    appBarSpacer: {
        minHeight: isTablet() ? 112.5 : 117.5
    },
    chevronIcon: {
        width: '12px!important', 
        height: '12px!important', 
        marginLeft: 7,
        marginRight: 7
    },
    content: {
        width: '100%',
        height: '100vh',
        paddingBottom: 24,
        overflowX: 'hidden',
        overflowY: 'auto',
        "&::-webkit-scrollbar": { 
            width: "initial!important"
        }
    },
    container: {
        padding: theme.spacing(3),
        paddingBottom: 20
    },
    page: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: 67.5,
        marginTop: offset,
        padding: theme.spacing(1.25, 1, 1, 1),
        background: '#fff',
        borderBottom: '1px solid #ddd'
    },
    [theme.breakpoints.down('sm')]: {
        content: {
            marginLeft: 0
        },
        page: {
            marginLeft: 0
        },
        servicePane: {
            display: 'none'
        }
    },
    [theme.breakpoints.down('xs')]: {
        container: {
            padding: theme.spacing(2.5),
            paddingBottom: 20,
            paddingLeft: '24px!important'
        },
        page: {
            paddingLeft: '24px!important'
        },
        content: {
            marginLeft: 0,
            webkitOverflowScrolling: 'touch',
        }
    },
    // ['@media (orientation: landscape) and (max-height: 767px)']: { // eslint-disable-line no-useless-computed-key
    //     content: {
    //         marginLeft: 'env(safe-area-inset-left)',
    //         marginRight: 'env(safe-area-inset-right)',
    //     },
    //     container: {
    //         padding: theme.spacing(2.5),
    //         paddingLeft: '24px!important'
    //     },
    //     page: {
    //         paddingLeft: '24px!important'
    //     }
    // },
});

const initialState = {
    scrollHeight: window.innerHeight,
    scheduledUpdateColor: '#d9534f',
    scheduledUpdateTime: false,
    scheduledUpdateTimeCountdown: false,
}

class AdminContentWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.countdown = false;
        this.main = null;
    }

    componentDidMount = () => {
        this.setState({
            scrollHeight: this.props.scrollHeight,
            scheduledUpdateTime: this.props.ui.updateNotification
        }, () => {
            if(this.state.scheduledUpdateTime !== false) {
                this.startScheduledUpdateTime();
            }
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.ui.updateNotification !== prevProps.ui.updateNotification) {
            this.setState({
                scheduledUpdateTime: this.props.ui.updateNotification
            }, () => {                
                if(this.state.scheduledUpdateTime !== false) {
                    this.startScheduledUpdateTime();
                }
            });
        }        
        if(this.props.scrollHeight !== prevProps.scrollHeight){
            this.setState({
                scrollHeight: this.props.scrollHeight,
            });
        }
        if(this.props.pagePath !== prevProps.pagePath){
            this.main.scrollTop = 0;
        }
    }

    componentWillUnmount = () => {
        if(this.countdown)          
            clearInterval(this.countdown);
    }
    
    startScheduledUpdateTime = () => {
        this.countdown = setInterval(this.getScheduledUpdateTimeCountdown, 1000);
    }

    getScheduledUpdateTimeCountdown = () => {
        var scheduled = moment(this.state.scheduledUpdateTime, 'X').subtract(10, 'seconds');
        const now = moment();
        if(now.unix() < scheduled.unix()) {
            const countdown = moment(scheduled - now);
            const mins = parseInt(countdown.format('m'));
            const countdownText = mins !== 0 ? countdown.format("m [ minutes] [and] s [ seconds]") : countdown.format("s[ seconds]");
            this.setState({                
                scheduledUpdateColor: mins > 1 ? '#F4511E' : '#d9534f',
                scheduledUpdateTimeCountdown: this.state.updateNotification !== false ? countdownText : ''
            });
        } else {      
            if(this.countdown) {      
                clearInterval(this.countdown);
            }
            setLastActivePage(this.props.location.pathname);
            this.setState({                
                scheduledUpdateTimeCountdown: ''
            }, () => this.props.handleAppError("APP_UPDATE", ""))    
        }
    }

    scrollToTop = () => {
        this.main.scrollTop = 0;
    }
    pageIcon = title => {
        if(title) {
            let icon,
                iconType = 'thin',
                iconColor = '#000';
            switch (title) {      
                case "Dashboard" :
                    icon = "home"; 
                    iconColor = COLOR_MAIN_DASHBOARD;
                break;
                case "Accounts":
                    icon = "coins";
                    iconColor = COLOR_MAIN_ACCOUNTS;
                break;
                case "Action Center":
                    icon = "bell";
                    iconColor = COLOR_MAIN_ACTION_CENTER;
                break;
                case "My Account":
                    icon = "user";
                break;
                case "Microsoft 365":
                    icon = "microsoft";
                    iconType = 'brand';
                    iconColor = COLOR_MAIN_MS365;
                break;
                case "MyCloud":
                    icon = "cloud";
                    iconColor = COLOR_MAIN_MYCLOUD;
                break;
                case "ConnectWise":
                    icon = "users";
                    iconColor = COLOR_MAIN_CONNECTWISE;
                break;
                case "Settings":
                    icon = "cog";
                    iconColor = COLOR_MAIN_SETTINGS;
                break;
                case "Support Contracts":
                    icon = "desktop";
                    iconColor = COLOR_MAIN_SUPPORT_CONTRACTS;
                break;
                case "VOIP and Connectivity":
                    icon = "phone-office";
                    iconColor = COLOR_MAIN_VOIP;
                break;
                default:
                    icon = "exclamation-triangle";
                    iconColor = '#d9534f';
            }
            return (
                <Typography variant="h6" component="div" style={{color: '#000'}}>
                    <FAIcon type={iconType} icon={icon} style={{color: iconColor, width: 45, height: 45}} />
                </Typography>
            );
        }
    }

    pageTitleLink = (link, seperator) => {
        var path;
        switch (link) {  
            case "Dashboard" :
                path = false;
            break;
            case "Acccounts":
                path = "/accounts";
            break;
            case "Action Center":
                path = false;
            break;
            case "ConnectWise" :
                path = '/connectwise';
            break;
            case "Microsoft 365" :
                path = '/ms365';
            break; 
            case "MyCloud" :
                path = '/mycloud';
            break; 
            case "My Account" :
                path = false;
            break;
            case "Settings" : 
                path = false;
            break;
            case "Support Contracts" : 
                path = '/support-contracts';
            break;
            case "VOIP and Connectivity" : 
                path = '/voip';
            break;
            default:
                path = false;
        }
        if(path) {
            return (
                <Typography style={{fontSize: 12, display: 'inline-block', color: '#000'}}> 
                    <Link to={path} style={{
                        color: '#000',
                        textDecoration: 'none'
                    }}>
                        {link}
                        {seperator !== false && (                            
                            <FAIcon icon="chevron-right" style={{color: '#000', width: 12, height: 12, marginLeft: 7}} />
                        )}
                    </Link>
                </Typography>
            )
        } else {
            return (
                <Typography style={{fontSize: 12, display: 'inline-block', color: '#000'}}> 
                    {link}
                    <FAIcon icon="chevron-right" style={{color: '#000', width: 12, height: 12, marginLeft: 7}} />
                </Typography>
            )
        }
    }

    render() {
        const { scrollHeight, scheduledUpdateTime, scheduledUpdateColor, scheduledUpdateTimeCountdown } = this.state;
        const { classes, changePath, page, pageTitle, ui } = this.props;
        const isMobile = this.props.ui.device.isMobile;
        return (
            <>
                <main ref={el => this.main = el} className={classes.content} style={{height: window.matchMedia('(display-mode: standalone)').matches ? `100vh` : `${scrollHeight}px`}}>
                    <div className={classes.pwaBarSpacer} />
                    <div className={classes.appBarSpacer} />
                    <AppBar color="secondary" position="absolute" elevation={0} className={classes.page} style={{paddingLeft: this.props.drawers?.nav ? (ui?.device?.isTablet ? 219 : 259) : 77}}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        {this.pageIcon(page[1])}
                                    </Grid>
                                    <Grid item xs>
                                        <Typography
                                            component="h1"
                                            variant="h6"
                                            color="inherit"
                                            noWrap
                                        >
                                            {page[1]}
                                        </Typography>
                                        <Typography
                                            component="h2"
                                            variant="caption"
                                            color="inherit"
                                            noWrap
                                            className={classes.pageBreadcrumb}
                                        > 
                                            {_.map(page, (el, idx) => {
                                                if(idx >= 1) {                                    
                                                    const seperator = idx !== (page.length - 1) ? true : false;
                                                    if(idx <= page[0]) {
                                                        return (
                                                            <React.Fragment key={`page-${idx}`}>
                                                                {this.pageTitleLink(el, seperator)}
                                                            </React.Fragment>
                                                        )
                                                    } else {
                                                        return (
                                                            <Typography style={{fontSize: 12, display: 'inline-block', color: '#000'}} key={`page-${idx}`}>
                                                                {el}
                                                                {seperator !== false && (
                                                                    <FAIcon icon="chevron-right" style={{color: '#000', width: 12, height: 12, marginLeft: 10}} />
                                                                )}
                                                                {page.length === 2 && (
                                                                    <React.Fragment>
                                                                        <FAIcon icon="chevron-right" style={{color: '#000', width: 12, height: 12, marginLeft: 10}} />
                                                                        Overview
                                                                    </React.Fragment>
                                                                )}
                                                            </Typography>
                                                        )
                                                    }
                                                } else {                                            
                                                    return (                                                    
                                                        <FAIcon key={`page-${idx}`} icon="chevron-right" style={{color: '#000', width: 12, height: 12, marginLeft: 2}} />
                                                    )
                                                } 
                                            })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {scheduledUpdateTime !== false && scheduledUpdateTimeCountdown !== false && (
                                <Grid item className={classes.servicePane} md={5} align="right" style={{cursor: 'pointer'}}>
                                    <Grid container spacing={2} alignItems='center'>
                                        <Grid item xs />
                                        <Grid item style={{paddingTop: 7.5}}>
                                            <FAIcon type="thin" icon='exclamation-triangle' size={isMobile ? 'small' : ''} style={{color: scheduledUpdateColor}} noMargin /> 
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='caption' style={{color : scheduledUpdateColor}}>
                                                {(scheduledUpdateTimeCountdown !== '' && ( 
                                                    <>
                                                        {`An update ${!isMobile ? `for BlueSync ` : ``} will begin in ${scheduledUpdateTimeCountdown} ${(!isMobile && (` - please save your work before the update starts!`)) || (``)}`}
                                                    </>
                                                )) || (
                                                    <>
                                                        An update for BlueSync is starting now...
                                                    </>
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </AppBar>
                    <div className={classes.container} style={{paddingLeft: this.props.drawers?.nav ? (ui?.device?.isTablet ? 219 : 259) : 77}}>
                        <AdminRouteSwitcher changePath={changePath} pageTitle={pageTitle} scrollToTop={this.scrollToTop} deploying={this.props.deploying} deploymentId={this.props.deploymentId}/>
                    </div>
                </main>
            </>
        );
    }
}

function mapStateToProps(state){
    return {
        notifications: state.notifications,
        ui: state.ui
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminContentWrapper));