import React  from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

export const SmallFormDialog = props => (
    <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth={(props.maxWidth && props.maxWidth) || 'sm'}
        open={props.open}
        fullWidth={true}
        scroll="body"
    >
        <DialogTitle disableTypography>
            <Typography color="primary" variant="h6">
                {props.title}
            </Typography>
        </DialogTitle>
        <DialogContent style={{padding: '0 24px 24px 24px'}}>
            {props.content}
        </DialogContent>
    </Dialog>
)