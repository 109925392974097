import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = {
    isLoading: true,
    results: {},
    company: '',
    companies: {},
    sync: '',
    allSyncs: {},
    allSyncsList: {},
    systemArea: '',
    systemAreaOptions: [
        {
            label: 'MyCloud',
            value: 'MC'
        },
        {
            label: 'Microsoft 365',
            value: 'MS365'
        },
        {
            label: 'VOIP & Connectivity',
            value: 'VOIP'
        },
        {
            label: 'Support Contracts',
            value: 'SC'
        },
    ]
}
 
class CWHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {        
        this.props.pageTitle([1, 'ConnectWise', 'Sync History'])  
        this.loadComponentData()
    }

    loadComponentData = () => {
        Promise.all([
            API.get('/connectwise/companyList'),
            API.get('/bluesync/synchronization/all')
        ])
        .then(([companiesResult, syncResults]) => {
            let groups = [];
            _.map(syncResults.data, (el) => {
                let syncAreas = el.queue.map(q => q.sync_queue_task).join(', ')
                const opt = {
                    value: el.sync_id,
                    label: `${moment(el.sync_created_datetime).format('DD/MM/YYYY [at] HH:mm')} (${el.sync_all ? 'All' : syncAreas})`
                }
                const ym = moment(el.sync_created_datetime).format('MMMM YYYY')
                if(_.findIndex(groups, grp => grp.label === ym) === -1) {
                    groups.push({
                        label: ym,
                        options: []
                    })
                }
                groups[_.findIndex(groups, grp => grp.label === ym)].options.push(opt);
            })
            this.setState({
                isLoading: false,
                companies: _.map(companiesResult.data, (el) => {
                    return _.assign({
                        value: el.cust_id,
                        label: el.cust_name
                    });
                }),
                allSyncs: syncResults.data,
                allSyncsList: groups
            }, 
            () => {
                this.getSync()
            })
        })
    }  

    getSync = () => {
        this.state.sync && this.setState({
            isLoading: true
        },
        () => {
            API.get('/bluesync/synchronization/' + this.state.sync, {
                params: {
                    company: this.state.company,
                    systemArea: this.state.systemArea
                }
            })
            .then(result => {
                let results = []
                result.data?.history && _.map(result.data.history, h => {
                    const syncData = JSON.parse(h.cwaah_cw_data)
                    const currentData = JSON.parse(h.current_addition.cwaa_cw_data)
                    const addition = h.current_addition;
                    const agreement = addition.agreement
                    const customer = agreement.customer;
                    if(_.findIndex(results, c => c.customer.id === customer.cust_id) === -1) {
                        results.push({
                            customer: {
                                id: customer.cust_id,
                                name: customer.cust_name,
                                accNo: customer.cust_account_number,
                                lastSync: customer.cust_last_sync_datetime
                            },
                            mappings: []
                        })
                    }
                    const mapping = {
                        agreement: {
                            cwaId: agreement.cwa_id,
                            name: agreement.cwa_cw_agreement_name
                        },
                        addition: {
                            cwaaId: addition.cwaa_id,
                            name: addition.cwaa_cw_product_identifier
                        },
                        syncData: {
                            qty: syncData.quantity,
                            unitCost: syncData.unitCost,
                            unitPrice: syncData.unitPrice,
                            totalCost: parseFloat(syncData.unitCost * syncData.quantity).toFixed(2),
                            totalPrice: parseFloat(syncData.unitPrice * syncData.quantity).toFixed(2)
                        },
                        currentData: {
                            qty: currentData.quantity,
                            unitCost: currentData.unitCost,
                            unitPrice: currentData.unitPrice,
                            totalCost: parseFloat(currentData.unitCost * currentData.quantity).toFixed(2),
                            totalPrice: parseFloat(currentData.unitPrice * currentData.quantity).toFixed(2)
                        }
                    }
                    results[_.findIndex(results, c => c.customer.id === customer.cust_id)].mappings.push(mapping);                    
                })
                this.setState({
                    isLoading: false,
                    results
                })
            });
        })
    }
    
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption ? selectedOption.value : ''
        },
        () => this.getSync());
    };

    render() {
        const { isLoading, results, company, companies, sync, allSyncs, allSyncsList, systemArea, systemAreaOptions } = this.state;
        const selectedSync = _.find(allSyncs, s => s.sync_id === sync)
        const mostRecentSync = _.first(allSyncs)
        return (
            <Grid container spacing={3}>                
                {(isLoading && (
                    <Grid item xs={12} lg={6}>
                        <LoadingCircle />
                    </Grid>
                )) || (   
                    <Grid item xs={12}>
                        <Grid container spacing={3}>     
                            <Grid item xs={12}> 
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={3}>
                                        <AutoCompleteSelect 
                                            options={allSyncsList}
                                            placeholder='Sync'
                                            onChange={this.handleSelectChange('sync')}
                                            value={sync}
                                            margin="none"
                                            variant="filled"
                                            isGrouped={true}
                                            noDefaultSort
                                            adornment="filter"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <AutoCompleteSelect 
                                            options={companies}
                                            placeholder='Company'
                                            onChange={this.handleSelectChange('company')}
                                            value={company}
                                            margin="none"
                                            variant="filled"
                                            adornment="filter"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <AutoCompleteSelect 
                                            options={systemAreaOptions}
                                            placeholder='System Area'
                                            onChange={this.handleSelectChange('systemArea')}
                                            value={systemArea}
                                            variant="filled"
                                            adornment="filter"
                                        />
                                    </Grid>
                                </Grid>  
                            </Grid>  
                            <Grid item xs={8}>
                                {!_.isEmpty(results) > 0 && (
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            {_.map(results, result => {
                                                const cust = result.customer
                                                const mappings = result.mappings
                                                return (
                                                    <PaddedPaper>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h5">
                                                                    {cust.name} ({cust.accNo})
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography color="textSecondary">
                                                                    Most Recent Connectwise Synchronisation
                                                                </Typography>
                                                                <Typography variant="caption" color="textSecondary">
                                                                    {moment(mostRecentSync.sync_created_datetime).format('DD/MM/YYYY [at] HH:mm')}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <DataTable
                                                                    style={{
                                                                        marginBottom: 24
                                                                    }}
                                                                    config={{
                                                                        key: 'addition.cwaaId',
                                                                        isLoading
                                                                        // title: 'ConnectWise Log',
                                                                    }}
                                                                    columns={[
                                                                        {
                                                                            heading: 'Agreement',
                                                                            field: rowData => rowData.agreement.name,
                                                                            sizeToContent: true,
                                                                        },
                                                                        {
                                                                            heading: 'Addition',
                                                                            field: rowData => rowData.addition.name,
                                                                            sizeToContent: true,
                                                                        },
                                                                        {
                                                                            heading: 'Current Qty',
                                                                            field: rowData => rowData.currentData.qty,
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'Current Cost',
                                                                            field: rowData => '£' + rowData.currentData.unitCost,
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'Current Price',
                                                                            field: rowData => '£' + rowData.currentData.unitCost,
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'Current T. Cost',
                                                                            field: rowData => '£' + rowData.currentData.totalCost,
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'Current T. Price',
                                                                            field: rowData => '£' + rowData.currentData.totalPrice,
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                    ]}
                                                                    rows={mappings}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography color="textSecondary">
                                                                    Previous Synchronisation
                                                                </Typography>
                                                                <Typography variant="caption" color="textSecondary">
                                                                    {moment(selectedSync.sync_created_datetime).format('DD/MM/YYYY [at] HH:mm')}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <DataTable
                                                                    config={{
                                                                        key: 'addition.cwaaId',
                                                                        isLoading,
                                                                        // title: 'ConnectWise Log',
                                                                    }}
                                                                    columns={[
                                                                        {
                                                                            heading: 'Agreement',
                                                                            field: rowData => rowData.agreement.name,
                                                                            sizeToContent: true,
                                                                        },
                                                                        {
                                                                            heading: 'Addition',
                                                                            field: rowData => rowData.addition.name,
                                                                            sizeToContent: true,
                                                                        },
                                                                        {
                                                                            heading: 'Sync Qty',
                                                                            field: rowData => rowData.syncData.qty,
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'Sync Cost',
                                                                            field: rowData => '£' + rowData.syncData.unitCost,
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'Sync Price',
                                                                            field: rowData => '£' + rowData.syncData.unitCost,
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'Sync T. Cost',
                                                                            field: rowData => '£' + rowData.syncData.totalCost,
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'Sync T. Price',
                                                                            field: rowData => '£' + rowData.syncData.totalPrice,
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                    ]}
                                                                    rows={mappings}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography color="textSecondary">
                                                                    Comparison
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <DataTable
                                                                    style={{
                                                                        marginBottom: 24
                                                                    }}
                                                                    config={{
                                                                        key: 'addition.cwaaId',
                                                                        isLoading,
                                                                        // title: 'ConnectWise Log',
                                                                    }}
                                                                    columns={[
                                                                        {
                                                                            heading: 'Agreement',
                                                                            field: rowData => rowData.agreement.name,
                                                                            sizeToContent: true,
                                                                        },
                                                                        {
                                                                            heading: 'Addition',
                                                                            field: rowData => rowData.addition.name,
                                                                            sizeToContent: true,
                                                                        },
                                                                        {
                                                                            heading: 'Qty Diff',
                                                                            field: rowData => {
                                                                                const diff = parseFloat(rowData.currentData.qty - rowData.syncData.qty).toFixed(2);
                                                                                const color = parseFloat(diff) < 0 ? 'rgb(67, 160, 71)' : (parseFloat(diff) !== 0 ? '#f44336' : '#e4e4e4')
                                                                                const icon = parseFloat(diff) > 0 ? 'long-arrow-up' : (parseFloat(diff) !== 0 ? 'long-arrow-down' : 'empty')
                                                                                return (
                                                                                    <React.Fragment>
                                                                                        <Typography style={{color}}>
                                                                                            <FAIcon icon={icon} style={{color}} size='small' />
                                                                                            {parseInt(rowData.currentData.qty - rowData.syncData.qty)}
                                                                                        </Typography>
                                                                                    </React.Fragment>
                                                                                )
                                                                            },
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'Cost Diff',
                                                                            field: rowData => {
                                                                                const diff = parseFloat(rowData.currentData.unitCost - rowData.syncData.unitCost).toFixed(2);
                                                                                const color = parseFloat(diff) < 0 ? 'rgb(67, 160, 71)' : (parseFloat(diff) !== 0 ? '#f44336' : '#e4e4e4')
                                                                                const icon = parseFloat(diff) > 0 ? 'long-arrow-up' : (parseFloat(diff) !== 0 ? 'long-arrow-down' : 'empty')
                                                                                return (
                                                                                    <React.Fragment>
                                                                                        <Typography style={{color}}>
                                                                                            <FAIcon icon={icon} style={{color}} size='small' />
                                                                                            {'£' + parseFloat(rowData.currentData.unitCost - rowData.syncData.unitCost).toFixed(2)}
                                                                                        </Typography>
                                                                                    </React.Fragment>
                                                                                )
                                                                            },
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'Price Diff',
                                                                            field: rowData => {
                                                                                const diff = parseFloat(rowData.currentData.unitPrice - rowData.syncData.unitPrice).toFixed(2);
                                                                                const color = parseFloat(diff) < 0 ? 'rgb(67, 160, 71)' : (parseFloat(diff) !== 0 ? '#f44336' : '#e4e4e4')
                                                                                const icon = parseFloat(diff) > 0 ? 'long-arrow-up' : (parseFloat(diff) !== 0 ? 'long-arrow-down' : 'empty')
                                                                                return (
                                                                                    <React.Fragment>
                                                                                        <Typography style={{color}}>
                                                                                            <FAIcon icon={icon} style={{color}} size='small' />
                                                                                            {'£' + parseFloat(rowData.currentData.unitPrice - rowData.syncData.unitPrice).toFixed(2)}
                                                                                        </Typography>
                                                                                    </React.Fragment>
                                                                                )
                                                                            },
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'T. Cost Diff',
                                                                            field: rowData => {
                                                                                const diff = parseFloat(rowData.currentData.totalCost - rowData.syncData.totalCost).toFixed(2);
                                                                                const color = parseFloat(diff) < 0 ? 'rgb(67, 160, 71)' : (parseFloat(diff) !== 0 ? '#f44336' : '#e4e4e4')
                                                                                const icon = parseFloat(diff) > 0 ? 'long-arrow-up' : (parseFloat(diff) !== 0 ? 'long-arrow-down' : 'empty')
                                                                                return (
                                                                                    <React.Fragment>
                                                                                        <Typography style={{color}}>
                                                                                            <FAIcon icon={icon} style={{color}} size='small' />
                                                                                            {'£' + parseFloat(rowData.currentData.totalCost - rowData.syncData.totalCost).toFixed(2)}
                                                                                        </Typography>
                                                                                    </React.Fragment>
                                                                                )
                                                                            },
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'T. Price Diff',
                                                                            field: rowData => {
                                                                                const diff = parseFloat(rowData.currentData.totalPrice - rowData.syncData.totalPrice).toFixed(2);
                                                                                const color = parseFloat(diff) > 0 ? 'rgb(67, 160, 71)' : (parseFloat(diff) !== 0 ? '#f44336' : '#e4e4e4')
                                                                                const icon = parseFloat(diff) > 0 ? 'long-arrow-up' : (parseFloat(diff) !== 0 ? 'long-arrow-down' : 'empty')
                                                                                return (
                                                                                    <React.Fragment>
                                                                                        <Typography style={{color}}>
                                                                                            <FAIcon icon={icon} style={{color}} size='small' />
                                                                                            {'£' + parseFloat(rowData.currentData.totalPrice - rowData.syncData.totalPrice).toFixed(2)}
                                                                                        </Typography>
                                                                                    </React.Fragment>
                                                                                )
                                                                            },
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                    ]}
                                                                    rows={mappings}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </PaddedPaper>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>           
                        </Grid>      
                    </Grid>   
                )}
            </Grid>
        )
    }
}

export default CWHistory;