import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import API from "API";
import DataTable from "Components/Common/DataTables/DataTable";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import DragFileInputMultiple from "Components/Common/Inputs/DragFileInputMultiple";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import LoadingScreen from "Components/Common/LoadingScreen/LoadingScreen";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import { handleAppError } from "Redux/Actions/UI/UI";
import { formatValidationErrors } from "../../../Helpers/ErrorHelper";

const BsStepConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    active: {
        "& $line": {
            borderColor: "#784af4",
        },
    },
    completed: {
        "& $line": {
            borderColor: "#784af4",
        },
    },
    line: {
        borderColor: "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useBsStepIconStyles = makeStyles({
    root: {
        color: "#eaeaf0",
        display: "flex",
        height: 22,
        alignItems: "center",
    },
    active: {
        color: "#784af4",
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor",
    },
    completed: {
        color: "#784af4",
        zIndex: 1,
        width: 15,
        height: 15,
    },
});

function BsStepIcon(props) {
    const classes = useBsStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <FAIcon icon="check" className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

const styles = (theme) => ({
    backButton: {
        marginRight: theme.spacing(1),
    },
    stepper: {
        padding: 0,
        backgroundColor: "transparent!important",
    },
    stepperInstruction: {
        marginBottom: theme.spacing(1),
    },
});

const initialState = {
    additionList: {},
    blocked: false,
    companyList: [],
    currentStep: 0,
    data: [],
    dialog: false,
    dialogParentIdx: 0,
    dialogMissing: false,
    dialogMissingAdditionIdx: 0,
    dialogServiceIdx: 0,
    formData: {
        servicesCsv: "",
        chargesCsv: "",
        data: {},
        missing: {},
        missingCharges: {},
    },
    formErrors: [],
    importId: 0,
    quantityOptions: [],
    missing: [],
    missingCharges: [],
    isLoading: true,
    isLoadingScreen: false,
};

class VoipImport extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount = () => {
        this.props.pageTitle([1, "VOIP and Connectivity", "Data Import"]);
        this.loadComponentData();
    };
    loadComponentData = () => {
        Promise.all([API.get("/voip/import/status"), API.get("/voip/companies", { params: { withAdditions: true } })]).then(([status, voipCompanies]) => {
            if (voipCompanies.data) {
                let additionList = {
                    [-1]: [
                        {
                            label: "BlueSync Actions",
                            options: [
                                {
                                    value: -1,
                                    label: "Ignore",
                                },
                            ],
                        },
                    ],
                };
                let companyList = [
                    {
                        label: "VOIP Companies",
                        options: _.map(voipCompanies.data, (el) => {
                            return _.assign({
                                label: el.voip_name,
                                value: el.voip_id,
                            });
                        }),
                    },
                ];
                companyList.unshift({
                    label: "BlueSync Actions",
                    options: [
                        {
                            value: -1,
                            label: "Ignore",
                        },
                    ],
                });
                voipCompanies.data.forEach((comp) => {
                    if (comp.voip_type === "Customer") {
                        additionList = {
                            ...additionList,
                            [comp.voip_id]: [
                                {
                                    label: "BlueSync Actions",
                                    options: [
                                        {
                                            value: -1,
                                            label: "Ignore",
                                        },
                                    ],
                                },
                            ],
                        };
                        if (!_.isEmpty(comp.connectwise_company.agreements)) {
                            comp.connectwise_company.agreements.forEach((agreement) => {
                                if (!_.isEmpty(agreement.additions)) {
                                    additionList = {
                                        ...additionList,
                                        [comp.voip_id]: [
                                            ...additionList[comp.voip_id],
                                            {
                                                label: agreement.cwa_cw_agreement_name,
                                                options: _.map(agreement.additions, (el) => {
                                                    return _.assign({
                                                        value: el.cwaa_id,
                                                        label: el.cwaa_cw_product_identifier,
                                                    });
                                                }),
                                            },
                                        ],
                                    };
                                }
                            });
                        }
                    } else {
                        additionList = {
                            ...additionList,
                            [comp.voip_id]: [
                                {
                                    label: "BlueSync Actions",
                                    options: [
                                        {
                                            value: -1,
                                            label: "Internal Billing",
                                        },
                                    ],
                                },
                            ],
                        };
                    }
                });
                let quantityOptions = [];
                for (let i = 1, j = 50; i <= j; i++) {
                    quantityOptions.push({
                        label: i.toString(),
                        value: i,
                    });
                }
                this.setState({
                    additionList,
                    companyList,
                    quantityOptions,
                    blocked: status.data && status.data.blocked,
                    isLoading: false,
                });
            }
        });
    };
    handleCompanySelection = (parentIdx, serviceIdx, voipId) => {
        voipId = (voipId && voipId.value) || 0;
        this.setState({
            data: {
                ...this.state.data,
                [parentIdx]: {
                    ...this.state.data[parentIdx],
                    voip_services: {
                        ...this.state.data[parentIdx].voip_services,
                        [serviceIdx]: {
                            ...this.state.data[parentIdx].voip_services[serviceIdx],
                            voip_service_voip_id: voipId,
                            addition:
                                voipId === -1
                                    ? -1
                                    : this.state.additionList[voipId].length === 1
                                    ? (this.state.additionList[voipId] &&
                                          this.state.additionList[voipId][0] &&
                                          this.state.additionList[voipId][0].options &&
                                          this.state.additionList[voipId][0].options[0] &&
                                          this.state.additionList[voipId][0].options[0].value) ||
                                      0
                                    : 0,
                        },
                    },
                },
            },
        });
    };
    handleAdditionSelection = (parentIdx, serviceIdx, additionId) => {
        additionId = (additionId && additionId.value) || 0;
        this.setState({
            data: {
                ...this.state.data,
                [parentIdx]: {
                    ...this.state.data[parentIdx],
                    voip_services: {
                        ...this.state.data[parentIdx].voip_services,
                        [serviceIdx]: {
                            ...this.state.data[parentIdx].voip_services[serviceIdx],
                            addition: additionId,
                        },
                    },
                },
            },
        });
    };
    handleChargeAllocation = (parentIdx, serviceIdx) => {
        this.setState({
            data: {
                ...this.state.data,
                [parentIdx]: {
                    ...this.state.data[parentIdx],
                    voip_charges_calls: {
                        ...this.state.data[parentIdx].voip_charges_calls,
                        voip_charges_map_idx: serviceIdx,
                    },
                },
            },
        });
    };
    handleFileChange = (drop, name, files) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: Array.prototype.slice.call(this.state.formData?.[name] ?? []).concat(Array.prototype.slice.call(files)),
            },
        });
    };
    handleFileClear = (field, idx) => {
        let newArr = Array.from(this.state.formData?.[field] ?? []);
        newArr.splice(idx, 1);
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: newArr,
            },
        });
    };

    handleMissingChargeAllocation = (serviceIdx, voipCustId) => {
        voipCustId = (voipCustId && voipCustId.value) || -1;
        this.setState({
            missingCharges: {
                ...this.state.missingCharges,
                [serviceIdx]: {
                    ...this.state.missingCharges[serviceIdx],
                    service_voip_id: voipCustId,
                },
            },
        });
    };
    handleStatusChange = (idx, status) => {
        status = (status && status.value) || "Active";
        this.setState({
            missing: {
                ...this.state.missing,
                [idx]: {
                    ...this.state.missing[idx],
                    voip_service_action: status,
                },
            },
        });
    };
    handleMissingChargeQuantity = (additionIdx, chargeIdx, qty) => {
        let missing = this.state.missing;
        missing = {
            ...missing,
            [additionIdx]: {
                ...missing[additionIdx],
                service_charges: {
                    ...missing[additionIdx].service_charges,
                    [chargeIdx]: {
                        ...missing[additionIdx].service_charges[chargeIdx],
                        voip_sc_quantity: (qty && qty.value) || 1,
                    },
                },
            },
        };
        this.setState({
            missing,
        });
    };
    handleMissingChargeBillable = (additionIdx, chargeIdx) => {
        let missing = this.state.missing;
        missing = {
            ...missing,
            [additionIdx]: {
                ...missing[additionIdx],
                service_charges: {
                    ...missing[additionIdx].service_charges,
                    [chargeIdx]: {
                        ...missing[additionIdx].service_charges[chargeIdx],
                        voip_sc_status: missing[additionIdx].service_charges[chargeIdx].voip_sc_status === "New" ? "NewIgnored" : "New",
                    },
                },
            },
        };
        this.setState({
            missing,
        });
    };
    handleMissingCharges = (dialogMissing, dialogMissingAdditionIdx) => {
        let missing = this.state.missing;
        let billable = 0;
        if (!dialogMissing) {
            let arr = missing[this.state.dialogMissingAdditionIdx].service_charges;
            for (var i = 0, total = _.size(arr); i < total; i++) {
                if (arr[i].voip_sc_status === "New") {
                    billable = billable + parseInt(arr[i].voip_sc_quantity);
                }
            }
            missing[this.state.dialogMissingAdditionIdx] = {
                ...missing[this.state.dialogMissingAdditionIdx],
                voip_service_billable: billable,
            };
        }
        this.setState({
            missing,
            dialogMissing,
            dialogMissingAdditionIdx,
        });
    };
    handleServiceCharges = (dialog, dialogParentIdx, dialogServiceIdx) => {
        let { data } = this.state;
        let billable = 0;
        if (!dialog) {
            let arr = data[this.state.dialogParentIdx].voip_services[this.state.dialogServiceIdx].voip_charges_services;
            for (var i = 0, total = _.size(arr); i < total; i++) {
                if (arr[i].voip_sc_status === "New") {
                    billable = billable + parseInt(arr[i].voip_sc_quantity);
                }
            }
            data[this.state.dialogParentIdx] = {
                ...data[this.state.dialogParentIdx],
                voip_services: {
                    ...data[this.state.dialogParentIdx].voip_services,
                    [this.state.dialogServiceIdx]: {
                        ...data[this.state.dialogParentIdx].voip_services[this.state.dialogServiceIdx],
                        service_charges_billable: billable,
                    },
                },
            };
        }
        this.setState({
            data,
            dialog,
            dialogParentIdx,
            dialogServiceIdx,
        });
    };
    handleServiceChargeQuantity = (parentIdx, serviceIdx, chargeIdx, qty) => {
        let { data } = this.state;
        data = {
            ...data,
            [parentIdx]: {
                ...data[parentIdx],
                voip_services: {
                    ...data[parentIdx].voip_services,
                    [serviceIdx]: {
                        ...data[parentIdx].voip_services[serviceIdx],
                        voip_charges_services: {
                            ...data[parentIdx].voip_services[serviceIdx].voip_charges_services,
                            [chargeIdx]: {
                                ...data[parentIdx].voip_services[serviceIdx].voip_charges_services[chargeIdx],
                                voip_sc_quantity: (qty && qty.value) || data[parentIdx].voip_services[serviceIdx].voip_charges_services[chargeIdx].voip_sc_quantity_original,
                            },
                        },
                    },
                },
            },
        };
        this.setState({
            data,
        });
    };
    handleServiceChargeBillable = (parentIdx, serviceIdx, chargeIdx) => {
        let { data } = this.state;
        data = {
            ...data,
            [parentIdx]: {
                ...data[parentIdx],
                voip_services: {
                    ...data[parentIdx].voip_services,
                    [serviceIdx]: {
                        ...data[parentIdx].voip_services[serviceIdx],
                        voip_charges_services: {
                            ...data[parentIdx].voip_services[serviceIdx].voip_charges_services,
                            [chargeIdx]: {
                                ...data[parentIdx].voip_services[serviceIdx].voip_charges_services[chargeIdx],
                                voip_sc_status: data[parentIdx].voip_services[serviceIdx].voip_charges_services[chargeIdx].voip_sc_status === "New" ? "NewIgnored" : "New",
                            },
                        },
                    },
                },
            },
        };
        this.setState({
            data,
        });
    };
    getPrettyImportStatus = (condition) => {
        switch (condition) {
            case "AutoIgnored":
                return "Ignored";
            case "Ignored":
                return "Ignored";
            case "Internal":
                return "Internal";
            default:
                return condition;
        }
    };
    getConditionColor = (condition) => {
        switch (condition) {
            case "AutoIgnored":
                return "#03A9F4";
            case "Ignored":
                return "#03A9F4";
            case "Internal":
                return "#5E35B1";
            case "Mapped":
                return "#4CAF50";
            case "Unmapped":
                return "#d9534f";
            default:
                return "#000";
        }
    };
    getSteps = () => {
        return ["Upload CSV Files", "Missing Services", "Unassigned Charges", "Service Mapping", "Completed"];
    };
    handleNext = () => {
        if (this.state.currentStep === 0) {
            this.setState(
                {
                    isLoading: true,
                },
                () => {
                    let formData = new FormData();
                    this.state.formData.servicesCsv.forEach((file, index) => {
                        formData.append(`servicesCsv[${index}]`, file);
                    });
                    this.state.formData.chargesCsv.forEach((file, index) => {
                        formData.append(`chargesCsv[${index}]`, file);
                    });
                    API.post("/voip/import", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }).then((res) => {
                        if (res.data.errors) {
                            this.setState(
                                {
                                    formErrors: formatValidationErrors(res.data.errors),
                                    isLoading: false,
                                },
                                () => {
                                    this.props.handleAppError("VOIP_FILES", this.state.formErrors["generic"]);
                                }
                            );
                        } else {
                            let dataTable = [];
                            let missingTable = [];
                            let data = res.data.data;
                            let missing = res.data.missing;
                            let missingCharges = res.data.missingCharges;
                            _.each(data, (row, idx) => {
                                _.each(row.voip_services, (src, sidx) => {
                                    dataTable.push({
                                        ...src,
                                        parentIdx: idx,
                                        serviceIdx: sidx,
                                    });
                                });
                            });
                            _.each(missing, (row, idx) => {
                                missingTable.push({
                                    ...row,
                                    idx: idx,
                                });
                            });
                            this.setState(
                                {
                                    data,
                                    dataTable,
                                    missing,
                                    missingTable,
                                    missingCharges,
                                    currentStep: this.state.currentStep + 1,
                                    isLoading: false,
                                },
                                () => {
                                    this.setState({
                                        formData: {
                                            ...initialState.formData,
                                            data: this.state.data,
                                            missing: this.state.missing,
                                            missingCharges: this.state.missingCharges,
                                        },
                                    });
                                }
                            );
                        }
                    });
                }
            );
        } else if (this.state.currentStep === 3) {
            let errors = false;
            _.each(this.state.data, (d) => {
                if (d.voip_charges_calls && d.voip_charges_calls.voip_charges_map_idx === -1) {
                    errors = true;
                }
                _.each(d.voip_services, (service) => {
                    if (service.voip_service_voip_id === 0) {
                        errors = true;
                    }
                });
            });
            if (!errors) {
                this.setState(
                    {
                        isLoadingScreen: true,
                        formData: {
                            ...initialState.formData,
                            data: this.state.data,
                            missing: this.state.missing,
                            missingCharges: this.state.missingCharges,
                        },
                    },
                    () => {
                        API.post("/voip/import/data", this.state.formData).then((res) => {
                            if (res.data) {
                                if (res.data.errors) {
                                    this.setState(
                                        {
                                            isLoadingScreen: false,
                                        },
                                        () => {
                                            this.props.handleAppError("FORM_ERROR", "");
                                        }
                                    );
                                } else {
                                    this.setState(
                                        {
                                            currentStep: this.state.currentStep + 1,
                                            importId: res.data.import,
                                            isLoadingScreen: false,
                                        },
                                        () => {
                                            this.props.deploySnackBar("success", "VOIP and Connectivity Data Import Completed Successfully");
                                        }
                                    );
                                }
                            }
                        });
                    }
                );
            } else {
                this.props.handleAppError("FORM_ERROR", "");
            }
        } else {
            this.setState({
                currentStep: this.state.currentStep + 1,
            });
        }
    };
    handleBack = () => {
        this.setState({
            currentStep: this.state.currentStep - 1,
        });
    };
    handleReset = () => {
        this.setState(
            {
                ...initialState,
            },
            () => this.loadComponentData()
        );
    };
    mappingTable = () => {
        const { additionList, companyList, data, dataTable, isLoading } = this.state;
        return (
            <Paper>
                <DataTable
                    config={{
                        key: "voip_id",
                        pagination: true,
                        alternatingRowColours: true,
                        isLoading: isLoading,
                        rowsPerPage: 250,
                        plainHeader: true,
                        title: "VOIP and Connectivity Service Mapping",
                    }}
                    columns={[
                        {
                            heading: "Service Identifier",
                            field: (rowData) => rowData.voip_service_identifier,
                            sizeToContent: true,
                        },
                        {
                            heading: "Service Name",
                            field: (rowData) => rowData.voip_service_name,
                            sizeToContent: true,
                        },
                        {
                            heading: "Service Charges",
                            field: (rowData) => _.size(rowData.voip_charges_services),
                            sizeToContent: true,
                            alignment: "center",
                            cellProps: (rowData) => {
                                return {
                                    style: {
                                        textDecoration:
                                            _.size(rowData.voip_charges_services) !== data[rowData.parentIdx]["voip_services"][rowData.serviceIdx].service_charges_billable &&
                                            "line-through",
                                    },
                                };
                            },
                        },
                        {
                            heading: "Billable",
                            field: (rowData) => (
                                <React.Fragment>
                                    {data[rowData.parentIdx]["voip_services"][rowData.serviceIdx].service_charges_billable}
                                    <IconButton style={{ marginLeft: 8 }} onClick={() => this.handleServiceCharges(true, rowData.parentIdx, rowData.serviceIdx)}>
                                        <FAIcon
                                            icon="cog"
                                            style={{ color: data[rowData.parentIdx]["voip_services"][rowData.serviceIdx].service_charges_billable > 1 && "#c62828" }}
                                            noMargin
                                            button
                                        />
                                    </IconButton>
                                </React.Fragment>
                            ),
                            cellProps: (rowData) => {
                                return {
                                    style: {
                                        color: data[rowData.parentIdx]["voip_services"][rowData.serviceIdx].service_charges_billable > 1 && "#c62828",
                                    },
                                };
                            },
                            sizeToContent: true,
                            alignment: "center",
                        },
                        {
                            heading: "Status",
                            field: (rowData) => this.getPrettyImportStatus(rowData.type),
                            sizeToContent: true,
                            cellProps: (rowData) => {
                                return {
                                    style: {
                                        color: this.getConditionColor(rowData.type),
                                    },
                                };
                            },
                        },
                        {
                            heading: "VOIP Company",
                            field: (rowData) => (
                                <FormControl style={{ minWidth: 275 }} fullWidth>
                                    <AutoCompleteSelect
                                        options={companyList}
                                        noClear
                                        isGrouped={true}
                                        label="Mapped To:"
                                        onChange={(v) => this.handleCompanySelection(rowData.parentIdx, rowData.serviceIdx, v)}
                                        error={data[rowData.parentIdx]["voip_services"][rowData.serviceIdx].voip_service_voip_id === 0 && true}
                                        value={data[rowData.parentIdx]["voip_services"][rowData.serviceIdx].voip_service_voip_id}
                                    />
                                </FormControl>
                            ),
                            sizeToContent: true,
                        },
                        {
                            heading: "Addition",
                            field: (rowData) => (
                                <FormControl style={{ minWidth: 275 }} fullWidth>
                                    <AutoCompleteSelect
                                        options={
                                            (data[rowData.parentIdx]["voip_services"][rowData.serviceIdx].voip_service_voip_id !== 0 &&
                                                additionList[data[rowData.parentIdx]["voip_services"][rowData.serviceIdx].voip_service_voip_id]) ||
                                            []
                                        }
                                        isGrouped={true}
                                        noClear
                                        label="Mapped To:"
                                        onChange={(v) => this.handleAdditionSelection(rowData.parentIdx, rowData.serviceIdx, v)}
                                        error={data[rowData.parentIdx]["voip_services"][rowData.serviceIdx].addition === 0 && true}
                                        value={data[rowData.parentIdx]["voip_services"][rowData.serviceIdx].addition}
                                        disabled={data[rowData.parentIdx]["voip_services"][rowData.serviceIdx].voip_service_voip_id === 0 && true}
                                    />
                                </FormControl>
                            ),
                            sizeToContent: true,
                        },
                        {
                            heading: "Total Call Charges",
                            field: (rowData) =>
                                (data[rowData.parentIdx].voip_charges_calls &&
                                    `${(data[rowData.parentIdx].voip_charges_calls.charges_total < 0 && "-") || ""}£${
                                        data[rowData.parentIdx].voip_charges_calls.charges_total
                                    }`) ||
                                `£0.000`,
                            sizeToContent: true,
                        },
                        {
                            heading: "Assign",
                            field: (rowData) =>
                                (!data[rowData.parentIdx].charges_mapped && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                style={{ color: (data[rowData.parentIdx]["voip_charges_calls"].voip_charges_map_idx === -1 && `#c62828`) || "#375885" }}
                                                onChange={(e) => this.handleChargeAllocation(rowData.parentIdx, rowData.serviceIdx)}
                                                checked={data[rowData.parentIdx]["voip_charges_calls"].voip_charges_map_idx === rowData.serviceIdx ? true : false}
                                            />
                                        }
                                    />
                                )) ||
                                "-",
                            sizeToContent: true,
                        },
                    ]}
                    rows={dataTable}
                />
            </Paper>
        );
    };
    missingTable = () => {
        const { isLoading, missing, missingTable } = this.state;
        const statusOptions = [
            {
                label: "Continue To Bill",
                value: "Billable",
            },
            {
                label: "Terminate Service",
                value: "Terminated",
            },
        ];

        return (
            <Paper>
                <DataTable
                    config={{
                        key: "voip_service_id",
                        pagination: true,
                        alternatingRowColours: true,
                        isLoading: isLoading,
                        rowsPerPage: 250,
                        plainHeader: true,
                        title: "VOIP and Connectivity Missing Services",
                    }}
                    columns={[
                        {
                            heading: "Service Identifier",
                            field: (rowData) => rowData.voip_service_identifier,
                            sizeToContent: true,
                        },
                        {
                            heading: "Service Name",
                            field: (rowData) => rowData.voip_service_name,
                            sizeToContent: true,
                        },
                        {
                            heading: "Action",
                            field: (rowData) => (
                                <FormControl fullWidth>
                                    <AutoCompleteSelect
                                        noClear
                                        options={statusOptions}
                                        onChange={(v) => this.handleStatusChange(rowData.idx, v)}
                                        value={missing[rowData.idx].voip_service_action}
                                    />
                                </FormControl>
                            ),
                            sizeToContent: true,
                        },
                        {
                            heading: "Last Import Service Charges",
                            field: (rowData) => _.size(rowData.service_charges),
                            sizeToContent: true,
                            alignment: "center",
                            cellProps: (rowData) => {
                                return {
                                    style: {
                                        textDecoration:
                                            (missing[rowData.idx].voip_service_action !== "Billable" || _.size(rowData.service_charges) !== rowData.voip_service_billable) &&
                                            "line-through",
                                    },
                                };
                            },
                        },
                        {
                            heading: "Billable",
                            field: (rowData) => (
                                <React.Fragment>
                                    {missing[rowData.idx].voip_service_billable}
                                    <IconButton
                                        style={{ marginLeft: 8 }}
                                        onClick={() => this.handleMissingCharges(true, rowData.rowNumber - 1)}
                                        disabled={missing[rowData.idx].voip_service_action === "Billable" ? false : true}
                                    >
                                        <FAIcon icon="cog" style={{ color: missing[rowData.idx].voip_service_billable > 1 && "#c62828" }} noMargin button />
                                    </IconButton>
                                </React.Fragment>
                            ),
                            cellProps: (rowData) => {
                                return {
                                    style: {
                                        color: missing[rowData.idx].voip_service_billable > 1 && "#c62828",
                                        textDecoration: missing[rowData.idx].voip_service_action !== "Billable" && "line-through",
                                    },
                                };
                            },
                            sizeToContent: true,
                            alignment: "center",
                        },
                        {
                            heading: "VOIP Company",
                            field: (rowData) => (rowData.company && rowData.company.voip_name) || "-",
                            sizeToContent: true,
                        },
                        {
                            heading: "Addition",
                            field: (rowData) =>
                                (rowData.addition && rowData.addition[0] && rowData.addition[0].cwaa_cw_product_identifier) ||
                                (rowData.company && rowData.company.voip_type === "Customer" ? "Ignored" : "Internal"),
                            sizeToContent: true,
                        },
                    ]}
                    rows={missingTable}
                />
            </Paper>
        );
    };
    missingChargesTable = () => {
        const { isLoading, companyList, missingCharges } = this.state;
        return (
            <Paper>
                <DataTable
                    config={{
                        key: "service_idx",
                        pagination: true,
                        alternatingRowColours: true,
                        isLoading: isLoading,
                        rowsPerPage: 100,
                        plainHeader: true,
                        title: "VOIP and Connectivity Unassigned Charges",
                    }}
                    columns={[
                        {
                            heading: "Service Identifier",
                            field: (rowData) => rowData.service_identifier,
                            sizeToContent: true,
                        },
                        {
                            heading: "VOIP Company",
                            field: (rowData) => (
                                <FormControl fullWidth>
                                    <AutoCompleteSelect
                                        noClear
                                        isGrouped
                                        options={companyList}
                                        onChange={(v) => this.handleMissingChargeAllocation(rowData.service_idx, v)}
                                        value={missingCharges[rowData.service_idx].service_voip_id}
                                    />
                                </FormControl>
                            ),
                            sizeToContent: true,
                        },
                        {
                            heading: "Charges",
                            field: (rowData) => `£${missingCharges[rowData.service_idx].service_charges_total}`,
                            sizeToContent: true,
                        },
                    ]}
                    rows={missingCharges}
                />
            </Paper>
        );
    };
    blocked = () => {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} align="center">
                    <PaddedPaper style={{ paddingTop: 50, paddingBottom: 50 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FAIcon type="thin" icon="exclamation-triangle" style={{ color: "#d9534f", width: 100, height: 100 }} size="xxlarge" noMargin />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" className="fw-300">
                                    Outstanding Data Import
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Please finalise the previous data import to continue, this can be achieved by discarding it or by completing the VOIP & Connectivity sync
                                    process.
                                </Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    };
    welcome = () => {
        const { formData, formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} align="center">
                    <PaddedPaper style={{ paddingTop: 50, paddingBottom: 50 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FAIcon type="thin" icon="cloud-upload-alt" style={{ color: "#1565C0", width: 100, height: 100 }} size="xxlarge" noMargin />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" className="fw-300">
                                    VOIP & Connectivity Data Files
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Please drag and drop or select the VOIP & Connectivity Services and Call Charge Data Files for the relevant billing period
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <DragFileInputMultiple
                                    name="servicesCsv"
                                    primary="csv"
                                    label="VOIP & Connectivity Services"
                                    file={formData.servicesCsv}
                                    errorText={formErrors && formErrors["servicesCsv"]}
                                    onChange={this.handleFileChange}
                                    cancelOnClick={(idx) => this.handleFileClear("servicesCsv", idx)}
                                    emptyText="No file selected"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DragFileInputMultiple
                                    name="chargesCsv"
                                    primary="csv"
                                    label="VOIP & Connectivity Call Charges"
                                    file={formData.chargesCsv}
                                    errorText={formErrors && formErrors["chargesCsv"]}
                                    onChange={this.handleFileChange}
                                    cancelOnClick={(idx) => this.handleFileClear("chargesCsv", idx)}
                                    emptyText="No file selected"
                                />
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    };
    completed = () => (
        <Grid container spacing={3}>
            <Grid item xs={12} align="center">
                <PaddedPaper style={{ paddingTop: 50, paddingBottom: 50 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FAIcon type="thin" icon="exclamation-circle" style={{ color: "#6A1B9A", width: 100, height: 100 }} size="xxlarge" noMargin />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" className="fw-300">
                                Billing Review Required
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" paragraph>
                                BlueSync will not process billing for this data import until you have reviewed and accepted it; you can do this now or later in{" "}
                                <i>VOIP & Connectivity > Data Import</i>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" onClick={() => this.props.history.push("/voip/import/" + this.state.importId)} variant="contained" size="large">
                                <FAIcon icon="check" buttonPrimary />
                                Review Now
                            </Button>
                        </Grid>
                    </Grid>
                </PaddedPaper>
            </Grid>
        </Grid>
    );
    nothingToDo = (step) => {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} align="center">
                    <PaddedPaper style={{ paddingTop: 50, paddingBottom: 50 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FAIcon type="thin" icon="check-circle" style={{ color: "#4CAF50", width: 100, height: 100 }} size="xxlarge" noMargin />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" className="fw-300">
                                    Nothing To Do
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">There are no {step} that require your attention</Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    };
    render() {
        const { classes } = this.props;
        const {
            blocked,
            data,
            dialog,
            dialogParentIdx,
            dialogServiceIdx,
            dialogMissing,
            dialogMissingAdditionIdx,
            missing,
            isLoading,
            isLoadingScreen,
            currentStep,
            quantityOptions,
        } = this.state;
        const steps = this.getSteps();
        return (
            <Grid container spacing={1}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Stepper activeStep={currentStep === 4 ? 5 : currentStep} classes={{ root: classes.stepper }} connector={<BsStepConnector />}>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel StepIconComponent={BsStepIcon}>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Grid>
                                <Grid item xs={12}>
                                    {currentStep === 0 && ((blocked && this.blocked()) || this.welcome())}
                                    {currentStep === 1 && ((_.isEmpty(this.state.missing) && this.nothingToDo("Missing Services")) || this.missingTable())}
                                    {currentStep === 2 && ((_.isEmpty(this.state.missingCharges) && this.nothingToDo("Unassigned Charges")) || this.missingChargesTable())}
                                    {currentStep === 3 && this.mappingTable()}
                                    {currentStep === 4 && this.completed()}
                                </Grid>
                                <Grid item xs={12} align="right">
                                    {(currentStep === steps.length && (
                                        <React.Fragment>
                                            <Typography variant="caption" className={classes.stepperInstruction}>
                                                VOIP and Connectivity Data Import Completed
                                            </Typography>
                                            <Button onClick={() => this.handleReset()}>Reset</Button>
                                        </React.Fragment>
                                    )) || (
                                        <React.Fragment>
                                            <React.Fragment>
                                                {(currentStep === 1 && (
                                                    <React.Fragment>
                                                        <Button onClick={() => this.handleReset()} className={classes.backButton}>
                                                            <FAIcon size={15} icon="undo" button />
                                                            Reset
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => this.handleNext()}
                                                            disabled={
                                                                currentStep === 0
                                                                    ? this.state.formData.servicesCsv !== "" && this.state.formData.chargesCsv !== ""
                                                                        ? false
                                                                        : true
                                                                    : false
                                                            }
                                                        >
                                                            <FAIcon
                                                                size={15}
                                                                icon="arrow-right"
                                                                disabled={
                                                                    currentStep === 0
                                                                        ? this.state.formData.servicesCsv !== "" && this.state.formData.chargesCsv !== ""
                                                                            ? false
                                                                            : true
                                                                        : false
                                                                }
                                                                buttonPrimary
                                                            />
                                                            Next
                                                        </Button>
                                                    </React.Fragment>
                                                )) ||
                                                    (currentStep !== 4 && (
                                                        <React.Fragment>
                                                            <Button disabled={currentStep === 0} onClick={() => this.handleBack()} className={classes.backButton}>
                                                                <FAIcon size={15} icon="arrow-left" disabled={currentStep === 0} buttonPrimary />
                                                                Back
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => this.handleNext()}
                                                                disabled={
                                                                    currentStep === 0
                                                                        ? this.state.formData.servicesCsv !== "" && this.state.formData.chargesCsv !== ""
                                                                            ? false
                                                                            : true
                                                                        : false
                                                                }
                                                            >
                                                                <FAIcon
                                                                    size={15}
                                                                    icon="arrow-right"
                                                                    disabled={
                                                                        currentStep === 0
                                                                            ? this.state.formData.servicesCsv !== "" && this.state.formData.chargesCsv !== ""
                                                                                ? false
                                                                                : true
                                                                            : false
                                                                    }
                                                                    buttonPrimary
                                                                />
                                                                Next
                                                            </Button>
                                                        </React.Fragment>
                                                    ))}
                                            </React.Fragment>
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <LoadingScreen open={isLoadingScreen} title="Please Wait" message="BlueSync is processing your VOIP & Connectivity Data Import" />
                        {dialog && (
                            <Dialog open={true} onClose={() => this.handleServiceCharges(false, 0, 0)} maxWidth="md" scroll="body">
                                <DialogTitle>
                                    Service Charges
                                    <br />
                                    <span style={{ fontSize: "12px" }}>
                                        {data[dialogParentIdx].voip_services[dialogServiceIdx].voip_service_identifier} |{" "}
                                        {data[dialogParentIdx].voip_services[dialogServiceIdx].voip_service_name}
                                    </span>
                                </DialogTitle>
                                <Typography component={DialogContent} variant="body2">
                                    <DataTable
                                        config={{
                                            key: "rowNumber",
                                            plainHeader: true,
                                        }}
                                        columns={[
                                            {
                                                heading: "Billing From",
                                                field: (rowData) => rowData.voip_sc_billing_from,
                                            },
                                            {
                                                heading: "Billing To",
                                                field: (rowData) => rowData.voip_sc_billing_to,
                                            },
                                            {
                                                heading: "Quantity",
                                                field: (rowData) => rowData.voip_sc_quantity_original,
                                                alignment: "right",
                                            },
                                            {
                                                heading: "Unit Cost",
                                                field: (rowData) => rowData.voip_sc_unit_cost,
                                                alignment: "right",
                                                fieldFormat: "currency",
                                            },
                                            {
                                                heading: "Total Cost",
                                                field: (rowData) => rowData.voip_sc_total_cost,
                                                alignment: "right",
                                                fieldFormat: "currency",
                                            },
                                            {
                                                heading: "Bill Charge",
                                                field: (rowData) => (
                                                    <IconButton
                                                        onClick={() => this.handleServiceChargeBillable(dialogParentIdx, dialogServiceIdx, rowData.rowNumber - 1)}
                                                        color="inherit"
                                                    >
                                                        <FAIcon
                                                            icon={
                                                                (data[dialogParentIdx].voip_services[dialogServiceIdx].voip_charges_services[rowData.rowNumber - 1]
                                                                    .voip_sc_status === "New" &&
                                                                    "check-circle") ||
                                                                "times-circle"
                                                            }
                                                            style={{
                                                                color:
                                                                    (data[dialogParentIdx].voip_services[dialogServiceIdx].voip_charges_services[rowData.rowNumber - 1]
                                                                        .voip_sc_status === "New" &&
                                                                        "#388E3C") ||
                                                                    "#d9534f",
                                                            }}
                                                            noMargin
                                                            button
                                                        />
                                                    </IconButton>
                                                ),
                                                alignment: "center",
                                            },
                                            {
                                                heading: "Bill Quantity",
                                                field: (rowData) => (
                                                    <FormControl style={{ minWidth: 100 }} fullWidth>
                                                        <AutoCompleteSelect
                                                            options={quantityOptions}
                                                            noClear
                                                            onChange={(v) => this.handleServiceChargeQuantity(dialogParentIdx, dialogServiceIdx, rowData.rowNumber - 1, v)}
                                                            value={parseInt(
                                                                data[dialogParentIdx].voip_services[dialogServiceIdx].voip_charges_services[rowData.rowNumber - 1]
                                                                    .voip_sc_quantity
                                                            )}
                                                            disabled={
                                                                data[dialogParentIdx].voip_services[dialogServiceIdx].voip_charges_services[rowData.rowNumber - 1]
                                                                    .voip_sc_status === "NewIgnored"
                                                                    ? true
                                                                    : false
                                                            }
                                                            fullWidth
                                                            noDefaultSort
                                                        />
                                                    </FormControl>
                                                ),
                                                alignment: "center",
                                            },
                                        ]}
                                        rows={data[dialogParentIdx].voip_services[dialogServiceIdx].voip_charges_services}
                                    />
                                </Typography>
                            </Dialog>
                        )}
                        {dialogMissing && (
                            <Dialog open={true} onClose={() => this.handleMissingCharges(false, 0)} maxWidth="md" scroll="body">
                                <DialogTitle>
                                    Service Charges
                                    <br />
                                    <span style={{ fontSize: "12px" }}>
                                        {missing[dialogMissingAdditionIdx].voip_service_identifier} | {missing[dialogMissingAdditionIdx].voip_service_name}
                                    </span>
                                </DialogTitle>
                                <Typography component={DialogContent} variant="body2">
                                    <DataTable
                                        config={{
                                            key: "rowNumber",
                                            plainHeader: true,
                                        }}
                                        columns={[
                                            {
                                                heading: "Billing From",
                                                field: (rowData) => rowData.voip_sc_billing_from,
                                                fieldFormat: "date",
                                                important: true,
                                            },
                                            {
                                                heading: "Billing To",
                                                field: (rowData) => rowData.voip_sc_billing_to,
                                                fieldFormat: "date",
                                                important: true,
                                            },
                                            {
                                                heading: "Quantity",
                                                field: (rowData) => rowData.voip_sc_quantity_original,
                                                alignment: "right",
                                            },
                                            {
                                                heading: "Bill Charge",
                                                field: (rowData) => (
                                                    <IconButton
                                                        onClick={() => this.handleMissingChargeBillable(dialogMissingAdditionIdx, rowData.rowNumber - 1)}
                                                        color="inherit"
                                                    >
                                                        <FAIcon
                                                            icon={
                                                                (missing[dialogMissingAdditionIdx].service_charges[rowData.rowNumber - 1].voip_sc_status === "New" &&
                                                                    "check-circle") ||
                                                                "times-circle"
                                                            }
                                                            style={{
                                                                color:
                                                                    (missing[dialogMissingAdditionIdx].service_charges[rowData.rowNumber - 1].voip_sc_status === "New" &&
                                                                        "#388E3C") ||
                                                                    "#d9534f",
                                                            }}
                                                            noMargin
                                                            button
                                                        />
                                                    </IconButton>
                                                ),
                                                alignment: "center",
                                            },
                                            {
                                                heading: "Bill Quantity",
                                                field: (rowData) => (
                                                    <FormControl style={{ minWidth: 100 }} fullWidth>
                                                        <AutoCompleteSelect
                                                            options={quantityOptions}
                                                            noClear
                                                            onChange={(v) => this.handleMissingChargeQuantity(dialogMissingAdditionIdx, rowData.rowNumber - 1, v)}
                                                            value={parseInt(missing[dialogMissingAdditionIdx].service_charges[rowData.rowNumber - 1].voip_sc_quantity)}
                                                            disabled={
                                                                missing[dialogMissingAdditionIdx].service_charges[rowData.rowNumber - 1].voip_sc_status === "NewIgnored"
                                                                    ? true
                                                                    : false
                                                            }
                                                            fullWidth
                                                            noDefaultSort
                                                        />
                                                    </FormControl>
                                                ),
                                                alignment: "center",
                                            },
                                        ]}
                                        rows={missing[dialogMissingAdditionIdx].service_charges}
                                    />
                                </Typography>
                            </Dialog>
                        )}
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
    };
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(VoipImport));
