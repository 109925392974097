import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StaffChip from 'Components/Common/Chips/StaffChip';
import StatusChip from 'Components/Common/Chips/StatusChip';

const initialState = {
    searchResults: {},
    searchString: '',
    access: {
        newImport: false,
        reviewImport: false,
        viewImport: false
    },
    dataLoading: true
}

class VoipImportSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    getAccess = () => {
        Promise.all([
            API.access('voip-import'), 
            API.access('voip-import-view'), 
            API.access('voip-import-review'), 
        ])
        .then(([newImport, viewImport, reviewImport]) =>  {
            this.setState({
                ...this.state,
                access: {
                    newImport: (newImport.data && newImport.data.has_access) || false,
                    reviewImport: (reviewImport.data && reviewImport.data.has_access) || false,
                    viewImport: (viewImport.data && viewImport.data.has_access) || false
                }
            });
        })
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'VOIP and Connectivity', 'Data Imports']);
        this.getSearchData();
        this.getAccess();
    }

    getSearchData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/voip/import', { params: { searchString: this.state.searchString } })
            .then(result => {
                this.setState({
                    dataLoading: false
                });
                if(result.data) {
                    this.setState({
                        searchResults: result.data
                    });
                }
            });
        });
    }
    
    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        }, () => {
            this.getSearchData();
        });
    }

    resetSearch = () => {
        this.setState({
            searchString: ''
        }, () => {
            this.getSearchData();
        });
    }

    render() {
        const { access, dataLoading } = this.state;
        return (
            <React.Fragment>
                <Grid container spacing={1}>      
                    <Grid container item xs={12} alignItems='center'>
                        <Grid item xs={3} style={{marginBottom: 12}}>
                            <TextField 
                                fullWidth
                                onChange={this.onSearchStringChange}
                                placeholder='Reference:'
                                value={this.state.searchString} 
                                variant="filled"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><FAIcon icon="search" size="small" /></InputAdornment>
                                }}
                            /> 
                        </Grid>      
                        {access.newImport && (
                            <Grid item xs={9} align='right'> 
                                <Button 
                                    color="primary" 
                                    size="small"
                                    variant="text"
                                    onClick={() => this.props.history.push('/voip/import/new')}
                                >   
                                    <FAIcon icon="plus-circle" size="small" button />
                                    New Data Import
                                </Button>
                            </Grid>
                        )}
                    </Grid>   
                    <Grid item xs={12}>
                        <Paper>
                            <DataTable  
                                config={{
                                    key: 'vdi_id',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    isLoading: dataLoading,
                                    responsiveImportance: true,
                                    plainHeader: true, 
                                    title: 'VOIP and Connectivity Data Imports',
                                }}
                                columns={[
                                    {
                                        heading: 'Ready',
                                        field: rowData => rowData.vdi_status === "Awaiting Review" ?  <FAIcon icon="times" style={{color: '#c62828'}} noMargin/> : <FAIcon icon="check" style={{color: '#4CAF50'}} noMargin/>,
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Reference',
                                        field: rowData => rowData.vdi_ref,
                                        important: true,
                                    },
                                    {
                                        heading: 'Service Charges',
                                        field: rowData => rowData.service_charges_count, 
                                        alignment: 'right',
                                        sizeToContent: true,    
                                    },
                                    {
                                        heading: 'Call Charges',
                                        field: rowData => (rowData.call_charges_count), 
                                        alignment: 'right',
                                        sizeToContent: true,    
                                    },
                                    {
                                        heading: 'Ignored Service Charges',
                                        field: rowData => rowData.ignored_service_charges_count, 
                                        alignment: 'right',
                                        sizeToContent: true,    
                                    },
                                    {
                                        heading: 'Ignored Call Charges',
                                        field: rowData => rowData.ignored_call_charges_count, 
                                        alignment: 'right',
                                        sizeToContent: true,    
                                    },
                                    {
                                        heading: 'Completed',
                                        field: rowData => rowData.vdi_created_datetime,
                                        fieldFormat: 'datetime',                         
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Imported By',
                                        field: rowData => <StaffChip staff={this.props?.staff?.[rowData?.staff?.staff_id ?? 0]} />, 
                                        sizeToContent: true,    
                                    },
                                    {
                                        heading: 'Status',
                                        field: rowData => <StatusChip status={rowData.vdi_status} />,  
                                        sizeToContent: true,   
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: rowData.vdi_status === "Awaiting Review" ? "Review Import" : "View Import", icon: 'search', link: '/voip/import/' + rowData.vdi_id, disabled: rowData.vdi_status === "Processing" ? true : (rowData.vdi_status === "Awaiting Review" ? (access.reviewImport ? false : true) : (access.viewImport ? false : true))}
                                            ]
                                        }
                                    }
                                ]}
                                rows={this.state.searchResults}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    staff: state.notifications.status
})

export default connect(mapStateToProps, null)(VoipImportSearch);