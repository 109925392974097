import React from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar from 'Components/Common/SnackBars/SnackBar';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { Button, IconButton } from '@material-ui/core';

const initialState = {
    isLoading: true,
    subscriptionTypes: {},
    initialFormData: {},
    formData: {},
    dataSave: false,
    confirmation: {
        dataSaved: false
    },
    snackbar: {
        dataSaved: false
    }
}
 
class MS365SubscriptionTypes extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = () => {
        this.props.pageTitle([1, 'Microsoft 365', 'Subscription Types'])  
        this.getSubscriptionTypes();
    }

    getSubscriptionTypes = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.get('/ms365/subscriptionTypes')
            .then(result => {
                if(result.data) {
                    let initialFormData = {}
                    _.each(result.data, st => {
                        initialFormData = this.mapFormData(st, initialFormData)
                    })
                    this.setState({
                        isLoading: false,
                        subscriptionTypes: result.data,
                        formData: initialFormData,
                        initialFormData,
                    })
                }
            });
        })
    }

    handleSubmit = () => {
        this.setState({
            dataSave: true,
            initialFormData: this.state.formData,
        },
        () => {
            API.put('/ms365/subscriptionTypes', {subscriptionTypesData: this.state.formData})
            .then(result => {
                if(result.data && result.data.success) {
                    this.setState({
                        dataSave: false,
                        snackbar: {
                            dataSaved: true
                        }
                    }, () => {
                        this.props.scrollToTop();
                        this.getSubscriptionTypes();
                    });
                }
            })
        })
    }

    mapFormData = (subscriptionType, initialFormData) => {
        return {
            ...initialFormData,
            [subscriptionType.msost_id]: {
                msostId: parseInt(subscriptionType.msost_id),
                msostAutoIgnore: parseInt(subscriptionType.msost_auto_ignore)
            }
        }
    }

    handleAutoIgnoreToggle = msostId => {
        this.setState({
            formData: {
                ...this.state.formData,
                [msostId]: {
                    ...this.state.formData[msostId],
                    msostAutoIgnore: this.state.formData[msostId].msostAutoIgnore === 1 ? 0 : 1,
                }
            }
        });
    }
    
    handleReset = () => {
       this.setState({
           isLoading: true
       }, () => this.loadComponentData());
    }

    handleConfirmationOpen = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: true
            },
        });
    };

    handleConfirmationClose = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false
            },
        });
    };

    handleConfirmationSuccess = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false
            },
        }, () => {
            this.handleSubmit();
        })
    };

    handleSnackbarClose = type => {
        this.setState({
            snackbar: {
                ...this.state.snackbar,
                [type]: false
            }
        })
    };

    render() {
        const { isLoading, subscriptionTypes, formData, initialFormData, dataSave, confirmation, snackbar } = this.state;        
        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {/*<Grid item xs={12}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={4}>
                                        <AutoCompleteSelect 
                                            options={searchOptions}
                                            placeholder='Tenant *'
                                            onChange={this.handleSelectChange('search')}
                                            value={search}
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <AutoCompleteSelect 
                                            options={filterOptions}
                                            placeholder='Log Type *'
                                            onChange={this.handleSelectChange('filter')}
                                            value={filter}
                                            variant="filled"
                                        />
                                    </Grid>
                                </Grid
                            </Grid>*/}
                            <Grid item xs={12}>  
                                <Paper>
                                    <DataTable
                                        config={{
                                            dense: true,
                                            key: 'msost_id',
                                            pagination: true,
                                            isLoading,
                                            rowsPerPage: 100,
                                            title: 'Microsoft 365 Subscription Types',
                                            plainHeader: true,
                                            actions: 
                                                <React.Fragment>
                                                    <Button 
                                                        variant="text"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => this.handleReset()}
                                                        style={{marginLeft: 6}} 
                                                        disabled={isLoading ? true : (dataSave ? true : (initialFormData === formData ? true : false))}
                                                    >
                                                        <FAIcon icon="trash" style={{color: isLoading ? '#ddd' : (dataSave ? '#ddd' : (initialFormData === formData ? '#ddd' : '#375885')), height: '1em'}} heading button/>
                                                        Discard
                                                    </Button>
                                                    <Button 
                                                        variant="text"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => this.handleConfirmationOpen('dataSaved')} 
                                                        style={{marginLeft: 6}} 
                                                        disabled={isLoading ? true : (dataSave ? true : (initialFormData === formData ? true : false))}
                                                    >
                                                        <FAIcon icon="save" style={{color: isLoading ? '#ddd' : (dataSave ? '#ddd' : (initialFormData === formData ? '#ddd' : '#375885'))}} size="small" swapOpacity button/>
                                                        Save
                                                    </Button>
                                                </React.Fragment>
                                        }}
                                        columns={[
                                            {
                                                heading: 'Part Number',
                                                field: rowData => rowData.msost_part_number,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Name',
                                                field: rowData => rowData.msost_name,
                                                main: true,
                                            },
                                            {
                                                heading: 'Auto Ignore?',
                                                field: rowData => (
                                                    <IconButton
                                                        onClick={() => this.handleAutoIgnoreToggle(rowData.msost_id)}
                                                        color='inherit'
                                                    >
                                                        <FAIcon
                                                            icon={
                                                                (formData[rowData.msost_id].msostAutoIgnore === 1 && 'check-circle') || 'times-circle'
                                                            }
                                                            style={{
                                                                color: ((formData[rowData.msost_id].msostAutoIgnore === 1 && '#388E3C') || '#d9534f')
                                                            }}
                                                            noMargin
                                                            button
                                                        />
                                                    </IconButton>
                                                ),             
                                                important: true,
                                                sizeToContent: true,
                                                alignment: 'center'
                                            },
                                        ]}
                                        rows={subscriptionTypes}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        <ConfirmationDialog 
                            open={confirmation.dataSaved} 
                            success={() => this.handleConfirmationSuccess('dataSaved')} 
                            close={() => this.handleConfirmationClose('dataSaved')} 
                            title="Save Subscription Types?" 
                            message="Are you sure you want to save changes to the Subscription Types?"
                        />
                        <SnackBar
                            autoHideDuration={2000}
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={snackbar.dataSaved}
                            onClose={() => this.handleSnackbarClose('dataSaved')}
                            message="Subscription Types Saved Successfully"
                        />
                    </Grid> 
                )}
            </Grid>
        )
    }
}

export default MS365SubscriptionTypes;