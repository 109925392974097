import React from 'react';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import API from 'API';
import ActionBadge from 'Components/Common/ActionBadge/ActionBadge';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import { formatValidationErrors } from '../../../../Helpers/ErrorHelper';

const initialState = {
    access: {
        logs: false,
        mapping: false,
    },
    confirmation: {
        dataSaved: false,
        importData: false
    },
    count: {
        hidden: 0
    },
    customers: {},
    customerList: [],
    dataLoading: true,
    dataSave: false,
    displayAllOus: false,
    filteredOus: {},
    formData: {},
    initialFormData: {},
    ous: {},
    snackbar: {
        dataSaved: false
    }    
}

class MyCloudOUMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.props.pageTitle([1, 'MyCloud', 'OU Mapping']);
        this.loadComponentData();
    }

    loadComponentData = () => {    
        Promise.all([
            API.get('/connectwise'),
            API.get('/mycloud', { params: { includeFiltered: true }}),
            API.access('mycloud-group-mapping'), 
            API.access('mycloud-logs')
        ])
        .then(([custRes, ouRes, mapRes, logRes]) =>  {
            if(custRes.data && ouRes.data && mapRes.data && logRes.data) {
                var assignedCustomers = []
                var initialFormData = {}
                _.each(ouRes.data.ous, ou => {
                    initialFormData = this.mapFormData(ou, assignedCustomers, initialFormData)
                });
                const customerList = _.map(custRes.data, el => {
                    return _.assign({
                        value: el.cust_id,
                        label: el.cust_name,
                        disabled: assignedCustomers.includes(el.cust_id) ? true : false
                    });      
                });
                this.setState({
                    ...this.state,
                    access: {
                        logs: (logRes.data && logRes.data.has_access) || false,
                        mapping: (mapRes.data && mapRes.data.has_access) || false
                    },
                    count: {
                        hidden: ouRes.data.count_hidden
                    },
                    customers: custRes.data,
                    customerList: customerList,
                    dataLoading: false,
                    formData: initialFormData,
                    initialFormData: initialFormData,
                    ous: ouRes.data.ous,
                    filteredOus: ouRes.data.filteredOus       
                });
            }
        })
    }

    mapFormData = (ou, assignedCustomers, initialFormData) => {
        if(ou.adou_cust_id !== 0) {
            assignedCustomers.push(ou.adou_cust_id);
        }
        initialFormData = {
            ...initialFormData,
            [ou.adou_id]: {
                adouId: parseInt(ou.adou_id),
                adouCustId: parseInt(ou.adou_cust_id),
                adouSync: parseInt(ou.adou_sync)
            }
        }
        if(ou.children) {
            _.each(ou.children, ouChild => {
                initialFormData = this.mapFormData(ouChild, assignedCustomers, initialFormData)
            })
        }
        return initialFormData;
    }
    
    handleReset = () => {
       this.setState({
           dataLoading: true
       }, () => this.loadComponentData());
    }

    handleSubmit = () => {
        this.setState({
            dataSave: true,
            initialFormData: this.state.formData,
        },
        () => {
            API.put('/mycloud', { adouData: this.state.formData })
            .then(result => {
                if(result.data.errors) {
                    this.setState({
                        dataSave: false,
                        formErrors: formatValidationErrors(result.data.errors)
                    }, () => this.props.scrollToTop());
                } else {
                    this.setState({
                        dataLoading: true,
                        dataSave: false,
                        snackbar: {
                            dataSaved: true
                        }
                    }, () => {
                        this.props.scrollToTop();
                        this.loadComponentData();
                    });
                }
            })
        })
    }

    handleSyncToggle = adouId => {
        this.setState({
            formData: {
                ...this.state.formData,
                [adouId]: {
                    ...this.state.formData[adouId],
                    adouSync: this.state.formData[adouId].adouSync === 1 ? 0 : 1,
                }
            }
        });
    }

    handleCustomerSelection = adouId => selectedCustomer => {
        const option = selectedCustomer === null ? 0 : selectedCustomer.value
        this.setState({
            formData: {
                ...this.state.formData,
                [adouId]: {
                    ...this.state.formData[adouId],
                    adouCustId: parseInt(option),
                    adouSync: parseInt(option) === 0 ? 0 : 1
                }
            }
        }, 
        () => {
            var assignedCustomers = []
            _.each(this.state.formData, ou => {
                if(ou.adouCustId !== 0)
                    assignedCustomers.push(ou.adouCustId);
            });
            var customerList = _.map(this.state.customers, el => {
                return _.assign({
                    value: el.cust_id,
                    label: el.cust_name,
                    disabled: assignedCustomers.includes(el.cust_id) ? true : false
                });      
            });
            this.setState({
                customerList: customerList
            })
        });
    };

    handleConfirmationOpen = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: true
            },
        });
    };

    handleConfirmationClose = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false
            },
        });
    };

    handleConfirmationSuccess = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false
            },
        }, () => {
            this.handleSubmit();
        })
    }

    handleSnackbarClose = type => {
        this.setState({ 
            snackbar: {
                ...this.state.snackbar,
                [type]: false
            }
        })
    };

    handleDisplayToggle = ou => {        
        this.setState({
            displayAllOus: !this.state.displayAllOus
        })
    }

    handleOuDisplayToggle = ou => {        
        API.get('/mycloud/' + ou + '/ouMapping/toggle')
        .then(result => {
            if(result.data) {
                this.setState({       
                    count: {
                        hidden: result.data.count_hidden
                    },         
                    ous: result.data.ous,
                    filteredOus: result.data.filteredOus
                })
            }
        })
    }

    render() {
        const { access, dataLoading, customerList, displayAllOus, filteredOus, ous, dataSave, formData, initialFormData, confirmation, snackbar } = this.state;
        return (
            <Grid container spacing={3}>
                {(dataLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Paper>
                                    <DataTable  
                                        config={{
                                            key: 'adou_id',
                                            pagination: true,
                                            footerColSpan: 2,
                                            footerText: `${this.state.count.hidden} Hidden`,
                                            alternatingRowColours: true,
                                            isLoading: dataLoading,
                                            rowsPerPage: 100,
                                            responsiveImportance: true,
                                            nesting: true,
                                            plainHeader: true,
                                            noRowHover: true,
                                            title: 'MyCloud OU Mapping',
                                            actions: 
                                                <React.Fragment>
                                                     <Button
                                                        variant="text"
                                                        color="primary"
                                                        size="small"
                                                        onClick={this.handleDisplayToggle} 
                                                    >
                                                        <FAIcon icon={displayAllOus ? 'eye-slash' : 'eye'} style={{color:'#375885', height: '1em'}} heading button/>
                                                        {displayAllOus ? 'Hide Hidden' : 'Display All'}
                                                    </Button>
                                                    <Button 
                                                        variant="text"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => this.handleReset()} 
                                                        style={{marginLeft: 6}} 
                                                        disabled={dataLoading ? true : (dataSave ? true : (initialFormData === formData ? true : false))}
                                                    >
                                                        <FAIcon icon="trash" style={{color: dataLoading ? '#ddd' : (dataSave ? '#ddd' : (initialFormData === formData ? '#ddd' : '#375885')), height: '1em'}} heading button/>
                                                        Discard
                                                    </Button>
                                                    <Button 
                                                        variant="text"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => this.handleConfirmationOpen('dataSaved')} 
                                                        style={{marginLeft: 6}} 
                                                        disabled={dataLoading ? true : (dataSave ? true : (initialFormData === formData ? true : false))}
                                                    >
                                                        <FAIcon icon="save" style={{color: dataLoading ? '#ddd' : (dataSave ? '#ddd' : (initialFormData === formData ? '#ddd' : '#375885'))}} size="small" swapOpacity button/> 
                                                        Save
                                                    </Button>
                                                </React.Fragment>
                                        }}
                                        columns={[ 
                                            {
                                                field: rowData => (
                                                    <Tooltip title={rowData.adou_display === 1 ? 'Hide' : 'Show'}>
                                                        <IconButton
                                                            onClick={() => this.handleOuDisplayToggle(rowData.adou_id)}
                                                            color='inherit'
                                                        >
                                                            <FAIcon
                                                                icon={
                                                                    (rowData.adou_display === 1 && 'eye')
                                                                    ||      
                                                                    'eye-slash'
                                                                }
                                                                size="small"
                                                                noMargin
                                                                button
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                ),             
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Organisational Unit',
                                                field: rowData => (
                                                    <React.Fragment>
                                                        {rowData.adou_name}
                                                        <ActionBadge badgeContent={rowData.contact_actions_count} variant="warning" />
                                                        <ActionBadge badgeContent={rowData.g_wo_additions_actions_count} variant="error" style={{paddingRight: 24}} />
                                                    </React.Fragment>
                                                ),
                                                important: true,
                                                nestingDropdown: true, 
                                            },
                                            {
                                                heading: 'Customer',
                                                field: rowData => (
                                                    <FormControl fullWidth style={{minWidth: 350, maxWidth: 350}}>
                                                        <AutoCompleteSelect 
                                                            options={customerList}
                                                            placeholder='Not Mapped'
                                                            onChange={this.handleCustomerSelection(rowData.adou_id)}
                                                            value={formData[rowData.adou_id].adouCustId}          
                                                            variant="filled"                                                  
                                                        />
                                                    </FormControl>
                                                ),             
                                                important: true,  
                                                sizeToContent: true   
                                            },
                                            {
                                                heading: 'Enabled',
                                                field: rowData => (
                                                    <IconButton
                                                        disabled={
                                                            (formData[rowData.adou_id].adouCustId === 0 && true)
                                                            ||                                                        
                                                            false
                                                        }
                                                        onClick={() => this.handleSyncToggle(rowData.adou_id)}
                                                        color='inherit'
                                                    >
                                                        <FAIcon
                                                            icon={
                                                                (formData[rowData.adou_id].adouSync === 1 && formData[rowData.adou_id].adouCustId !== 0 && 'check-circle')
                                                                ||                                                        
                                                                (formData[rowData.adou_id].adouSync === 0 && formData[rowData.adou_id].adouCustId !== 0 && 'times-circle')
                                                                || 
                                                                'times-circle'
                                                            }
                                                            style={{
                                                                color: 
                                                                    (formData[rowData.adou_id].adouSync === 1 && formData[rowData.adou_id].adouCustId !== 0 && '#388E3C')
                                                                    ||                                                        
                                                                    (formData[rowData.adou_id].adouSync === 0 && formData[rowData.adou_id].adouCustId !== 0 && '#d9534f')
                                                                    || 
                                                                    '#BDBDBD'
                                                                }}
                                                            noMargin
                                                            button
                                                        />
                                                    </IconButton>
                                                ),             
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Manage', icon: 'chevron-right', link: '/mycloud/mapping/groups/' + rowData.adou_id, disabled: rowData.adou_cust_id === 0 ? true : (!access.mapping ? true : false)},
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={displayAllOus === true ? ous : filteredOus}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        <ConfirmationDialog 
                            open={confirmation.importData} 
                            success={() => this.handleConfirmationSuccess('importData')} 
                            close={() => this.handleConfirmationClose('importData')} 
                            title="Import MyCloud Data?" 
                            message={`This will import data from MyCloud for all Organisational Units`}
                        />
                        <ConfirmationDialog 
                            open={confirmation.dataSaved} 
                            success={() => this.handleConfirmationSuccess('dataSaved')} 
                            close={() => this.handleConfirmationClose('dataSaved')} 
                            title="Save MyCloud OU Configuration?" 
                            message="Are you sure you want to save these changes to the MyCloud OU Mapping?"
                        />
                        <SnackBar
                            autoHideDuration={2000}
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={snackbar.dataSaved}
                            onClose={() => this.handleSnackbarClose('dataSaved')}
                            message="MyCloud OU Mapping Saved Successfully"
                        />
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default MyCloudOUMapping;