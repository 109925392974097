import React from 'react';
import { connect } from 'react-redux';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-google';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import FAIcon from '../Icons/FontAwesome/FAIcon';
import LoginStoredProfile from '../../Login/LoginStoredProfile';

import { RECAPTCHA_SITE_KEY } from '../../../Constants';
import { authStaff } from 'Redux/Actions/Auth/Auth';
import { handleAppError } from 'Redux/Actions/UI/UI';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import {
    setToken, 
    getDecodedToken, 
    hasStoredProfile, 
    getStoredProfile, 
    setStoredProfile, 
    clearStoredProfile,
    clearToken
} from '../../../Functions/AuthFunctions';

const initialState = {
    formData: {
        email: '',
        password: '',
        mfaCodeInd: {
            0: ' ',
            1: ' ',
            2: ' ',
            3: ' ',
            4: ' ',
            5: ' ',
        },
        mfaCode: '',
        storeProfile: false,
        googleRecaptchaToken: ''
    },
    formErrors: [],
    disableSubmit: false,
    storedProfile: false,
    isLoading: false,
    ipBanned: false,
    action: 'login'
}

class ErrorLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.mfaCode0 = React.createRef();
        this.mfaCode1 = React.createRef();
        this.mfaCode2 = React.createRef();
        this.mfaCode3 = React.createRef();
        this.mfaCode4 = React.createRef();
        this.mfaCode5 = React.createRef();

        if(hasStoredProfile()) {
            const storedProfile = getStoredProfile();
            if(storedProfile.active || storedProfile.temp){
                this.setState({
                    storedProfile: storedProfile,
                    formData: {
                        ...this.state.formData,
                        email: storedProfile.email,                        
                        storeProfile: storedProfile.active,
                    }
                });
            }
        }
        
        loadReCaptcha();

    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        })
    }

    handleClearStoredProfile = () => {
        this.props.handleAppError(false, "");
        this.props.history.push('/login');
        clearToken();
        clearStoredProfile();
    }

    handleMfa = (e) => {
        if(e) {
            e.preventDefault();
        }
        const mfaCode = this.state.formData.mfaCodeInd;
        this.setState({
            formData: {
                ...this.state.formData,
                mfaCode: mfaCode[0]+mfaCode[1]+mfaCode[2]+mfaCode[3]+mfaCode[4]+mfaCode[5]
            }            
        }, () => this.handleSubmit());
    }

    handleMfaChange = (id, e) => {
        if(id === 0 && e.target.value.length >= 1) {
            this.mfaCode1.current.focus();
        } else if(id > 0 && id < 5 && e.target.value.length >= 1) {
            this['mfaCode'+(id+1)].current.focus();            
        } else if(id > 0) {            
            this['mfaCode'+(id-1)].current.focus();  
        }
        this.setState({
            formData: {
                ...this.state.formData,
                mfaCodeInd: {
                    ...this.state.formData.mfaCodeInd,
                    [id]: e.target.value.length > 0 ? (e.target.value.trim().length === 1 ? e.target.value.trim() : e.target.value.trim()[1]) : ' '
                },
            }
        }, () => {
            if(id === 5 && this.state.formData.mfaCodeInd[5].length > 0) { 
                this.handleMfa(false);
            }
        });
    }

    handleRecaptcha = (e) => {
        e.preventDefault();        
        if (this.googleRecaptcha) {
            this.googleRecaptcha.reset();
            this.googleRecaptcha.execute();
            return;
        }
    }
    
    handleRecaptchaVerifyCallback = (recaptchaToken) => {
        this.setState({
            formData: {
                ...this.state.formData,
                googleRecaptchaToken: recaptchaToken
            },
            disableSubmit: true,
        },
        () => { 
            this.handleSubmit()
        });
    }

    handleRetryIpBan = () => {
        this.setState({
            ipBanned: false
        })
    }

    handleSubmit = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.post('/auth/login', this.state.formData)
            .then((result) => {
                if(result.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        disableSubmit: false,
                        isLoading: false,
                        formData: {
                            ...this.state.formData,
                            mfaCode: '',
                            mfaCodeInd: {
                                0: ' ',
                                1: ' ',
                                2: ' ',
                                3: ' ',
                                4: ' ',
                                5: ' '
                            }
                        }
                    }, () => {
                        if(this.state.formErrors['mfaCode']){
                            this.mfaCode0.current.focus();
                        }
                        if (this.googleRecaptcha) {
                            this.googleRecaptcha.reset();
                        } 
                    });
                } else if(result.data.ip_locked) {
                    this.setState({
                        action: 'login',
                        ipBanned: true,
                        isLoading: false,
                        disableSubmit: false,
                        formErrors: [],
                    });
                } else if(result.data.mfa_required) {
                    this.setState({
                        action: 'mfa',
                        isLoading: false,
                        disableSubmit: false,
                        formErrors: [],
                    }, () => this.mfaCode0.current.focus());
                } else {
                    setToken(result.data.token);
                    Promise.all([ 
                        API.get('/staff/my/profilePhoto', {
                            params: {
                                asImage: true,
                                asBase64: true
                            }
                        }), 
                        API.get('/staff/my/password/requiresUpdate')
                    ])
                    .then(([photoRes, viewRes]) => {
                        const profile = getDecodedToken();
                        setStoredProfile({
                            active: this.state.formData.storeProfile ? true : false,
                            temp: false,
                            firstName: profile.firstName,
                            lastName: profile.lastName,
                            email: profile.email,
                            avatar: photoRes.data ? photoRes.data : '',
                        });       
                        this.props.deploySnackBar("success", "Thank you for logging back into BlueSync, " + profile.firstName + "!")
                        this.props.authStaff(result.data.token, photoRes.data ? photoRes.data : '');
                        if(viewRes.data){
                            if(viewRes.data.requires_update){
                                this.props.handleAppError("PASSWORD_UPDATE", "");
                            } else {
                                this.props.handleAppError(false, "");
                            }
                        } else {
                            this.props.handleAppError(false, "");
                        }
                    });
                }
            })
        })
    }

    render(){
        const { action, formData, formErrors, ipBanned, isLoading, storedProfile} = this.state
        return (
            <React.Fragment>
                {(isLoading && (
                    <Grid item xs={12} align='center'>                                    
                        <CircularProgress size={50} />       
                    </Grid>
                )) || (
                    (ipBanned && (
                        <React.Fragment>
                            <Grid item xs={12} align='center'>    
                                <FAIcon 
                                    icon="exclamation-triangle"
                                    size="xlarge"
                                    noMargin
                                    heading
                                />  
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Typography variant="h6" gutterBottom>
                                    IP Banned
                                </Typography>
                                <Typography variant="caption" component="div" gutterBottom>
                                    You cannot login at this time due to multiple failed attempts from this IP Address
                                </Typography>  
                                <Typography variant="caption" style={{fontSize: '0.75em'}} color="textSecondary" component="div" paragraph>
                                    For help and support, please contact Crushed Ice on 01933 664344
                                </Typography>
                                <Typography variant="caption" style={{fontSize: '0.75em'}} color="textSecondary" component="div">
                                    <Button
                                        margin="normal"
                                        variant="text"
                                        color="secondary"
                                        onClick={this.handleRetryIpBan}
                                    >                            
                                        Retry
                                    </Button>   
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    )) || (
                        <Grid container justify="center">                
                            <Grid item xs={12} lg={8} align="center">
                                {(action === 'login' && (
                                    <form onSubmit={this.handleRecaptcha} noValidate autoComplete="off">    
                                        <Grid container spacing={2}>
                                            {storedProfile && (
                                                <Grid item xs={12}>
                                                    <LoginStoredProfile staffData={storedProfile} />
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <Typography variant="caption" className="fw-400" gutterBottom>
                                                    Please login again to continue using BlueSync
                                                </Typography>
                                                { this.state.formErrors.googleRecaptchaToken && 
                                                    <Typography component="div" className="fw-400" variant="caption" color="error" style={{width: '100%', textAlign: 'center', marginBottom: 12}}>{this.state.formErrors.googleRecaptchaToken}</Typography>
                                                }              
                                            </Grid>
                                            {!storedProfile && (
                                                <Grid item xs={12}>         
                                                    <TextField
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        id="email"
                                                        placeholder="Email Address"
                                                        name="email"
                                                        autoComplete="off"
                                                        autoFocus
                                                        value={formData.email}
                                                        error={formErrors && formErrors['email'] && true}
                                                        helperText={formErrors && formErrors['email']}
                                                        onChange={this.handleChange} 
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        variant="filled"
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    placeholder="Password"
                                                    type="password"
                                                    id="password"
                                                    autoComplete="new-password"
                                                    value={formData.password}
                                                    error={formErrors && formErrors['password'] && true}
                                                    helperText={formErrors && formErrors['password']}
                                                    onChange={this.handleChange}    
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}                
                                                    variant="filled"         
                                                />                   
                                            </Grid>
                                            <Grid item xs={12}>      
                                                {window.grecaptcha ? (
                                                    <ReCaptcha
                                                        ref={(el) => {this.googleRecaptcha = el}}
                                                        size="invisible"
                                                        render="explicit"
                                                        sitekey={RECAPTCHA_SITE_KEY}
                                                        onloadCallback={() => undefined}
                                                        verifyCallback={this.handleRecaptchaVerifyCallback}
                                                    />
                                                ) : null}
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!this.state.formData.email || !this.state.formData.password || this.state.disableSubmit}
                                                    size="large"
                                                >          
                                                    <FAIcon icon="sign-in" disabled={!this.state.formData.email || !this.state.formData.password || this.state.disableSubmit} buttonPrimary />              
                                                    Sign In
                                                </Button>   
                                            </Grid> 
                                            {storedProfile && (
                                                <Grid item xs={12} align="right">
                                                    <Link href="#" onClick={this.handleClearStoredProfile} variant="body2">
                                                        <Typography variant="caption" component="div">
                                                            {`Not ${storedProfile.firstName} ${storedProfile.lastName}?`}
                                                        </Typography>
                                                    </Link>
                                                </Grid>
                                            )}
                                        </Grid>                                                 
                                    </form>
                                )) || (
                                    <form onSubmit={this.handleMfa} noValidate autoComplete="off">             
                                            {storedProfile && (
                                            <LoginStoredProfile staffData={storedProfile} />
                                        )}                                                              
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} align='center'> 
                                                <Typography variant="caption">                                                             
                                                    Please enter your multi-factor authentication code  
                                                </Typography>                                                            
                                            </Grid> 
                                            {this.state.formErrors.mfaCode && 
                                                <Grid item xs={12} style={{color: 'red'}} align='center'>   
                                                    {this.state.formErrors.mfaCode}
                                                </Grid>
                                            }    
                                            <Grid item xs={2}>
                                                <TextField
                                                    id="mfa-0"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    autoComplete="off"
                                                    className='mfaInput'
                                                    value={formData.mfaCodeInd[0]}
                                                    error={formErrors && formErrors['mfaCode'] && true}
                                                    onChange={(e) => this.handleMfaChange(0, e)} 
                                                    inputProps={{
                                                        ref:this.mfaCode0,
                                                        size:2,
                                                        maxLength:2,
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    id="mfa-1"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    autoComplete="off"
                                                    className='mfaInput'
                                                    value={formData.mfaCodeInd[1]}
                                                    error={formErrors && formErrors['mfaCode'] && true}
                                                    onChange={(e) => this.handleMfaChange(1, e)} 
                                                    inputProps={{
                                                        ref:this.mfaCode1,
                                                        size:2,
                                                        maxLength:2,
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    id="mfa-2"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    name="mfaCode3"
                                                    autoComplete="off"
                                                    className='mfaInput'
                                                    value={formData.mfaCodeInd[2]}
                                                    error={formErrors && formErrors['mfaCode'] && true}
                                                    onChange={(e) => this.handleMfaChange(2, e)} 
                                                    inputProps={{
                                                        ref:this.mfaCode2,
                                                        size:2,
                                                        maxLength:2,
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    id="mfa-3"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    autoComplete="off"
                                                    className='mfaInput'
                                                    value={formData.mfaCodeInd[3]}
                                                    error={formErrors && formErrors['mfaCode'] && true}
                                                    onChange={(e) => this.handleMfaChange(3, e)} 
                                                    inputProps={{                                                                        
                                                        ref:this.mfaCode3,
                                                        size:2,
                                                        maxLength:2,
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    id="mfa-4"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    autoComplete="off"
                                                    className='mfaInput'
                                                    value={formData.mfaCodeInd[4]}
                                                    error={formErrors && formErrors['mfaCode'] && true}
                                                    onChange={(e) => this.handleMfaChange(4, e)} 
                                                    inputProps={{
                                                        ref:this.mfaCode4,
                                                        size:2,
                                                        maxLength:2,
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    id="mfa-5"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    autoComplete="off"
                                                    className='mfaInput'
                                                    value={formData.mfaCodeInd[5]}
                                                    error={formErrors && formErrors['mfaCode'] && true}
                                                    onChange={(e) => this.handleMfaChange(5, e)} 
                                                    inputProps={{
                                                        ref:this.mfaCode5,
                                                        size:2,
                                                        maxLength:2,
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>                                     
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    onClick={this.handleSubmit}
                                                    disabled={!this.state.formData.mfaCode || this.state.disableSubmit}
                                                >                            
                                                    Continue
                                                </Button>   
                                            </Grid>             
                                            {storedProfile && (
                                                <Grid item xs={12} align="right">
                                                    <Link href="#" onClick={this.handleClearStoredProfile} variant="body2">
                                                        <Typography variant="caption" component="div">
                                                            {`Not ${storedProfile.firstName} ${storedProfile.lastName}?`}
                                                        </Typography>
                                                    </Link>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </form>
                                )}
                            </Grid>
                        </Grid>
                    )
                )}
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authStaff: (token, profilePhoto) => dispatch(authStaff(token, profilePhoto)),
        handleAppError: (error, message) => dispatch(handleAppError(error, message))
    };
}

export default connect(null, mapDispatchToProps)(ErrorLogin);