import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { TextField, Dialog, DialogTitle, DialogContent, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Tile from 'Components/Common/Dashboard/Tile';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { COLOR_MAIN_SUPPORT_CONTRACTS, COLOR_TEXT_SUPPORT_CONTRACTS } from 'ColorSchemes';
import {
    handleConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationSuccess
} from 'Functions/FormFunctions';
import ActionBadge from 'Components/Common/ActionBadge/ActionBadge';

function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

const styles = theme => ({
    chipCategory: {
        background: '#4CAF50',
        color: '#fff'
    },
    chipSite: {
        background: '#2196F3',
        color: '#fff'
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    warning: {
        backgroundColor: '#FB8C00',
        color: '#FFF'
    }
})

const initialState = {
    activeMappings: {},
    agreementsList: [],
    currentTab: 0,
    categories: [],
    confirmation: {
        submit: false
    },
    dataSave: false,
    expand: {
        sites: {}
    },
    financials: {
        cost: 0.00,
        sales: 0.00,
        profit: 0.00,
        margin: 0
    },
    devicesDialog: {
        open: false,
        siteId: 0,
        dcId: 0
    },
    formData: {},
    initialFormData: {},
    model: {},
    quantity: {},
    isLoading: true,
}

class ScMappingManagement extends React.Component {  

    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this)
        this.handleConfirmationClose = handleConfirmationClose.bind(this)
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this)
    }

    componentDidMount = () => {
        this.props.pageTitle([1, 'Support Contracts', 'Mapping']);
        if(this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            this.loadComponentData(this.props.match.params.id);
        } else {
            this.props.history.push('/support-contracts')
        }
    }

    loadComponentData = () => {
        Promise.all([
            API.get(`/supportContracts/companies/${this.props.match.params.id}`),
            API.get('/supportContracts/categories')
        ])
        .then(([res, cats]) => {
            if(res && cats && res.data && cats.data) {

                /* Vars */
                let activeMappings = {},
                    agreementsList = [],
                    categories = cats.data,
                    expand = initialState.expand,
                    financials = {},
                    formData = {},
                    initialFormData = {},
                    isLoading = false,
                    quantity = {},
                    model = res.data,
                    cost = 0.00,
                    sales = 0.00,
                    profit = 0.00,
                    margin = 0;

                /* Build agreement addition list */
                if(model && model.connectwise_company && model.connectwise_company.agreements) {
                    agreementsList = _.map(model.connectwise_company.agreements, el => {
                        return _.assign({
                            label: el.cwa_parent_id === 0 ? el.cwa_cw_agreement_name : `${_.find(model.connectwise_company.agreements, parent => { return parent.cwa_id === el.cwa_parent_id }).cwa_cw_agreement_name} ⟶ ${el.cwa_cw_agreement_name}`,
                            options: _.map(el.additions, _el => {
                                return _.assign({
                                    value: parseInt(_el.cwaa_id),
                                    label: _el.cwaa_cw_product_identifier,
                                });
                            })
                        });
                    });
                }

                /* Configure expansion state for sites */
                _.each(model.sites, site => {
                    expand = {
                        ...expand, 
                        sites: {
                            [site.sc_site_id]: false
                        }
                    }
                })

                /* Build initialFormData mapping state */
                _.each(categories, category => {
                    _.each(model.sites, site => {
                        let additionId = 0, additionName = "Ignore", adjustment = 0, adjustmentDevices = '', devices = [], data = {}, totalPrice = (0.00).toFixed(2), totalCost = (0.00).toFixed(2), unitCost = (0.00).toFixed(2), unitPrice = (0.00).toFixed(2), qty = 0, siteName = site.sc_site_name;
                        //if(site.categories && site.categories[category.sc_dc_id] && site.categories[category.sc_dc_id].devices_count) {
                            qty = site?.categories[category.sc_dc_id]?.devices?.filter(dev => dev.sc_device_sync === 1)?.length + (site?.categories[category.sc_dc_id]?.addition[0]?.pivot?.scm_adjustment || 0)
                        //}
                        devices = site.categories[category.sc_dc_id].devices;
                        if(site.categories && site.categories[category.sc_dc_id] && site.categories[category.sc_dc_id].addition && site.categories[category.sc_dc_id].addition[0]  && site.categories[category.sc_dc_id].addition[0].cwaa_id) {
                            additionId = parseInt(site.categories[category.sc_dc_id].addition[0].cwaa_id)
                            additionName = site.categories[category.sc_dc_id].addition[0].cwaa_cw_product_identifier
                            adjustment = parseInt(site.categories[category.sc_dc_id].addition[0].pivot.scm_adjustment);
                            adjustmentDevices = site.categories[category.sc_dc_id].addition[0].pivot.scm_adjustment_devices ?? '';
                            data = JSON.parse(site.categories[category.sc_dc_id].addition[0].cwaa_cw_data)
                            unitPrice = data.unitPrice;
                            unitCost = data.unitCost;
                            if(qty > 0) {
                                totalCost = parseFloat((data.unitCost * qty))
                                totalPrice = parseFloat((data.unitPrice * qty))
                                if(isNaN(totalCost))
                                    totalCost = 0
                                if(isNaN(totalPrice))
                                    totalPrice = 0
                                cost = parseFloat((cost + totalCost))
                                sales = parseFloat((sales + totalPrice))
                            }
                        }
                        initialFormData = {
                            ...initialFormData,
                            [site.sc_site_id]: {
                                ...initialFormData[site.sc_site_id],
                                [category.sc_dc_id]: {
                                    additionId,
                                    adjustment,
                                    adjustmentDevices,
                                    devices
                                }
                            }
                        }
                        quantity = {
                            ...quantity,
                            [site.sc_site_id]: {
                                ...quantity[site.sc_site_id],
                                [category.sc_dc_id]: site.categories[category.sc_dc_id].devices_count
                            }
                        }
                        let mapping = {}
                        if(activeMappings && activeMappings[site.sc_site_id] && activeMappings[site.sc_site_id].mapping) {
                            mapping = {
                                ...activeMappings[site.sc_site_id].mapping
                            }
                        }
                        mapping = {
                            ...mapping,
                            [category.sc_dc_id]: {
                                ...category,
                                additionId,
                                additionName,
                                qty,
                                unitCost,
                                unitPrice,
                                totalCost,
                                totalPrice
                            }
                        }
                        activeMappings = {
                            ...activeMappings,
                            [site.sc_site_id]: {
                                ...activeMappings[site.sc_site_id],
                                ...site,
                                siteName,
                                mapping
                            }
                        }
                        // activeAdditions = {
                        //     ...activeAdditions,
                        //     [additionId]: {
                        //         ...activeMappings[site.sc_site_id],
                        //         ...site,
                        //         siteName,
                        //         mapping
                        //     }
                        // }
                    });
                })

                cost = cost.toFixed(2);
                sales = sales.toFixed(2);
                
                profit = parseFloat((sales - cost))
                if(isNaN(profit)) 
                    profit = parseFloat(0);
                profit = profit.toFixed(2)

                margin = (((((sales - cost) / sales).toFixed(2)) * 100).toFixed(2))
                if(isNaN(margin)) 
                    margin = 0;
                
                financials = {
                    cost,
                    sales,
                    profit,
                    margin
                }

                /* Copy initialFormData to formData */
                formData = initialFormData

                /* Set State */
                this.setState({
                    activeMappings,
                    agreementsList,
                    categories,
                    expand,
                    financials,
                    formData,
                    initialFormData,
                    isLoading,
                    model,
                    quantity
                }, () => this.props.pageTitle([1, 'Support Contracts', 'Mapping', this.state.model.sc_comp_name]))
            } else {
                this.props.history.push('/support-contracts')
            }
        })
    }

    handleSelect = (siteId, categoryId, selectedAddition) => {
        const additionId = selectedAddition === null ? 0 : parseInt(selectedAddition.value)
        const adjustment = (selectedAddition === null || selectedAddition.value < 1) ? 0 : this.state.formData[siteId][categoryId].adjustment
        const adjustmentDevices = (selectedAddition === null || selectedAddition.value < 1) ? '' : this.state.formData[siteId][categoryId].adjustmentDevices
        this.setState({
            formData: {
                ...this.state.formData,
                [siteId]: {
                    ...this.state.formData[siteId],
                    [categoryId]: {
                        ...this.state.formData[siteId][categoryId],
                        additionId: additionId,
                        adjustment,
                        adjustmentDevices
                    }
                }
            }
        })
    }

    handleAdjustmentChange = (siteId, categoryId, adjustment) => {
        let adjustmentDevices = this.state.formData[siteId][categoryId].adjustmentDevices
        if(isNaN(adjustment) || parseInt(adjustment) === 0) {
            adjustmentDevices = ''
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [siteId]: {
                    ...this.state.formData[siteId],
                    [categoryId]: {
                        ...this.state.formData[siteId][categoryId],
                        adjustment: isNaN(adjustment) ? 0 : parseInt(adjustment),
                        adjustmentDevices
                    }
                }
            }
        })
    }

    handleAdjustmentDevicesChange = (siteId, categoryId, adjustmentDevices) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [siteId]: {
                    ...this.state.formData[siteId],
                    [categoryId]: {
                        ...this.state.formData[siteId][categoryId],
                        adjustmentDevices
                    }
                }
            }
        })
    }

    handleTabChange = (event, tabIndex) => {
        if(this.state.pendingTab === 0) {
            if((this.state.currentTab === 1 && tabIndex !== 1 && this.state.initialFormData !== this.state.formData)/* || (this.state.currentTab === 2 && tabIndex !== 2 && this.state.initialUserMapping !== this.state.userMapping)*/) {
                this.setState({
                    confirmation: {
                        ...this.state.confirmation,
                        formData: true
                    },
                    pendingTab: tabIndex
                })
                return;
            }
        }
        this.setState({
            currentTab: tabIndex,
            pendingTab: 0
        })
    }

    handleExpand = (type, id) => {
        this.setState({
            expand: {
                ...this.state.expand,
                [type]: {
                    ...this.state.expand[type],
                    [id]: !this.state.expand[type][id]
                }
            }
        })
    }

    mapping = () => {
        const { agreementsList, categories, formData, isLoading, model } = this.state;
        let columns = [
            {
                heading: 'Site Name',
                field: (rowData) => <span className="fw-400">{rowData.sc_site_name}</span>,
                sizeToContent: true
            },
        ];
        let hasAdjErr = false;
        _.each(categories, category => {
            _.each(model.sites, site => {
                let adjQty = formData[site.sc_site_id][category.sc_dc_id].adjustment;
                let adjDevices = formData[site.sc_site_id][category.sc_dc_id].adjustmentDevices;
                let adjDevicesSplit = (adjDevices.length > 0) ? formData[site.sc_site_id][category.sc_dc_id].adjustmentDevices.split(",") : [];
                let adjDevicesSplitQty = adjDevicesSplit.length;
                let emptyDeviceNames = (adjDevicesSplitQty > 0) ? adjDevicesSplit.filter(el => { return el.length === 0 }) : [];
                let adjDevicesErr = (adjQty !== adjDevicesSplitQty) || (emptyDeviceNames.length > 0);
                if(adjDevicesErr) {
                    hasAdjErr = true;
                }
            })
            columns.push(
                {
                    heading: `${category.sc_dc_name} Contract`,
                    field: (rowData) => {
                        let adjQty = formData[rowData.sc_site_id][category.sc_dc_id].adjustment;
                        let adjDevices = formData[rowData.sc_site_id][category.sc_dc_id].adjustmentDevices;
                        let adjDevicesSplit = (adjDevices.length > 0) ? formData[rowData.sc_site_id][category.sc_dc_id].adjustmentDevices.split(",") : [];
                        let adjDevicesSplitQty = adjDevicesSplit.length;
                        let emptyDeviceNames = (adjDevicesSplitQty > 0) ? adjDevicesSplit.filter(el => { return el.length === 0 }) : [];
                        let adjDevicesErr = (adjQty !== adjDevicesSplitQty) || (emptyDeviceNames.length > 0);
                        let billableDeviceQty = formData[rowData.sc_site_id][category.sc_dc_id].devices.filter(dev => dev.sc_device_sync === 1).length;
                        let billableWarningQty = formData[rowData.sc_site_id][category.sc_dc_id].devices.filter(dev => dev.sc_device_sync === 1 && (parseInt(category.sc_dc_id) === 1 ? dev.sc_device_wmi_warning : (parseInt(category.sc_dc_id) === 2 ? dev.sc_device_av_warning : 0)) === 1).length;
                        let totalQty = parseInt(billableDeviceQty) + parseInt(formData[rowData.sc_site_id][category.sc_dc_id].adjustment);
                        let disabledQty = formData[rowData.sc_site_id][category.sc_dc_id].devices.length - billableDeviceQty;
                        return (
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box pb={2}>
                                                <span className="fw-400">Addition</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box minWidth={250} maxWidth={250}>
                                                <FormControl fullWidth>
                                                    <AutoCompleteSelect
                                                        options={[{label:"BlueSync Actions", options: [{label:((totalQty > 0 && "None (Ignore Error)") || "None"), value: -1}]}, ...agreementsList]}
                                                        isGrouped={true}
                                                        placeholder='Not Mapped'
                                                        onChange={v => this.handleSelect(rowData.sc_site_id, category.sc_dc_id, v)}
                                                        value={formData[rowData.sc_site_id][category.sc_dc_id].additionId}
                                                        error={formData[rowData.sc_site_id][category.sc_dc_id].additionId === 0 && totalQty > 0 ? true : false}
                                                        variant="filled"
                                                    />
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pt={2} pb={1}>
                                                <span className="fw-400">Billable</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12}>
                                                    <span>Devices:</span> {`${totalQty}`}
                                                    {(billableWarningQty > 0 && formData[rowData.sc_site_id][category.sc_dc_id].additionId > 0) && (
                                                        <FAIcon icon="exclamation-triangle" style={{marginLeft: 4, color: '#FB8C00'}} size="xs" noMargin />
                                                    )}
                                                    {disabledQty > 0 && (
                                                        <React.Fragment>
                                                            &nbsp;| Disabled Quantity: {disabledQty}
                                                        </React.Fragment>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box pt={1} pb={1}>
                                                        <Button onClick={() => { this.handleDevicesDialogOpen(rowData.sc_site_id, category.sc_dc_id) }} variant="contained" color="primary" size="large" style={{marginTop: 8}}>
                                                            <FAIcon icon={category.sc_dc_icon} buttonPrimary /> VIEW DEVICES
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box pb={2}>
                                                <span className="fw-400">Adjustments</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <Box width={40} textAlign='right'>
                                                        <span >Qty:{'  '}</span>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Box width={50}>
                                                        <FormControl fullWidth margin="none">
                                                            <TextField
                                                                autoComplete="off"
                                                                placeholder="Adjustment Qty:"
                                                                disabled={!(formData[rowData.sc_site_id][category.sc_dc_id].additionId > 0)}
                                                                value={formData[rowData.sc_site_id][category.sc_dc_id].adjustment}
                                                                onChange={(e) => { this.handleAdjustmentChange(rowData.sc_site_id, category.sc_dc_id, e.target.value) }}
                                                                fullWidth
                                                                variant="filled"
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {(formData[rowData.sc_site_id][category.sc_dc_id].additionId && formData[rowData.sc_site_id][category.sc_dc_id].additionId > 0 && !isNaN(formData[rowData.sc_site_id][category.sc_dc_id].adjustment) && (parseInt(formData[rowData.sc_site_id][category.sc_dc_id].adjustment) > 0)) ? (
                                            <Grid item xs={12}>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Box width={40} textAlign='right'>
                                                            <span >Devices:{'  '}</span>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Box width={250} pt={1}>
                                                            <FormControl fullWidth margin="none">
                                                                <TextField
                                                                    autoComplete="off"
                                                                    // placeholder="Device Name(s):"
                                                                    disabled={!(formData[rowData.sc_site_id][category.sc_dc_id].additionId && !isNaN(formData[rowData.sc_site_id][category.sc_dc_id].adjustment) && parseInt(formData[rowData.sc_site_id][category.sc_dc_id].adjustment))}
                                                                    value={formData[rowData.sc_site_id][category.sc_dc_id].adjustmentDevices}
                                                                    onChange={(e) => { this.handleAdjustmentDevicesChange(rowData.sc_site_id, category.sc_dc_id, e.target.value) }}
                                                                    error={adjDevicesErr}
                                                                    fullWidth
                                                                    variant="filled"
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : <React.Fragment />}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }
                },
            )
        })
        return (
            <Paper>
                <DataTable  
                    config={{
                        key: 'sc_site_id',
                        pagination: true,
                        alternatingRowColours: true,
                        isLoading: isLoading,
                        rowsPerPage: 100,
                        plainHeader: true,
                        title: 'Site Device Mapping',
                        actions: 
                            <React.Fragment>
                                <Button 
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    onClick={() => this.handleReset()} 
                                    style={{marginLeft: 6}}
                                    disabled={this.state.isLoading ? true : (this.state.dataSave ? true : (this.state.initialFormData === this.state.formData ? true : false))}
                                >
                                    <FAIcon icon="trash" style={{height: '1em'}} disabled={this.state.isLoading ? true : (this.state.dataSave ? true : (this.state.initialFormData === this.state.formData ? true : false))} swapOpacity button/>
                                    Discard
                                </Button>
                                <Button 
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    onClick={() => this.handleConfirmationOpen('submit')}
                                    style={{marginLeft: 6}} 
                                    disabled={this.state.isLoading ? true : (this.state.dataSave ? true : (this.state.initialFormData === this.state.formData ? true : (hasAdjErr ? true : false)))}
                                >
                                    <FAIcon icon="save" style={{height: '1em'}} disabled={this.state.isLoading ? true : (this.state.dataSave ? true : (this.state.initialFormData === this.state.formData ? true : (hasAdjErr ? true : false)))} swapOpacity button/>
                                    Save
                                </Button>
                            </React.Fragment>
                    }}
                    columns={columns}
                    rows={model.sites}
                />
            </Paper>
        )
    }

    sites = () => {
        const { classes } = this.props;
        const { model:{sites}, expand } = this.state;
        return (
            <Grid container spacing={3}>
                {_.map(sites, (site, idx) => (
                    <Grid item xs={12} lg={3} key={idx}> 
                        <Paper>
                            <CardHeader
                                title={
                                    <Typography variant='h6'>
                                        {site.sc_site_name} 
                                    </Typography>
                                }
                                subheader={
                                    <Typography variant={'caption'} gutterBottom>
                                        {site.devices_count > 0 ? (site.devices_count / 2) : 0} Devices
                                    </Typography>
                                }
                                action={
                                    <IconButton
                                        className={clsx(classes.expand, {
                                            [classes.expandOpen]: expand.sites[site.sc_site_id],
                                        })}
                                        disabled={!site.devices_count}
                                        onClick={() => this.handleExpand('sites', site.sc_site_id)}
                                    >
                                        <FAIcon disabled={!site.devices_count} icon="chevron-down" button noMargin />
                                    </IconButton>  
                                }
                                disableTypography
                            />  
                            <Collapse in={expand.sites[site.sc_site_id]} timeout="auto" unmountOnExit>
                                <CardContent style={{paddingTop: 0}}>
                                    {!_.isEmpty(site.devices) && (      
                                        <Table size="small" padding="none">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2" className="fw-400" color="primary" gutterBottom>
                                                            Device Name
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {_.map(site.devices, (device, idx) => {
                                                    return (
                                                        <TableRow key={idx}>
                                                            <TableCell>
                                                                <Box pt={1} pb={1}>
                                                                    <Typography variant="caption">
                                                                        > {device.sc_device_name}
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    )}
                                </CardContent>   
                            </Collapse>                       
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        )
    }

    // devices = () => {
    //     const { classes } = this.props;
    //     const { model:{devices} } = this.state;
    //     return (
    //         <Grid container spacing={3}>
    //             {_.map(devices, (device, idx) => (
    //                 <Grid item xs={12} lg={3} key={idx}> 
    //                     <Paper>
    //                         <CardHeader
    //                             title={
    //                                 <Typography variant='h6' paragraph>
    //                                     {device.sc_device_name} 
    //                                 </Typography>
    //                             }
    //                             subheader={
    //                                 <Grid container spacing={3}>
    //                                     <Grid item>
    //                                         <Chip
    //                                             avatar={<Avatar alt="Site"><FAIcon size="xs" icon="map-marker" className='textWhite' noMargin /></Avatar>}
    //                                             label={device.site.sc_site_name}
    //                                             size="small"
    //                                             className={classes.chipSite}
    //                                         />
    //                                     </Grid>
    //                                     <Grid item>
    //                                         <Chip
    //                                             avatar={<Avatar alt="Site"><FAIcon size="xs" icon={device.category.sc_dc_icon} className='textWhite' noMargin  /></Avatar>}
    //                                             label={device.category.sc_dc_name}
    //                                             size="small"
    //                                             className={classes.chipCategory}
    //                                         />
    //                                     </Grid>
    //                                 </Grid>
    //                             }
    //                             disableTypography
    //                         />  
    //                     </Paper>
    //                 </Grid>
    //             ))}
    //         </Grid>
    //     )
    // }

    handleReset = () => {
        this.setState({
            isLoading: true
        }, () => this.loadComponentData());
    }

    submit = () => {
        const { formData, model } = this.state;
        this.setState({
            dataSave: true,
        },
        () => {
            API.put(`/supportContracts/companies/${model.sc_comp_id}/mapping`, 
                {
                    formData
                }
            )
            .then(result => {
                this.setState({
                    isLoading: true,
                    dataSave: false
                }, () => {
                    this.props.deploySnackBar("success", "Your support contract addition mapping was successfully saved")
                    this.props.scrollToTop()
                    this.loadComponentData()

                })
            })
        })
    }

    overview = () => {
        const { activeMappings, financials } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} style={{maxHeight: 135}}>
                    <Grid container spacing={3}>
                        <Tile grid xs={6} lg={6} icon="coins" backgroundColor={COLOR_TEXT_SUPPORT_CONTRACTS} data={`£${parseFloat(financials.cost).toFixed(2)}`} title="Service Cost" />
                        <Tile grid xs={6} lg={6} icon="file-invoice-dollar" backgroundColor={COLOR_TEXT_SUPPORT_CONTRACTS} data={`£${parseFloat(financials.sales).toFixed(2)}`} title="Service Turnover" />
                        <Tile grid xs={6} lg={6} icon="sack-dollar" backgroundColor={COLOR_TEXT_SUPPORT_CONTRACTS} data={`£${parseFloat(financials.profit).toFixed(2)}`} title="Service Profit" />
                        <Tile grid xs={6} lg={6} icon="chart-line" backgroundColor={COLOR_TEXT_SUPPORT_CONTRACTS} data={`${parseFloat(financials.margin).toFixed(2)}%`} title="Service Margin" />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {(_.size(activeMappings) > 0 && (
                            _.map(activeMappings, site => (
                                _.map(site.mapping, (mapping, idx) => {
                                    return mapping.additionId > 0 && (
                                        <Grid item xs={12} key={idx}> 
                                            <Paper>
                                                <CardHeader
                                                    subheader={
                                                        <Typography variant={'caption'} gutterBottom>
                                                            {mapping.additionName} | Quantity: {mapping.qty} | Unit Cost: £{parseFloat(mapping.unitCost).toFixed(2)} | Unit Price: £{parseFloat(mapping.unitPrice).toFixed(2)} | Total Cost: £{parseFloat(mapping.totalCost).toFixed(2)} | Total Price: £{parseFloat(mapping.totalPrice).toFixed(2)}
                                                        </Typography>
                                                    }
                                                    title={
                                                        <Typography variant={'h6'}>
                                                            {site.siteName}: {mapping.sc_dc_name} 
                                                        </Typography>
                                                    }
                                                    disableTypography
                                                />  
                                                                    
                                            </Paper>
                                        </Grid>
                                    )
                                })
                            ))
                        ))}    
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    handleDevicesDialogOpen = (siteId, dcId) => {
        this.setState({
            devicesDialog: {
                open: true,
                siteId,
                dcId
            }
        })
    }

    handleDevicesDialogClose = () => {
        this.setState({
            devicesDialog: {
                ...this.state.devicesDialog,
                open: false
            }
        })
    }

    handleSyncToggle = scDeviceId => {
        const { devicesDialog, formData } = this.state;
        const devIdx = formData[devicesDialog.siteId][devicesDialog.dcId].devices.map(d => d.sc_device_id).indexOf(scDeviceId)
        let newData = {...this.state.formData};
        newData[devicesDialog.siteId][devicesDialog.dcId].devices[devIdx].sc_device_sync = newData[devicesDialog.siteId][devicesDialog.dcId].devices[devIdx].sc_device_sync === 1 ? 0 : 1;
        this.setState({
            formData: newData
        })
    }

    devicesDialog = () => {
        const { devicesDialog, activeMappings, formData } = this.state;

        const category = activeMappings[devicesDialog.siteId].categories[devicesDialog.dcId]
        const site = activeMappings[devicesDialog.siteId]
        const devices = [...formData[devicesDialog.siteId][devicesDialog.dcId].devices]
        const adjustmentDevices = formData[devicesDialog.siteId][devicesDialog.dcId].adjustmentDevices

        if(adjustmentDevices.length > 0) {
            let adjDevicesSplit = adjustmentDevices.split(",");
            _.map(adjDevicesSplit, dev => {
                devices.push({
                    sc_device_name: dev,
                    sc_manual: true
                })
            })
        }

        return (
            <Dialog open={devicesDialog.open}
                    onClose={this.handleDevicesDialogClose}
                    maxWidth='md'
                    scroll='body'>
                <DialogTitle>
                    {site.sc_site_name} {category.sc_dc_name}
                </DialogTitle>
                <Typography component={DialogContent} variant="body2" style={{marginBottom: 24}}>
                    <DataTable
                        config={{
                            key: 'name',
                            plainHeader: true,
                            dense: true
                        }}
                        columns={[
                            {
                                heading: 'Name',
                                field: rowData => {
                                    return ((rowData.sc_manual && (
                                        <Typography variant="body2">
                                            <Box pt={1} pb={1} minWidth={200}>
                                                <em>{rowData.sc_device_name}</em>
                                            </Box>
                                        </Typography>
                                    ))
                                    || (
                                        <Box pt={1} pb={1} minWidth={200}>
                                            <Typography variant="body2">
                                                {rowData.sc_device_name}
                                            </Typography>
                                        </Box>
                                    ))
                                }
                            },
                            {
                                heading: '',
                                field: rowData => {
                                    return (
                                        parseInt(devicesDialog.dcId) === 1 ? (
                                            (rowData.sc_device_wmi_warning ? (
                                                <Tooltip title={'This is a Windows device, but WMI is not enabled.'}>
                                                    <div><FAIcon icon="exclamation-triangle" style={{color: '#FB8C00'}} noMargin /></div>
                                                </Tooltip>
                                            ) : (
                                                <React.Fragment />
                                            ))
                                        ) : (
                                            parseInt(devicesDialog.dcId) === 2 ? (
                                                (rowData.sc_device_av_warning ? (
                                                    <Tooltip title={'This device does not have Avast installed.'}>
                                                        <div><FAIcon icon="exclamation-triangle" style={{color: '#FB8C00'}} noMargin /></div>
                                                    </Tooltip>
                                                ) : (
                                                    <React.Fragment />
                                                ))
                                            ) : (
                                                <React.Fragment />
                                            )
                                        )
                                    )
                                }
                            },
                            {
                                heading: 'Sync',
                                field: rowData => (
                                    <IconButton
                                        style={{
                                            padding: 0
                                        }}
                                        disabled={rowData.sc_manual}
                                        onClick={() => this.handleSyncToggle(rowData.sc_device_id)}
                                        color='inherit'
                                    >
                                        <FAIcon
                                            icon={
                                                (rowData.sc_device_sync === 1 && 'check-circle')
                                                ||                                                        
                                                (rowData.sc_device_sync === 0 && 'times-circle')
                                                || 
                                                'check-circle'
                                            }
                                            style={{
                                                color: 
                                                    (rowData.sc_device_sync === 1 && !rowData.sc_manual && '#388E3C')
                                                    ||                                                        
                                                    (rowData.sc_device_sync === 0 && !rowData.sc_manual && '#d9534f')
                                                    || 
                                                    '#BDBDBD'
                                                }}
                                            disabled={rowData.sc_manual}
                                            noMargin
                                            button
                                        />
                                    </IconButton>
                                ),
                                alignment: 'center'
                            },
                        ]}
                        rows={devices}
                    />
                </Typography>
            </Dialog>
        )
    }

    render() {
        const { confirmation, currentTab, model, isLoading } = this.state;
        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>     
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item>
                                                <FAIcon type='thin' icon="desktop" size={85} style={{color: COLOR_MAIN_SUPPORT_CONTRACTS }} />
                                            </Grid>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h3" className="fw-300">
                                                            {model.sc_comp_name}
                                                        </Typography>
                                                    </Grid>    
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="link-horizontal"
                                                                    label={`Mapped: ${model.connectwise_company.cust_name}`}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="buildings"
                                                                    label={`Sites: ${model.sites_count}`}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="desktop"
                                                                    label={`Devices: ${model.devices_count}`}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="calendar-alt"
                                                                    label={`Created: ${model.sc_comp_created_datetime === '0000-00-00 00:00:00' ? 'Unknown' : moment(model.sc_comp_created_datetime).format('MMM D, YYYY')}`}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="check"
                                                                    label={`Sync: ${model.sc_comp_sync === 1 ? 'Enabled' : 'Disabled'}`}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>     
                                                </Grid> 
                                            </Grid>                                     
                                        </Grid>                                     
                                    </Grid> 
                                    <Grid item xs={12}>
                                        <Paper>
                                            <AppBar style={{backgroundColor: COLOR_MAIN_SUPPORT_CONTRACTS}} position="static" elevation={0}>    
                                                <Tabs value={currentTab} onChange={this.handleTabChange} variant="scrollable">
                                                    <Tab disableRipple value={0} label="Overview" disabled={this.state.initialFormData !== this.state.formData} />
                                                    <Tab disableRipple value={1} label={<ActionBadge badgeContent={model.error_actions_count} variant="error">Mapping</ActionBadge>} />
                                                    <Tab disableRipple value={2} label={`Sites`} disabled={this.state.initialFormData !== this.state.formData} />
                                                    {/* <Tab disableRipple value={3} label={`Devices (${model.devices_count})`} /> */}
                                                </Tabs>
                                            </AppBar>
                                        </Paper>
                                    </Grid>     
                                    <Grid item xs={12}>  
                                        <TabPanel value={currentTab} index={0}>
                                            {this.overview()}
                                        </TabPanel>   
                                        <TabPanel value={currentTab} index={1}>
                                            {this.mapping()}
                                        </TabPanel>  
                                        <TabPanel value={currentTab} index={2}>
                                            {this.sites()}
                                        </TabPanel>  
                                        {/* <TabPanel value={currentTab} index={3}>
                                            {this.devices()}
                                        </TabPanel>   */}
                                    </Grid>
                                </Grid>             
                            </Grid>
                            <ConfirmationDialog
                                open={confirmation.submit} 
                                success={() => this.handleConfirmationSuccess('submit')} 
                                close={() => this.handleConfirmationClose('submit')} 
                                title="Save Site Device Mapping?" 
                                message="Are you sure you want to save these changes?"
                            /> 
                        </React.Fragment>       
                    )}
                </Grid>
                {this.state.devicesDialog.open && this.devicesDialog()}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ScMappingManagement));