import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';

import StaffMember from 'Components/Common/Staff/StaffMember';
import Avatar from 'Components/Common/Avatars/Avatar';
import { handleClick, handleClose } from 'Functions/PopperFunctions';

const initialState = {
    anchorEl: null
}

class AdminUser extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleClick = handleClick.bind(this);
        this.handleClose = handleClose.bind(this);
    }
    
    render() {

        const { 
            device,
            loggedInStaff,
            notifications:{status}
        } = this.props;

        const { 
            anchorEl 
        } = this.state;

        const open = Boolean(anchorEl);

        let staffId = loggedInStaff?.id ?? null,
            team = _.filter(status, el => el.status !== 0 && el.id !== staffId);

        return (
            (staffId && (
                <>
                    <ClickAwayListener onClickAway={this.handleClose}>
                        <div>
                            <IconButton
                                aria-controls='team-menu'
                                aria-haspopup="true"
                                size="small"
                                onClick={this.handleClick}
                            >
                                <Avatar 
                                    staffId={staffId}
                                    size={this.props.size ?? (device?.isTablet ? 27.5 : 30)}
                                />
                            </IconButton>
                            <Popper 
                                id={open ? 'team-menu' : undefined} 
                                open={open}
                                anchorEl={anchorEl}
                                className={`dd-menu`}
                                style={{marginTop: device?.isTablet ? 6.5 : 8.5}}
                                disablePortal={true} 
                            >
                                <Paper>
                                    <Box pl={2} pt={2}>
                                        <Typography component="div" variant="h6">
                                            Online Users
                                        </Typography>
                                    </Box>
                                    {(!_.isEmpty(team) && (
                                        <List disablePadding>
                                            {_.map(team, (staff, idx) => (
                                                <StaffMember
                                                    key={idx}
                                                    staff={staff}
                                                />
                                            ))}
                                        </List>
                                    )) || (
                                        <Grid container alignItems='center' className='sm-menu-text'>
                                            <Grid item xs={12}>
                                                <Typography component="div" variant="body2">
                                                    No other users are online
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Paper>
                            </Popper>
                        </div>
                    </ClickAwayListener>
               </>
            )) || (
                null
            )
        )
    }
}

const mapStateToProps = state => ({
		loggedInStaff: state.staffAuth.staff,
        notifications: state.notifications
})

export default connect(mapStateToProps)(AdminUser);