import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import StatusChip from 'Components/Common/Chips/StatusChip';
import StaffChip from 'Components/Common/Chips/StaffChip';
import InsightChip from 'Components/Common/Chips/InsightChip';

const initialState = {
    batch: {},
    isLoading: true
}

class AccountsInvoiceBatchView extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.props.pageTitle([1, 'Accounts', 'Batch Invoices']);
        this.loadComponentData();
    }

    loadComponentData = () => {
        API.get(`/bluesync/accounts/batches/invoices/${this.props.match.params.id}`)
        .then(result => {
            if(result.data) {
                this.setState({
                    batch: result.data,
                    isLoading: false
                }, () => this.props.pageTitle([1, 'Accounts', 'Batch Invoices', this.state.batch.bi_identifier]))
            }
        });
    }

    render() {
        const { staff } = this.props;
        const { batch, isLoading } = this.state;
        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (    
                    <React.Fragment>
                        <Grid item xs={12}>     
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item>
                                    <FAIcon type="thin" icon="file-invoice" size={70} />
                                </Grid>
                                <Grid item xs>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item xs={12}>
                                            <Typography variant="h3" className="fw-300">
                                                {batch.bi_identifier}
                                            </Typography>
                                        </Grid>    
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <StaffChip staff={staff?.[batch?.bi_staff_id ?? 0]} label="Imported by: " />
                                                </Grid>
                                                <Grid item>
                                                    <InsightChip 
                                                        icon="check"
                                                        label={`Imported on: ${moment(batch.bi_created_datetime).format('MMM DD, YYYY')}`}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <StatusChip invert status={batch.bi_status} label='Status: ' />
                                                </Grid>
                                            </Grid>
                                        </Grid>     
                                    </Grid> 
                                </Grid>                                     
                            </Grid>                                     
                        </Grid> 
                        <Grid item xs={12}>
                            <Paper>
                                <DataTable  
                                    config={{
                                        key: 'bii_id',
                                        pagination: true,
                                        alternatingRowColours: true,
                                        responsiveImportance: true,
                                        plainHeader: true, 
                                        title: 'Batch Invoices'
                                    }}
                                    columns={[
                                        {
                                            heading: 'Posted',
                                            field: rowData => rowData.bii_status !== "Completed" ? <FAIcon icon="times" style={{color: '#c62828'}} noMargin/> : <FAIcon icon="check" style={{color: '#4CAF50'}} noMargin/>,
                                            sizeToContent: true,
                                            alignment: 'center'
                                        },
                                        {
                                            heading: 'Invoice #',
                                            field: rowData => rowData.bii_txn_ref,
                                            important: true,
                                        },
                                        {
                                            heading: 'Date',
                                            field: rowData => moment(rowData.bii_invoice_date).format("DD/MM/YYYY"),
                                        },
                                        {
                                            heading: 'Due Date',
                                            field: rowData => JSON.parse(rowData.bii_data).dueDate,
                                            fieldFormat: "date",
                                        },
                                        {
                                            heading: 'Total',
                                            field: rowData => `£${parseFloat(JSON.parse(rowData.bii_data).total).toFixed(2)}`,
                                        },
                                        {
                                            heading: 'VAT',
                                            field: rowData => `£${parseFloat(JSON.parse(rowData.bii_data).salesTax).toFixed(2)}`,
                                        },
                                        {
                                            heading: 'Taxable',
                                            field: rowData => JSON.parse(rowData.bii_data).taxable,
                                            fieldFormat: "boolean", 
                                            alignment: 'center',
                                        },
                                        {
                                            heading: 'Tax Code',
                                            field: rowData => JSON.parse(rowData.bii_data).stateTaxXref,
                                            alignment: 'center',
                                        },
                                        {
                                            heading: 'Company',
                                            field: rowData => rowData.company?.cust_name, 
                                        },
                                        {
                                            heading: 'Post Account',
                                            field: rowData => rowData.bii_sage_ref, 
                                        },
                                        {
                                            heading: 'Status',
                                            field: rowData => <StatusChip status={rowData.bii_status} />, 
                                        }
                                    ]}
                                    rows={batch.items}
                                />
                            </Paper>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    staff: state.notifications.status
})

export default connect(mapStateToProps, null)(AccountsInvoiceBatchView);