import React from 'react';
import _ from 'lodash';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
class StaffAccessItems extends React.PureComponent {
    render() {
        const { accessItems, parentId, toggleAccess, checkAllToggle, staffAccess, showPageExample, dataLoading } = this.props;
        const filteredAccessItems = (this.props.parentId && _.filter(accessItems, function (el) { return el.access_id === parentId})) || accessItems;
        return (
            (dataLoading && (
                <LoadingCircle />
            )) || (
                _.map(filteredAccessItems, item => {
                    let nestedDepth = (this.props.nestedDepth ? this.props.nestedDepth : 1);
                    return (
                        <React.Fragment key={item.access_id}>
                            {(nestedDepth === 1 && (
                                <PaddedPaper style={{marginBottom: 24}}>
                                    <Grid container spacing={3} alignItems='center'>
                                        <Grid item xs>
                                            <Typography variant="h6">
                                                {item.access_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{paddingRight: 25}}>
                                            <Checkbox 
                                                color="primary"
                                                name={item.access_id.toString()}
                                                onChange={item.children ? checkAllToggle : toggleAccess}
                                                checked={staffAccess.hasOwnProperty(item.access_id)} 
                                            />
                                        </Grid>
                                    </Grid>
                                    <List style={{width:'100%'}}>
                                        {(item.children && (
                                            item.children && (
                                                <StaffAccessItems checkAllToggle={checkAllToggle} toggleAccess={toggleAccess} staffAccess={staffAccess} accessItems={item.children} nestedDepth={nestedDepth + 1} showPageExample={showPageExample} />
                                            )
                                        )) || (
                                            <React.Fragment>
                                                <ListItem style={{paddingLeft: (50 * (nestedDepth - 2))}} key={item.access_id} dense>
                                                    <Checkbox color="primary"
                                                            name={item.access_id.toString()}
                                                            onClick={toggleAccess}
                                                            checked={staffAccess.hasOwnProperty(item.access_id)} />
                                                    <ListItemText>
                                                        {item.access_name}
                                                    </ListItemText>
                                                    {item.access_route_example && (
                                                        <ListItemSecondaryAction>
                                                            <IconButton onClick={() => { showPageExample(item.access_route_example) }}>
                                                                <FAIcon icon="external-link-alt" size="small" button noMargin style={{color:'#375885'}} />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    )}
                                                </ListItem>
                                            </React.Fragment>
                                        )}
                                    </List>
                                </PaddedPaper>
                            )) || (
                                <React.Fragment>
                                    <ListItem style={{paddingLeft: (50 * (nestedDepth - 2))}} key={item.access_id} dense>
                                        <Checkbox 
                                            color="primary"
                                            name={item.access_id.toString()}
                                            onChange={item.children ? checkAllToggle : toggleAccess}
                                            checked={staffAccess.hasOwnProperty(item.access_id)} 
                                        />
                                        <ListItemText>
                                            {item.access_name}
                                        </ListItemText>
                                        {item.access_route_example && (
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => { showPageExample(item.access_route_example) }}>
                                                    <FAIcon icon="external-link-alt" size="small" button noMargin style={{color:'#375885'}} />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        )}
                                    </ListItem>
                                    {item.children && (
                                        <StaffAccessItems checkAllToggle={checkAllToggle} toggleAccess={toggleAccess} staffAccess={staffAccess} accessItems={item.children} nestedDepth={nestedDepth + 1} showPageExample={showPageExample} />
                                    )}
                                </React.Fragment>
                            )} 
                        </React.Fragment>
                    )
                })
            )
        ) 
    }
}

export default StaffAccessItems;