/* 
 * Crushed Ice - React Pagination
 * Version 1.00
 */
import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import FAIcon from '../Icons/FontAwesome/FAIcon';

const actionsStyles = theme => ({
    root: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2.5),
    },
    [theme.breakpoints.down('sm')]: {
        root: {
            marginLeft: 0,
            width: '100%',
            textAlign: 'center'
        },
    },
});

class TablePaginationActions extends Component {
    handleFirstPageButtonClick = event => {
      this.props.onChangePage(event, 0);
    };
  
    handleBackButtonClick = event => {
      this.props.onChangePage(event, this.props.page - 1);
    };
  
    handleNextButtonClick = event => {
      this.props.onChangePage(event, this.props.page + 1);
    };
  
    handleLastPageButtonClick = event => {
      this.props.onChangePage(
        event,
        Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
      );
    };
  
    render() {
      const { classes, count, page, rowsPerPage, theme } = this.props;
  
      return (
        <div className={classes.root}>
          <IconButton
            onClick={this.handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="First Page"
          >
            {theme.direction === 'rtl' ? <FAIcon icon="step-forward" size="small" button noMargin disabled={page === 0} /> : <FAIcon icon="step-backward" size="small" button noMargin disabled={page === 0} />}
          </IconButton>
          <IconButton
            onClick={this.handleBackButtonClick}
            disabled={page === 0}
            aria-label="Previous Page"
          >
            {theme.direction === 'rtl' ? <FAIcon icon="chevron-right" size="small" button noMargin disabled={page === 0} /> : <FAIcon icon="chevron-left" size="small" button noMargin disabled={page === 0} />}
          </IconButton>
          <IconButton
            onClick={this.handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Next Page"
          >
            {theme.direction === 'rtl' ? <FAIcon icon="chevron-left" size="small" button noMargin disabled={page >= Math.ceil(count / rowsPerPage) - 1} /> : <FAIcon icon="chevron-right" size="small" button noMargin disabled={page >= Math.ceil(count / rowsPerPage) - 1} />}
          </IconButton>
          <IconButton
            onClick={this.handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Last Page"
          >
            {theme.direction === 'rtl' ? <FAIcon icon="step-backward" size="small" button noMargin disabled={page >= Math.ceil(count / rowsPerPage) - 1} /> : <FAIcon icon="step-forward" size="small" button noMargin disabled={page >= Math.ceil(count / rowsPerPage) - 1} />}
          </IconButton>
        </div>
      );
    }
}

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
    TablePaginationActions,
);

class Pagination extends Component {
    render() {
        return (
            <React.Fragment>
                <TablePagination
                  style={{borderBottom: this.props.plainPagination && 'none'}}
                  colSpan={this.props.colSpan}
                  count={this.props.count}
                  rowsPerPage={this.props.rowsPerPage}
                  page={this.props.page}
                  onChangePage={this.props.onChangePage}
                  onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}   
                  labelDisplayedRows={({ from, to, count }) => { return this.props.isMobile ? '' :  `${from}-${to} of ${count}`; }}
                  labelRowsPerPage={(this.props.isMobile || this.props.noRowSelector) ? '' : 'Rows per page:'}                   
                  rowsPerPageOptions={(this.props.isMobile || this.props.noRowSelector)  ? [] : [5, 10, 25, 50, 100]} 
                />
            </React.Fragment>
        );
    }
}
  
export default Pagination;