import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import MuiAvatar from '@material-ui/core/Avatar';

import StatusIndicator from 'Components/Common/Badges/StatusIndicator';
import { CLOUDFRONT_URL } from 'Constants';

const Avatar = ({staffId, size}) => {
    let staff = useSelector(state => state.notifications.status);
    if(staff?.[staffId]) {
        if(!_.isEmpty(staff?.[staffId]?.picture)) {
            return (
                <StatusIndicator status={staff?.[staffId].status}>
                    <MuiAvatar alt={staff[staffId]?.name} src={`${staff?.[staffId]?.picture}`} style={{width: size ?? 32, height: size ?? 32}} />
                </StatusIndicator>
            )
        } else {
            return (
                <StatusIndicator status={staff?.[staffId].status}>
                    <MuiAvatar alt='Default Avatar' src={`${CLOUDFRONT_URL}resources/images/avatar.png`} style={{width: size ?? 32, height: size ?? 32}} />
                </StatusIndicator>
            )
        }
    } else {
        return (
            <MuiAvatar alt='Default Avatar' src={`${CLOUDFRONT_URL}resources/images/avatar.png`} style={{width: size ?? 32, height: size ?? 32}} />
        )
    }

}

export default Avatar;