import React from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Helmet } from "react-helmet";

import API from 'API';
import AdminContentWrapper from 'Components/Layout/AdminLayout/AdminContentWrapper';
import AdminDialogs from 'Components/Layout/AdminLayout/AdminDialogs';
import AdminFooter from 'Components/Layout/AdminLayout/AdminFooter';
import AdminHeader from 'Components/Layout/AdminLayout/AdminHeader';
import AdminNav from 'Components/Layout/AdminLayout/AdminNav';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import Dialog from 'Components/Common/Dialogs/Dialog';
import ErrorDialog from 'Components/Common/ErrorHandling/ErrorDialog'
import RequireAuth from 'Components/RequireAuth';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { handleAppError } from 'Redux/Actions/UI/UI';

const initialState = () => ({
    appError: {
        key: uuidv4(),
        detail: "",
        state: false,
    }, 
    blueSyncDialog: {
        selection: false,
        accounts: false,
        import: false,
        sync: false
    },
    confirmation: {
        key: uuidv4(),
        open: false,
        message: '',
        close: () => {},
        success: () => {},
        successText: null,
        closeText: null,
        successOnly: false,
        disableExit: false
    }, 
    dialog: {
        key: uuidv4(),
        dialogOpen: false,
        disableDialogContent: false,
        dialogContent: '',
        dialogTitle: '',
        dialogVariant: '',
        dialogSize: '',
        dialogFullScreen: false,
        dialogDisableExit: false
    },
    drawers: {
        nav: true,
    },
    isMobile: false,
    pagePath: '',
    pageTitle: []
})
class AdminLayout extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount = () => {

        const {
            enqueueSnackbar
        } = this.props;
        

        const {
            ui,
            confirmation,
            dialog,
            history,
            snackbar
        } = this.props;

        if(!_.isEmpty(snackbar.message)) {
            enqueueSnackbar(snackbar.message, { variant: snackbar.variant })
        }

        this.setState({
            appError: {
                ...this.state.appError,
                detail: ui.appError.detail,
                state: ui.appError.state
            },
            confirmation: {
                ...this.state.confirmation,
                open: confirmation.open,
                message: confirmation.message,
                close: confirmation.close,
                success: confirmation.success,
                successText: confirmation.successText,
                closeText: confirmation.closeText,
                successOnly: confirmation.successOnly,
                disableExit: confirmation.disableExit
            },
            dialog: {
                ...this.state.dialog,
                dialogOpen: dialog.dialogOpen,
                dialogContent: dialog.dialogContent,
                dialogTitle: dialog.dialogTitle,
                disableDialogContent: dialog.disableDialogContent,
                dialogVariant: dialog.dialogVariant,
                dialogSize: dialog.dialogSize,
                dialogFullScreen: dialog.dialogFullScreen,
                dialogDisableExit: dialog.dialogDisableExit
            },
            isMobile: ui.device.isMobile,
            drawers: {
                ...this.state.drawers,
                nav: ui.device.isMobile ? false : true
            },
            pagePath: history.location.pathname
        })        
    }

    componentDidUpdate = prevProps => {

        const {
            ui,
            confirmation,
            dialog,
            enqueueSnackbar,
            snackbar
        } = this.props;

        if(snackbar.key !== prevProps.snackbar.key) { 
            if(!_.isEmpty(snackbar.message)) {
                enqueueSnackbar(snackbar.message, { variant: snackbar.variant })
            }
        }

        if(ui.appError.state !== prevProps.ui.appError.state) {
            this.setState({
                appError: {
                    ...this.state.appError,
                    key: uuidv4(),
                    state: ui.appError.state,
                    detail: ui.appError.detail
                }
            });
        }

        if(confirmation.message !== prevProps.confirmation.message) {
            this.setState({
                confirmation: {
                    ...this.state.confirmation,
                    key: uuidv4(),
                    open: confirmation.open,
                    message: confirmation.message,
                    close: confirmation.close,
                    success: confirmation.success,
                    successText: confirmation.successText,
                    closeText: confirmation.closeText,
                    successOnly: confirmation.successOnly,
                    disableExit: confirmation.disableExit
                }
            });
        }

        if(dialog.dialogContent !== prevProps.dialog.dialogContent) {
            this.setState({
                dialog: {
                    ...this.state.dialog,
                    key: uuidv4(),
                    dialogOpen: dialog.dialogOpen,
                    dialogContent: dialog.dialogContent,
                    disableDialogContent: dialog.disableDialogContent,
                    dialogTitle: dialog.dialogTitle,
                    dialogVariant: dialog.dialogVariant,
                    dialogSize: dialog.dialogSize,
                    dialogFullScreen: dialog.dialogFullScreen,
                    dialogDisableExit: dialog.dialogDisableExit
                }
            });
        }

        if(ui.device.isMobile !== prevProps.ui.device.isMobile) {
            this.setState({
                isMobile: ui.device.isMobile,
                drawers: {
                    ...this.state.drawers,
                    nav: ui.device.isMobile ? false : true
                }
            });
        }

    }
    
    toggleDrawer = type => {
        this.setState({
            drawers: {
                ...this.state.drawers,
                [type]: !this.state.drawers[type]
            }
        });
    }

    changePath = path => {
        if(path !== this.state.pagePath){
            this.setState({
                drawers: {
                    ...this.state.drawers,
                    nav: this.state.isMobile === true ? false : this.state.drawers.nav
                },
                pagePath: path,
            });
        } else { 
            this.setState({
                drawers: {
                    ...this.state.drawers,
                    nav: this.state.isMobile === true ? false : this.state.drawers.nav
                }
            });
        }
    }

    handleDialogClose = () => {
        this.props.closeDialog();
    }

    handleBlueSyncDialogClose = type => {
        this.setState({
            blueSyncDialog: {
                ...this.state.blueSyncDialog,
                [type]: false
            },
        });
    };

    handleBlueSyncDialogOpen = type => {
        API.get('/bluesync/import/lastImported', { props: { noLoading: true }})
        .then(result => {       
            this.setState({
                blueSyncDialog: {
                    ...this.state.blueSyncDialog,
                    [type]: true
                },          
            });
        })
    };

    handleBlueSyncDialogSelection = type => {
        this.setState({
            blueSyncDialog: {
                ...this.state.blueSyncDialog,
                selection: false,
                [type]: true
            },
        });
    }

    pageTitle = title => {
        if(title !== this.state.pageTitle){
            this.setState({
                pageTitle: title
            }, () => {
                // update html page title
            });
        } 
    }

    render() {
        const { history, location, badges, ui } = this.props;
        const { appError, blueSyncDialog, confirmation, dialog, drawers, pagePath, pageTitle } = this.state;

        let thisPage    = _.size(pageTitle) > 2 ? _.join(_.filter(pageTitle, (el, idx) => !_.isNumber(el) && idx !== 1), " > ") : _.join(_.filter(pageTitle, el => !_.isNumber(el)), " > "),
            ogTitle     = _.isEmpty(thisPage) ? `Loading | BlueSync` : `${thisPage} | BlueSync`;

        return (
            <>
                <Helmet>
                    <title>{ogTitle}</title>
                    <meta property="og:title" content={ogTitle} />
                </Helmet>
                <AdminHeader    
                    history={history}
                    location={location}
                    toggleDrawer={this.toggleDrawer}
                    handleDialogOpen={this.handleBlueSyncDialogOpen}
                />
                <AdminNav   
                    history={history}
                    navOpen={drawers.nav}
                    notifications={badges}
                    toggleDrawer={this.toggleDrawer}
                    pagePath={pagePath} 
                    scrollHeight={ui.device.height}
                    ui={ui}
                />
                <AdminContentWrapper    
                    changePath={this.changePath}
                    location={location}
                    history={history}
                    drawers={drawers}
                    page={pageTitle}
                    pageTitle={this.pageTitle}    
                    pagePath={pagePath}
                    scrollHeight={ui.device.height}
                    ui={ui}
                />
                <AdminFooter />
                <AdminDialogs 
                    dialog={blueSyncDialog}
                    handleDialogSelection={this.handleBlueSyncDialogSelection}
                    handleDialogOpen={this.handleBlueSyncDialogOpen}
                    handleDialogClose={this.handleBlueSyncDialogClose}
                />
                <ConfirmationDialog 
                    open={confirmation.open} 
                    success={confirmation.success} 
                    close={confirmation.close} 
                    successText={confirmation.successText}
                    closeText={confirmation.closeText}
                    successOnly={confirmation.successOnly}
                    message={confirmation.message}
                    disableExit={confirmation.disableExit}
                /> 
                <Dialog 
                    key={dialog.key}
                    dialogOpen={dialog.dialogOpen}
                    dialogContent={dialog.dialogContent}
                    disableDialogContent={dialog.disableDialogContent}
                    dialogTitle={dialog.dialogTitle}
                    dialogVariant={dialog.dialogVariant}
                    dialogSize={dialog.dialogSize}
                    handleDialogClose={this.handleDialogClose}
                    dialogFullScreen={dialog.dialogFullScreen}
                    dialogDisableExit={dialog.dialogDisableExit}
                />
                {appError.state && (
                    <ErrorDialog
                        key={appError.key}
                        state={appError.state}
                        detail={appError.detail}
                        history={history}
                        closeError={() => this.props.handleAppError(false, "")}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = state => ({
        confirmation: state.confirmation,
        dialog: state.dialog,
        badges: state.notifications.badges,
        notifications: state.notifications,
        snackbar: state.snackbar,
        ui: state.ui,
});

const mapDispatchToProps = dispatch => ({
        handleAppError: (error, message) => dispatch(handleAppError(error, message)),
        closeDialog: () => dispatch(closeDialog()),
})

export default RequireAuth(connect(mapStateToProps, mapDispatchToProps)(withSnackbar(AdminLayout)));