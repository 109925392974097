import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MaterialLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ActionBadge from 'Components/Common/ActionBadge/ActionBadge';
import NotificationBadge from 'Components/Common/Badges/NotificationBadge';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const styles = theme => ({
    link: {
        marginLeft: 0,
        textDecoration: 'none!important',
        color: '#000'
    },
    subLink: {
        textDecoration: 'none!important',
        color: '#000'
    },
});

const initialState = {
    open: false,
}
class AdminNavItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        if(this.props.navId === this.props.selectedNavId){
            this.setState({
                open: true,
            });
        } else {
            if(this.props.selectedNavId === false){
                if(typeof this.props.submenu !== "undefined"){ 
                    this.props.submenu.forEach(menu => {
                        if(menu.access_route.length > 0 && this.props.pagePath.startsWith(menu.access_route)){
                            this.props.onClick(this.props.navId);
                        }
                    })
                }
            }
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.selectedNavId !== this.props.selectedNavId){
            if(this.props.navId === this.props.selectedNavId) {
                this.setState({
                    open: true,
                });
            } else {
                if(this.state.open === true){
                    this.setState({
                        open: false,
                    });
                }
            }
        }
    }

    checkExpand() {
        if(typeof(this.props.link) === "undefined") {
            return(
                <>
                    {this.state.open ? <FAIcon type="light" size="small" icon="chevron-up" noMargin button /> : <FAIcon type="light" size="small" icon="chevron-down" noMargin button />}
                </>
            );
        }
    }

    checkNavOpen() {
        if(this.props.navOpen === false) {
            return false
        } else {
            return this.state.open
        }
    }

    renderActionBadges = () => {

        const {
            badges
        } = this.props;

        return (
            <Grid container>
                <Grid item xs />
                <Grid item>
                    {badges?.['action-center-success'] > 0 && (
                        <ActionBadge badgeContent={badges?.['action-center-success']} variant="info" />
                    )}
                    {badges?.['action-center-notice'] > 0 && (
                        <ActionBadge badgeContent={badges?.['action-center-notice']} variant="warning" style={{paddingRight: 30}} />
                    )}
                    {badges?.['action-center-error'] > 0 && (
                        <ActionBadge badgeContent={badges?.['action-center-error']} variant="error"  style={{paddingRight: 30}} />
                    )}
                </Grid>
            </Grid>
        )

    }

    render() {

        const { classes, notifications, ui } = this.props;

        let subNotifications = 0, 
            toggleButton = null;

        if(typeof(this.props.submenu) !== undefined) {
            _.each(this.props.submenu, sub => {
                if(notifications[sub.access_reference] && notifications[sub.access_reference] > 0) {
                    subNotifications = (subNotifications + notifications[sub.access_reference])
                }
            })
        }

        if(subNotifications === 0) {
            subNotifications = notifications[this.props.reference] ?? 0
        }

        const   color = (((this.state.open && _.some(this.props.submenu, el => el.access_route === this.props.pagePath || (this.props.pagePath === el.access_route || (el.access_route?.match?.(/\//g)?.length > 1 && this.props.pagePath.startsWith?.(el.access_route))) )) || (this.props.pagePath === this.props.link || (this.props?.link?.match(/\//g)?.length > 1 && this.props.pagePath.startsWith(this.props.link))))) ? this.props.color : 'inherit',
                fontColor = (((this.state.open && _.some(this.props.submenu, el => el.access_route === this.props.pagePath || (this.props.pagePath === el.access_route || (el.access_route?.match?.(/\//g)?.length > 1 && this.props.pagePath.startsWith?.(el.access_route))) )) || (this.props.pagePath === this.props.link || (this.props?.link?.match(/\//g)?.length > 1 && this.props.pagePath.startsWith(this.props.link))))) ? '#fff' : this.props.color;

        return (
            <>
                <div id={`adminNavItem-${this.props.navId}`}>
                    {(typeof(this.props.link) !== "undefined" && ( 
                        <Link 
                            className={classes.link} 
                            to={this.props.link} 
                        > 
                            <ListItem 
                                button 
                                onClick={() => { typeof this.props.submenu !== "undefined" && this.props.onClick(this.props.navId) }} 
                                style={{
                                    // maxHeight: ui?.device?.isTablet ? 30 : 36,
                                    paddingTop: 19, 
                                    paddingBottom: 19, 
                                    color: fontColor,
                                    backgroundColor: color
                                }}
                            >
                                <ListItemIcon> 
                                    <FAIcon 
                                        icon={this.props.icon} 
                                        type={this.props.icon === "microsoft" ? "brand" : "thin"}
                                        size={this.props.navOpen ? 30 : 20}
                                        color={fontColor}
                                        button 
                                        fixedWidth 
                                    />
                                </ListItemIcon>
                                <ListItemText 
                                    disableTypography
                                    primary={<Typography variant="h6" style={{color: 'inherit', fontWeight: 300, fontSize: '0.9rem'}}>{this.props.name}{toggleButton}</Typography>} 
                                />
                                {<NotificationBadge variant={this.props.navOpen ? `standard` : `dot`} color="error" badgeContent={subNotifications} style={{position: 'absolute', right: this.props.navOpen ? 50 : 9, top: '50%'}} />}
                                {this.checkExpand()} 
                                {this.props.name === 'Action Center' && this.renderActionBadges()}    
                            </ListItem>
                        </Link>
                    )) || (
                        <ListItem 
                            button 
                            onClick={() => { typeof this.props.submenu !== "undefined" && this.props.onClick(this.props.navId) }} 
                            style={{
                                // maxHeight: ui?.device?.isTablet ? 30 : 36,
                                paddingTop: 19, 
                                paddingBottom: 19, 
                                color: this.state.open === true ? fontColor : this.props.color,
                                backgroundColor: this.state.open === true ? color : undefined
                            }}
                        >
                            <ListItemIcon> 
                                <FAIcon 
                                    icon={this.props.icon} 
                                    type={this.props.icon === "microsoft" ? "brand" : "thin"}
                                    size={this.props.navOpen ? 30 : 20}
                                    color={this.state.open === true ? fontColor : this.props.color}
                                    button 
                                    fixedWidth 
                                />
                            </ListItemIcon>
                            <ListItemText 
                                disableTypography
                                primary={<Typography variant="h6" style={{color: 'inherit', fontWeight: 300, fontSize: '0.9rem'}}>{this.props.name}{toggleButton}</Typography>} 
                            />
                            {<NotificationBadge variant={this.props.navOpen ? `standard` : `dot`} color="error" badgeContent={subNotifications} style={{position: 'absolute', right: this.props.navOpen ? 50 : 9, top: '50%'}} />}
                            {this.checkExpand()} 
                            {this.props.name === 'Action Center' && this.renderActionBadges()}    
                        </ListItem>
                    )}
                    {typeof this.props.submenu !== "undefined" ? this.props.submenu.map(menu => {
                        return (menu.access_display_on_nav && (
                            <React.Fragment 
                                key={menu.access_id}
                            >
                                <Collapse 
                                    in={this.checkNavOpen()} 
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        {(menu.access_route.startsWith('http') && (
                                            <MaterialLink 
                                                className={classes.subLink} 
                                                href={menu.access_route} 
                                                target="_blank"
                                            >
                                                <ListItem 
                                                    button 
                                                    style={{paddingTop: 7.5, paddingBottom: 7.5, maxHeight: ui?.device?.isTablet ? 30 : 50}}
                                                >
                                                    <ListItemText 
                                                        disableTypography
                                                        inset
                                                        primary={<Typography variant="body2" style={{color: this.props.color}}>{menu.access_name}</Typography>} 
                                                    />
                                                </ListItem>
                                                {menu.access_display_divider === 1 &&
                                                    <Divider />
                                                }
                                            </MaterialLink>
                                        )) || (
                                            <Link 
                                                className={classes.subLink} 
                                                to={menu.access_route}
                                            >
                                                <ListItem 
                                                    button 
                                                    style={{paddingTop: 7.5, paddingBottom: 7.5, maxHeight: ui?.device?.isTablet ? 30 : 50}}
                                                >
                                                    <ListItemText 
                                                        disableTypography
                                                        inset
                                                        primary={
                                                            <Typography variant="body2" component="div" style={{position: 'relative', color: this.props.color}}>
                                                                {(this.props.pagePath === menu.access_route || (menu.access_route.match(/\//g).length > 1 && this.props.pagePath.startsWith(menu.access_route))) && (
                                                                    <div style={{position: 'absolute', top: -2, left: -24}}><FAIcon type='solid' icon="circle" style={{color: this.props.color, height: 7, width: 7}} noMargin /></div>
                                                                )}
                                                                {menu.access_name}
                                                                {<NotificationBadge color="error" badgeContent={notifications[menu.access_reference]} style={{position: 'absolute', right: 6, top: '50%'}} />}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                                {menu.access_display_divider === 1 &&
                                                    <Divider />
                                                }
                                            </Link>
                                        )}
                                    </List>
                                </Collapse>
                            </React.Fragment>  
                        )) || (
                            null
                        );
                    }) : null}                        
                    {this.props.access_display_divider === 1 &&
                        <Divider />
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    badges: state.notifications.badges,
    ui: state.ui
})

export default connect(mapStateToProps, null)(withStyles(styles)(AdminNavItem));