import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import MaterialLink from "@material-ui/core/Link";

import API from "API";
import DataTable from "Components/Common/DataTables/DataTable";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import SnackBar from "Components/Common/SnackBars/SnackBar";
import {
    handleChange,
    handleSelectChange,
    handleUpdateSelectChange,
    handleSnackbarClose,
    handleConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationSuccess,
    handleDialogClose,
} from "../../../Functions/FormFunctions";
import { MS365_CLIENT_ID, MS365_REDIRECT_URI } from "../../../Constants";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { Paper, InputAdornment } from "@material-ui/core";
import { ErrorDialog } from "Components/Common/Dialogs/ErrorDialog";
import { BasicDialog } from "Components/Common/Dialogs/BasicDialog";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import { closeDialog, deployDialog } from "Redux/Actions/Dialog/Dialog";

const initialState = {
    tenantError: "",
    tenantData: {
        tenantUuid: "",
    },
    snackbar: {
        new: false,
    },
    dialog: {
        error: false,
    },
    isLoading: true,
    requestLoading: false,
    rows: [],
};

const UpdateEmail = ({ tenant, callback }) => {
    const [errors, setErrors] = useState(false);
    const [email, setEmail] = useState(tenant.mso_email_address);
    const dispatch = useDispatch();
    const handleClose = () => dispatch(closeDialog());
    const handleUpdate = () => {
        setErrors(false);
        API.put(`/ms365/tenants/${tenant.mso_company_id}/emailAddress`, {
            email,
        }).then((result) => {
            if (result?.data?.errors) {
                setErrors(true);
            } else {
                callback?.();
                dispatch(deploySnackBar?.("success", "You have successfully updated the Microsoft 365 Tenant email address"));
                handleClose();
            }
        });
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body2">
                    <strong>Tenant:</strong> {tenant.mso_company_name}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={errors}
                    helperText={errors && `Please enter a valid email address`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    variant="filled"
                    size="large"
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    onClick={() => dispatch(deployConfirmation(`Are you sure you want to update the e-mail address?`, handleUpdate))}
                    variant="contained"
                    color="primary"
                    fullWidth
                >
                    <FAIcon icon="check" buttonPrimary size={15} />
                    Update
                </Button>
            </Grid>
        </Grid>
    );
};

const UpdateName = ({ tenant, callback }) => {
    const [errors, setErrors] = useState(false);
    const [name, setName] = useState(tenant.mso_contact_name);
    const dispatch = useDispatch();
    const handleClose = () => dispatch(closeDialog());
    const handleUpdate = () => {
        setErrors(false);
        API.put(`/ms365/tenants/${tenant.mso_company_id}/contactName`, {
            name,
        }).then((result) => {
            if (result?.data?.errors) {
                setErrors(true);
            } else {
                callback?.();
                dispatch(deploySnackBar?.("success", "You have successfully updated the Microsoft 365 Tenant contact name"));
                handleClose();
            }
        });
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body2">
                    <strong>Tenant:</strong> {tenant.mso_company_name}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={errors}
                    helperText={errors && `Please enter a contact name`}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    variant="filled"
                    size="large"
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    onClick={() => dispatch(deployConfirmation(`Are you sure you want to update the contact name?`, handleUpdate))}
                    variant="contained"
                    color="primary"
                    fullWidth
                >
                    <FAIcon icon="check" buttonPrimary size={15} />
                    Update
                </Button>
            </Grid>
        </Grid>
    );
};
class Tenants extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleChange = handleChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleUpdateSelectChange = handleUpdateSelectChange.bind(this);
        this.handleSnackbarClose = handleSnackbarClose.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
        this.handleDialogClose = handleDialogClose.bind(this);
    }

    componentDidMount = () => {
        this.props.pageTitle([1, "Microsoft 365", "Tenants"]);
        this.getTenants();

        let search = window.location.search;
        let params = new URLSearchParams(search);

        let paramAdminConsent = params.get("admin_consent");
        let paramError = params.get("error");
        let paramErrorDesc = params.get("error_description");
        let paramTenant = params.get("tenant");

        if (paramAdminConsent === "True" && paramTenant !== null && paramError === null) {
            /*
             * Microsoft Login was successful
             */
            window.history.pushState({}, document.title, window.location.origin + window.location.pathname);
            this.setState(
                {
                    ...this.state,
                    tenantData: {
                        tenantUuid: paramTenant,
                    },
                },
                () => {
                    this.create();
                }
            );
        } else if (paramAdminConsent === "True" && paramError !== null) {
            /*
             * Error from Microsoft Login when adding new tenant
             */
            window.history.pushState({}, document.title, window.location.origin + window.location.pathname);
            this.setState({
                ...this.state,
                tenantData: initialState.tenantData,
                tenantError: (
                    <React.Fragment>
                        {paramErrorDesc.split("\n").map((i) => {
                            return (
                                <React.Fragment>
                                    {i}
                                    <br />
                                </React.Fragment>
                            );
                        })}
                        <Typography variant="body2" component="p" style={{ marginTop: 16 }}>
                            If you are not expecting this error message, please contact Crushed Ice for support.
                        </Typography>
                    </React.Fragment>
                ),
                dialog: {
                    ...this.state.dialog,
                    error: true,
                },
            });
        }

        //CHECK FOR ?admin_consent=True | Then check for ?error=access_denied (also has error_description)
    };

    create = () => {
        this.setState(
            {
                requestLoading: true,
            },
            () => {
                API.post("/ms365/tenants", this.state.tenantData).then((result) => {
                    if (result.data) {
                        if (result.data.errors) {
                            this.setState({
                                ...this.state,
                                tenantData: initialState.tenantData,
                                tenantError: (
                                    <React.Fragment>
                                        {result.data.errors.map((err) => {
                                            return <Typography variant="body2">{err.msg}</Typography>;
                                        })}
                                    </React.Fragment>
                                ),
                                dialog: {
                                    ...this.state.dialog,
                                    error: true,
                                },
                                requestLoading: false,
                            });
                        } else {
                            this.setState(
                                {
                                    ...this.state,
                                    tenantData: initialState.tenantData,
                                    tenantError: initialState.tenantError,
                                    snackbar: {
                                        ...this.state.snackbar,
                                        new: true,
                                    },
                                    requestLoading: false,
                                },
                                () => {
                                    this.getTenants();
                                }
                            );
                        }
                    }
                });
            }
        );
    };

    getTenants = () => {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                API.get("/ms365/tenants").then((result) => {
                    this.setState({
                        ...this.state,
                        rows: result.data,
                        isLoading: false,
                    });
                });
            }
        );
    };

    handleDeleteCompany = (id) => {
        API.put(`/ms365/tenants/${id}/delete`).then((result) => {
            if (result?.data) {
                this.getTenants();
                this.props?.scrollToTop?.();
                this.props?.deploySnackBar?.("success", "You have successfully deleted this Microsoft 365 Tenant");
            }
        });
    };

    handleDeployNameChange = (tenant) => {
        this.props.deployDialog(<UpdateName tenant={tenant} callback={this.getTenants} />, false, "Update Tenant Contact Name", "standard", "xs");
    };

    handleDeployEmailChange = (tenant) => {
        this.props.deployDialog(<UpdateEmail tenant={tenant} callback={this.getTenants} />, false, "Update Tenant E-mail Address", "standard", "xs");
    };

    handleSyncBillingProfile = (id, type) => {
        API.put(`/ms365/tenants/${id}/billingProfile`, {
            type,
        }).then((result) => {
            if (result?.data) {
                this.getTenants();
                this.props?.deploySnackBar?.("success", "You have successfully updated the Microsoft 365 Tenant billing profile");
            }
        });
    };

    render() {
        const { isLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid container item xs={12} alignItems="center">
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            onChange={this.onSearchStringChange}
                            placeholder="Tenant Name / UUID:"
                            value={this.state.searchString}
                            variant="filled"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FAIcon icon="search" size="small" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    {/* {access.addStaff && ( */}
                    <Grid item xs={9} align="right">
                        <MaterialLink
                            href={`https://login.microsoftonline.com/common/adminconsent?client_id=${MS365_CLIENT_ID}&redirect_uri=${MS365_REDIRECT_URI}`}
                            style={{ textDecoration: "none" }}
                        >
                            <Button variant="text" color="primary" size="small">
                                <FAIcon icon="plus-circle" size="small" button /> Add Tenant
                            </Button>
                        </MaterialLink>
                    </Grid>
                    {/* )} */}
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <DataTable
                            config={{
                                key: "mso_company_id",
                                pagination: true,
                                plainHeader: true,
                                plainPagination: true,
                                isLoading: isLoading,
                                options: {
                                    minimalPadding: true,
                                },
                                noRowHover: true,
                                title: "Microsoft 365 Tenants",
                            }}
                            columns={[
                                {
                                    heading: "Company",
                                    field: (rowData) => rowData.mso_company_name,
                                    main: true,
                                },
                                {
                                    heading: "Tenant ID",
                                    field: (rowData) => rowData.mso_company_tenant_uuid,
                                },
                                // {
                                //     heading: "Tenant Added",
                                //     field: (rowData) => rowData.mso_company_created_datetime,
                                //     fieldFormat: "datetime",
                                // },
                                {
                                    heading: "Contact Name",
                                    field: (rowData) => (
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                {rowData.mso_contact_name.length > 0
                                                    ? rowData.mso_contact_name
                                                    : rowData.customer?.default_contact?.cc_first_name?.length > 0
                                                    ? rowData.customer?.default_contact?.cc_first_name
                                                    : rowData.mso_company_name}
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Update contact name">
                                                    <IconButton onClick={() => this.handleDeployNameChange(rowData)}>
                                                        <FAIcon icon="pencil" size={12.5} button noMargin />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    ),
                                },
                                {
                                    heading: "Tenant E-mail Address",
                                    field: (rowData) => (
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                {rowData.mso_email_address.length > 0
                                                    ? rowData.mso_email_address
                                                    : rowData.customer?.default_contact?.cc_email?.length > 0
                                                    ? rowData.customer?.default_contact?.cc_email
                                                    : "Not Set"}
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Update e-mail address">
                                                    <IconButton onClick={() => this.handleDeployEmailChange(rowData)}>
                                                        <FAIcon icon="pencil" size={12.5} button noMargin />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    ),
                                },
                                {
                                    heading: "Users",
                                    field: (rowData) => rowData.users_count,
                                    sizeToContent: true,
                                    alignment: "right",
                                },
                                {
                                    heading: "Subscriptions",
                                    field: (rowData) => rowData.subscriptions_count,
                                    sizeToContent: true,
                                    alignment: "right",
                                },
                                {
                                    heading: "Enabled Licences",
                                    field: (rowData) => rowData.stats.licences.enabled,
                                    sizeToContent: true,
                                    alignment: "right",
                                },
                                {
                                    heading: "Unused Licences",
                                    field: (rowData) => (
                                        <span
                                            style={{
                                                color: rowData.stats.licences.unused > 0 && "#d9534f",
                                                fontWeight: rowData.stats.licences.unused > 0 && "bold",
                                            }}
                                        >
                                            {rowData.stats.licences.unused}
                                        </span>
                                    ),
                                    sizeToContent: true,
                                    alignment: "right",
                                },
                                {
                                    heading: "NCE",
                                    field: (rowData) => (
                                        <IconButton
                                            onClick={() =>
                                                this.props.deployConfirmation(
                                                    <>
                                                        <Typography variant="body2">
                                                            Are you sure you want to {rowData.mso_annual === 1 ? `disable NCE billing?` : `enable NCE billing?`}
                                                        </Typography>
                                                        {rowData.mso_annual === 1 && (
                                                            <Typography variant="caption" className="textError">
                                                                All NCE mappings will be removed!
                                                            </Typography>
                                                        )}
                                                    </>,
                                                    () => this.handleSyncBillingProfile(rowData.mso_company_id, "annual")
                                                )
                                            }
                                        >
                                            <FAIcon
                                                icon={rowData.mso_annual === 1 ? "check-circle" : "times-circle"}
                                                style={{
                                                    color: rowData.mso_annual === 1 ? "#388E3C" : "#d9534f",
                                                }}
                                                noMargin
                                                button
                                            />
                                        </IconButton>
                                    ),
                                    sizeToContent: true,
                                    alignment: "center",
                                },
                                {
                                    heading: "CSP",
                                    field: (rowData) => (
                                        <IconButton
                                            onClick={() =>
                                                this.props.deployConfirmation(
                                                    <>
                                                        <Typography variant="body2">
                                                            Are you sure you want to {rowData.mso_monthly === 1 ? `disable CSP billing?` : `enable CSP billing?`}
                                                        </Typography>
                                                        {rowData.mso_monthly === 1 && (
                                                            <Typography variant="caption" className="textError">
                                                                All CSP mappings will be removed!
                                                            </Typography>
                                                        )}
                                                    </>,
                                                    () => this.handleSyncBillingProfile(rowData.mso_company_id, "monthly")
                                                )
                                            }
                                        >
                                            <FAIcon
                                                icon={rowData.mso_monthly === 1 ? "check-circle" : "times-circle"}
                                                style={{
                                                    color: rowData.mso_monthly === 1 ? "#388E3C" : "#d9534f",
                                                }}
                                                noMargin
                                                button
                                            />
                                        </IconButton>
                                    ),
                                    sizeToContent: true,
                                    alignment: "center",
                                },
                                {
                                    actions: (rowData) => [
                                        {
                                            name: "Delete",
                                            icon: "trash-alt",
                                            onClick: () =>
                                                this.props.deployConfirmation(`Are you sure you want to delete this tenant?`, () =>
                                                    this.handleDeleteCompany(rowData.mso_company_id)
                                                ),
                                        },
                                    ],
                                },
                            ]}
                            rows={this.state.rows}
                        />
                    </Paper>
                </Grid>
                <SnackBar
                    variant="success"
                    anchorOriginVertical="bottom"
                    anchorOriginHorizontal="right"
                    open={this.state.snackbar.new}
                    onClose={() => this.handleSnackbarClose("new")}
                    message="You have successfully added a new Microsoft 365 Tenant to BlueSync"
                />
                <ErrorDialog
                    open={this.state.dialog.error}
                    title="New Tenant Error"
                    content={
                        <React.Fragment>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{this.state.tenantError}</Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                    onClose={() => this.handleDialogClose("error")}
                    maxWidth="sm"
                />
                <BasicDialog
                    open={this.state.requestLoading}
                    title="Adding Tenant"
                    content={
                        <React.Fragment>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <LoadingCircle />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" align="center">
                                        Please wait whilst BlueSync attempts to add the tenant...
                                    </Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                    maxWidth="xs"
                />
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, callback) => dispatch(deployConfirmation(message, callback)),
        deployDialog: (content, disableDialogContent, title, variant = "standard", size = "md", fullscreen = false, disableExit = false) =>
            dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableExit)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
    };
};

export default connect(null, mapDispatchToProps)(Tenants);
