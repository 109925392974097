import React  from 'react';
import moment from 'moment';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import StaffAccess from '../StaffAccess/StaffAccess';
import UpdateStaffMfa from '../UpdateStaff/UpdateStaffMfa';
import ChangeStaffPasswordForm from '../UpdateStaff/ChangeStaffPasswordForm';
import UpdateStaffDetailsForm from '../UpdateStaff/UpdateStaffDetailsForm';
import UploadStaffProfilePhotoForm from '../UpdateStaff/UploadStaffProfilePhotoForm';
import ViewStaffAuditLog from './ViewStaffAuditLog';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { UserAvatar } from 'Components/Common/Badges/Avatar';
import { toggleDialog, handleTabChange } from '../../../Functions/MiscFunctions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

const styles = theme => ({
    tab: {
        color: '#fff',
        opacity: 1
    },
    tabIndicator: {
        backgroundColor: '#fff'
    },
})

const initialState = {
    access: {
        updateStaff: false,
        staffAccess: false,
        updateMfa: false,
        updatePassword: false,
        updateAvatar: false 
    }, 
    currentTab: 0,
    dialog: {
        updateStaff: false,
        updatePassword: false,
        updateAvatar: false 
    },
    staff: {},
    staffId: 0,
    isLoading: true,
}

class ViewStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.toggleDialog = toggleDialog.bind(this);
        this.handleTabChange = handleTabChange.bind(this);
    }

    componentDidMount = () => {
        let staffId = this.props.id ? this.props.id : this.props.match.params.id
        this.setState({
            staffId
        }, 
        ()=> {
            this.loadComponentData();
        });
    }

    loadComponentData = () => {
        Promise.all([
            API.get('/staff/' + this.state.staffId, { params: { viewStaff: true } }),
            API.access('update-staff'),
            API.access('update-staff:multi-factor-auth'),
            API.access('update-staff:change-password'),
            API.access('update-staff:upload-profile-photo'),
            API.access('staff-access'),
        ])
        .then(([result, updateStaff, updateMfa, updatePassword, updateAvatar, staffAccess]) => {  
            if(result.data.errors) {
                this.props.history.push('/staff')
            } else {
                this.setState({
                    ...this.state,
                    access: {
                        staffAccess: (staffAccess.data && staffAccess.data.has_access) || false,
                        updateStaff: (updateStaff.data && updateStaff.data.has_access) || false,
                        updateMfa: (updateMfa.data && updateMfa.data.has_access) || false,
                        updatePassword: (updatePassword.data && updatePassword.data.has_access) || false,
                        updateAvatar: (updateAvatar.data && updateAvatar.data.has_access) || false
                    },
                    staff: result.data,
                    isLoading: false,
                }, () => this.props.pageTitle([1, 'Settings', 'System Users', `${this.state.staff.staff_first_name} ${this.state.staff.staff_last_name}`]));
            }
        })
    }

    

    render() {
        const { classes, scrollToTop } = this.props;
        const { access, currentTab, dialog, isLoading, staff, staffId } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    <Grid item xs={12}>
                        {(isLoading && (
                            <LoadingCircle />
                        )) || (
                            <React.Fragment>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>     
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item>
                                                {UserAvatar(staff, {style: {width: 75, height: 75}})}
                                            </Grid>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h3" className="fw-300">
                                                            {staff.staff_first_name} {staff.staff_last_name}
                                                        </Typography>
                                                    </Grid>    
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="envelope"
                                                                    label={`Email: ${staff.staff_email}`}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="calendar-alt"
                                                                    label={`Created: ${staff.staff_created_datetime === '0000-00-00 00:00:00' ? 'Unknown' : moment(staff.staff_created_datetime).format('MMM D, YYYY')}`}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="check"
                                                                    label={`Last Login: ${staff.staff_last_login_datetime === '0000-00-00 00:00:00' ? 'Unknown' : moment(staff.staff_last_login_datetime).format('MMM D, YYYY')}`}
                                                                />
                                                            </Grid>
                                                            <Grid item xs />
                                                            <Grid item align='right'>
                                                                <Grid container spacing={1}>
                                                                    {access.updateAvatar && (
                                                                        <Grid item>
                                                                            <Button
                                                                                variant="text"
                                                                                color="primary"
                                                                                size="small" 
                                                                                onClick={() => this.toggleDialog('updateAvatar')}
                                                                            >
                                                                                <FAIcon icon="square-user" size={15} button />
                                                                                Change Avatar
                                                                            </Button>
                                                                        </Grid>    
                                                                    )} 
                                                                    {access.updatePassword && (
                                                                        <Grid item>
                                                                            <Button
                                                                                variant="text"
                                                                                color="primary"
                                                                                size="small" 
                                                                                onClick={() => this.toggleDialog('updatePassword')}
                                                                            >
                                                                                <FAIcon icon="key" size={15} button />
                                                                                Change Password
                                                                            </Button>
                                                                        </Grid>    
                                                                    )}
                                                                    {access.updateStaff && (
                                                                        <Grid item>
                                                                            <Button
                                                                                variant="text"
                                                                                color="primary"
                                                                                size="small" 
                                                                                onClick={() => this.toggleDialog('updateStaff')}
                                                                            >
                                                                                <FAIcon icon="pencil-alt" size={15} button />
                                                                                Modify User
                                                                            </Button>
                                                                        </Grid>    
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>     
                                                </Grid> 
                                            </Grid>                                     
                                        </Grid>                                     
                                    </Grid> 
                                    <Grid item xs={12}>
                                        <Paper>
                                            <AppBar color="primary" position="static" elevation={0}>
                                                <Tabs classes={{indicator: classes.tabIndicator}} variant='scrollable' value={currentTab} onChange={this.handleTabChange}>
                                                    {access.updateMfa && (
                                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Multi-Factor Authentication" />
                                                    )}
                                                    {access.staffAccess && (
                                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="System Access" />
                                                    )}
                                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Login History" />
                                                </Tabs>   
                                            </AppBar>   
                                        </Paper>
                                    </Grid>     
                                    <Grid item xs={12}>  
                                        {access.updateMfa && (
                                            <TabPanel value={currentTab} index={0}>
                                                <UpdateStaffMfa staff={staff} staffId={staffId} scrollToTop={scrollToTop} callback={() => this.componentDidMount()} />
                                            </TabPanel>
                                        )}
                                        {access.staffAccess && (
                                            <TabPanel value={currentTab} index={1}>
                                                <StaffAccess id={staffId} scrollToTop={scrollToTop} />
                                            </TabPanel>
                                        )}
                                        <TabPanel value={currentTab} index={2}>
                                            <Paper>
                                                <ViewStaffAuditLog staff={staff} isLoading={isLoading} />
                                            </Paper>
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                                <SmallFormDialog
                                    open={dialog.updateAvatar}
                                    title="Change Avatar"
                                    content={<UploadStaffProfilePhotoForm staffId={staffId} toggleDialog={() => this.toggleDialog('updateAvatar')} />}
                                    maxWidth='xs'
                                />
                                <SmallFormDialog
                                    open={dialog.updatePassword}
                                    title="Change Password"
                                    content={<ChangeStaffPasswordForm staffId={staffId} myPassword={false} toggleDialog={() => this.toggleDialog('updatePassword')} />}
                                    maxWidth='xs'
                                />
                                <SmallFormDialog
                                    open={dialog.updateStaff}
                                    title="Modify Staff Member"
                                    content={<UpdateStaffDetailsForm staffId={staffId} toggleDialog={() => this.toggleDialog('updateStaff')} />}
                                    maxWidth='xs'
                                />         
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(ViewStaff);