import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { clearToken, setLastActivePage, forceStoredProfile, clearLastActivePage } from 'Functions/AuthFunctions';
import { handleClick, handleClose } from 'Functions/PopperFunctions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';

const styles = theme => ({
    dropDownItem: {
        padding: theme.spacing(2, 3, 2, 2),
        fontSize: 12
    }
})

const initialState = {
    anchorEl: null
}

class AdminMenu extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleClick = handleClick.bind(this);
        this.handleClose = handleClose.bind(this)
    }
    
    handleLockSession = () => {
        forceStoredProfile();        
        setLastActivePage(this.props.location.pathname);
        clearToken();
        this.props.history.push('/login');
    }

    handleLogout = () => {
        clearToken();
        clearLastActivePage();
        this.props.history.push('/login');
    }

    handleMyAccount = () => {
        this.props.history.push('/my-account');
        this.handleClose();
    }

    render() {
        const { classes, device } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <ClickAwayListener onClickAway={this.handleClose}>
                <div>                                        
                    <IconButton
                        aria-label="More"
                        aria-owns={anchorEl ? 'dropdown-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        className={classes.iconButton}
                        size={`small`}
                    >
                        <FAIcon type="light" icon='ellipsis-v' buttonPrimary noMargin />
                    </IconButton>
                    <Popper 
                        id={open ? 'dropdown-menu' : undefined} 
                        open={open}
                        anchorEl={anchorEl}
                        className={`dd-menu ${device.isTablet ? `dd-menu-small-header` : ``}`}
                        disablePortal={true} 
                    >
                        <Paper>
                            <MenuItem onClick={this.handleMyAccount} className={`${classes.dropDownItem} not-xs`}>
                                <FAIcon type="light" icon='user' button size={15} /> My Account
                            </MenuItem>
                            <MenuItem onClick={() => this.props.deployConfirmation(`Are you sure you want to lock your session?`, this.handleLockSession)} className={classes.dropDownItem}>
                                <FAIcon type="light" icon='lock-alt' button size={15} /> Lock Session
                            </MenuItem>
                            <MenuItem onClick={() => this.props.deployConfirmation(`Are you sure you want to logout?`, this.handleLogout)} className={classes.dropDownItem}>
                                <FAIcon type="light" icon='sign-out-alt' button size={15} /> Logout
                            </MenuItem>
                        </Paper>
                    </Popper>
                </div>
            </ClickAwayListener>
        )
    }
}

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success, cancel = false, successText = null, cancelText = null, successOnly = false) => dispatch(deployConfirmation(message, success, cancel, successText, cancelText, successOnly)),
    }
)

export default connect(null, mapDispatchToProps)(withStyles(styles)(withRouter(AdminMenu)));