/*
* Crushed Ice - ReactJS Service Worker v2 Registration
*/

/* Localhost check */
const isLocalhost = Boolean(
    window.location.hostname === 'localhost'    ||
    window.location.hostname === '[::1]'        ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

/* Register service worker */
export const register = config => {

    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        
        /* Get the public url */
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

        /* Check the public url is on the same origin */
        if (publicUrl.origin !== window.location.origin) {
            return;
        }

        /* Add event listener for window load */
        window.addEventListener('load', () => {

            /* Service worker file url */
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            /* If localhost, check the service worker is valid */
            if(isLocalhost) {

                /* Check service worker */
                checkValidServiceWorker(swUrl, config);

                /* Callback for service worker registration */
                navigator
                .serviceWorker
                .ready.then(() => {
                    console.log(
                        'This web app is being served cache-first by a service ' +
                        'worker. To learn more, visit https://cra.link/PWA'
                    );
                });

            } else {

                /* Register service worker - production */
                registerValidSW(swUrl, config);

            }
        });
    }   
}

/* Un-register service worker */
export function unregister() {

    /* Check service worker is active */
    if('serviceWorker' in navigator) {

        /* Un-register */
        navigator
        .serviceWorker
        .ready
        .then(registration => {
            registration.unregister();
        })
        .catch(error => {
            console.error(error.message);
        });

    }

}

/* Register valid service worker */
const registerValidSW = (swUrl, config) => {

    navigator
    .serviceWorker
    .register(swUrl)
    .then(registration => {

        /* Update application */
        registration.onupdatefound = () => {

            /* Get the "installing" service worker process */
            const installingWorker = registration.installing;

            /* If no service worker exists, cancel */
            if (installingWorker == null) {
                return;
            }

            /* Check the installation state change of the service worker */
            installingWorker.onstatechange = () => {

                /* Service worker is currently installing */
                if(installingWorker.state === 'installing') {
                    console.log("Service worker installing...");
                }

                /* Service worker has installed */
                if (installingWorker.state === 'installed') {

                    if (navigator.serviceWorker.controller) {

                        /* Service worker updated */
                        console.log('Service worker callback - installed');
                        config?.onUpdate?.(registration);

                        config.serviceWorkerCallback?.(true);   

                        setTimeout(() => { 
                            console.log("Service worker callback - active");
                            config.serviceWorkerCallback?.(false) 
                        }, 2500);

                    } else {

                        /* Service worker cached data */
                        console.log('Content is cached for offline use.');
                        config?.onSuccess?.(registration);

                    }
                    
                }

            };

        };

    })
    .catch(error => {
        console.error('Error during service worker registration:', error);
    });
}

/* Check valid service worker file exists */
const checkValidServiceWorker = (swUrl, config) => {

    /* Get service worker */
    fetch(swUrl, {
        headers: { 
            'Service-Worker': 'script' 
        },
    })
    .then(response => {

        /* Get response content type */
        const contentType = response.headers.get('content-type');

        /* Check the file exists and is a js file */
        if (response.status === 404 ||(contentType != null && contentType.indexOf('javascript') === -1)) {

            /* No service worker found. Probably a different app. Reload the page. */
            navigator
            .serviceWorker
            .ready
            .then(registration => {
                registration.unregister().then(() => {
                    window.location.reload(true);
                });
            });

        } else {

            /* Register valid service worker */
            registerValidSW(swUrl, config);

        }

    })
    .catch(() => {
        console.log('No internet connection');
    });
}