import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { DEFAULT_AVATAR } from 'Components/Common/ImageWithError/Types/Types';

const styles = theme => ({
    content: {
        display: 'flex',
        alignItems: 'center',
    },
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        spacing: 3,
    },
    avatar: {
        width: 125,
        height: 125,
        margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
        root: {
            marginTop: 0,
            spacing: 2,
        },
        avatar: {
            width: 75,
            height: 75,
        },
    },
});

class LoginStoredProfile extends React.Component {
    render() {
        const { classes, staffData } = this.props;
        const avatar = staffData && staffData.avatar ? staffData.avatar : DEFAULT_AVATAR;
        return (
            <React.Fragment>
                <Grid container className={classes.root} spacing={3}>
                    <Grid item align="center" xs={12}>
                        <Avatar alt={`${staffData && staffData.firstName} ${staffData && staffData.lastName}`} src={avatar} className={classes.avatar} />
                    </Grid>
                    <Grid item align="center" xs={12}>
                        <Typography variant="h4" className="fw-400">
                            {`${staffData && staffData.firstName} ${staffData && staffData.lastName}`}
                        </Typography>               
                        <Typography variant="subtitle1" className="fw-400">
                            {staffData && staffData.email}
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>                    
        )
    }
}

export default withStyles(styles)(LoginStoredProfile);