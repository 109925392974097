import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import InsightChip from 'Components/Common/Chips/InsightChip';
import StatusChip from 'Components/Common/Chips/StatusChip';
import StaffChip from 'Components/Common/Chips/StaffChip';

const initialState = {
    batch: {},
    isLoading: true
}

class AccountsExpenseBatchView extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.props.pageTitle([1, 'Accounts', 'Batch Expenses']);
        this.loadComponentData();
    }

    loadComponentData = () => {
        API.get(`/bluesync/accounts/batches/expenses/${this.props.match.params.id}`)
        .then(result => {
            if(result.data) {
                this.setState({
                    batch: result.data,
                    isLoading: false
                }, () => this.props.pageTitle([1, 'Accounts', 'Batch Expenses', this.state.batch.be_identifier]))
            }
        });
    }

    render() {
        const { staff } = this.props;
        const { batch, isLoading } = this.state;
        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (    
                    <React.Fragment>
                        <Grid item xs={12}>     
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item>
                                    <FAIcon type="thin" icon="money-check-pen" size={75} />
                                </Grid>
                                <Grid item xs>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item xs={12}>
                                            <Typography variant="h3" className="fw-300">
                                                {batch.be_identifier}
                                            </Typography>
                                        </Grid>    
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <StaffChip staff={staff?.[batch?.be_staff_id ?? 0]} label="Imported by: " />
                                                </Grid>
                                                <Grid item>
                                                    <InsightChip 
                                                        icon="check"
                                                        label={`Imported on: ${moment(batch.be_created_datetime).format('MMM DD, YYYY')}`}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <StatusChip invert status={batch.be_status} label='Status: ' />
                                                </Grid>
                                            </Grid>
                                        </Grid>     
                                    </Grid> 
                                </Grid>                                     
                            </Grid>                                     
                        </Grid>     
                        <Grid item xs={12}>
                            <Paper>
                                <DataTable  
                                    config={{
                                        key: 'bei_id',
                                        pagination: true,
                                        alternatingRowColours: true,
                                        responsiveImportance: true,
                                        plainHeader: true, 
                                        title: 'Batch Expenses'
                                    }}
                                    columns={[
                                        {
                                            heading: 'Posted',
                                            field: rowData => rowData.bei_status !== "Completed" ? <FAIcon icon="times" style={{color: '#c62828'}} noMargin/> : <FAIcon icon="check" style={{color: '#4CAF50'}} noMargin/>,
                                            sizeToContent: true,
                                            alignment: 'center'
                                        },
                                        {
                                            heading: 'Date',
                                            field: rowData => moment(JSON.parse(rowData.bei_data).documentDate).format("DD/MM/YYYY"),
                                        },
                                        {
                                            heading: 'Staff Member',
                                            field: rowData => rowData.bei_member, 
                                        },
                                        {
                                            heading: 'Detail',
                                            field: rowData => rowData.bei_detail,
                                            important: true,
                                        },
                                        {
                                            heading: 'Total',
                                            field: rowData => `£${parseFloat(JSON.parse(rowData.bei_data).total).toFixed(2)}`,
                                        },
                                        {
                                            heading: 'Sage Ref',
                                            field: rowData => `${rowData.bei_cw_id}-${rowData.bei_detail_id}-${JSON.parse(rowData.bei_data).member.identifier}`
                                        },
                                        {
                                            heading: 'Nominal Account',
                                            field: rowData => JSON.parse(rowData.bei_data).glClass,
                                            alignment: 'center',
                                        },
                                        {
                                            heading: 'Post Account',
                                            field: rowData => JSON.parse(rowData.bei_data).vendorNumber, 
                                        },
                                        {
                                            heading: 'Status',
                                            field: rowData => <StatusChip status={rowData.bei_status} />, 
                                        }
                                    ]}
                                    rows={batch.items}
                                />
                            </Paper>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    staff: state.notifications.status
})

export default connect(mapStateToProps, null)(AccountsExpenseBatchView);