import * as ConfirmationActionTypes from 'Redux/Actions/Confirmation/Types/Types';

export const deployConfirmation = (message, success = false, close = false, successText = null, closeText = null, successOnly = false, disableExit = false) => {
    return dispatch => {
        dispatch({
            type: ConfirmationActionTypes.CONFIRMATION_OPEN, 
            close: () => { dispatch(closeConfirmation()); if(close) { close(); }},
            success: () => { dispatch(closeConfirmation()); if(success) { success(); }},
            message,
            closeText,
            successText,
            successOnly,
            disableExit
        });
    }
}

export const closeConfirmation = () => {
    return dispatch => {
        dispatch({type: ConfirmationActionTypes.CONFIRMATION_CLOSE});
    }
}