import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useDispatch } from "react-redux";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import API from "API";
import Alert from "Components/Common/Alert/Alert";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import StatusChip from "Components/Common/Chips/StatusChip";
import { closeDialog, deployDialog } from "Redux/Actions/Dialog/Dialog";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";

const ResendEmail = ({ callback, data, id }) => {
    const emailData = JSON.parse(data?.email_data ?? []);

    const initialState = () => ({
        to: data.email_to,
        enabled: data?.msosa_enabled ?? "",
        active: data?.msosa_active ?? "",
        inactive: data?.msosa_inactive ?? "",
        renewDate: emailData?.renewDate ?? "",
        changeDate: emailData?.changeDate ?? "",
    });

    const [errors, setErrors] = useState(false);

    const [formData, setFormData] = useState(initialState);

    const dispatch = useDispatch();

    const handleChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };

    const handleClose = () => dispatch(closeDialog());

    const handleResend = () => {
        setErrors(false);
        API.put(`/ms365/email/${id}`, {
            formData,
        }).then((result) => {
            if (result?.data?.errors) {
                setErrors(true);
            } else {
                callback?.();
                dispatch(deploySnackBar?.("success", "You have successfully re-sent the renewal e-mail"));
                handleClose();
            }
        });
    };

    const { to, enabled, active, inactive, renewDate, changeDate } = formData;

    return (
        <Grid container spacing={3} className="pt-1">
            <Grid item xs={12}>
                <Typography variant="body1" className="fw-400" paragraph>
                    E-mail Address
                </Typography>
                <TextField
                    error={errors}
                    helperText={errors && `Please enter a valid email address`}
                    value={to}
                    onChange={(e) => handleChange("to", e.target.value)}
                    fullWidth
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12} lg={4}>
                <Typography variant="body1" className="fw-400" paragraph>
                    Enabled Licenses
                </Typography>
                <TextField
                    value={enabled}
                    onChange={(e) => handleChange("enabled", e.target.value)}
                    fullWidth
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12} lg={4}>
                <Typography variant="body1" className="fw-400" paragraph>
                    Active Licenses
                </Typography>
                <TextField
                    value={active}
                    onChange={(e) => handleChange("active", e.target.value)}
                    fullWidth
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12} lg={4}>
                <Typography variant="body1" className="fw-400" paragraph>
                    Inactive Licenses
                </Typography>
                <TextField
                    value={inactive}
                    onChange={(e) => handleChange("inactive", e.target.value)}
                    fullWidth
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <Typography variant="body1" className="fw-400" paragraph>
                    Next Billing Date
                </Typography>
                <TextField
                    value={renewDate}
                    onChange={(e) => handleChange("renewDate", e.target.value)}
                    fullWidth
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <Typography variant="body1" className="fw-400" paragraph>
                    Change Date
                </Typography>
                <TextField
                    value={changeDate}
                    onChange={(e) => handleChange("changeDate", e.target.value)}
                    fullWidth
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    disabled={_.some(formData, (el) => el === "")}
                    onClick={() =>
                        dispatch(deployConfirmation(`Are you sure you want to re-send the e-mail?`, handleResend))
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                >
                    <FAIcon disabled={_.some(formData, (el) => el === "")} icon="envelope" buttonPrimary size={15} />
                    Send
                </Button>
            </Grid>
        </Grid>
    );
};

const ViewEmail = ({ callback, id }) => {
    const dispatch = useDispatch();

    const initialState = {
        isLoading: true,
        email: {},
    };

    const [state, setState] = useState(initialState);

    const resend = () => {
        dispatch(
            deployDialog(
                <ResendEmail callback={callback} data={email} id={id} />,
                false,
                "Resend Renewal E-mail",
                "standard",
                "sm"
            )
        );
    };

    const cancel = () => {
        API.put(`/ms365/email/${id}/cancel`).then((result) => {
            if (result?.data?.success) {
                callback?.();
                dispatch(deploySnackBar?.("success", "You have successfully cancelled the renewal e-mail"));
                dispatch(closeDialog());
            }
        });
    };

    useEffect(() => {
        Promise.all([API.get(`/ms365/email/${id}`)]).then(([res]) => {
            if (res?.data) {
                setState({
                    isLoading: false,
                    email: res.data,
                });
            }
        });
    }, [id]);

    const { email, isLoading } = state;

    return (
        (isLoading && <LoadingCircle />) || (
            <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()} className="pt-3">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h6">{email.email_subject}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                            Sent
                        </Typography>
                        <Typography variant="body2" paragraph>
                            {moment(email.email_created_datetime).format("LLLL")}
                        </Typography>
                        {email.email_opened_datetime !== "0000-00-00 00:00:00" && (
                            <>
                                <Typography variant="caption" color="textSecondary">
                                    Read
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    {moment(email.email_opened_datetime).format("LLLL")}
                                </Typography>
                            </>
                        )}
                        <Typography variant="caption" color="textSecondary">
                            Sender
                        </Typography>
                        <Typography variant="body2" paragraph>
                            {email.email_from}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            Recipient
                        </Typography>
                        <Typography variant="body2" paragraph>
                            {email.email_to}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            Status
                        </Typography>
                        <Typography variant="body2" paragraph>
                            <Box pt={1}>
                                <StatusChip status={email.email_status} />
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} className="pt-2">
                        <Typography variant="body2" paragraph>
                            {(!_.isEmpty(email.email_body) && (
                                <div dangerouslySetInnerHTML={{ __html: email.email_body }} />
                            )) || <em>This e-mail has no body</em>}
                        </Typography>
                    </Grid>
                    {email.actions > 0 && (
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Alert severity="warning">
                                    You must clear outstanding actions for this subscription before this e-mail can be
                                    re-sent
                                </Alert>
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12} align="right">
                        <Grid container spacing={3} justifyContent="flex-end">
                            {email.email_status === "On Hold" && (
                                <Grid item>
                                    <Button onClick={cancel} variant="contained" className="btn btn-error">
                                        <FAIcon icon="times" buttonPrimary size={15} />
                                        Cancel E-mail
                                    </Button>
                                </Grid>
                            )}
                            <Grid item>
                                <Button
                                    disabled={email.actions > 0}
                                    onClick={resend}
                                    variant="contained"
                                    color="primary"
                                >
                                    <FAIcon disabled={email.actions > 0} icon="envelope" buttonPrimary size={15} />
                                    Re-send E-mail
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        )
    );
};

export default ViewEmail;
