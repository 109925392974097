import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fas as fasp } from '@fortawesome/pro-solid-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';

library.add(fab, fad, fal, fas, fasp, fat);

const iconType = (type) => {
    return  (type === 'solid' && 'fas') ||
            (type === 'brand' && 'fab') ||
            (type === 'duo' && 'fad') ||
            (type === 'thin' && 'fat') ||
            'fal'
}

const FAIcon = ({
        button, 
        buttonPrimary, 
        color, 
        className, 
        disabled, 
        error, 
        fixedWidth,
        fixedHeight,
        icon,
        light,
        noMargin,
        rotation, 
        size, 
        spin, 
        style, 
        transform,
        type
    }) => {
        
    const iconSize = (!isNaN(size) && size)           ||
                        (size === 'avatar' && 32)     || 
                        (size === 'xxlarge' && 75)    || 
                        (size === 'xlarge' && 50)     || 
                        (size === 'large' && 35)      || 
                        (size === 'small' && 15)      || 
                        20;

    const width = (fixedHeight && 'auto') || iconSize,
          height = (fixedWidth && 'auto') || iconSize;

    return (
        <FontAwesomeIcon    
            icon={[
                iconType(type), 
                icon
            ]}
            rotation={
                rotation && rotation
            } 
            spin={
                spin && spin
            }
            transform={
                transform && transform
            }
            className={
                (className && className) ||
                (disabled && 'iconStyleDisabled') ||
                (error && 'iconStyleError') ||
                (light && 'iconStyleLight') || 
                'iconStyle'
            }
            style={{
                width: width,
                height: height,
                margin:
                    (noMargin) && 0,
                cursor: 
                    (button || buttonPrimary) && 'pointer',
                color: 
                    (disabled && (
                        undefined      
                    )) || (
                        (buttonPrimary && !disabled && '#fff')  || 
                        (color === 'primary' && '#375885')      || 
                        (color === 'info' && '#0288D1')         || 
                        (color === 'error' && '#d9534f')        ||  
                        (color === 'success' && '#388E3C')      ||  
                        (color === 'white' && '#fff')           ||
                        color
                    ),
                ...style
            }}
        />
    )
}

export default FAIcon;