import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import MaterialLink from '@material-ui/core/Link';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import { 
    handleChange,
    handleSelectChange,
    handleUpdateSelectChange,
    handleSnackbarClose,
    handleConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationSuccess,
    handleDialogClose
} from '../../../Functions/FormFunctions';
import {
    SAGE_CLOUD_API_VERSION,
    SAGE_CLOUD_CLIENT_ID,
    SAGE_CLOUD_REDIRECT_URI
} from '../../../Constants';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StatusChip from 'Components/Common/Chips/StatusChip';
import { ErrorDialog } from 'Components/Common/Dialogs/ErrorDialog';
import { BasicDialog } from 'Components/Common/Dialogs/BasicDialog';

const initialState = () => ({
    authData: {
        authCode: '',
    },
    authRequest: false,
    dialog: {
        error: false
    },
    snackbar: {
        auth: false,
    },
    isLoading: true,
    rows: []
})

class SageCloudAuth extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleUpdateSelectChange = handleUpdateSelectChange.bind(this);
        this.handleSnackbarClose = handleSnackbarClose.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
        this.handleDialogClose = handleDialogClose.bind(this);
    }

    componentDidMount = () => {

        /* Set Page Title */
        this.props.pageTitle([1, 'Accounts', 'Sage Business Cloud Accounting', 'Authentication History']);

        /* Get Tasks */
        this.getAuthHistory();

        /* Check for Sage Cloud Response */
        let params      = new URLSearchParams(window.location.search),
            authCode    = params.get('code');

        /* Action Sage Cloud Response? */
        if(authCode !== null) {

            /* Sage Cloud - Login Successful */
            
            window.history.pushState({}, document.title, `${window.location.origin}${window.location.pathname}`);
            
            this.setState({
                authData: {
                    authCode
                }
            }, () => {
                this.convertAuthCodeToToken()
            })

        }

    }

    convertAuthCodeToToken = () => {

        this.setState({
            authRequest: true
        }, () => {

            API.post('/sage/auth', this.state.authData)
            .then(result => {

                if(result?.data) {

                    if(result.data.errors) {

                        this.setState({
                            authData: initialState().authData,
                            authError: result.data.errors,
                            authRequest: false,
                            dialog: {
                                ...this.state.dialog,
                                error: true
                            }
                        });

                    } else {

                        this.setState({
                            authData: initialState().authData,
                            authError: initialState().authError,
                            authRequest: false,
                            snackbar: {
                                ...this.state.snackbar,
                                auth: true
                            }
                        }, () => {
                            this.getAuthHistory()
                        });

                    }

                }

            })

        })

    }

    getAuthHistory = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/sage/auth')
            .then(result => {
                this.setState({
                    rows: result.data,
                    isLoading: false
                });
            })
        })
    }

    render = () => {
        
        const {
            isLoading 
        } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={12} align='right'>
                        <MaterialLink href={`https://www.sageone.com/oauth2/auth/central?filter=apiv${SAGE_CLOUD_API_VERSION}&client_id=${SAGE_CLOUD_CLIENT_ID}&response_type=code&redirect_uri=${SAGE_CLOUD_REDIRECT_URI}&scopes=full_access&country=gb&locale=en-GB`} style={{textDecoration: 'none'}}>
                            <Tooltip title="Connect to Sage Business Cloud Accounting">
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="small"
                                >
                                    <FAIcon icon='sync' size='small' button /> Authorise BlueSync
                                </Button>
                            </Tooltip>
                        </MaterialLink>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <DataTable  
                            config={{
                                key: 'id',
                                pagination: true,
                                plainHeader: true,
                                plainPagination: true,
                                isLoading: isLoading,
                                options: {
                                    minimalPadding: true
                                },
                                title: 'Authentication History',
                            }}
                            columns={[
                                {
                                    heading: 'Token Reference',
                                    field: rowData => rowData.ref,
                                    main: true,
                                },
                                {
                                    heading: 'Generated',
                                    field: rowData => rowData.cr,
                                    fieldFormat: 'datetime',
                                },
                                {
                                    heading: 'Validity Period',
                                    field: rowData => rowData.ex,
                                    fieldFormat: 'datetime',
                                },
                                {
                                    heading: 'Token Status',
                                    field: rowData => <StatusChip status={rowData.st} />,
                                },
                            ]}
                            rows={this.state.rows}
                        />
                    </Paper>
                </Grid>
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbar.auth}
                    onClose={() => this.handleSnackbarClose('auth')}
                    message='You have successfully authorised BlueSync to access Sage Business Cloud Accounting'
                />
                <BasicDialog
                    open={this.state.authRequest}
                    title=""
                    content={
                        <React.Fragment>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <LoadingCircle />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" align="center" paragraph>
                                        Sage Cloud Accounting
                                    </Typography>
                                    <Typography variant="subtitle2" align="center">
                                        Please wait whilst BlueSync attempts to connect to your <br />
                                        Sage Business Cloud Accounts
                                    </Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                    maxWidth='xs'
                />
                <ErrorDialog
                    open={this.state.dialog.error}
                    title="Authentication Error"
                    content={
                        <React.Fragment>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                        {this.state.authError}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                    onClose={() => this.handleDialogClose('error')}
                    maxWidth='sm'
                />
            </Grid>
        )
    }
}

export default SageCloudAuth;