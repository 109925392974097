import React from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import API from "API";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import DataTable from "Components/Common/DataTables/DataTable";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import ViewEmail from "Components/Microsoft365/RenewalEmails/ViewEmail";
import StaffChip from "Components/Common/Chips/StaffChip";
import StatusChip from "Components/Common/Chips/StatusChip";
import { closeDialog, deployDialog } from "Redux/Actions/Dialog/Dialog";

const initialState = () => ({
    dataLoading: true,
    searchResults: {},
    searchString: "",
});

class RenewalEmails extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.timeout = false;
    }

    componentDidMount = () => {
        this.props.pageTitle([0, "Microsoft 365", "Renewal E-mails"]);
        this.getRenewalEmails(true);
    };

    componentWillUnmount = () => {
        if (this.timeout) clearTimeout(this.timeout);
    };

    getRenewalEmails = (dataLoading) => {
        let params = {
            keywords: this.state.searchString,
        };
        this.setState(
            {
                dataLoading,
            },
            () => {
                API.get("/ms365/email", {
                    props: {
                        cancellation: true,
                    },
                    params,
                }).then((result) => {
                    if (result?.data) {
                        this.setState({
                            dataLoading: false,
                            searchResults: result.data,
                        });
                    }
                });
            }
        );
    };

    handleDeployViewEmail = (id) =>
        this.props.deployDialog(<ViewEmail id={id} callback={this.getRenewalEmails} />, false, "", "standard", "md");

    onSearchStringChange = (event) => {
        this.setState(
            {
                searchString: event?.target?.value ?? "",
            },
            () => {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }
                this.timeout = setTimeout(() => this.getRenewalEmails(true), 200);
            }
        );
    };

    render() {
        const { staff } = this.props;
        const { dataLoading, searchResults, searchString } = this.state;
        return (
            <Grid container spacing={1}>
                <Grid container item xs={12} alignItems="center">
                    <Grid item xs={12} sm={6} md={4} style={{ marginBottom: 12 }}>
                        <TextField
                            fullWidth
                            onChange={this.onSearchStringChange}
                            placeholder="Search:"
                            value={searchString}
                            variant="filled"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FAIcon type="thin" icon="search" size="small" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Microsoft 365 Renewal E-mails
                        </Typography>
                        <DataTable
                            config={{
                                key: "email_id",
                                pagination: true,
                                plainHeader: true,
                                plainPagination: true,
                                isLoading: dataLoading,
                                options: {
                                    minimalPadding: true,
                                },
                            }}
                            columns={[
                                {
                                    heading: "Tenant",
                                    field: (rowData) => rowData.tenant?.mso_company_name ?? "-",
                                    dataRef: "tenant.mso_company_name",
                                    important: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Subscription",
                                    field: (rowData) => rowData.subscription?.subscription_type?.msost_name ?? "-",
                                    dataRef: "subscription.subscription_type.msost_name ",
                                    important: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Recipient",
                                    field: (rowData) => rowData.email_to,
                                    dataRef: "email_to",
                                    important: true,
                                    sizeToContent: true,
                                },
                                // {
                                //     heading: "Sender",
                                //     field: (rowData) => rowData.email_from,
                                //     dataRef: "email_from",
                                //     important: true,
                                //     sizeToContent: true,
                                // },
                                {
                                    heading: "Subject",
                                    field: (rowData) => rowData.email_subject,
                                    dataRef: "email_subject",
                                    main: true,
                                },
                                {
                                    heading: "Sent",
                                    field: (rowData) => rowData.email_created_datetime,
                                    fieldFormat: "datetime",
                                    dataRef: "email_created_datetime",
                                    important: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Sent By",
                                    field: (rowData) => <StaffChip staff={staff?.[rowData.email_staff_id]} />,
                                    fieldFormat: "staff",
                                    dataRef: "email_staff_id",
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Status",
                                    field: (rowData) => <StatusChip status={rowData.email_status} />,
                                    dataRef: "email_status",
                                    sizeToContent: true,
                                },
                                {
                                    actions: (rowData) => {
                                        return [
                                            {
                                                name: "View",
                                                icon: "search",
                                                onClick: () => this.handleDeployViewEmail(rowData.email_id),
                                            },
                                        ];
                                    },
                                },
                            ]}
                            rows={searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    staff: state.notifications.status,
});

const mapDispatchToProps = (dispatch) => ({
    closeDialog: () => dispatch(closeDialog()),
    deployDialog: (component, disableContentArea, title, type = "standard", size = "md") =>
        dispatch(deployDialog(component, disableContentArea, title, (type = "standard"), (size = "md"))),
});

export default connect(mapStateToProps, mapDispatchToProps)(RenewalEmails);
