import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

class LoadingScreen extends React.PureComponent {
    render(){
        return (
            <Dialog 
                open={this.props.open}
                maxWidth='md' 
                style={{
                    backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : 'rgba(0,0,0,0.5)'
                }}
            >
                <DialogContent style={{padding: '100px 25px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} align='center'>
                            <LoadingCircle size='large' />
                        </Grid>
                        <Grid item xs={12} align='center'>
                            <Typography variant="h3" gutterBottom>
                                {this.props.title}
                            </Typography>
                            <Typography variant="subtitle1" className="fw-400" gutterBottom>
                                {this.props.message}
                            </Typography>                            
                            {this.props.additional && (
                            <Typography variant="subtitle1" className="fw-400">
                                {this.props.additional}
                            </Typography>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }
}

export default LoadingScreen;