import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';

const initialState = {
    formData: {
        currentPassword: '',
        password:'',
        confirmPassword: '',
    },
    formErrors: {},
    confirmationOpen: false,
}

class ChangeStaffPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    handleSubmit = () => {
        API.put(this.props.staffId ? '/staff/' + this.props.staffId + '/password' : '/staff/my/password', this.state.formData).then(result => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                })
            } else {
                this.setState({
                    ...initialState
                }, () => {
                    this.props.deploySnackBar("success", "The system user's password has been changed")
                    this.props.toggleDialog()
                })
            }
        })
    }

    handleChange = (event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value
            }
        })
    }

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    }

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }

    render() {
        const { myPassword } = this.props;
        const { formErrors, formData } = this.state;
        return (
            <React.Fragment>
                <form onChange={this.handleChange} noValidate autoComplete="off">
                    {myPassword &&
                        <TextField
                            type="password"
                            name="currentPassword"
                            placeholder="Current password:"
                            margin="none"
                            autoComplete="off"
                            value={(formData.currentPassword && formData.currentPassword) || ''}
                            error={formErrors && formErrors['currentPassword'] && true}
                            helperText={formErrors && formErrors['currentPassword']}
                            fullWidth
                            variant="filled"
                            className="pt-2"
                        />
                    }
                    <TextField
                        type="password"
                        name="password"
                        placeholder="New password:"
                        margin="none"
                        autoComplete="off"
                        value={(formData.password && formData.password) || ''}
                        error={formErrors && formErrors['password'] && true}
                        helperText={formErrors && formErrors['password']}
                        fullWidth
                        variant="filled"
                        className="pt-2"
                    />
                    <TextField
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm password:"
                        margin="none"
                        autoComplete="off"
                        value={(formData.confirmPassword && formData.confirmPassword) || ''}
                        error={formErrors && formErrors['confirmPassword'] && true}
                        helperText={formErrors && formErrors['confirmPassword']}
                        fullWidth
                        variant="filled"
                        className="pt-2"
                    />
                    <div className='buttonRow'>
                        <Grid container spacing={3}>
                            <Grid item xs />
                            <Grid item>
                                <Button onClick={() => this.props.toggleDialog()}
                                        variant="text"
                                >
                                    <FAIcon icon="times" size={15} button />
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                        disabled={!(formData.password && formData.confirmPassword)}>
                                    <FAIcon icon="check" size={15} buttonPrimary disabled={!(formData.password && formData.confirmPassword)} />
                                    Change Password
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen} 
                        success={this.handleConfirmationSuccess} 
                        close={this.handleConfirmationClose} 
                        title="Update Password?" 
                        message={`Are you sure you want to update ${this.props.staffId ? "this system user's" : "your"} password?`}
                    />
                </form>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(ChangeStaffPasswordForm);