import React from 'react';
import moment from 'moment';

import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

export const ColorAvatar = (props) => (
    <Avatar alt={props.alt} style={{backgroundColor: props.color, color: '#fff', width: props.size === "lg" ? 64 : 32, height: props.size === "lg" ? 64 : 32}}>{props.alt.charAt(0).toUpperCase()}</Avatar>
)

export const UserAvatar = (staffModel, props) => {

    let avatar = <Avatar alt={`${staffModel.staff_first_name} ${staffModel.staff_last_name}`} src={staffModel.staff_profile_photo} style={{width: props && props.size === "lg" ? 64 : 32, height: props && props.size === "lg" ? 64 : 32}} {...props} />

    let lastActive = moment(staffModel.staff_last_active_datetime)
    let activePeriod = moment().subtract(3, 'minutes')

    let lastIdle = moment(staffModel.staff_last_idle_datetime)
    let idlePeriod = moment().subtract(1, 'minutes')

    if(lastIdle.isAfter(idlePeriod)) {
        if(lastActive.isAfter(activePeriod)) {
            return (
                <UserOnline
                    overlap="circle"
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    variant="dot"
                >
                    {avatar}
                </UserOnline>
            )
        } else {
            return (
                <UserIdle
                    overlap="circle"
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    variant="dot"
                >
                    {avatar}
                </UserIdle>
            )
        }
    } else {
        return (
            <UserOffline
                overlap="circle"
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                variant="dot"
            >
                {avatar}
            </UserOffline>
        )
    }
}

export const ReduxAvatar = (staffModel, props) => {

    let avatar = <Avatar alt={`${staffModel.staff_first_name} ${staffModel.staff_last_name}`} src={staffModel.staff_profile_photo} style={{width: props && props.size === "lg" ? 64 : (props && props.size === "md" ? 50 : 32), height: props && props.size === "lg" ? 64 : (props && props.size === "md" ? 50 : 32)}} />
    
    switch(staffModel.status) {
        case "Online":
            return (
                <UserOnline
                    overlap="circle"
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    variant="dot"
                >
                    {avatar}
                </UserOnline>
            )
        case "Idle": 
            return (
                <UserIdle
                    overlap="circle"
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    variant="dot"
                >
                    {avatar}
                </UserIdle>
            )
        default:
            return (
                <UserOffline
                    overlap="circle"
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    variant="dot"
                >
                    {avatar}
                </UserOffline>
            )
    }
}

const UserOnline = withStyles(theme => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        }
    }
}))(Badge);

const UserIdle = withStyles(theme => ({
    badge: {
        backgroundColor: '#FFA726',
        color: '#FFA726',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        }
    }
}))(Badge);

const UserOffline = withStyles(theme => ({
    badge: {
        backgroundColor: '#d9534f',
        color: '#d9534f',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
    }
}))(Badge);