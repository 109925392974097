export function handleClick(event) {
    let anchorEl = null;
    if(!this.state.anchorEl) {
        anchorEl = event.currentTarget;
    }
    this.setState({ anchorEl });
}

export function handleClose() {
    this.setState({ anchorEl: null });
}