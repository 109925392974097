import React from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    info: {
        backgroundColor: '#1565C0',
        color: '#fff'
    },
    error: {
        backgroundColor: '#d9534f',
        color: '#fff'
    },
    warning: {
        backgroundColor: '#F57C00',
        color: '#fff'
    }
});

class ActionBadge extends React.PureComponent {
    render() {
        const { classes } = this.props;
        return (
            <Badge 
                badgeContent={this.props.badgeContent}
                classes={{badge: this.props.variant === 'warning' ? classes.warning : (this.props.variant === 'info' ? classes.info : classes.error)}}
                showZero={this.props.showZero ? this.props.showZero : false}
                style={{paddingRight: 12, ...this.props.style}}
            >            
                {this.props.children}
            </Badge>
        );
    }
}

export default withStyles(styles)(ActionBadge);
