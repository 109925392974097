export function handleChange(e) {
    this.setState({
        formData: {
            ...this.state.formData,
            [e.target.name]: e.target.value
        }
    });
}

export function handleSelectChange(fieldName, selectedOption) {
    this.setState({
        formData: {
            ...this.state.formData,
            [fieldName]: selectedOption && selectedOption.value
        }
    });
};

export function handleMultiSelectChange(fieldName, selectedOptions) {
    let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
    this.setState({
        formData: {
            ...this.state.formData,
            [fieldName]: values
        }
    });
}

export function handleFloat(e) {
    this.setState({
        formData: {
            ...this.state.formData,
            [e.target.name]: parseFloat(e.target.value).toFixed(2)
        }
    })
}

export function handleUpdateChange(e) {
    this.setState({
        updateData: {
            ...this.state.updateData,
            [e.target.name]: e.target.value
        }
    });
}

export function handleUpdateSelectChange(fieldName, selectedOption) {
    this.setState({
        updateData: {
            ...this.state.updateData,
            [fieldName]: selectedOption && selectedOption.value
        }
    });
};

export function handleUpdateMultiSelectChange(fieldName, selectedOptions) {
    let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
    this.setState({
        updateData: {
            ...this.state.updateData,
            [fieldName]: values
        }
    });
}

export function handleSnackbarClose(type) {
    this.setState({ 
        snackbar: {
        ...this.state.snackbar,
        [type]: false,
        } 
    });
};

export function handleConfirmationOpen(type) {
    this.setState({ 
        confirmation: {
            ...this.state.confirmation,
            [type]: true,
        } 
    });
};

export function handleConfirmationClose(type) {
    this.setState({ 
        confirmation: {
            ...this.state.confirmation,
            [type]: false,
        } 
    });
};

export function handleConfirmationSuccess(type) {
    this.setState({ 
        confirmation: {
            ...this.state.confirmation,
            [type]: false,
        } 
    }, () => {
        if(type === 'submit') {
            this.submit();
        } else if(type === 'update') {
            this.update();
        } else if(type === 'sync') {
            this.sync();
        }
    });
}

export function handleDialogClose(type) {
    this.setState({ 
        dialog: {
            ...this.state.dialog,
            [type]: false,
        } 
    });
}

export function cancelSubmit(e) {
    e.preventDefault();
}

function buildFormData(formData, data, parentKey) {
    if(data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}

export function getFormData() {
    const formData = new FormData();
    buildFormData(formData, this.state.formData);
    return formData;
}