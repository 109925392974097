import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StatusChip from 'Components/Common/Chips/StatusChip';
import StaffChip from 'Components/Common/Chips/StaffChip';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const initialState = () => ({
    sageCloudConnected: true,
    sageCloudExpiry: null,
    searchResults: {},
    searchString: '',
    access: {
        deleteBatch: false,
        processBatch: false,
        viewBatch: false
    },
    dataLoading: true,
    isLoading: true,
    processedBatches: []
})

class AccountsExpenseBatches extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.dataUpdate = false;
    }

    componentDidMount = () => {
        this.props.pageTitle([1, 'Accounts', 'Batch Expenses']);
        this.getSearchData(true);
        this.getAccess();
        this.getSageBusinessCloudStatus();
    }

    componentWillUnmount = () => {
        if(this.dataUpdate)
            clearInterval(this.dataUpdate);
    }

    getAccess = () => {
        Promise.all([
            API.access('accounts-batches-expenses-view'), 
            API.access('accounts-batches-expenses:process-batch'), 
            API.access('accounts-batches-expenses:delete-batch'), 
        ])
        .then(([viewBatch, processBatch, deleteBatch]) =>  {
            this.setState({
                ...this.state,
                access: {
                    deleteBatch: (deleteBatch.data && deleteBatch.data.has_access) || false,
                    processBatch: (processBatch.data && processBatch.data.has_access) || false,
                    viewBatch: (viewBatch.data && viewBatch.data.has_access) || false
                }
            });
        })
    }

    getSageBusinessCloudStatus = () => {
        API.get('/bluesync/accounts/status', { props: { noLoading: true }})
        .then(result => {
            this.setState({
                sageCloudConnected: result?.data?.status === 1,
                sageCloudExpiry: result?.data?.expiry ?? null,
                isLoading: false
            });
        })
    }

    getSearchData = (dataLoading) => {
        this.setState({
            dataLoading
        }, () => {
            API.get('/bluesync/accounts/batches/expenses', { params: { searchString: this.state.searchString }, props: { noLoading: !dataLoading } })
            .then(result => {
                this.setState({
                    dataLoading: false
                });
                if(result.data) {
                    this.setState({
                        searchResults: result.data
                    });
                }
            });
        });
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.getSearchData(true);
        });
    }

    resetSearch = () => {
        this.setState({
            searchString: ''
        }, 
        () => {
            this.getSearchData(true);
        });
    }

    deleteBatch = id => {

        if(!this.dataUpdate)
            this.dataUpdate = setInterval(() => this.getSearchData(false), 1500);

        API.post(`/bluesync/accounts/batches/expenses/${id}`)
        .then(result => {
            if(result.data) {
                let { processedBatches } = this.state;
                processedBatches.push(id)
                this.setState({
                    processedBatches
                }, () => {
                    this.props.deploySnackBar("success", `Batch ${result.data.be_identifier} has been deleted from BlueSync and ConnectWise`)
                })
            }
        })

    }

    processBatch = (id, ref) => {

        if(!this.dataUpdate)
            this.dataUpdate = setInterval(() => this.getSearchData(false), 1500);

        let { 
            processedBatches 
        } = this.state;

        processedBatches.push(id)

        this.setState({
            processedBatches
        }, () => {

            this.props.deploySnackBar("success", `Batch ${ref} has been queued to be processed into Sage Business Cloud Accounting`)
            
            API.put(`/bluesync/accounts/batches/expenses/${id}`)
            .then(result => {
                this.getSearchData(false);
                if(result?.data?.errors) {
                    this.props.deploySnackBar("error", `Batch ${ref} could not be processed - please check the logs and try again`)
                } else if(result?.data?.success) {
                    this.props.deploySnackBar("success", `Batch ${ref} has been successfully posted to Sage Business Cloud Accounting`)
                }
            })

        });       

    }

    render() {
        const { access, sageCloudConnected, sageCloudExpiry, dataLoading, isLoading, processedBatches } = this.state;
        return (
            <Grid container spacing={1}>
                <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={3} style={{marginBottom: 12}}>
                        <TextField 
                            fullWidth
                            onChange={this.onSearchStringChange}
                            placeholder='Batch Identifier:'
                            value={this.state.searchString} 
                            variant="filled"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><FAIcon icon="search" size="small" /></InputAdornment>
                            }}
                        /> 
                    </Grid> 
                    {!isLoading && (
                        <Grid item xs={9} align='right'>
                            <Box pb={1}>
                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item xs>    
                                        <Typography variant="body1" style={{fontWeight: 400}} className={!sageCloudConnected && 'textError'}>                                                
                                            {(sageCloudConnected && ( 
                                                `Sage Business Cloud Accounting - Connected`
                                            )) || (
                                                `Sage Business Cloud Accounting - Unauthorised`
                                            )}
                                        </Typography>      
                                        <Typography variant="caption" className={`${!sageCloudConnected && 'textError'} fw-400`}>                                                
                                            {(sageCloudConnected && ( 
                                                `You can process accounting batches to Sage Business Cloud Accounting until ${sageCloudExpiry}`
                                            )) || (
                                                `Please re-connect to Sage Business Cloud Accounting to process accounting batches`
                                            )}
                                        </Typography>     
                                    </Grid>
                                    <Grid item>
                                        <FAIcon icon={sageCloudConnected ? 'check-circle' : 'exclamation-triangle'} color={sageCloudConnected ? 'success' : 'error'} size="large" noMargin />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    )}   
                </Grid>   
                <Grid item xs={12}> 
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <DataTable  
                                    config={{
                                        key: 'be_id',
                                        pagination: true,
                                        alternatingRowColours: true,
                                        isLoading: dataLoading,
                                        responsiveImportance: true,
                                        plainHeader: true, 
                                        title: 'Batch Expenses'
                                    }}
                                    columns={[
                                        {
                                            heading: 'Posted',
                                            field: rowData => rowData.be_status !== "Completed" ?  <FAIcon icon="times" style={{color: '#c62828'}} noMargin/> : <FAIcon icon="check" style={{color: '#4CAF50'}} noMargin/>,
                                            sizeToContent: true,
                                            alignment: 'center'
                                        },
                                        {
                                            heading: 'Batch Identifier',
                                            field: rowData => (rowData.be_identifier.length > 0 && rowData.be_identifier) || 'Batch Generating...',
                                            cellProps: rowData => {
                                                return ({
                                                    style: {
                                                        fontStyle: rowData.be_identifier.length === 0 ? 'italic' : 'normal' 
                                                    }
                                                });
                                            },
                                            important: true,
                                        },
                                        {
                                            heading: 'Expenses',
                                            field: rowData => rowData.items_count, 
                                            alignment: 'center',
                                            sizeToContent: true,    
                                        },
                                        {
                                            heading: 'Created By',
                                            field: rowData => <StaffChip staff={this.props?.staff?.[rowData?.staff?.staff_id ?? 0]} />, 
                                            sizeToContent: true,    
                                        },
                                        {
                                            heading: 'Batch Created',
                                            field: rowData => rowData.be_created_datetime,
                                            fieldFormat: 'datetime',                              
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Batch Completed',
                                            field: rowData => rowData.be_status === "Completed" ? rowData.be_updated_datetime : "0000-00-00 00:00:00",
                                            fieldFormat: 'datetime',                         
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Status',
                                            field: rowData => (rowData.be_status !== 'Processing' && ( <StatusChip status={rowData.be_status} /> )) || ( <><StatusChip status={rowData.be_status} /><br /><Typography variant="caption" className="fw-400">{`${(rowData.items_count - rowData.items_pending_count)}/${rowData.items_count} - ${((rowData.items_count - rowData.items_pending_count) / rowData.items_count * 100).toFixed(0)}%`}</Typography></> ),
                                            sizeToContent: true,
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Delete Batch', icon: 'trash', onClick: () => this.deleteBatch(rowData.be_id), disabled: !access.deleteBatch ? true : (processedBatches.includes(rowData.be_id) ? true : (rowData.be_status === "Generated" ? false : true))},
                                                    {name: 'Process Batch', icon: 'check', onClick: () => this.processBatch(rowData.be_id, rowData.be_identifier), disabled: !access.processBatch ? true : (!sageCloudConnected ? false : (processedBatches.includes(rowData.be_id) ? true : (rowData.be_status === "Generated" ? false : true)))},
                                                    {name: 'View', icon: 'search', link: '/accounts/batches/expenses/view/' + rowData.be_id, disabled: !access.viewBatch}
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={this.state.searchResults}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    staff: state.notifications.status
})

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsExpenseBatches);