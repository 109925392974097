import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LogoDark from 'Assets/Images/logo_dark.png';

const SyncSwitch = withStyles({
    switchBase: {
      color: '#fff',
      '&$checked': {
        color: '#4CAF50'
      },
      '&$checked + $track': {
        backgroundColor: '#4CAF50',
      },
    },
    checked: {},
    track: {},
  })(Switch);

  
const styles = theme => ({   
    dialogSelection: {
        border: '1px solid #ddd',
        borderColor: '#fff',
        cursor: 'pointer',  
        paddingTop: '26px!important',
        paddingBottom: '26px!important',      
        '&:hover': {            
            backgroundColor: 'rgba(0,0,0,0.1)',
            borderColor: '#ddd'
        }
    },
    dialogSelectionTitle: {
        paddingLeft: '0!important'
    }
});

const initialState = {
    accounts: {
        completed: false,
        error: false,
        inProgress: false,
        options: {
            All: false,
            Invoices: false,
            Expenses: false,
        },
        result: {},
        unavailable: false
    },
    import: {
        completed: false,
        error: false,
        inProgress: false,
        lastImported: [],
        options: {
            All: false,
            ConnectWise: false,
            MyCloud: false,
            MS365: false,
            SC: false
        },
        progress: [],
        result: {},
        unavailable: false
    },
    sync: {
        completed: false,
        error: false,
        inProgress: false,
        lastImported: [],
        options: {
            All: false,
            ConnectWise: false,
            MyCloud: false,
            VOIP: false,
            MS365: false,
            SC: false
        },
        progress: [],
        result: {},
        unavailable: false,
        voipDisabled: true
    }
}


class AdminDialogs extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.state = initialState;
        this.delay = false;
        this.importData = false;
        this.syncUpdate = false;
        this.accountsUpdate = false;
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.dialog !== prevProps.dialog) {
            this.delay = setTimeout(
                () => {
                    this.setState({
                        ...initialState
                    }, () => {
                        if(this.props.dialog.sync && !prevProps.dialog.sync) {
                            API.get('/voip/sync')
                            .then(res => {
                                if(res.data) {
                                    this.setState({
                                        sync: {
                                            ...this.state.sync,
                                            voipDisabled: !!res.data.disabled ? true : false
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            , 250);
        }
    }

    componentWillUnmount = () => {
        if(this.delay)
            clearTimeout(this.delay)
        if(this.importData)
            clearInterval(this.importData)
        if(this.syncUpdate)
            clearInterval(this.syncUpdate)
        if(this.accountsUpdate)
            clearInterval(this.accountsUpdate)
    }

    handleAccounts = () => {
        const error = _.every(this.state.accounts.options, (opt) => { return opt === false });
        if(error) {
            this.setState({
                accounts: {
                    ...this.state.accounts,
                    error: true
                }
            })
        } else {
            let options = [];
            _.each(Object.keys(this.state.accounts.options), (opt) => {
                if(opt !== 'All') {
                    if(this.state.accounts.options[opt]) {
                        options.push({
                            name: opt,
                            status: 'queued'
                        })
                    }
                }
            })
            this.setState({
                accounts: {
                    ...this.state.accounts,
                    error: false,
                    inProgress: true,
                    progress: options
                }
            }, 
            () => {

                this.accountsUpdate = setInterval(() => { this.handleAccountsProgressUpdate() }, 1000);

                API.post('/bluesync/accounts/batches', this.state.accounts.options, { props: { noLoading: true } })
                .then(result => {   

                    if(this.accountsUpdate)
                        clearInterval(this.accountsUpdate)

                    if(result.data && result.data.unavailable) {
                        this.setState({
                            accounts: {
                                ...this.state.accounts,
                                unavailable: true
                            }
                        })
                    } else {

                        let progress = [...this.state.accounts.progress],
                            updatedProgress = []

                        _.each(progress, (task) => {
                            updatedProgress.push({
                                name: task.name,
                                status: 'Completed'
                            })
                        })
            
                        this.setState({
                            accounts: {
                                ...this.state.accounts,
                                completed: true,
                                progress: updatedProgress,
                                result: result.data
                            }
                        })
                    }

                })
            })
        }
    }

    handleAccountsProgressUpdate = () => {
        API.get('/bluesync/accounts/batches', { props: { noLoading: true } })
        .then(result => {
            if(result.data) {

                let completed = result.data.acc_status === "Completed" ? true : false

                if(completed) {
                    if(this.accountsUpdate)
                        clearInterval(this.accountsUpdate)
                }

                let progress = [...this.state.accounts.progress],
                    updatedProgress = []

                if(result.data && result.data.queue) {
                    _.each(progress, (task) => {
                        let idx = _.findIndex(result.data.queue, (el) => { return el.acc_queue_task === task.name })
                        let status = idx !== -1 ? result.data.queue[idx].acc_queue_status.toLowerCase() : 'error'
                        updatedProgress.push({
                            name: task.name,
                            status: status
                        })
                    })
                }

                if(!this.state.accounts.completed) {
                    this.setState({
                        accounts: {
                            ...this.state.accounts,
                            completed: completed,
                            progress: updatedProgress
                        }
                    })
                }
            }
        })
    }

    handleImport = () => {
        const error = _.every(this.state.import.options, (opt) => { return opt === false });
        if(error) {
            this.setState({
                import: {
                    ...this.state.import,
                    error: true
                }
            })
        } else {
            let options = [];
            _.each(Object.keys(this.state.import.options), (opt) => {
                if(opt !== 'All') {
                    if(this.state.import.options[opt]) {
                        options.push({
                            name: opt,
                            status: 'queued'
                        })
                    }
                }
            })
            this.setState({
                import: {
                    ...this.state.import,
                    error: false,
                    inProgress: true,
                    progress: options
                }
            }, 
            () => {

                this.importData = setInterval(() => { this.handleImportProgressUpdate() }, 1000);

                API.post('/bluesync/import', this.state.import.options, { props: { noLoading: true } })
                .then(result => {   

                    if(this.importData)
                        clearInterval(this.importData)

                    if(result.data && result.data.unavailable) {
                        this.setState({
                            import: {
                                ...this.state.import,
                                unavailable: true
                            }
                        })
                    } else {

                        let progress = [...this.state.import.progress],
                            updatedProgress = []

                        _.each(progress, (task) => {
                            updatedProgress.push({
                                name: task.name,
                                status: 'Completed'
                            })
                        })
            
                        this.setState({
                            import: {
                                ...this.state.import,
                                completed: true,
                                progress: updatedProgress,
                                result: result.data
                            }
                        })
                    }

                })
            })
        }
    }

    handleImportProgressUpdate = () => {
        API.get('/bluesync/import', { props: { noLoading: true } })
        .then(result => {
            if(result.data) {

                let completed = result.data.import_status === "Completed" ? true : false

                if(completed) {
                    if(this.importData)
                        clearInterval(this.importData)
                }

                let progress = [...this.state.import.progress],
                    updatedProgress = []

                if(result.data && result.data.queue) {
                    _.each(progress, (task) => {
                        let idx = _.findIndex(result.data.queue, (el) => { return el.import_queue_task === task.name })
                        let status = idx !== -1 ? result.data.queue[idx].import_queue_status.toLowerCase() : 'error'
                        updatedProgress.push({
                            name: task.name,
                            status: status
                        })
                    })
                }

                if(!this.state.import.completed) {
                    this.setState({
                        import: {
                            ...this.state.import,
                            completed: completed,
                            progress: updatedProgress
                        }
                    })
                }
            }
        })
    }

    handleAccountsAllChange = event => {
        if(event.target.checked) {            
            this.setState({
                accounts: {
                    ...this.state.accounts,
                    options: {
                        ...this.state.accounts.options,
                        All: true,
                        Expenses: true,
                        Invoices: true
                    }
                }
            })
        } else {
            this.setState({
                accounts: {
                    ...this.state.accounts,
                    options: {
                        ...initialState.accounts.options
                    }
                }
            })
        }
    }

    handleAccountsChange = name => event => {
        this.setState({
            accounts: {
                ...this.state.accounts,
                options: {
                    ...this.state.accounts.options,
                    [name]: event.target.checked
                }
            }
        }, () => {
            this.setState({
                accounts: {
                    ...this.state.accounts,
                    options: {
                        ...this.state.accounts.options,
                        All: (this.state.accounts.options.Invoices && this.state.accounts.options.Expenses && true) || false 
                    }
                }
            })
        })
    }
    
    handleImportAllChange = event => {
        if(event.target.checked) {            
            this.setState({
                import: {
                    ...this.state.import,
                    options: {
                        ...this.state.import.options,
                        All: true,
                        ConnectWise: true,
                        MyCloud: true,
                        MS365: true,
                        SC: true
                    }
                }
            })
        } else {
            this.setState({
                import: {
                    ...this.state.import,
                    options: {
                        ...initialState.import.options
                    }
                }
            })
        }
    }

    handleImportChange = name => event => {
        this.setState({
            import: {
                ...this.state.import,
                options: {
                    ...this.state.import.options,
                    [name]: event.target.checked
                }
            }
        }, () => {
            this.setState({
                import: {
                    ...this.state.import,
                    options: {
                        ...this.state.import.options,
                        All: (this.state.import.options.ConnectWise && this.state.import.options.MyCloud && this.state.import.options.MS365 && this.state.import.options.SC &&true) || false 
                    }
                }
            })
        })
    }

    handleSync = () => {
        const { sync } = this.state;
        const error = _.every(sync.options, (opt) => { return opt === false });
        if(error) {
            this.setState({
                sync: {
                    ...this.state.sync,
                    error: true
                }
            })
        } else {
            let options = [];
            _.each(Object.keys(sync.options), (opt) => {
                if(opt !== 'All') {
                    if(sync.options[opt]) {
                        options.push({
                            name: opt,
                            status: 'queued'
                        })
                    }
                }
            })
            this.setState({
                sync: {
                    ...this.state.sync,
                    error: false,
                    inProgress: true,
                    progress: options
                }
            }, 
            () => {

                this.syncUpdate = setInterval(() => { this.handleSyncProgressUpdate() }, 1000);

                API.post('/bluesync/synchronization', this.state.sync.options, { props: { noLoading: true } })
                .then(result => {

                    if(this.syncUpdate)
                        clearInterval(this.syncUpdate)

                    if(result.data && result.data.unavailable) {

                        this.setState({
                            sync: {
                                ...this.state.sync,
                                unavailable: true
                            }
                        })

                    } else {

                        let progress = [...this.state.sync.progress],
                            updatedProgress = []

                        _.each(progress, (task) => {
                            updatedProgress.push({
                                name: task.name,
                                status: 'Completed'
                            })
                        })
            
                        this.setState({
                            sync: {
                                ...this.state.sync,
                                completed: true,
                                progress: updatedProgress,
                                result: result.data
                            }
                        })

                    }

                })
            })
        }
    }

    handleSyncProgressUpdate = () => {

        API.get('/bluesync/synchronization', { props: { noLoading: true } })
        .then(result => {

            if(result.data) {

                let completed = result.data.sync_status === "Completed" ? true : false

                if(completed) {
                    if(this.syncUpdate)
                        clearInterval(this.syncUpdate)
                }

                let progress = [...this.state.sync.progress],
                    updatedProgress = []

                if(result.data && result.data.queue) {
                    _.each(progress, (task) => {
                        let idx = _.findIndex(result.data.queue, (el) => { return el.sync_queue_task === task.name })
                        let status = idx !== -1 ? result.data.queue[idx].sync_queue_status.toLowerCase() : 'error'
                        updatedProgress.push({
                            name: task.name,
                            status: status
                        })
                    })
                }

                if(!this.state.sync.completed) {
                    this.setState({
                        sync: {
                            ...this.state.sync,
                            completed: completed,
                            progress: updatedProgress
                        }
                    })
                }
            }
            
        })
    }

    handleSyncAllChange = event => {
        if(event.target.checked) {            
            this.setState({
                sync: {
                    ...this.state.sync,
                    options: {
                        ...this.state.sync.options,
                        All: true,
                        MyCloud: true,
                        VOIP: !this.state.sync.voipDisabled,
                        MS365: true,
                        SC: true
                    }
                }
            })
        } else {
            this.setState({
                sync: {
                    ...this.state.sync,
                    options: {
                        ...initialState.sync.options
                    }
                }
            })
        }
    }
    
    handleSyncChange = name => event => {
        this.setState({
            sync: {
                ...this.state.sync,
                options: {
                    ...this.state.sync.options,
                    [name]: event.target.checked
                }
            }
        }, () => {
            this.setState({
                sync: {
                    ...this.state.sync,
                    options: {
                        ...this.state.sync.options,
                        All: (this.state.sync.options.MyCloud && (this.state.sync.options.VOIP || this.state.sync.voipDisabled) && this.state.sync.options.MS365 && true) || false 
                    }
                }
            })
        })
    }

    render() {
        const { classes, dialog } = this.props;
        const { sync} = this.state;
        return (
            <React.Fragment>
                <Dialog 
                    open={dialog.selection}
                    onClose={() => this.props.handleDialogClose('selection')}
                >
                    <DialogTitle
                        disableTypography
                        style={{
                            color: '#fff',
                            backgroundColor: '#375885',
                            padding: '24px 0 20px 24px',
                            paddingRight: 200,
                            maxWidth: 500
                        }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <img src={LogoDark} width={170} alt="BlueSync" />
                            </Grid>
                        </Grid>
                    </DialogTitle>
                        <DialogContent style={{padding: '18px 36px 36px 36px', maxWidth: 500}}>
                            <Grid container spacing={3}>  
                                <Grid item xs={12} className={classes.dialogSelectionTitle}>
                                    <Typography variant="caption" className="fw-400" gutterBottom>
                                        Please select the task you want to complete:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} onClick={() => this.props.handleDialogSelection('accounts')} className={classes.dialogSelection} align='center' style={{borderRight: '1px solid #ddd'}}>
                                    <FAIcon type='thin' icon="funnel-dollar" size={50} button />
                                    <Typography variant="h6">
                                        Accounts
                                    </Typography>
                                </Grid> 
                                <Grid item xs={12} sm={4} onClick={() => this.props.handleDialogSelection('import')} className={classes.dialogSelection} align='center' style={{borderRight: '1px solid #ddd'}}>
                                    <FAIcon type='thin' icon="cloud-download-alt" size={50} button />
                                    <Typography variant="h6">
                                        Data Import
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} onClick={() => this.props.handleDialogSelection('sync')} className={classes.dialogSelection} align='center'>
                                    <FAIcon type='thin' icon="cloud-upload-alt" size={50} button />
                                    <Typography variant="h6">
                                        Synchronize
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                </Dialog> 
                <Dialog 
                    open={dialog.accounts}
                    maxWidth='xs' 
                >
                    <DialogTitle
                        disableTypography
                        style={{
                            backgroundColor: 
                                this.state.accounts.unavailable ? '#d9534f' 
                                : 
                                (this.state.accounts.completed ? '#4CAF50' : '#375885'),
                            color: '#fff',
                            padding: '24px 0 20px 24px',
                            paddingRight: 96
                        }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <FAIcon type='light' icon="sync" size="large" style={{color: '#fff', marginTop: 5}} swapOpacity noMargin />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="caption">
                                    BlueSync
                                </Typography>
                                <Typography variant="h6" className="textLight">
                                    Accounting Batches
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    {(this.state.accounts.unavailable && (  
                        <DialogContent style={{padding: 24}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} align='center'>
                                    <FAIcon type='light' icon='exclamation-triangle' color='error' size='xlarge' />
                                </Grid>
                                <Grid item xs={12} align='center'>
                                    <Typography variant="h6">                                        
                                        Something Went Wrong
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align='center'>
                                    <Typography component="div" variant="caption">                                        
                                        Another user or process is currently creating accounting batches<br />
                                        Please try again soon or contact Crushed Ice for support
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} />
                                <Grid item xs={12} align='right'>
                                    <Button 
                                        onClick={() => this.props.handleDialogClose('accounts')}
                                        variant="text"
                                    >
                                        <FAIcon type='light' icon="check" size={15} />
                                        Close
                                    </Button>
                                </Grid>                               
                            </Grid>
                        </DialogContent>
                    )) || (this.state.accounts.inProgress && (  
                        <DialogContent style={{padding: 24}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {(this.state.accounts.completed && (
                                        <Typography variant="caption" className="fw-400">
                                            BlueSync has finished processing accounting data
                                        </Typography>
                                    )) || (
                                        <Typography variant="caption" className="fw-400">
                                            Please wait whilst BlueSync processes accounting data...
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} style={{paddingLeft: 24}}>
                                    <Grid container alignItems='center' spacing={3}>
                                        {_.map(this.state.accounts.progress, (task, idx) => {
                                            return (
                                                <Grid item xs={12} key={idx}>
                                                    {(task.status === 'queued' && (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <FAIcon type='light' icon="pause-circle" noMargin />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2">                                                           
                                                                    Unposted {task.name} (Queued)
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>                                                        
                                                    )) || (task.status === 'processing' && (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <CircularProgress size={19.38} style={{marginTop: 3}} />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2">                                                           
                                                                    Unposted {task.name} (Processing)
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )) || (task.status === 'error' && (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <FAIcon type='light' icon="exclamation-triangle" noMargin />                                                                
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2">                                                           
                                                                    Unposted {task.name} (Unknown)
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )) || (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <FAIcon type='light' icon="check-circle" color="success" noMargin />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2">                                                           
                                                                    Unposted {task.name} (Completed)
                                                                </Typography>   
                                                                {this.state.accounts.completed && (                                                             
                                                                    <Typography variant="caption" component="div">        
                                                                        {(this.state.accounts.result && this.state.accounts.result[task.name] && this.state.accounts.result[task.name].total > 0 && (
                                                                            `${this.state.accounts.result[task.name].identifier} generated (${this.state.accounts.result[task.name].total} ${task.name})`
                                                                        )) || (
                                                                            `Nothing to do`
                                                                        )}
                                                                    </Typography>
                                                                )}
                                                            </Grid>
                                                        </Grid>   
                                                    )}
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    {!this.state.accounts.completed && (
                                        <Grid item xs={12} align='right'>
                                            <Button 
                                                onClick={() => this.props.handleDialogClose('accounts')}
                                                variant="text"
                                                size="small"
                                            >
                                                <FAIcon type='light' icon="check" size={15} />
                                                Close (Process will continue)
                                            </Button>
                                        </Grid>
                                    )}
                                    {this.state.accounts.completed && (
                                        <Grid item xs={12} align='right'>
                                            <Button 
                                                onClick={() => this.props.handleDialogClose('accounts')}
                                                variant="text"
                                            >
                                                <FAIcon type='light' icon="check" size={15} />
                                                Close
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>                               
                            </Grid>
                        </DialogContent>
                    )) || (
                        <DialogContent style={{padding: 24}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="caption" className="fw-400" color={this.state.accounts.error ? 'error' : 'initial'} component="div">
                                        Please select the accounting data you want to process:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <SyncSwitch color="default" checked={this.state.accounts.options.All} onChange={this.handleAccountsAllChange} value={this.state.accounts.options.All} />
                                                }
                                                label="All"
                                            />       
                                        </Grid>                                        
                                        <Grid item xs={12}>                       
                                            <FormControlLabel
                                                control={
                                                    <SyncSwitch color="default" checked={this.state.accounts.options.Invoices} onChange={this.handleAccountsChange('Invoices')} value={this.state.accounts.options.Invoices} />
                                                }
                                                label="Unposted Invoices"
                                            />  
                                            <Typography variant="caption" component="div">                                                           
                                                Get Unposted Invoices and create an Accounting Batch for Sage
                                            </Typography>
                                        </Grid>  
                                        <Grid item xs={12}>                       
                                            <FormControlLabel
                                                control={
                                                    <SyncSwitch color="default" checked={this.state.accounts.options.Expenses} onChange={this.handleAccountsChange('Expenses')} value={this.state.accounts.options.Expenses} />
                                                }
                                                label="Unposted Expenses"
                                            />  
                                            <Typography variant="caption" component="div">                                                           
                                                Get Unposted Expenses and create an Accounting Batch for Sage
                                            </Typography>
                                        </Grid>  
                                    </Grid>  
                                </Grid>
                                <Grid item xs={12} align='right'>
                                    <Button 
                                        onClick={() => this.props.handleDialogClose('accounts')}
                                        variant="text"
                                    >
                                        <FAIcon type='light' icon="times" size={15} />
                                        Cancel                                        
                                    </Button>
                                    <Button 
                                        onClick={this.handleAccounts}
                                        variant="text"
                                    >
                                        <FAIcon type='light' icon="check" size={15} />
                                        Continue                                        
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    )}
                </Dialog>
                <Dialog 
                    open={dialog.import}
                    maxWidth='xs' 
                >
                    <DialogTitle
                        disableTypography
                        style={{
                            backgroundColor: 
                                this.state.import.unavailable ? '#d9534f' 
                                : 
                                (this.state.import.completed ? '#4CAF50' : '#375885'),
                            color: '#fff',
                            padding: '24px 0 20px 24px',
                            paddingRight: 96
                        }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <FAIcon type='light' icon="sync" size="large" style={{color: '#fff', marginTop: 5}} swapOpacity noMargin />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="caption">
                                    BlueSync
                                </Typography>
                                <Typography variant="h6" className="textLight">
                                    Data Import
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    {(this.state.import.unavailable && (  
                        <DialogContent style={{padding: 24}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} align='center'>
                                    <FAIcon type='light' icon='exclamation-triangle' color='error' size='xlarge' />
                                </Grid>
                                <Grid item xs={12} align='center'>
                                    <Typography variant="h6">                                        
                                        Something Went Wrong
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align='center'>
                                    <Typography component="div" variant="caption">                                        
                                        Another user or process is currently completing a data import<br />
                                        Please try again soon or contact Crushed Ice for support
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} />
                                <Grid item xs={12} align='right'>
                                    <Button 
                                        onClick={() => this.props.handleDialogClose('import')}
                                        variant="text"
                                    >
                                        <FAIcon type='light' icon="check" size={15} />
                                        Close
                                    </Button>
                                </Grid>                               
                            </Grid>
                        </DialogContent>
                    )) || (this.state.import.inProgress && (  
                        <DialogContent style={{padding: 24}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {(this.state.import.completed && (
                                        <Typography variant="caption" className="fw-400">
                                            BlueSync has finished importing data
                                        </Typography>
                                    )) || (
                                        <Typography variant="caption" className="fw-400">
                                            Please wait whilst BlueSync imports data...
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} style={{paddingLeft: 24}}>
                                    <Grid container alignItems='center' spacing={3}>
                                        {_.map(this.state.import.progress, (task, idx) => {
                                            return (
                                                <Grid item xs={12} key={idx}>
                                                    {(task.status === 'queued' && (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <FAIcon type='light' icon="pause-circle" noMargin />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2">                                                           
                                                                    {task.name} (Queued)
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>                                                        
                                                    )) || (task.status === 'processing' && (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <CircularProgress size={19.38} style={{marginTop: 3}} />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2">                                                           
                                                                    {task.name} (Processing)
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )) || (task.status === 'error' && (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <FAIcon type='light' icon="exclamation-triangle" noMargin />                                                                
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2">                                                           
                                                                    {task.name} (Unknown)
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )) || (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <FAIcon type='light' icon="check-circle" color="success" noMargin />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2">
                                                                    {task.name} (Completed)
                                                                </Typography>   
                                                                {this.state.import.completed && (
                                                                    <Typography variant="caption" component="div">
                                                                        Imported data for {this.state.import.result && this.state.import.result[task.name] && this.state.import.result[task.name].success}/{this.state.import.result && this.state.import.result[task.name] && this.state.import.result[task.name].count} {task.name === 'ConnectWise' && 'Companies'}{task.name === 'MS365' && 'Tenants'}{task.name === 'MyCloud' && 'Organisational Units'}{task.name === 'SC' && 'Sites'} {this.state.import.result && this.state.import.result[task.name] && this.state.import.result[task.name].errors > 0 && `(${this.state.import.result[task.name].errors} errors)`}
                                                                    </Typography>
                                                                )}
                                                            </Grid>
                                                        </Grid>   
                                                    )}
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    {!this.state.import.completed && (
                                        <Grid item xs={12} align='right'>
                                            <Button 
                                                onClick={() => this.props.handleDialogClose('import')}
                                                variant="text"
                                                size="small"
                                            >
                                                <FAIcon type='light' icon="check" size={15} />
                                                Close (Process will continue)
                                            </Button>
                                        </Grid>
                                    )}
                                    {this.state.import.completed && (
                                        <Grid item xs={12} align='right'>
                                            <Button 
                                                onClick={() => this.props.handleDialogClose('import')}
                                                variant="text"
                                            >
                                                <FAIcon type='light' icon="check" size={15} />
                                                Close
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>                               
                            </Grid>
                        </DialogContent>
                    )) || (
                        <DialogContent style={{padding: 24}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="caption" className="fw-400" color={this.state.import.error ? 'error' : 'initial'} component="div">
                                        Please select the services you want to import:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <SyncSwitch color="default" checked={this.state.import.options.All} onChange={this.handleImportAllChange} value={this.state.import.options.All} />
                                                }
                                                label="All"
                                            />       
                                        </Grid>                                        
                                        <Grid item xs={12}>                       
                                            <Tooltip placement="right" title={`Last Imported: ${this.state.import.lastImported.ConnectWise === 'Never' ? 'Never' : moment(this.state.import.lastImported.ConnectWise).format("LLLL")}`}>
                                                <FormControlLabel
                                                    control={
                                                        <SyncSwitch color="default" checked={this.state.import.options.ConnectWise} onChange={this.handleImportChange('ConnectWise')} value={this.state.import.options.ConnectWise} />
                                                    }
                                                    label="ConnectWise Data"
                                                />  
                                            </Tooltip>                                       
                                            <Typography variant="caption" component="div">                                                           
                                                Import ConnectWise Companies, Contacts, Agreements and Additions
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>                       
                                            <Tooltip placement="right" title={`Last Imported: ${this.state.import.lastImported.MyCloud === 'Never' ? 'Never' : moment(this.state.import.lastImported.MyCloud).format("LLLL")}`}>
                                                <FormControlLabel
                                                    control={
                                                        <SyncSwitch color="default" checked={this.state.import.options.MyCloud} onChange={this.handleImportChange('MyCloud')} value={this.state.import.options.MyCloud} />
                                                    }
                                                    label="MyCloud LDAP Data"
                                                />  
                                            </Tooltip>                                       
                                            <Typography variant="caption" component="div">                                                           
                                                Import OUs, Groups and Users from MyCloud via LDAP
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>                       
                                            <Tooltip placement="right" title={`Last Imported: ${this.state.import.lastImported.MS365 === 'Never' ? 'Never' : moment(this.state.import.lastImported.MS365).format("LLLL")}`}>
                                                <FormControlLabel
                                                    control={
                                                        <SyncSwitch color="default" checked={this.state.import.options.MS365} onChange={this.handleImportChange('MS365')} value={this.state.import.options.MS365} />
                                                    }
                                                    label="Microsoft 365 Data"
                                                />  
                                            </Tooltip>                                       
                                            <Typography variant="caption" component="div">                                                           
                                                Import users and subscriptions from your Microsoft 365 Tenants
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>                       
                                            <Tooltip placement="right" title={`Last Imported: ${this.state.import.lastImported.SC === 'Never' ? 'Never' : moment(this.state.import.lastImported.SC).format("LLLL")}`}>
                                                <FormControlLabel
                                                    control={
                                                        <SyncSwitch color="default" checked={this.state.import.options.SC} onChange={this.handleImportChange('SC')} value={this.state.import.options.SC} />
                                                    }
                                                    label="Support Contracts Data"
                                                />  
                                            </Tooltip>                                       
                                            <Typography variant="caption" component="div">                                                           
                                                Import data for Support Contracts from Barracuda MW
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} align='right'>
                                    <Button 
                                        onClick={() => this.props.handleDialogClose('import')}
                                        variant="text"
                                    >
                                        <FAIcon type='light' icon="times" size={15} />
                                        Cancel
                                    </Button>
                                    <Button 
                                        onClick={this.handleImport}
                                        variant="text"
                                    >
                                        <FAIcon type='light' icon="check" size={15} />
                                        Continue                                        
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    )}
                </Dialog>
                <Dialog 
                    open={dialog.sync}
                    maxWidth='xs' 
                >
                    <DialogTitle
                        disableTypography
                        style={{
                            color: '#fff',
                            padding: '24px 0 20px 24px',
                            paddingRight: 96,                    
                            backgroundColor: 
                                sync.unavailable ? '#d9534f' 
                                : 
                                (sync.completed ? '#4CAF50' : '#375885'),
                        }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <FAIcon type='light' icon="sync" size="large" style={{color: '#fff', marginTop: 5}} swapOpacity noMargin />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="caption">
                                    BlueSync
                                </Typography>
                                <Typography variant="h6" className="textLight">
                                    Data Synchronization
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    {(sync.unavailable && (  
                        <DialogContent style={{padding: 24}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} align='center'>
                                    <FAIcon type='light' icon='exclamation-triangle' color='error' size='xlarge' />
                                </Grid>
                                <Grid item xs={12} align='center'>
                                    <Typography variant="h6">                                        
                                        Something Went Wrong
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align='center'>
                                    <Typography component="div" variant="caption">                                        
                                        Another user or process is currently completing a synchronization<br />
                                        Please try again soon or contact Crushed Ice for support
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} />
                                <Grid item xs={12} align='right'>
                                    <Button 
                                        onClick={() => this.props.handleDialogClose('sync')}
                                        variant="text"
                                    >
                                        <FAIcon type='light' icon="check" size={15} />
                                        Close
                                    </Button>
                                </Grid>                               
                            </Grid>
                        </DialogContent>
                    )) || (sync.inProgress && (  
                        <DialogContent style={{padding: 24}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {(sync.completed && (
                                        <Typography variant="caption" className="fw-400">
                                            BlueSync has finished synchronizing with ConnectWise!
                                        </Typography>
                                    )) || (
                                        <Typography variant="caption" className="fw-400">
                                            Please wait whilst BlueSync synchronizes with ConnectWise...
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} style={{paddingLeft: 24}}>
                                    <Grid container alignItems='center' spacing={3}>
                                        {_.map(sync.progress, (task, idx) => {
                                            return (
                                                <Grid item xs={12} key={idx}>
                                                    {(task.status === 'queued' && (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <FAIcon type='light' icon="pause-circle" noMargin />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2">                                                           
                                                                    {task.name} (Queued)
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>                                                        
                                                    )) || (task.status === 'processing' && (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <CircularProgress size={19.38} style={{marginTop: 3}} />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2">                                                           
                                                                    {task.name} (Processing)
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )) || (task.status === 'error' && (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <FAIcon type='light' icon="exclamation-triangle" noMargin />                                                                
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2">                                                           
                                                                    {task.name} (Unknown)
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )) || (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <FAIcon type='light' icon="check-circle" color="success" noMargin />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2">                                                           
                                                                    {task.name} (Completed)
                                                                </Typography>
                                                                {sync.completed && (                                                           
                                                                    <Typography variant="caption" component="div">                                                           
                                                                        Updated {sync.result && sync.result[task.name] && sync.result[task.name].success}/{sync.result && sync.result[task.name] && sync.result[task.name].count} additions for {sync.result && sync.result[task.name] && sync.result[task.name].companies} companies {sync.result && sync.result[task.name] && sync.result[task.name].errors > 0 && `(${sync.result[task.name].errors} errors)`}
                                                                    </Typography>
                                                                )}
                                                            </Grid>
                                                        </Grid>   
                                                    )}
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    {!sync.completed && (
                                        <Grid item xs={12} align='right'>
                                            <Button 
                                                onClick={() => this.props.handleDialogClose('sync')}
                                                variant="text"
                                                size="small"
                                            >
                                                <FAIcon type='light' icon="check" size={15} />
                                                Close (Process will continue)
                                            </Button>
                                        </Grid>
                                    )}
                                    {sync.completed && (
                                        <Grid item xs={12} align='right'>
                                            <Button 
                                                onClick={() => this.props.handleDialogClose('sync')}
                                                variant="text"
                                            >
                                                <FAIcon type='light' icon="check" size={15} />
                                                Close
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>                               
                            </Grid>
                        </DialogContent>
                    )) || (
                        <DialogContent style={{padding: 24}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="caption" className="fw-400" color={sync.error ? 'error' : 'initial'} component="div">
                                        Please select the services you want to synchronize with ConnectWise:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <SyncSwitch color="default" checked={sync.options.All} onChange={this.handleSyncAllChange} value={sync.options.All} />
                                                }
                                                label="All"
                                            />       
                                        </Grid>
                                        <Grid item xs={12}>                       
                                            <Tooltip placement="right" title={`Last Imported: ${this.state.import.lastImported.MyCloud === 'Never' ? 'Never' : moment(this.state.import.lastImported.MyCloud).format("LLLL")}`}>
                                                <FormControlLabel
                                                    control={
                                                        <SyncSwitch color="default" checked={sync.options.MyCloud} onChange={this.handleSyncChange('MyCloud')} value={sync.options.MyCloud} />
                                                    }
                                                    label="MyCloud"
                                                /> 
                                            </Tooltip>                                            
                                            <Typography variant="caption" component="div">                                                           
                                                Update Agreement Additions and Contacts for MyCloud
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>                       
                                            <FormControlLabel
                                                control={
                                                    <SyncSwitch color="default" checked={sync.options.MS365} onChange={this.handleSyncChange('MS365')} value={sync.options.MS365} />
                                                }
                                                label="Microsoft 365"
                                            /> 
                                            <Typography variant="caption" component="div">                                                           
                                                Update Agreement Additions and Contacts for Microsoft 365
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>                       
                                            <FormControlLabel
                                                control={
                                                    <SyncSwitch color="default" checked={sync.options.VOIP} onChange={this.handleSyncChange('VOIP')} value={sync.options.VOIP} disabled={sync.voipDisabled} />
                                                }
                                                label="VOIP and Connectivity"
                                            /> 
                                            <Typography variant="caption" component="div">                                                           
                                                {sync.voipDisabled ? 'No outstanding charges to synchronize' : 'Bill outstanding VOIP and Connectivity charges'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>                       
                                            <FormControlLabel
                                                control={
                                                    <SyncSwitch color="default" checked={sync.options.SC} onChange={this.handleSyncChange('SC')} value={sync.options.SC} />
                                                }
                                                label="Support Contracts"
                                            /> 
                                            <Typography variant="caption" component="div">                                                           
                                                Update Agreement Additions for Support Contracts
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} align='right'>
                                    <Button 
                                        onClick={() => this.props.handleDialogClose('sync')}
                                        variant="text"
                                    >
                                        <FAIcon type='light' icon="times" size={15} />
                                        Cancel
                                    </Button>
                                    <Button 
                                        onClick={this.handleSync}
                                        variant="text"
                                    >
                                        <FAIcon type='light' icon="check" size={15} />
                                        Proceed
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    )}
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(AdminDialogs);