import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

const Tile = ({color, dashboard, grid, icon, data, title}) => {
    const Element = dashboard ? Box : PaddedPaper;
    return (
        <Grid item xs={grid ? grid : 12}>
            <Element p={dashboard ? 1 : undefined}>
                <Grid container spacing={3} alignItems='center' align='left'>
                    <Grid item>
                        <FAIcon icon={icon} type="thin" size={40} noMargin style={{color}} />
                    </Grid>
                    <Grid item xs>
                        <Typography variant={dashboard? 'h3' : 'h4'}  className={dashboard ? 'fw-300' : 'fw-300'}>      
                            {data}
                        </Typography>
                        <Typography variant={dashboard? 'h6' : 'body2'} className={dashboard ? 'fw-400' : undefined} style={{fontSize: dashboard ? '1rem' : undefined}}>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </Element>
        </Grid>      
    )
}

export default Tile;