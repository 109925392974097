import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ConfirmationDialog extends React.Component {
    
    componentDidMount = () => {
        window.addEventListener('keydown', this.pdaNavEvents);
    }

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.pdaNavEvents);/* eslint-disable-next-line*/
    }

    pdaNavEvents = e => {
        if(e.keyCode === 112) {
            e.preventDefault();
            this.props.close();
        }
        if(e.keyCode === 113) {
            e.preventDefault();
            this.props.success();
        }
    }

    render = () => {

        const   { close, closeText, content, message, open, success, successOnly, successText, ui } = this.props,
                { device:{ isPDA } } = ui;

        return (
        <>
                <Dialog
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    onClose={close}
                    open={open}
                    TransitionComponent={Transition}
                >
                    <Box p={3} style={{minWidth: isPDA ? undefined : 320, maxWidth: 500, cursor: 'move'}} id="draggable-control">
                        {(message && (
                            <Typography variant={'body2'} align={isPDA ? 'center' : undefined} color="textPrimary">
                                {message}
                            </Typography>
                        )) || (
                            content
                        )}
                        {message && (
                            <Box pt={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs />
                                    {!successOnly && (
                                        <Grid item>
                                            <Button
                                                onClick={close}
                                                onTouchStart={close}
                                                variant="text"
                                                style={{zIndex: 9999}}
                                            >
                                                <FAIcon icon="times" size={15} button />
                                                {closeText ?? 'No'}
                                            </Button>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Button 
                                            onClick={success} 
                                            onTouchStart={success}
                                            variant="text" 
                                            color="primary"
                                            style={{zIndex: 9999}}
                                        >
                                            <FAIcon icon="check" size={15} button />
                                            {successText ?? 'Yes'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Box>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = state => ({
    ui: state.ui
})

export default connect(mapStateToProps)(ConfirmationDialog);