import * as ConfirmationActionTypes from 'Redux/Actions/Confirmation/Types/Types';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    key: uuidv4(),
    open: false,
    message: '',
    close: () => { },
    success: () => { },
    closeText: null,
    successText: null,
    successOnly: false,
    disableExit: false
}

const ConfirmationReducer = (state = initialState, action) => {
    switch(action.type) {
        case ConfirmationActionTypes.CONFIRMATION_OPEN:
            return {
                ...state,
                key: uuidv4(),
                open: true,
                message: action.message,
                close: action.close,
                success: action.success,
                closeText: action.closeText,
                successText: action.successText,
                successOnly: action.successOnly,
                disableExit: action.disableExit
            };
        case ConfirmationActionTypes.CONFIRMATION_CLOSE:
            return {
                ...state,
                open: false,
                message: initialState.message,
                close: initialState.close,
                success: initialState.success,
                closeText: initialState.closeText,
                successText: initialState.successText,
                successOnly: initialState.successOnly,
                disableExit: initialState.disableExit
            };
        default:
            return state;
    }
}
export default ConfirmationReducer;