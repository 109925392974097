import React from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StatusChip from 'Components/Common/Chips/StatusChip';

const initialState = {
    searchResults: {},
    searchString: '',
    access: {
        viewCustomer: false
    },
    dataLoading: true
}

class ConnectWiseSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    getAccess = () => {
        Promise.all([
            API.access('connectwise-view'), 
        ])
        .then(([viewCustomerRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    viewCustomer: (viewCustomerRes.data && viewCustomerRes.data.has_access) || false
                }
            });
        })
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'ConnectWise']);
        this.getSearchData();
        this.getAccess();
    }

    getSearchData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/connectwise', { params: { searchString: this.state.searchString } })
            .then(result => {
                this.setState({
                    dataLoading: false
                });
                if(result.data) {
                    this.setState({
                        searchResults: result.data
                    });
                }
            });
        });
    }
    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.getSearchData();
        });
    }
    resetSearch = () => {
        this.setState({
            searchString: ''
        }, 
        () => {
            this.getSearchData();
        });
    }
  render() {
    const { access, dataLoading } = this.state;
    return (
        <Grid container spacing={1}>
            <Grid container item xs={12} alignItems='center'>
                <Grid item xs={3} style={{marginBottom: 12}}>
                    <TextField 
                        fullWidth
                        onChange={this.onSearchStringChange}
                        placeholder='Company:'
                        value={this.state.searchString} 
                        variant="filled"
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><FAIcon icon="search" size="small" /></InputAdornment>
                        }}
                    /> 
                </Grid>    
            </Grid>   
            <Grid item xs={12}>
                <Paper>
                    <DataTable  
                        config={{
                            key: 'cust_id',
                            pagination: true,
                            alternatingRowColours: true,
                            isLoading: dataLoading,
                            responsiveImportance: true,
                            plainHeader: true, 
                            title: 'ConnectWise Companies',
                        }}
                        columns={[
                            {
                                heading: 'Sync',
                                field: rowData => rowData.cust_sync_blocked === 1 ?  <FAIcon icon="exclamation-triangle" style={{color: '#c62828'}} noMargin/> : <FAIcon icon="check" style={{color: '#4CAF50'}} noMargin/>,
                                sizeToContent: true,
                            },
                            {
                                heading: 'Company Name',
                                field: rowData => rowData.cust_name,
                                important: true,
                                main: true
                            },
                            {
                                heading: 'Agreements',
                                field: rowData => rowData.agreements_count, 
                                alignment: 'right',
                                sizeToContent: true,    
                            },
                            {
                                heading: 'Mapped Additions',
                                field: rowData => rowData.mapped_agreements && _.sumBy(rowData.mapped_agreements, ma => {
                                    return ma.mapped_additions_count;
                                }),
                                alignment: 'right',
                                sizeToContent: true,    
                            },
                            {
                                heading: 'Account Contacts',
                                field: rowData => rowData.contacts_count, 
                                alignment: 'right',
                                sizeToContent: true,    
                            },
                            {
                                heading: 'Last Imported',
                                field: rowData => rowData.cust_last_import_datetime,
                                fieldFormat: 'datetime',                              
                                sizeToContent: true
                            },
                            {
                                heading: 'Last Synchronized',
                                field: rowData => rowData.cust_last_sync_datetime,
                                fieldFormat: 'datetime',                         
                                sizeToContent: true
                            },
                            {
                                heading: 'Status',
                                field: rowData => <StatusChip status={rowData.cust_status} />,  
                                sizeToContent: true
                            },
                            {
                                actions: rowData => {
                                    return [
                                        {name: 'View', icon: 'search', link: '/connectwise/companies/view/' + rowData.cust_id, disabled: !access.viewCustomer}
                                    ]
                                }
                            }
                        ]}
                        rows={this.state.searchResults}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
  }
}

export default ConnectWiseSearch;