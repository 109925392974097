import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import _ from 'lodash';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ActionBadge from 'Components/Common/ActionBadge/ActionBadge';
import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import LoadingScreen from 'Components/Common/LoadingScreen/LoadingScreen';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import Tile from 'Components/Common/Dashboard/Tile';
import { formatValidationErrors } from '../../../../Helpers/ErrorHelper';
import { Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import { COLOR_TEXT_MYCLOUD } from 'ColorSchemes'

const styles = theme => ({
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    warning: {
        backgroundColor: '#FB8C00',
        color: '#FFF'
    }
})

function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

const initialState = {
    access: {
        logs: false
    },
    activeMappings: {},
    additions: {},
    agreements: {},
    agreementsList: {},
    badge: {
        contactActions: 0,
        genericWoMapping: 0
    },
    contacts: {},
    contactData: {},
    contactsList: [],
    contactsUnused: [],
    confirmation: {
        dataSaved: false,
        formData: false,
        peopleSaved: false
    },
    currentTab: 0,
    dataLoading: true,
    dataSave: false,
    errors: {
        genericWoMapping: []
    },
    expanded: {},
    formData: {},
    groups: {
        ou: {},
        generic: {}
    },
    initialFormData: {},
    initialPeopleMapping: {},
    initialPeopleData: {},
    logs: {},
    pendingTab: 0,
    peopleData: {},
    peopleDialog: {
        open: false,
        group: {}
    },
    peopleMapping: {},
    snackbar: {
        dataSaved: false,
        error: false,
        peopleSaved: false,
        toggleContact: false
    },
    userList: {},
    unassignedContacts: []
}

class MyCloudGroupMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.props.pageTitle([1, 'MyCloud', 'Group Mapping']);
        this.loadComponentData();
    }

    loadComponentData = () => {  
        Promise.all([
            API.get('/mycloud/' + this.props.match.params.id + '/groupMapping'),
            API.access('mycloud-logs')
        ])
        .then(([result, accessLogs]) => {
            if(result.data.errors) {
                this.props.history.push('/mycloud/mapping/ou')
            } else {
                /*
                 * Set Page Title
                 */
                this.props.pageTitle([1, 'MyCloud', 'Group Mapping', result.data.ou.adou_name]);

                /* 
                 * Group Mapping 
                 */
                let initialFormData = {}
                let assignedAdditions = []

                _.each(result.data.ou.ad_groups, group => {
                    let people = {};
                    _.each(group.ad_people, person => {
                        people = {
                            ...people,
                            [person.adp_id]: {
                                adpId: parseInt(person.adp_id),
                                adpSync: parseInt(person.pivot.adpg_sync)
                            }
                        }
                    })
                    initialFormData = {
                        ...initialFormData,
                        [group.adg_id]: {
                            adgId: parseInt(group.adg_id),
                            adgPeople: people,
                            cwAdditionId: parseInt(group.group_addition.length > 0 ? group.group_addition[0].cwaa_id : 0),
                            generic: false
                        }
                    }
                });

                _.each(result.data.generic.ad_groups, group => {
                    let people = {};
                    _.each(group.ad_people, person => {
                        people = {
                            ...people,
                            [person.adp_id]: {
                                adpId: parseInt(person.adp_id),
                                adpSync: parseInt(person.pivot.adpg_sync)
                            }
                        }
                    })
                    initialFormData = {
                        ...initialFormData,
                        [group.adg_id]: {
                            adgId: parseInt(group.adg_id),
                            adgPeople: people,
                            cwAdditionId: parseInt(group.group_addition.length > 0 ? group.group_addition[0].cwaa_id : 0),
                            generic: true
                        }
                    }
                });

                _.each(initialFormData, group => {
                    if(group.cwAdditionId !== 0)
                        assignedAdditions.push(group.cwAdditionId);
                });

                let agreementsList = _.map(result.data.ou.customer.agreements, el => {
                    return _.assign({
                        label: el.cwa_parent_id === 0 ? el.cwa_cw_agreement_name : `${_.find(result.data.ou.customer.agreements, parent => { return parent.cwa_id === el.cwa_parent_id }).cwa_cw_agreement_name} ⟶ ${el.cwa_cw_agreement_name}`,
                        options: _.map(el.additions, _el => {
                            return _.assign({
                                value: _el.cwaa_id,
                                label: _el.cwaa_cw_product_identifier,
                                disabled: assignedAdditions.includes(_el.cwaa_id) ? true : false
                            });
                        })
                    });
                });

                let allAdditions = _.map(result.data.ou.customer.agreements, el => {
                    return _.map(el.additions, _el => {
                        return _el;
                    })    
                });

                let combinedAllAdditions = Array.prototype.concat(...allAdditions);

                let additions = {};

                for (var i = 0, l = combinedAllAdditions.length; i < l; i++) {
                    var obj = combinedAllAdditions[i];
                    additions[obj.cwaa_id] = obj;
                    additions[obj.cwaa_id].cwaa_cw_data = JSON.parse(obj.cwaa_cw_data);
                }

                let activeMappings = _.filter(initialFormData, ({cwAdditionId}) => {return cwAdditionId > 0});


                /* 
                 * Contact Mapping 
                 */
                let contacts = result.data.ou.customer.contacts;
                let assignedContacts = []
                let assignedUsers = []
                let unassignedContacts = []
                let unassignedUsers = []
                let initialPeopleMapping = {}
                _.each(result.data.ou.ad_people, person => {
                    initialPeopleMapping = {
                        ...initialPeopleMapping,
                        [person.adp_id]: {
                            adpId: parseInt(person.adp_id),
                            adpName: person.adp_name,
                            adpEmail: person.adp_email,                
                            ccId: person.adp_cc_id === 0 ? (person.adp_cc_action === 'Create' ? 'create' : 'ignore') : person.adp_cc_id
                        }
                    }         
                    if(person.adp_cc_id !== 0) {
                        assignedContacts.push(person.adp_cc_id)
                        assignedUsers.push(person.adp_id)
                    } else {
                        unassignedUsers.push(person)
                    }
                });
                _.each(result.data.ou.customer.contacts, (contact, idx) => {
                    if(!assignedContacts.includes(contact.cc_id)) {
                        unassignedContacts.push(contact)
                    }    
                });
                
                let contactsList = [
                    {
                        label: 'BlueSync Actions',
                        options: [
                            {
                                value: 'create',
                                label: 'Create ConnectWise Contact'
                            },
                            {
                                value: 'ignore',
                                label: 'Ignore'
                            }
                        ]
                    },
                    {
                        label: 'ConnectWise Contacts',
                        options: _.map(result.data.ou.customer.contacts, el => {
                            return _.assign({
                                value: el.cc_id,
                                label: `${(el.cc_first_name && el.cc_first_name !== null && `${el.cc_first_name} `) || ''}${(el.cc_last_name && el.cc_last_name !== null && `${el.cc_last_name} `) || ''}${(el.cc_email && el.cc_email.length > 0 && `(${el.cc_email})`) || ''}`,
                                disabled: assignedContacts.includes(el.cc_id) ? true : false  
                            });
                        })
                    }
                ]

                let userList = _.map(unassignedUsers, el => {
                    return _.assign({
                        value: el.adp_id,
                        label: `${(el.adp_name && el.adp_name !== null && `${el.adp_name} `) || ''}${(el.adp_email && el.adp_email !== 'false' && `(${el.adp_email})`) || ''}`,
                        disabled: assignedUsers.includes(el.adp_id) ? true : false  
                    });
                })

                let initialPeopleData = []
                _.each(unassignedContacts, contact => {
                    initialPeopleData.push(
                        {
                            ccId: contact.cc_id,
                            action: contact.cc_sync_action
                        }
                    )
                });

                /* 
                 * Mapping Overview > Manage Expansion State 
                 */                
                let initialExpanded = {}
                for (let i=0, j=activeMappings.length; i < j; i++) {
                    initialExpanded = {
                        ...initialExpanded,
                        [i]: false
                    }
                }

                /* 
                 * User Groups > Manage Expansion State 
                 */                
                let userGroups = {}
                for (let i=0, j=result.data.ou.ad_people.length; i < j; i++) {
                    userGroups = {
                        ...userGroups,
                        [i]: false
                    }
                }
                /* 
                 * Set State 
                 */
                this.setState({
                    ...this.state,
                    access: {
                        logs: (accessLogs.data && accessLogs.data.has_access) || false,
                    },
                    activeMappings: activeMappings,
                    additions: additions,
                    agreements: result.data.ou.customer.agreements,
                    agreementsList: agreementsList,
                    badge: {
                        contactActions: result.data.ou.contact_actions_count,
                        genericWoMapping: result.data.ou.g_wo_additions_actions_count
                    },
                    contacts: contacts,
                    contactsList: contactsList,
                    dataLoading: false,
                    errors: {
                        genericWoMapping: result.data.genericWoMapping
                    },
                    expanded: initialExpanded,
                    formData: initialFormData,
                    groups: {
                        ou: result.data.ou,
                        generic: result.data.generic
                    },
                    initialFormData,
                    initialPeopleData,
                    initialPeopleMapping,
                    peopleData: initialPeopleData,
                    peopleMapping: initialPeopleMapping,
                    unassignedContacts: unassignedContacts,
                    userGroups,
                    userList: userList,
                }, 
                () => {
                    
                    /*
                     * Get MyCloud Logs (subject to access)
                     */
                    if(this.state.access.logs) {
                        API.get('/mycloud/' + this.props.match.params.id + '/logs')
                        .then(result => {
                            if(result.data) {
                                this.setState({
                                    logs: result.data
                                })
                            }
                        })
                    }
                });
            }
        })
    }

    handleAdditionSelection = adgId => selectedAddition => {
        const cwAdditionId = selectedAddition === null ? 0 : selectedAddition.value
        this.setState({
            formData: {
                ...this.state.formData,
                [adgId]: {
                    ...this.state.formData[adgId],
                    cwAdditionId: parseInt(cwAdditionId),
                }
            }
        },
        () => {      

            let assignedAdditions = []

            _.each(this.state.formData, group => {
                if(group.cwAdditionId !== 0)
                    assignedAdditions.push(group.cwAdditionId);
            });

            let agreementsList = _.map(this.state.agreements, el => {
                return _.assign({
                    label: el.cwa_cw_agreement_name,
                    options: _.map(el.additions, _el => {
                        return _.assign({
                            value: _el.cwaa_id,
                            label: _el.cwaa_cw_product_identifier,
                            disabled: assignedAdditions.includes(_el.cwaa_id) ? true : false
                        });
                    })
                });
            });

            this.setState({
                agreementsList: agreementsList
            })    
           
        });
    }

    handleConfirmationOpen = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: true
            },
        });
    }

    handleConfirmationClose = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false
            },
            pendingTab: type === 'formData' ? 0 : this.state.pendingTab
        });
    }

    handleConfirmationSuccess = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false
            },
        }, () => {
            if (type === 'formData') {
                this.handleDiscardFormData();
            } else if (type === 'peopleSaved') {
                this.handlePeopleMapping();
            } else {
                this.handleSubmit();
            }
        })
    }
    
    handleContactSelection = (idType, id) => selectedOption => {

        const adpId = idType === 'adpId' ? id : (selectedOption === null ? 'None' : selectedOption.value)
        const ccId = idType === 'ccId' ? id : (selectedOption === null ? 'ignore' : selectedOption.value)

        const currCcId = adpId === 'None' ? false : (adpId === 'Deactivate' ? false : (adpId === 'Activate' ? false : this.state.peopleMapping[adpId].ccId));

        if(currCcId) {
            this.setState({
                peopleMapping: {
                    ...this.state.peopleMapping,
                    [adpId]: {
                        ...this.state.peopleMapping[adpId],
                        ccId: ccId,
                    }
                }
            },
            () => {  

                let peopleData = [...this.state.peopleData]

                /* Remove old contact value from peopleData (if required) */
                if(ccId !== 'ignore' && ccId !== 'create') {
                    let idx = _.findIndex(peopleData, function (el) { return el.ccId === ccId })
                    if(idx !== -1) {
                        peopleData.splice(idx, 1)
                    }
                }

                let assignedContacts = []
                let assignedUsers = []
                let unassignedContacts = []
                let unassignedUsers = []

                _.each(this.state.peopleMapping, person => { 
                    if(person.ccId !== 'ignore' && person.ccId !== 'create') {
                        assignedContacts.push(person.ccId)
                        assignedUsers.push(person.ccId)
                    } else {
                        unassignedUsers.push(person)
                    }
                });
                
                _.each(this.state.contacts, contact => {
                    if(!assignedContacts.includes(contact.cc_id)) {
                        unassignedContacts.push(contact)
                    }    
                });
                
                /* Add old contact value to peopleData (if required) */
                if(currCcId !== 'ignore' && currCcId !== 'create') {
                    let idx = _.findIndex(unassignedContacts, function (el) { return el.cc_id === currCcId })
                    if(idx !== -1) {          
                        const contact = unassignedContacts[idx]          
                        peopleData.push(
                            {
                                ccId: contact.cc_id,
                                action: contact.cc_sync_action,
                            }
                        )
                    }
                }
                
                let contactsList = [
                    {
                        label: 'BlueSync Actions',
                        options: [
                            {
                                value: 'create',
                                label: 'Create ConnectWise Contact'
                            },
                            {
                                value: 'ignore',
                                label: 'Ignore'
                            }
                        ]
                    },
                    {
                        label: 'ConnectWise Contacts',
                        options: _.map(this.state.contacts, el => {
                            return _.assign({
                                value: el.cc_id,
                                label: `${(el.cc_first_name && el.cc_first_name !== null && `${el.cc_first_name} `) || ''}${(el.cc_last_name && el.cc_last_name !== null && `${el.cc_last_name} `) || ''}${(el.cc_email && el.cc_email.length > 0 && `(${el.cc_email})`) || ''}`,
                                disabled: assignedContacts.includes(el.cc_id) ? true : false  
                            });
                        })
                    }
                ]
                
                let userList = _.map(unassignedUsers, el => {
                    return _.assign({
                        value: el.adpId,
                        label: `${(el.adpName && el.adpName !== null && `${el.adpName} `) || ''}${(el.adpEmail && el.adpEmail !== 'false' && `(${el.adpEmail})`) || ''}`,
                        disabled: assignedUsers.includes(el.adpId) ? true : false  
                    });
                })

                this.setState({
                    contactsList: contactsList,
                    unassignedContacts: unassignedContacts,
                    peopleData: peopleData,
                    userList: userList
                })           

            })
        } else {

            let peopleData = [...this.state.peopleData]
            let idx = _.findIndex(peopleData, function (el) { return el.ccId === ccId })
            if(idx !== -1) {
                peopleData[idx].action = adpId
            }
            this.setState({
                peopleData: peopleData,
            })
        }
    }

    handleDiscardFormData = () => {
        this.setState({
            formData: this.state.initialFormData
        }, () => this.handleTabChange(false, this.state.pendingTab))
    }

    handleExpandActiveMapping = idx => {
        this.setState({
            expanded: {
                ...this.state.expanded,
                [idx]: !this.state.expanded[idx]
            }
        })
    }    

    handleExpandUserGroup = idx => {
        this.setState({
            userGroups: {
                ...this.state.userGroups,
                [idx]: !this.state.userGroups[idx]
            }
        })
    }

    handlePeopleDialogClose = () => {
        this.setState({
            peopleDialog: {
                ...this.state.peopleDialog,
                open: false
            }
        })
    }

    handlePeopleDialogOpen = (type, adgId) => {
        let group = this.state.groups[type].ad_groups.filter(obj => {
            return obj.adg_id === adgId
        })
        this.setState({
            peopleDialog: {
                open: true,
                group: group[0]
            }
        })
    }

    handlePeopleMapping = () => {
        this.setState({
            initialPeopleMapping: this.state.peopleMapping,
        },
        () => {
            API.put('/mycloud/' + this.props.match.params.id + '/contactMapping', 
                { 
                    data: this.state.peopleMapping,
                    peopleData: this.state.peopleData,
                    ou: this.state.groups.ou.adou_id 
                }
            )
            .then(result => {
                if(result.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors)
                    });
                } else {
                    this.setState({
                        dataLoading: true,
                        snackbar: {
                            peopleSaved: true
                        }
                    }, () => {
                        this.props.scrollToTop();
                        this.loadComponentData()
                    });
                }
            })
        })
    }

    handleReset = () => {
       this.setState({
           dataLoading: true
       }, () => this.loadComponentData());
    }

    handleSnackbarClose = type => {
        this.setState({ 
            snackbar: {
                ...this.state.snackbar,
                [type]: false
            }
        })
    }

    handleSubmit = () => {
        this.setState({
            dataSave: true,
            initialFormData: this.state.formData,
        },
        () => {
            API.put('/mycloud/' + this.props.match.params.id + '/groupMapping', 
                { 
                    data: this.state.formData,
                    ou: this.state.groups.ou.adou_id 
                }
            )
            .then(result => {
                if(result.data.errors) {
                    this.setState({
                        dataSave: false,
                        formErrors: formatValidationErrors(result.data.errors)
                    });
                } else {
                    this.setState({
                        dataLoading: true,
                        dataSave: false,
                        snackbar: {
                            dataSaved: true
                        }
                    }, () => {
                        this.props.scrollToTop();
                        this.loadComponentData()
                    });
                }
            })
        })
    }

    handleSyncToggle = (adgId, adpId) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [adgId]: {
                    ...this.state.formData[adgId],
                    adgPeople: {
                        ...this.state.formData[adgId].adgPeople,
                        [adpId]: {
                            ...this.state.formData[adgId].adgPeople[adpId],
                            adpSync: this.state.formData[adgId].adgPeople[adpId].adpSync === 1 ? 0 : 1
                        }
                    }
                }
            }
        });
    }
    
    handleTabChange = (event, tabIndex) => {
        if(this.state.pendingTab === 0) {
            if(this.state.currentTab === 1 || this.state.currentTab === 2) {
                if(tabIndex !== 1 && tabIndex !== 2) {
                    if(this.state.initialFormData !== this.state.formData) {
                        this.setState({
                            confirmation: {
                                ...this.state.confirmation,
                                formData: true
                            },
                            pendingTab: tabIndex
                        })
                        return;                        
                    }
                }
            }
        }
        this.setState({
            currentTab: tabIndex,
            pendingTab: 0
        })
    }    
    
    importLogs = () => {
        const { logs } = this.state;
        return (
            <Grid item xs={12}>
                <DataTable  
                    config={{
                        key: 'mclog_id',
                        pagination: true,
                        rowsPerPage: 7,
                        noHeader: true,
                        noRowHover: true,
                        noResultsText: 'No MyCloud Logs Exist',
                        title: 'MyCloud Import Log',
                    }}
                    columns={[
                        {
                            field: rowData => (
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item />
                                    <Grid item align='center'>                                        
                                        <FAIcon 
                                            type="thin"
                                            size={30}
                                            icon={rowData.mclog_type === 'Success' ? 'check-circle' : (rowData.mclog_type === 'Error' ? 'times-circle' : (rowData.mclog_type === 'Warning' ? 'exclamation-triangle' : (rowData.mclog_type === 'Notice' ? 'info-circle' : 'flag-alt')))}
                                            style={{
                                                color: rowData.mclog_type === 'Success' ? '#388E3C' : (rowData.mclog_type === 'Error' ? '#d9534f' : (rowData.mclog_type === 'Warning' ? '#EF6C00' : (rowData.mclog_type === 'Notice' ? '#6A1B9A' : '#0288D1')))
                                            }}  
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2" className="fw-400" component="div">                                                       
                                            <span style={{
                                                color: rowData.mclog_type === 'Success' ? '#388E3C' : (rowData.mclog_type === 'Error' ? '#d9534f' : (rowData.mclog_type === 'Warning' ? '#EF6C00' : (rowData.mclog_type === 'Notice' ? '#6A1B9A' : '#0288D1')))
                                            }}>
                                                {rowData.mclog_title.split(":")[0]}{rowData.mclog_title.split(":").length > 1 && ':'}
                                            </span> 
                                            {rowData.mclog_title.split(":")[1]}
                                        </Typography>      
                                        {!_.isEmpty(rowData.mclog_text) && (
                                            <Typography variant="caption" component="div">
                                                {rowData.mclog_text}
                                            </Typography>
                                        )}
                                        <Typography variant="caption" color="textSecondary">
                                            {moment(rowData.mclog_created_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ),
                        },             
                    ]}
                    rows={logs}
                />
            </Grid>
        )
    }

    mappingOverview = () => {
        const { classes } = this.props;
        const { activeMappings, additions, groups, expanded } = this.state;
        let overallTotalCost = [];
        let overallTotalPrice = [];
        return (
            <Grid container spacing={3} direction='column-reverse'>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                            <PaddedPaper>     
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item xs>
                                        <Typography variant={'h6'}>
                                            Users
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {(_.size(this.state.groups.ou.ad_people) > 0 && (                                                                                                        
                                            _.map(this.state.groups.ou.ad_people, (person, idx) => (              
                                                <Grid item xs={12} key={idx}>  
                                                    <Typography variant="caption">  
                                                        > {person.adp_name}
                                                    </Typography>
                                                </Grid>    
                                            ))
                                        )) || (
                                            <Grid item xs={12}>  
                                                <Typography variant="caption" paragraph>
                                                    No users imported
                                                </Typography>
                                            </Grid>   
                                        )}  
                                    </Grid>
                                </Grid>                                     
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <PaddedPaper>     
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item xs>
                                        <Typography variant={'h6'}>
                                            Groups
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {(_.size(this.state.groups.ou.ad_groups) > 0 && (                                                                                                        
                                            _.map(this.state.groups.ou.ad_groups, (group, idx) => (              
                                                <Grid item xs={12} key={idx}>  
                                                    <Typography variant="caption">  
                                                        > {group.adg_name}
                                                    </Typography>
                                                </Grid>    
                                            ))
                                        )) || (
                                            <Grid item xs={12}>  
                                                <Typography variant="caption" paragraph>
                                                    No groups imported
                                                </Typography>
                                            </Grid>   
                                        )}  
                                    </Grid>
                                </Grid>                                     
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={6}> 
                            <Grid container spacing={3}>
                                <Grid item xs={12}>                                
                                    <Grid container spacing={2} alignItems='center'>
                                        <Grid item xs={12}>        
                                            <Grid container spacing={3}>                         
                                                {(_.size(activeMappings) > 0 && (
                                                    _.map(activeMappings, (mapping, idx) => {
                                                        let group = _.filter(groups[mapping.generic ? 'generic' : 'ou'].ad_groups, ({adg_id}) => {return adg_id === mapping.adgId})[0]
                                                        let addition = additions[mapping.cwAdditionId];
                                                        let peopleActive = _.orderBy(_.map(_.filter(mapping.adgPeople, ({adpSync}) => {return adpSync === 1}), person => {
                                                            return _.filter(group.ad_people, ({adp_id}) => {return adp_id === person.adpId})[0];
                                                        }), 'adp_name', 'asc');
                                                        let peopleInactive = _.orderBy(_.map(_.filter(mapping.adgPeople, ({adpSync}) => {return adpSync === 0}), person => {
                                                            return _.filter(group.ad_people, ({adp_id}) => {return adp_id === person.adpId})[0];
                                                        }), 'adp_name', 'asc');
                                                        let totalCost = parseFloat((addition.cwaa_cw_data.unitCost * peopleActive.length));
                                                        if(isNaN(totalCost))
                                                            totalCost = 0                                                            
                                                        let totalPrice = parseFloat((addition.cwaa_cw_data.unitPrice * peopleActive.length));
                                                        if(isNaN(totalPrice))
                                                            totalPrice = 0
                                                        overallTotalCost.push(parseFloat(totalCost.toFixed(2)))
                                                        overallTotalPrice.push(parseFloat(totalPrice.toFixed(2)))
                                                        return (
                                                            <Grid item xs={12} key={idx}> 
                                                                <Paper>
                                                                    <CardHeader
                                                                        subheader={
                                                                            <Typography variant={'caption'} gutterBottom>
                                                                                {addition.cwaa_cw_product_identifier} | Quantity: {peopleActive.length} | Unit Cost: £{(addition.cwaa_cw_data.unitCost && addition.cwaa_cw_data.unitCost.toFixed(2)) || 0} | Unit Price: £{(addition.cwaa_cw_data.unitPrice && addition.cwaa_cw_data.unitPrice.toFixed(2)) || 0} | Total Price: £{(totalPrice && totalPrice.toFixed(2)) || 0}
                                                                            </Typography>
                                                                        }
                                                                        title={
                                                                            <Typography variant={'h6'}>
                                                                                {group.adg_name}
                                                                            </Typography>
                                                                        }
                                                                        action={
                                                                            <IconButton
                                                                                className={clsx(classes.expand, {
                                                                                    [classes.expandOpen]: expanded[idx],
                                                                                })}
                                                                                onClick={() => this.handleExpandActiveMapping(idx)}
                                                                            >
                                                                                <FAIcon icon="chevron-down" button noMargin/>
                                                                            </IconButton>  
                                                                        }
                                                                        disableTypography
                                                                    />  
                                                                    <Collapse in={expanded[idx]} timeout="auto" unmountOnExit>
                                                                        <CardContent style={{paddingTop: 0}}>
                                                                            {((peopleActive.length + peopleInactive.length) > 0 && (      
                                                                                <React.Fragment>                                    
                                                                                    <Table size="small" padding="none">
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                <TableCell>
                                                                                                    <Typography variant="body2" className="fw-400" color="primary" gutterBottom>
                                                                                                        Active Members
                                                                                                    </Typography>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {_.map(peopleActive, (person, idx) => {
                                                                                                return (
                                                                                                    <TableRow key={idx}>
                                                                                                        <TableCell>
                                                                                                            <Typography variant="caption">
                                                                                                                <Box pt={1} pb={1}>
                                                                                                                    > {person.adp_name}
                                                                                                                </Box>
                                                                                                            </Typography>
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                )
                                                                                            })}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                    {peopleInactive.length > 0 && (
                                                                                        <React.Fragment>
                                                                                            <br />
                                                                                            <Table size="small" padding="none">
                                                                                                <TableHead>
                                                                                                    <TableRow>
                                                                                                        <TableCell>
                                                                                                            <Typography variant="body2" className="fw-400" color="primary" gutterBottom>
                                                                                                                Inactive Members
                                                                                                            </Typography>
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                </TableHead>
                                                                                                <TableBody>
                                                                                                    {_.map(peopleInactive, (person, idx) => {
                                                                                                        return (
                                                                                                            <TableRow key={idx}>
                                                                                                                <TableCell>
                                                                                                                    <Typography variant="caption">
                                                                                                                        <Box pt={1} pb={1}>
                                                                                                                            > {person.adp_name}
                                                                                                                        </Box>
                                                                                                                    </Typography>
                                                                                                                </TableCell>
                                                                                                            </TableRow>
                                                                                                        )
                                                                                                    })}
                                                                                                </TableBody>
                                                                                            </Table>
                                                                                        </React.Fragment>
                                                                                    )}      
                                                                                </React.Fragment>
                                                                            )) || (
                                                                                <Typography variant="caption">
                                                                                    <IconButton disabled>
                                                                                        <FAIcon icon="exclamation-circle" />
                                                                                    </IconButton>
                                                                                    This group contains no members
                                                                                </Typography>
                                                                            )}
                                                                        </CardContent>   
                                                                    </Collapse>                       
                                                                </Paper>
                                                            </Grid>
                                                        )
                                                    })
                                                )) || (
                                                    <Grid item xs={12}> 
                                                        <PaddedPaper>
                                                            <Typography variant={'body2'} gutterBottom>
                                                                <FAIcon icon="times-circle" size="small" color="error"/>
                                                                No groups are mapped within the {this.state.groups.ou.adou_name} OU
                                                            </Typography>
                                                        </PaddedPaper>
                                                    </Grid>
                                                )}
                                            </Grid>       
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {(_.size(activeMappings) > 0 && (
                    <Grid item xs={12} style={{maxHeight: 135, paddingBottom: 0}}>
                        <Grid container spacing={3}>
                            <Tile grid={3} icon="coins" backgroundColor={COLOR_TEXT_MYCLOUD} data={`£${overallTotalCost.reduce((a, b) => (a + b), 0).toFixed(2)}`} title="Service Cost" />                                             
                            <Tile grid={3} icon="file-invoice-dollar" backgroundColor={COLOR_TEXT_MYCLOUD} data={`£${overallTotalPrice.reduce((a, b) => (a + b), 0).toFixed(2)}`} title="Service Turnover" />                                             
                            <Tile grid={3} icon="sack-dollar" backgroundColor={COLOR_TEXT_MYCLOUD} data={`£${(overallTotalPrice.reduce((a, b) => (a + b), 0).toFixed(2) - overallTotalCost.reduce((a, b) => (a + b), 0).toFixed(2)).toFixed(2)}`} title="Service Profit" />                                             
                            <Tile grid={3} icon="chart-line" backgroundColor={COLOR_TEXT_MYCLOUD} data={`${isNaN((((overallTotalPrice.reduce((a, b) => (a + b), 0).toFixed(2) - overallTotalCost.reduce((a, b) => (a + b), 0).toFixed(2)) / overallTotalPrice.reduce((a, b) => (a + b), 0).toFixed(2)) * 100).toFixed(2)) ? 0 : (((overallTotalPrice.reduce((a, b) => (a + b), 0).toFixed(2) - overallTotalCost.reduce((a, b) => (a + b), 0).toFixed(2)) / overallTotalPrice.reduce((a, b) => (a + b), 0).toFixed(2)) * 100).toFixed(2)}%`} title="Service Margin" />                                             
                        </Grid> 
                    </Grid>
                ))}
            </Grid>
        )
    }

    mappingTable = (type) => {
        const { dataLoading, agreementsList, formData, groups } = this.state;
        return (
            <DataTable  
                config={{
                    key: 'adg_id',
                    pagination: true,
                    alternatingRowColours: true,
                    isLoading: dataLoading,
                    rowsPerPage: 100,
                    plainHeader: true,
                    showTotals: true,
                    title: type === 'generic' ? 'MyCloud Generic Group Mapping' : 'MyCloud OU Group Mapping',
                    actions: 
                        <React.Fragment>
                            <Button 
                                variant="text"
                                color="primary"
                                size="small"
                                onClick={() => this.handleReset()} 
                                style={{marginLeft: 6}} 
                                disabled={this.state.dataLoading ? true : (this.state.dataSave ? true : (this.state.initialFormData === this.state.formData ? true : false))}
                            >
                                <FAIcon icon="trash" style={{color: this.state.dataLoading ? '#ddd' : (this.state.dataSave ? '#ddd' : (this.state.initialFormData === this.state.formData ? '#ddd' : '#375885')), height: '1em'}} heading button/>
                                Discard
                            </Button>
                            <Button 
                                variant="text"
                                color="primary"
                                size="small"
                                onClick={() => this.handleConfirmationOpen('dataSaved')} 
                                style={{marginLeft: 6}} 
                                disabled={this.state.dataLoading ? true : (this.state.dataSave ? true : (this.state.initialFormData === this.state.formData ? true : false))}
                            >
                                <FAIcon icon="save" style={{color: this.state.dataLoading ? '#ddd' : (this.state.dataSave ? '#ddd' : (this.state.initialFormData === this.state.formData ? '#ddd' : '#375885')), height: '1em'}} swapOpacity button/>
                                Save
                            </Button>
                        </React.Fragment>
                }}
                columns={[
                    {
                        heading: 'Group Name',
                        field: rowData => rowData.adg_name,
                        showTotalLabel: true
                    },
                    {
                        heading: 'CW Addition',
                        field: rowData => (
                            <Box maxWidth={350} minWidth={350}>
                                <FormControl fullWidth>
                                    <AutoCompleteSelect 
                                        options={type === 'generic' && (formData[rowData.adg_id].cwAdditionId === 0 || formData[rowData.adg_id].cwAdditionId === -1) && rowData.ad_people.length > 0 ? [{label:"BlueSync Actions", options: [{label:"None (Ignore Error)", value: -1}]},...agreementsList] : agreementsList}
                                        isGrouped={true}
                                        placeholder='Not Mapped'
                                        onChange={this.handleAdditionSelection(rowData.adg_id)}
                                        error={type === 'generic' && formData[rowData.adg_id].cwAdditionId === 0 && rowData.ad_people.length > 0 ? true : false}
                                        value={formData[rowData.adg_id].cwAdditionId}
                                        variant="filled"
                                    />
                                </FormControl>
                            </Box>
                        ),
                        sizeToContent: true
                    },
                    {
                        heading: 'Members',
                        field: rowData => (
                            <React.Fragment>
                                <span className="fw-400">{rowData.ad_people.length}</span> <em style={{fontSize: '12px'}}>({(Object.keys(formData[rowData.adg_id].adgPeople).length > 0 && _.filter(formData[rowData.adg_id].adgPeople, ({adpSync}) => {return adpSync === 1}).length) || 0} enabled, {(Object.keys(formData[rowData.adg_id].adgPeople).length > 0 && _.filter(formData[rowData.adg_id].adgPeople, ({adpSync}) => {return adpSync === 0}).length) || 0} disabled)</em>
                            </React.Fragment>
                        ),
                        sizeToContent: true,
                    },
                    {
                        field: rowData => (
                            <IconButton onClick={() => { this.handlePeopleDialogOpen(type, rowData.adg_id) }}>
                                <FAIcon icon='users-cog' button noMargin />
                            </IconButton>
                        ),
                        sizeToContent: true,
                    },
                    {
                        heading: 'Unit Price',
                        field: rowData => formData[rowData.adg_id].cwAdditionId === 0 || formData[rowData.adg_id].cwAdditionId === -1 ? '-' : this.state.additions[formData[rowData.adg_id].cwAdditionId].cwaa_cw_data.unitPrice,
                        fieldFormat: 'currency',
                        sizeToContent: true,
                    },
                    {
                        heading: 'Total Price',
                        field: rowData => formData[rowData.adg_id].cwAdditionId === 0 || formData[rowData.adg_id].cwAdditionId === -1 ? '-' : (this.state.additions[formData[rowData.adg_id].cwAdditionId].cwaa_cw_data.unitPrice * ((Object.keys(formData[rowData.adg_id].adgPeople).length > 0 && _.filter(formData[rowData.adg_id].adgPeople, ({adpSync}) => {return adpSync === 1}).length) || 0)),
                        totalField: rowData => formData[rowData.adg_id].cwAdditionId === 0 || formData[rowData.adg_id].cwAdditionId === -1 ? 0 : (this.state.additions[formData[rowData.adg_id].cwAdditionId].cwaa_cw_data.unitPrice * ((Object.keys(formData[rowData.adg_id].adgPeople).length > 0 && _.filter(formData[rowData.adg_id].adgPeople, ({adpSync}) => {return adpSync === 1}).length) || 0)),
                        fieldFormat: 'currency',
                        sizeToContent: true,
                        showTotal: true
                    },
                ]}
                rows={groups[type].ad_groups}
            />
        )
    }

    handleUnassignedOptions(contact) {
        const bsOptions = [
            {
                value: 'None',
                label: 'None'
            }
        ];
        if(contact.cc_active === 'Y') {
            bsOptions.push(
                {
                    value: 'Deactivate',
                    label: 'Deactivate'
                }
            )
        } else {
            bsOptions.push(
                {
                    value: 'Activate',
                    label: 'Activate'
                }
            )
        }
        return [
            {
                label: 'BlueSync Actions',
                options: bsOptions
            },
            {
                label: 'MyCloud Users',
                options: this.state.userList
            }
        ]
    }

    peopleTable = () => {
        const { contacts, contactsList, dataLoading, groups, peopleData, peopleMapping, unassignedContacts } = this.state;
        let countAdActivate = 0;
        _.each(peopleMapping, map => {
            if(map.ccId !== 'ignore' && map.ccId !== 'create') {
                let idx = _.findIndex(contacts, function (el) { return el.cc_id === map.ccId });
                if(contacts[idx] && contacts[idx].cc_active === 'N') {
                    countAdActivate++;
                }
            }
        })
        let countCreate = _.size(_.filter(this.state.peopleMapping, function(el) { return el.ccId === 'create'}))
        let countActivate = (_.size(_.filter(this.state.peopleData, function(el) { return el.action === 'Activate'})) + countAdActivate)
        let countDeactivate = _.size(_.filter(this.state.peopleData, function(el) { return el.action === 'Deactivate'}))
        let btnDisabled = this.state.dataLoading ? true : (this.state.dataSave ? true : (this.state.initialPeopleMapping === this.state.peopleMapping && this.state.initialPeopleData === this.state.peopleData ? true : false))
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} align='right'>
                        <Grid container>
                            <Grid item xs align="right">
                                <Button 
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    onClick={() => this.handleReset()} 
                                    style={{marginLeft: 6}} 
                                    disabled={btnDisabled}
                                >
                                    <FAIcon icon="trash" style={{color: btnDisabled ? '#ddd' : '#375885', height: '1em'}} heading button/>
                                    Discard
                                </Button>
                                <Button 
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    onClick={() => this.handleConfirmationOpen('peopleSaved')} 
                                    style={{marginLeft: 6}} 
                                    disabled={btnDisabled}
                                >
                                    <FAIcon icon="save" style={{color: btnDisabled ? '#ddd' : '#375885', height: '1em'}} swapOpacity button/>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>                        
                    <Grid item xs={12} lg={6}>
                        <Paper>                       
                            <DataTable  
                                config={{
                                    key: 'adp_id',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    isLoading: dataLoading,
                                    rowsPerPage: 100,
                                    plainHeader: true,
                                    title: 'Active Directory Users',
                                }}
                                columns={[
                                    {
                                        field: rowData => {
                                            let contactIdx = _.findIndex(contacts, function (el) { return el.cc_id === peopleMapping[rowData.adp_id].ccId });
                                            return (
                                                (peopleMapping[rowData.adp_id] 
                                                    && (
                                                        (peopleMapping[rowData.adp_id].ccId === 'create'
                                                            && <FAIcon icon='exclamation-triangle' className='textWarning' style={{margin: '0 0 0 10px'}} />)
                                                        || 
                                                        (peopleMapping[rowData.adp_id].ccId === 'ignore' 
                                                            && <React.Fragment />)
                                                        ||
                                                        (contacts[contactIdx] && contacts[contactIdx].cc_active === 'N'
                                                            && <FAIcon icon='exclamation-triangle' className='textWarning' style={{margin: '0 0 0 10px'}} />)
                                                    )) || <React.Fragment />
                                        )},             
                                        alignment: 'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'MyCloud Name',
                                        field: rowData => (
                                            <React.Fragment>
                                                <Typography variant="body2" className="fw-400">
                                                    {`${(rowData.adp_name && rowData.adp_name !== 'false' && `${rowData.adp_name}`) || '-'}`}
                                                </Typography>
                                                <Typography variant="caption">
                                                    {`${(rowData.adp_email && rowData.adp_email !== 'false' && `${rowData.adp_email}`) || '-'}`}
                                                </Typography>
                                            </React.Fragment>
                                        ),
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'ConnectWise Contact',
                                        field: rowData => {
                                            let contactIdx = _.findIndex(contacts, function (el) { return el.cc_id === peopleMapping[rowData.adp_id].ccId });
                                            return (
                                                <FormControl fullWidth>
                                                    <AutoCompleteSelect 
                                                        options={contactsList}
                                                        isGrouped={true}
                                                        placeholder={
                                                            (peopleMapping[rowData.adp_id] 
                                                                && (
                                                                    (peopleMapping[rowData.adp_id].ccId === 'create'
                                                                        && 'Pending Action (Contact Creation):')
                                                                    || 
                                                                    (peopleMapping[rowData.adp_id].ccId === 'ignore' 
                                                                        && 'Mapped Contact:')
                                                                    ||
                                                                    (contacts[contactIdx] && contacts[contactIdx].cc_active === 'N'
                                                                        && 'Pending Action (Contact Activation):')
                                                                )) || 'Mapped Contact:'
                                                        }
                                                        warning={
                                                            (peopleMapping[rowData.adp_id] 
                                                                && (
                                                                    (peopleMapping[rowData.adp_id].ccId === 'create'
                                                                        && true)
                                                                    || 
                                                                    (peopleMapping[rowData.adp_id].ccId === 'ignore' 
                                                                        && false)
                                                                    ||
                                                                    (contacts[contactIdx] && contacts[contactIdx].cc_active === 'N'
                                                                        && true)
                                                                )) || false
                                                        }
                                                        onChange={this.handleContactSelection("adpId", rowData.adp_id)}
                                                        value={peopleMapping[rowData.adp_id].ccId}
                                                        noClear
                                                        variant="filled"
                                                    />
                                                </FormControl>
                                            )
                                        }
                                    }                    
                                ]}
                                rows={groups.ou.ad_people}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper>                       
                                    <DataTable  
                                        config={{
                                            key: 'cc_id',
                                            pagination: true,
                                            alternatingRowColours: true,
                                            isLoading: dataLoading,
                                            rowsPerPage: 100,
                                            plainHeader: true,
                                            title: 'Unassigned ConnectWise Contacts',
                                            noResultsText: 'All ConnectWise contacts are assigned to a user'
                                        }}
                                        columns={[
                                            {
                                                field: rowData => {
                                                    let idx = _.findIndex(peopleData, function (el) { return el.ccId === rowData.cc_id})
                                                    return (
                                                        (peopleData[idx] 
                                                            && (
                                                                (peopleData[idx].action === 'Activate' 
                                                                    && <FAIcon icon='exclamation-triangle' color='warning' style={{margin: '0 0 0 10px'}} />)
                                                                || 
                                                                (peopleData[idx].action === 'Deactivate' 
                                                                    && <FAIcon icon='exclamation-triangle' color='warning' style={{margin: '0 0 0 10px'}} />)
                                                            )) || <React.Fragment />
                                                    )
                                                },                                        
                                                alignment: 'right',
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Name',
                                                field: rowData => (
                                                    <React.Fragment>
                                                        <Typography variant="body2" className="fw-400">
                                                            {`${(rowData.cc_first_name && rowData.cc_first_name !== null && `${rowData.cc_first_name} `) || ''}${(rowData.cc_last_name && rowData.cc_last_name !== null && `${rowData.cc_last_name} `) || ''}`}
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            {(rowData.cc_email && rowData.cc_email.length > 0 && `${rowData.cc_email}`) || '-'}
                                                        </Typography>
                                                    </React.Fragment>
                                                ),
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Active',
                                                field: rowData => rowData.cc_active,
                                                fieldFormat: 'boolean',
                                                alignment: 'center',
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'MyCloud AD User',
                                                field: rowData => {
                                                    let idx = _.findIndex(this.state.peopleData, function (el) { return el.ccId === rowData.cc_id})
                                                    return (                                            
                                                        <FormControl fullWidth>
                                                            <AutoCompleteSelect 
                                                                options={this.handleUnassignedOptions(rowData)}
                                                                isGrouped={true}
                                                                placeholder={
                                                                    (this.state.peopleData[idx] 
                                                                        && (
                                                                            (this.state.peopleData[idx].action === 'Activate' 
                                                                                && 'Pending Action (Contact Activation): ')
                                                                            || 
                                                                            (this.state.peopleData[idx].action === 'Deactivate' 
                                                                                && 'Pending Action (Contact Deactivation): ')
                                                                        )) || 'Sync Action'
                                                                }
                                                                warning={
                                                                    (this.state.peopleData[idx] 
                                                                        && (
                                                                            (this.state.peopleData[idx].action === 'Activate' 
                                                                                && true)
                                                                            || 
                                                                            (this.state.peopleData[idx].action === 'Deactivate' 
                                                                                && true)
                                                                        )) || false
                                                                }                                    
                                                                onChange={this.handleContactSelection("ccId", rowData.cc_id)}
                                                                value={(this.state.peopleData[idx] && this.state.peopleData[idx].action) || 'None'}
                                                                noClear
                                                                variant="filled"
                                                            />
                                                        </FormControl>
                                                    )
                                                }
                                            }
                                                    
                                        ]}
                                        rows={_.sortBy(unassignedContacts, [function(el) { return el.cc_first_name }])}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12}> 
                                <Paper>
                                    <DataTable
                                        config={{
                                            key: 'id',
                                            plainHeader: true,
                                            title: 'Actions Pending MyCloud Synchronization',
                                        }}
                                        columns={[
                                            {
                                                field: rowData => <React.Fragment />,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Action',
                                                field: rowData => rowData.action,
                                                main: true
                                            },
                                            {
                                                heading: 'Count',
                                                field: rowData => rowData.count,
                                                sizeToContent: true
                                            }
                                        ]}
                                        rows={[
                                            {
                                                id: 1,
                                                action: 'Create ConnectWise Contact',
                                                count: countCreate
                                            },
                                            {
                                                id: 2,
                                                action: 'Activate ConnectWise Contact',
                                                count: countActivate
                                            },
                                            {
                                                id: 3,
                                                action: 'Deactivate ConnectWise Contact',
                                                count: countDeactivate
                                            }
                                        ]}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    peopleDialog = () => {
        const { peopleDialog, formData } = this.state;
        return (
            <Dialog open={peopleDialog.open}
                    onClose={this.handlePeopleDialogClose}
                    maxWidth='md'
                    scroll='body'>
                <DialogTitle>
                    Members of {peopleDialog.group.adg_name}<br />
                    <span style={{fontSize: '12px'}}>{peopleDialog.group.adg_dn}</span>
                </DialogTitle>
                <Typography component={DialogContent} variant="body2">
                    <DataTable  
                        config={{
                            key: 'name',
                            plainHeader: true
                        }}
                        columns={[
                            {
                                heading: 'Name',
                                field: rowData => rowData.adp_name
                            },
                            {
                                heading: 'Sync',
                                field: rowData => (
                                    <IconButton
                                        style={{
                                            padding: 0
                                        }}
                                        disabled={false}
                                        onClick={() => {this.handleSyncToggle(peopleDialog.group.adg_id, rowData.adp_id)}}
                                        color='inherit'
                                    >
                                        <FAIcon
                                            icon={
                                                (formData[peopleDialog.group.adg_id].adgPeople[rowData.adp_id].adpSync === 1 && 'check-circle')
                                                ||                                                        
                                                (formData[peopleDialog.group.adg_id].adgPeople[rowData.adp_id].adpSync === 0 && 'times-circle')
                                                || 
                                                'times-circle'
                                            }
                                            style={{
                                                color: 
                                                    (formData[peopleDialog.group.adg_id].adgPeople[rowData.adp_id].adpSync === 1 && '#388E3C')
                                                    ||                                                        
                                                    (formData[peopleDialog.group.adg_id].adgPeople[rowData.adp_id].adpSync === 0 && '#d9534f')
                                                    || 
                                                    '#BDBDBD'
                                                }}
                                            noMargin
                                            button
                                        />
                                    </IconButton>
                                ),
                                alignment: 'center'
                            },
                        ]}
                        rows={peopleDialog.group.ad_people}
                    />
                </Typography>
            </Dialog>
        )
    }

    userGroups = () => {
        const { classes } = this.props;
        const { userGroups } = this.state;
        return (
            <Grid container spacing={3}>
                {_.map(this.state.groups.ou.ad_people, (person, idx) => (
                    <Grid item xs={12} lg={3} key={idx}> 
                        <Paper>
                            <CardHeader
                                subheader={
                                    <Typography variant={'caption'} gutterBottom>
                                        {person.ad_groups.length} Groups
                                    </Typography>
                                }
                                title={
                                    <Typography variant={'h6'}>
                                        {person.adp_name} 
                                    </Typography>
                                }
                                 action={
                                    <IconButton
                                        className={clsx(classes.expand, {
                                            [classes.expandOpen]: userGroups[idx],
                                        })}
                                        onClick={() => this.handleExpandUserGroup(idx)}
                                    >
                                        <FAIcon icon="chevron-down" button noMargin/>
                                    </IconButton>  
                                }
                                disableTypography
                            />  
                            <Collapse in={userGroups[idx]} timeout="auto" unmountOnExit>
                                <CardContent style={{paddingTop: 0}}>
                                    {(!_.isEmpty(person.ad_groups) && (      
                                            <Table size="small" padding="none">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography variant="body2" className="fw-400" color="primary" gutterBottom>
                                                                Active Directory Groups
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {_.map(person.ad_groups, (group, idx) => {
                                                        return (
                                                            <TableRow key={idx}>
                                                                <TableCell>
                                                                    <Typography variant="caption">
                                                                        <Box pt={1} pb={1}>
                                                                            > {group.adg_name}
                                                                        </Box>
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                    )) || (
                                        <Typography variant="caption">
                                            <IconButton disabled>
                                                <FAIcon icon="exclamation-circle" />
                                            </IconButton>
                                            {person.adp_name} is not associated to any Active Directory Groups
                                        </Typography>
                                    )}
                                </CardContent>   
                            </Collapse>                       
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        )
    }

    render() {
        const { access, currentTab, dataLoading, dataSave, confirmation, snackbar } = this.state;
        return (
            <Grid container spacing={1}>
                {(dataLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>     
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item>
                                            <FAIcon type="thin" icon="server" size={85} />
                                        </Grid>
                                        <Grid item xs>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item xs={12}>
                                                    <Typography variant="h3" className="fw-300">
                                                        {this.state.groups.ou.adou_name} OU
                                                    </Typography>
                                                </Grid>    
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="link-horizontal"
                                                                label={`Mapped: ${this.state.groups.ou.customer.cust_name}`}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="calendar-alt"
                                                                label={`Created: ${this.state.groups.ou?.adou_created_datetime === '0000-00-00 00:00:00' ? 'Unknown' : moment(this.state.groups.ou?.adou_created_datetime).format('MMM D, YYYY')}`}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="check"
                                                                label={`Sync: ${this.state.groups.ou?.adou_sync === 1 ? 'Enabled' : 'Disabled'}`}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="network-wired"
                                                                label={`DN: ${this.state.groups.ou.adou_dn}`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>     
                                            </Grid> 
                                        </Grid>                                     
                                    </Grid>                                     
                                </Grid> 
                                <Grid item xs={12}>                                    
                                    <Paper>     
                                        <AppBar style={{backgroundColor: '#0288D1'}} position="static" elevation={0}>
                                            <Tabs value={currentTab} onChange={this.handleTabChange} variant="scrollable">
                                                <Tab disableRipple value={0} label="OU Overview" />
                                                <Tab disableRipple value={4} label="AD User Group Overview" />
                                                <Tab disableRipple value={1} label="OU Group Mapping" />                                            
                                                <Tab disableRipple value={2} label={<ActionBadge badgeContent={this.state.badge.genericWoMapping} variant="error">Generic Group Mapping</ActionBadge>} />
                                                <Tab disableRipple value={3} label={<ActionBadge badgeContent={this.state.badge.contactActions} variant="warning">Contact Mapping</ActionBadge>} />
                                                {access.logs && (                                                
                                                    <Tab disableRipple value={5} label="Import Log" />
                                                )}
                                            </Tabs>                                     
                                        </AppBar>
                                    </Paper>
                                </Grid>     
                                <Grid item xs={12}>  
                                    <TabPanel value={currentTab} index={0}>
                                        {this.mappingOverview()}
                                    </TabPanel>  
                                    <TabPanel value={currentTab} index={1}>
                                        <Paper>
                                            {this.mappingTable("ou")}
                                        </Paper>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={2}>
                                        <Paper>
                                            {this.mappingTable("generic")} 
                                        </Paper>
                                    </TabPanel>   
                                    <TabPanel value={currentTab} index={3}>
                                        {this.peopleTable()}
                                    </TabPanel>    
                                    <TabPanel value={currentTab} index={4}>
                                        {this.userGroups()}
                                    </TabPanel>  
                                    {access.logs && (
                                        <TabPanel value={currentTab} index={5}>
                                            <Paper>
                                                {this.importLogs()}
                                            </Paper>
                                        </TabPanel>
                                    )}                                                                
                                    {this.state.peopleDialog.open && this.peopleDialog()}
                                    <LoadingScreen 
                                        open={dataSave}
                                        title='Please Wait'
                                        message='BlueSync is configuring your requested MyCloud Group Mapping'
                                    />
                                    <ConfirmationDialog 
                                        open={confirmation.dataSaved} 
                                        success={() => this.handleConfirmationSuccess('dataSaved')} 
                                        close={() => this.handleConfirmationClose('dataSaved')} 
                                        title="Save MyCloud Group Mapping?" 
                                        message="Are you sure you want to save these changes?"
                                    />
                                    <ConfirmationDialog 
                                        open={confirmation.peopleSaved} 
                                        success={() => this.handleConfirmationSuccess('peopleSaved')} 
                                        close={() => this.handleConfirmationClose('peopleSaved')} 
                                        title="Save Contact Mapping?" 
                                        message="Are you sure you want to save these changes?"
                                    />
                                    <ConfirmationDialog
                                        open={confirmation.formData}
                                        success={() => this.handleConfirmationSuccess('formData')}
                                        close={() => this.handleConfirmationClose('formData')}
                                        title="You have not saved your configuration!"
                                        message="Are you sure you want to leave the tab and discard your changes?"
                                    />
                                    <SnackBar
                                        autoHideDuration={2000}
                                        variant="success"
                                        anchorOriginVertical='bottom'
                                        anchorOriginHorizontal='right'
                                        open={snackbar.peopleSaved}
                                        onClose={() => this.handleSnackbarClose('peopleSaved')}
                                        message="MyCloud User Contact Mapping Saved Successfully"
                                    />
                                    <SnackBar
                                        autoHideDuration={2000}
                                        variant="success"
                                        anchorOriginVertical='bottom'
                                        anchorOriginHorizontal='right'
                                        open={snackbar.dataSaved}
                                        onClose={() => this.handleSnackbarClose('dataSaved')}
                                        message="MyCloud Group Mapping Saved Successfully"
                                    />
                                    <SnackBar
                                        autoHideDuration={2000}
                                        variant="success"
                                        anchorOriginVertical='bottom'
                                        anchorOriginHorizontal='right'
                                        open={snackbar.toggleContact}
                                        onClose={() => this.handleSnackbarClose('toggleContact')}
                                        message='ConnectWise Contact Status Toggled'
                                    />
                                    <SnackBar
                                        autoHideDuration={2000}
                                        variant="error"
                                        anchorOriginVertical='bottom'
                                        anchorOriginHorizontal='right'
                                        open={snackbar.error}
                                        onClose={() => this.handleSnackbarClose('error')}
                                        message='Something went wrong, please try again!'
                                    />
                                </Grid>
                            </Grid>             
                        </Grid>   
                    </React.Fragment>       
                )}
            </Grid>
        );
    }
}

export default withStyles(styles)(MyCloudGroupMapping);