import MainStore from 'Redux/MainStore';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

export function formatValidationErrors(errorArray) {
    let validation = [];
    for (let i = 0; i < errorArray.length; i++) {
        if(errorArray[i].field === undefined){
            validation['generic'] = errorArray[i].msg
        }
        else if(Array.isArray(errorArray[i].field)){
            for (let x = 0; x < errorArray[i].field.length; x++) {
                validation[errorArray[i].field[x]] = errorArray[i].msg      
            };
        }
        else {
            validation[errorArray[i].field] = errorArray[i].msg
        }               
    }
    if(validation && validation['generic']) {
        MainStore.dispatch(deploySnackBar("error", validation['generic']))
    }
    return validation;
}

export function formatValidationWarnings(warningArray) {
    let validation = [];
    for (let i = 0; i < warningArray.length; i++) {
        if(warningArray[i].field === undefined){
            validation['generic'] = warningArray[i].msg
        }
        else if(Array.isArray(warningArray[i].field)){
            for (let x = 0; x < warningArray[i].field.length; x++) {
                validation[warningArray[i].field[x]] = warningArray[i].msg      
            };
        }
        else {
            validation[warningArray[i].field] = warningArray[i].msg
        }               
    }
    return validation;
}