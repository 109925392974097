import React from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import StatusChip from 'Components/Common/Chips/StatusChip';

const initialState = {
    companyList: [],
    searchResults: {},
    searchString: '',
    searchStatus: '',
    access: {
        viewService: false
    },
    dataLoading: true
}

class VoipServicesSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'VOIP and Connectivity', 'Services']);
        this.loadComponentData();
    }

    loadComponentData = () => {
        Promise.all([
            API.access('voip-services-view'),
            API.get('/voip/companies')
        ])
        .then(([res, companies]) =>  {
            let companyList = _.map(companies.data, el => {
                return _.assign({
                    label: el.voip_name,
                    value: el.voip_id,
                });
            })
            companyList.push({
                label: "Unassigned",
                value: 0
            })
            this.setState({
                ...this.state,
                access: {
                    viewService: (res.data && res.data.has_access) || false
                },
                companyList
            }, () => this.getSearchData());
        })
    }

    getSearchData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/voip/services', { params: { searchString: this.state.searchString, searchStatus: this.state.searchStatus } })
            .then(result => {
                this.setState({
                    dataLoading: false
                });
                if(result.data) {
                    this.setState({
                        searchResults: result.data
                    });
                }
            });
        });
    }
    
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption ? selectedOption.value : ''
        },
        () => this.getSearchData());
    };

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        }, () => {
            this.getSearchData();
        });
    }

    resetSearch = () => {
        this.setState({
            searchString: ''
        }, () => {
            this.getSearchData();
        });
    }

    render() {
        const { access, dataLoading } = this.state;
        return (
            <React.Fragment>
                <Grid container spacing={1}>      
                    <Grid container item xs={12} spacing={3} alignItems='center'>  
                        <Grid item xs={3} style={{marginBottom: 12}}>
                            <AutoCompleteSelect 
                                options={this.state.companyList}
                                placeholder='VOIP Company: '
                                onChange={this.handleSelectChange('searchString')}
                                value={this.state.searchString}
                                variant="filled"
                                adornment='filter'
                            />
                        </Grid>  
                        <Grid item xs={3} style={{marginBottom: 12}}>
                            <AutoCompleteSelect 
                                options={[
                                    {
                                        label: "Active",
                                        value: "active"
                                    },
                                    {
                                        label: "Ignored",
                                        value: "ignored"
                                    },
                                    {
                                        label: "Terminated",
                                        value: "terminated"
                                    }
                                ]}
                                placeholder='Status: '
                                onChange={this.handleSelectChange('searchStatus')}
                                value={this.state.searchStatus}
                                variant="filled"
                                adornment='filter'
                            />
                        </Grid>  
                    </Grid>   
                    <Grid item xs={12}>
                        <Paper>
                            <DataTable  
                                config={{
                                    key: 'voip_service_id',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    isLoading: dataLoading,
                                    responsiveImportance: true,
                                    plainHeader: true, 
                                    title: 'VOIP and Connectivity Services',
                                }}
                                columns={[
                                    {
                                        heading: 'Service Identifier',
                                        field: rowData => rowData.voip_service_identifier,
                                        sizeToContent: true,   
                                        important: true,
                                    },
                                    {
                                        heading: 'Subscription',
                                        field: rowData => rowData.voip_service_name,  
                                        sizeToContent: true,   
                                    },
                                    {
                                        heading: 'VOIP Company',
                                        field: rowData => (rowData.company && rowData.company.voip_name) || 'Unassigned',  
                                        sizeToContent: true,   
                                        cellProps: rowData => {
                                            return ({
                                                style: {
                                                    color: 
                                                        (rowData.company && rowData.company.voip_name ? '#4CAF50' : 'rgb(106, 27, 154)')
                                                }
                                            });
                                        },
                                    },
                                    {
                                        heading: 'Total Service Charges',
                                        field: rowData => rowData.service_charges_count, 
                                        alignment: 'right',
                                        sizeToContent: true,    
                                    },
                                    {
                                        heading: 'Total Call Charges',
                                        field: rowData => rowData.call_charges_count, 
                                        alignment: 'right',
                                        sizeToContent: true,    
                                    },
                                    {
                                        heading: 'Status',
                                        field: rowData => <StatusChip status={rowData.voip_service_status} />,
                                        sizeToContent: true
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: "View", icon: 'search', link: '/voip/services/' + rowData.voip_service_id, disabled: !access.viewService}
                                            ]
                                        }
                                    }
                                ]}
                                rows={this.state.searchResults}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
    }

export default VoipServicesSearch;