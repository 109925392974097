/*
 * Notifications
 */
export const HANDLE_NOTIFICATIONS = 'HANDLE_NOTIFICATIONS';
export const DISCARD_NOTIFICATION = 'DISCARD_NOTIFICATION';
export const DISCARD_ALL_NOTIFICATIONS = 'DISCARD_ALL_NOTIFICATIONS';
export const DISCARD_ACTION = 'DISCARD_ACTION';
export const LOAD_STAFF_CONFIG = 'LOAD_STAFF_CONFIG';
export const LOAD_STAFF_STATUS = 'LOAD_STAFF_STATUS';
export const LOAD_TEAMS = 'LOAD_TEAMS';
export const I_AM_ACTIVE = 'I_AM_ACTIVE';
export const MINUS_UNREAD_MESSAGE = 'MINUS_UNREAD_MESSAGE';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';