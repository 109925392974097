import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';

const initialState = {
    initialFormData: {},
    formData: {
        firstName: '',
        lastName: '',
        email: '',
        status: '',
    },
    formErrors: {},
    confirmationOpen: false,
    isLoading: true,
}

class UpdateStaffDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.loadCompontentData();
    }

    loadCompontentData = () => {
        let initialDataFieldMapping = {
            'staff_first_name': 'firstName',
            'staff_last_name': 'lastName',
            'staff_email': 'email',
            'staff_status': 'status'
        }
        Promise.all([
            API.get('/staff/' + this.props.staffId)
        ])
        .then(([result]) => {                 
            if(result.data) {
                let initialFormData = {};// eslint-disable-next-line
                for (const [key, value] of Object.entries(initialDataFieldMapping)) {
                    if(result.data[key] || result.data[key] === 0) {
                        initialFormData[value] = result.data[key];
                    }
                }
                this.setState({
                    initialFormData: initialFormData,
                    formData: initialFormData,
                    isLoading: false
                })
            }
        })
    }

    handleSubmit = () => {
        API.put('/staff/' + this.props.staffId, this.state.formData).then(result => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...this.state,
                    initialFormData: this.state.formData
                }, () => {
                    this.props.deploySnackBar("success", "The system user's details have been updated")
                    this.props.toggleDialog()
                })
            }
        })
    }
    handleChange = (event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value
            }
        })
    }

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    }

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };

    render() {
        const { formErrors, initialFormData, isLoading, formData } = this.state;
        return (
            <React.Fragment>                
                {(isLoading && (
                    <LoadingCircle />
                )) || (
                    <React.Fragment>
                        <form onChange={this.handleChange} noValidate autoComplete="off">
                            <TextField
                                name="firstName"
                                label="First Name"
                                margin="none"
                                error={formErrors && formErrors['firstName'] && true}
                                helperText={formErrors && formErrors['firstName']}
                                value={formData.firstName}
                                InputLabelProps={{
                                    shrink: !!formData.firstName
                                }}
                                fullWidth
                            />
                            <TextField
                                name="lastName"
                                label="Last Name"
                                margin="none"
                                error={formErrors && formErrors['lastName'] && true}
                                helperText={formErrors && formErrors['lastName']}
                                value={formData.lastName}
                                InputLabelProps={{
                                    shrink: !!formData.lastName
                                }}
                                fullWidth
                            />
                            <TextField
                                name="email"
                                label="Email Address"
                                margin="none"
                                error={formErrors && formErrors['email'] && true}
                                helperText={formErrors && formErrors['email']}
                                value={formData.email}
                                InputLabelProps={{
                                    shrink: !!formData.email
                                }}
                                fullWidth
                            />
                            <AutoCompleteSelect 
                                options={[
                                    {value: 'active', label: 'Active'},
                                    {value: 'inactive', label: 'Inactive'}
                                ]}
                                label='Status *'
                                onChange={this.handleSelectChange('status')}
                                error={formErrors && formErrors['status'] && true}
                                errorText={formErrors && formErrors['status']}
                                value={this.state.formData.status}
                            />
                            <div className='buttonRow'>
                                <Grid container spacing={3}>
                                    <Grid item xs />
                                    <Grid item>
                                        <Button onClick={() => this.props.toggleDialog()}
                                                variant="text"
                                        >
                                            <FAIcon icon="times" size={15} button />
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary"
                                                disabled={_.isEqual(initialFormData, formData)}>
                                            <FAIcon icon="check" size={15} buttonPrimary disabled={_.isEqual(initialFormData, formData)} />
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                            <ConfirmationDialog 
                                open={this.state.confirmationOpen} 
                                success={this.handleConfirmationSuccess} 
                                close={this.handleConfirmationClose} 
                                title="Update system user?" 
                                message="Are you sure you want to update this system user?"
                            />
                        </form>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(UpdateStaffDetailsForm);