import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ActionBadge from 'Components/Common/ActionBadge/ActionBadge';

const initialState = {
    access: {
        mapping: false,
    },
    companies: [],
    dataLoading: true,
}

class ScMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.props.pageTitle([1, 'Support Contracts', 'Device Mapping', 'Company Selection']);
        this.loadComponentData();
    }
0
    loadComponentData = () => {    
        Promise.all([
            API.get('/supportContracts/companies'),
            API.access('sc-mapping-management')
        ])
        .then(([companyRes, mapRes]) =>  {
            if(companyRes && companyRes.data) {
                this.setState({
                    ...this.state,
                    access: {
                        mapping: (mapRes.data && mapRes.data.has_access) || false
                    },
                    companies: companyRes.data,
                    dataLoading: false,
                });
            }
        })
    }

    handleSyncToggle = id => {
        API.get(`/supportContracts/companies/${id}/toggleSync`)
        .then(res => {
            if(res && res.data) {
                this.loadComponentData();
            }
        })
    }

    render() {
        const { access, companies, dataLoading } = this.state;
        return (
            <Grid container spacing={3}>
                {(dataLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Paper>
                                    <DataTable  
                                        config={{
                                            key: 'sc_comp_id',
                                            pagination: true,
                                            alternatingRowColours: true,
                                            isLoading: dataLoading,
                                            rowsPerPage: 100,
                                            responsiveImportance: true,
                                            plainHeader: true,
                                            title: 'Support Contract Mapping',
                                        }}
                                        columns={[ 
                                            {
                                                heading: 'Company',
                                                field: rowData => (
                                                    <React.Fragment>
                                                        {rowData.sc_comp_name}
                                                        {(rowData.sc_comp_sync && (
                                                            <React.Fragment>
                                                                <ActionBadge badgeContent={rowData.notice_actions_count} variant="warning" style={{marginLeft: 8}} />
                                                                <ActionBadge badgeContent={rowData.error_actions_count} variant="error" style={{paddingRight: 24}} />
                                                            </React.Fragment>
                                                        )) ||
                                                            <React.Fragment />
                                                        }
                                                    </React.Fragment>
                                                ),
                                                important: true,
                                                main: true
                                            },       
                                            {
                                                heading: 'ConnectWise Company',
                                                field: rowData => rowData.connectwise_company.cust_name,
                                            },    
                                            {
                                                heading: 'Sites',
                                                field: rowData => rowData.sites_count,
                                                alignment: 'right'
                                            },   
                                            {
                                                heading: 'Devices',
                                                field: rowData => rowData.devices_count > 0 ? (rowData.devices_count / 2) : 0, //Device count will be across both categories, divide by 2 for actual count.
                                                alignment: 'right'
                                            },   
                                            {
                                                heading: 'Enabled',
                                                field: rowData => (
                                                    <IconButton
                                                        onClick={() => this.handleSyncToggle(rowData.sc_comp_id)}
                                                        color='inherit'
                                                    >
                                                        <FAIcon
                                                            icon={
                                                                (rowData.sc_comp_sync && 'check-circle')
                                                                ||        
                                                                'times-circle'
                                                            }
                                                            style={{
                                                                color: 
                                                                    (rowData.sc_comp_sync && '#388E3C')
                                                                    ||                                                        
                                                                    '#d9534f'
                                                                }}
                                                            noMargin
                                                            button
                                                        />
                                                    </IconButton>
                                                ),             
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Manage', icon: 'chevron-right', link: '/support-contracts/mapping/' + rowData.sc_comp_id, disabled: (!rowData.sc_comp_sync || (!access.mapping ? true : false))},
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={companies}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default ScMapping;