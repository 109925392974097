import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class LoadingCircle extends React.Component {
    render() {
        return (
            <div 
                style={{
                    textAlign: 'center', 
                    padding: (this.props.padding && (this.props.padding === 'large' ? 48 : (this.props.padding === 'xs' ? 6 : 12))) || 24,
                }}
                {...this.props}
            >
                <CircularProgress
                    color={this.props?.style?.color}
                    size={this.props.size && (this.props.size === 'large' ? 70 : (this.props.size === 'xlarge' ? 100 : 40))}
                />
            </div>
        )
    }
}

export default LoadingCircle;