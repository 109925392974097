import React from "react";
import moment from "moment";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import API from "API";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import Tile from "Components/Common/Dashboard/Tile";
import { COLOR_MAIN_MS365 } from "ColorSchemes";

const initialState = {
    dataLoading: true,
    charts: {
        tenants: [],
        subscriptions: [],
        users: [],
        sales: [],
    },
    insight: {},
};

class MS365Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.props.pageTitle([1, "Microsoft 365", "Dashboard"]);
        this.loadComponentData();
    };

    loadComponentData = () => {
        Promise.all([API.get("/ms365/insight"), API.get("/ms365/insight/charts")]).then(([insightLatest, insight]) => {
            let historyTenants = [];
            let historySubscriptions = [];
            let historyUsers = [];
            let historyLabels = [];
            let historySales = [];

            for (let i = 0, j = 11; i <= j; i++) {
                historySales.push(insight.data[i].sales.total);
                historyTenants.push(parseInt(insight.data[i].ms365.tenants));
                historySubscriptions.push(parseInt(insight.data[i].ms365.subscriptions));
                historyUsers.push(parseInt(insight.data[i].ms365.users));
                historyLabels.push(insight.data[i].label);
            }

            const chartTenants = {
                labels: historyLabels,
                datasets: [
                    {
                        lineTension: 0.1,
                        backgroundColor: "rgba(171,71,188,0.5)",
                        borderColor: "#AB47BC",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#AB47BC",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#AB47BC",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        fill: true,
                        data: historyTenants,
                    },
                ],
            };

            const chartSubscriptions = {
                labels: historyLabels,
                datasets: [
                    {
                        lineTension: 0.1,
                        backgroundColor: "rgba(255,109,0,0.5)",
                        borderColor: "#FF6D00",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#FF6D00",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#FF6D00",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        fill: true,
                        data: historySubscriptions,
                    },
                ],
            };

            const chartUsers = {
                labels: historyLabels,
                datasets: [
                    {
                        lineTension: 0.1,
                        backgroundColor: "rgba(244,67,54,0.5)",
                        borderColor: "#f44336",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#f44336",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#f44336",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        fill: true,
                        data: historyUsers,
                    },
                ],
            };

            const chartSales = {
                labels: historyLabels,
                datasets: [
                    {
                        lineTension: 0.1,
                        backgroundColor: "#81C784",
                        borderColor: "#4CAF50",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#4CAF50",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#4CAF50",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        fill: true,
                        data: historySales,
                    },
                ],
            };

            this.setState({
                dataLoading: false,
                charts: {
                    tenants: chartTenants,
                    subscriptions: chartSubscriptions,
                    users: chartUsers,
                    sales: chartSales,
                },
                insight: insightLatest.data.insight,
            });
        });
    };

    render() {
        const { charts, dataLoading, insight } = this.state;
        return (
            <Grid container spacing={3}>
                {(dataLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={8} xl={9}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Service Sales
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.sales}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Service Tenants
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.tenants}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                    suggestedMax: insight && insight.imsi_count_tenants * 1.5,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Service Subscriptions
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.subscriptions}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                    suggestedMax: insight && insight.imsi_count_subscriptions * 1.5,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Service Users
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.users}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                    suggestedMax: insight && insight.imsi_count_users * 1.5,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={4} xl={3}>
                                    <Grid container spacing={3}>
                                        <Tile
                                            dashboard
                                            icon="sync"
                                            color={COLOR_MAIN_MS365}
                                            backgroundColor={"#fff"}
                                            data={insight && insight.imsi_created_datetime ? moment(insight.imsi_created_datetime).format("DD/MM/YYYY") : "Never"}
                                            title="Last Microsoft 365 Data Import"
                                        />
                                        <Tile
                                            dashboard
                                            icon="sitemap"
                                            color={COLOR_MAIN_MS365}
                                            backgroundColor={"#fff"}
                                            data={insight ? insight.imsi_count_tenants : 0}
                                            title="Tenants"
                                        />
                                        <Tile
                                            dashboard
                                            icon="users-class"
                                            color={COLOR_MAIN_MS365}
                                            backgroundColor={"#fff"}
                                            data={insight ? insight.imsi_count_subscriptions : 0}
                                            title="Subscriptions"
                                        />
                                        <Tile
                                            dashboard
                                            icon="users"
                                            color={COLOR_MAIN_MS365}
                                            backgroundColor={"#fff"}
                                            data={insight ? insight.imsi_count_users : 0}
                                            title="Users"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

export default MS365Dashboard;
