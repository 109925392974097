import React, { useRef, useState } from 'react';
import ReactSelectAsyncSelect from 'react-select/async';
import ReactSelectAsyncCreatableSelect from 'react-select/async-creatable';
import _ from 'lodash';

import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

const groupStyles = {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
}

const groupBadgeStyles = {
    backgroundColor: '#375885',
    borderRadius: '2em',
    color: '#fff',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
}

const selectStyles = {
    control: base => ({
        ...base,
        background: 'transparent',
        border: 0,
        boxShadow: 'none',
        borderBottom: '1px solid rgba(0,0,0,0.54)',
        '&:focus': {
            border: 0,
            borderBottom: '2px solid #375885!important',
        },
        marginTop: -5
    }),
    input: base => ({
        ...base,
        paddingLeft: 0,
        color: '#000', 
        '& input': {
            font: 'inherit',
        },
    }),
    menuList: base => ({
        ...base,
        padding: 0,
    }),
    menu: base => ({
        ...base,
        borderRadius: 0,
        marginTop: 8
    }),
    menuPortal: base => ({
        ...base,
        zIndex: 9999
    }),
    dropdownIndicator: (base, props) => ({
        ...base,
        paddingRight: 0,
        marginRight: 0,
        paddingLeft: 0,
        transform: 'scale(0.75)',
        height: '30px',
        color: (props.selectProps.error ? '#f44336' : 'rgba(0, 0, 0, 0.54)')
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    clearIndicator: (base) => ({
        ...base,
        paddingLeft: 5,
        paddingRight: 5,
        transform: 'scale(0.75)',
        height: '30px',
        cursor: 'pointer'
    }),
    option: (base, props) => ({
        ...base,
        backgroundColor: props.isSelected ? '#375885' : (props.isFocused ? 'rgba(239,51,64,0.1)' : '#ffffff'),  
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
    }),
    placeholder: (base, props) => ({
        color: '#b9b9b9'
    }),
    multiValueLabel: (base) => ({
        ...base,
        color: '#ffffff',
        backgroundColor: '#375885',
        borderRadius: 0,
        fontSize: '13px',
        padding: 3,
        paddingLeft: 8,
        paddingRight: 8
    }),
    multiValueRemove: (base) => ({
        ...base,
        backgroundColor: '#375885',
        color: '#ffffff',
        borderRadius: 0,
        padding: 4,
        cursor: 'pointer',
        ':hover': {
            backgroundColor: '#c6101c',
            color: 'white',
        }
    })
}

const filledSelectStyles = {
    control: base => ({
        ...base,
        background: '#fff',
        borderColor: '#ddd!important',
        boxShadow: 'none',
        paddingTop: 2,
        paddingBottom: 2.5,
        marginTop: -5,
    }),
    input: base => ({
        ...base,
        paddingLeft: 0,
        color: '#000', 
        '& input': {
            font: 'inherit',
        },
    }),
    menuList: base => ({
        ...base,
        padding: 0,
    }),
    menu: base => ({
        ...base,
        borderRadius: 0,
        marginTop: 8
    }),
    menuPortal: base => ({
        ...base,
        zIndex: 9999
    }),
    dropdownIndicator: (base, props) => ({
        ...base,
        paddingRight: 0,
        marginRight: 4,
        paddingLeft: 0,
        transform: 'scale(0.75)',
        height: '30px',
        color: (props.selectProps.error ? '#f44336' : 'rgba(0, 0, 0, 0.54)')
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    clearIndicator: (base) => ({
        ...base,
        paddingLeft: 5,
        paddingRight: 5,
        transform: 'scale(0.75)',
        height: '30px',
        cursor: 'pointer'
    }),
    option: (base, props) => ({
        ...base,
        backgroundColor: props.isSelected ? '#375885' : (props.isFocused ? 'rgba(239,51,64,0.1)' : '#ffffff'),  
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
    }),
    placeholder: (base, props) => ({
        color: '#b9b9b9'
    }),
    multiValueLabel: (base) => ({
        ...base,
        color: '#ffffff',
        backgroundColor: '#375885',
        borderRadius: 0,
        fontSize: '13px',
        padding: 3,
        paddingLeft: 8,
        paddingRight: 8
    }),
    multiValueRemove: (base) => ({
        ...base,
        backgroundColor: '#375885',
        color: '#ffffff',
        borderRadius: 0,
        padding: 4,
        cursor: 'pointer',
        ':hover': {
            backgroundColor: '#c6101c',
            color: 'white',
        }
    })
}

const AsyncSelect = ({apiCall, creatable, creatableMessage, defaultOptions, error, errorText, formatOptionLabel, innerRef, isMulti, label, menuPlacement, noClear, onChange, placeholder, value, variant}) => {

    const selectRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
      
    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>
                {_.size(data.options)}
            </span>
        </div>
    )

    const search = (inputValue, callback) => {
        const activeRef = innerRef ?? selectRef;
        if(isLoading) {
            setIsLoading(false);
        }
        apiCall(inputValue, callback, activeRef);
    }

    const Component = creatable ? ReactSelectAsyncCreatableSelect : ReactSelectAsyncSelect;

    return (
        <FormControl fullWidth style={{marginBottom: label ? 16 : 0}}>
            {label && (
                <Typography variant="caption" component="div" color="textSecondary">
                    {label}
                </Typography>
            )}
            <Component
                // cacheOptions
                defaultOptions={defaultOptions ?? []}
                error={!!error}
                errorText={errorText}
                formatCreateLabel={creatableMessage}
                formatGroupLabel={formatGroupLabel}
                formatOptionLabel={formatOptionLabel}
                isClearable={!noClear}
                isOptionDisabled={(option) => option.disabled === true}
                isMulti={isMulti ?? false}
                loadOptions={search}
                menuPlacement={menuPlacement ?? 'auto'}
                menuPortalTarget={document.body}
                noOptionsMessage={() => !isLoading ? 'No Options' : 'Start typing to search...'}
                onChange={onChange}
                onSelectResetsInput={false}
                placeholder={placeholder ?? (creatable ? `Start typing to search or create an option...` : `Start typing to search...`)}
                ref={innerRef ?? selectRef}
                styles={variant === "filled" ? filledSelectStyles : selectStyles}
                value={value}
                theme={(theme) => ({
                    ...theme,
                    background: '#fff',
                    underline: 0,
                    borderRadius: 0,
                    boxShadow: 'none',
                    padding: '2px 0',
                    colors: {
                        ...theme.colors,
                        primary: 'rgba(239, 51, 64, 1)',
                        primary25:'rgba(239, 51, 64, 0.25)',
                        primary50:'rgba(239, 51, 64, 0.50)',
                        primary75:'rgba(239, 51, 64, 0.75)'
                    }
                })}
            />
        </FormControl>
    )
}

export default AsyncSelect;