import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export function cloneObject(obj) {
    var clone = {};
    for(var i in obj) {
        if(obj[i] != null &&  typeof(obj[i])=="object")
            clone[i] = cloneObject(obj[i]);
        else
            clone[i] = obj[i];
    }
    return clone;
}

export function handleTabChange(event, tabIndex) {
    this.setState({
        currentTab: tabIndex
    })
}   

export const isPortrait = () => {
    return window.innerHeight > window.innerWidth;
}

export const isTouchEnabled = () => {
    return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
}

export const isTablet = () => {
    return (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent.toLowerCase())) || (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)) || window.innerWidth < 1400;
}

export const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const TabPanel = ({children, value, index, ...other}) => (
    <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        {...other}
    >
        {value === index && (
            <Box>
                {children}
            </Box>
        )}
    </Typography>
)

export const hex2rgba = (hex, alpha = 1) => {
    if(hex.length === 4) {
        hex = `${hex}${hex.substr(1,4)}`
    }
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))
    return `rgba(${r},${g},${b}, ${alpha})`
}

export function toggleDialog(dialog) {
    let closing = this.state.dialog[dialog]
    this.setState({
        dialog: {
            ...this.state.dialog,
            [dialog]: !this.state.dialog[dialog]
        }
    }, () => {
        if(closing)
            this.loadComponentData()
    })
}

export const currencyFormat = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2
})

export const currencyFormat3 = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 3
})