import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = {
    dataLoading: true,
    filter: '',
    filterOptions: _.map(['Info', 'Success', 'Notice', 'Warning', 'Error'], el => {
        return _.assign({
            value: el,
            label: el
        });
    }),
    isLoading: true,
    logs: {},
    searchString: '',
    searchOptions: '',
    task: 'Accounting'
}
 
class AccountsLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {        
        this.props.pageTitle([1, 'Accounts', 'Logs'])  
        this.loadComponentData()
    }

    loadComponentData = () => {           
        API.get('/connectwise/companyList')
        .then(result => {
            if(this.props.match.params.id) {
                this.setState({
                    isLoading: false,
                    searchOptions: _.map(result.data, (el) => {
                        return _.assign({
                            value: el.cust_id,
                            label: el.cust_name
                        });
                    }),
                    searchString: this.props.match.params.id
                }, 
                () => {
                    const idx = _.findIndex(this.state.searchOptions, function (el) { return el.cust_id === this.props.match.params.id })
                    if(idx === -1) {
                        this.props.history.push('/accounts/logs')
                    } else {
                        this.getLogs()
                    }
                })
            } else {
                this.setState({
                    isLoading: false,
                    searchOptions: _.map(result.data, (el) => {
                        return _.assign({
                            value: el.cust_id,
                            label: el.cust_name
                        });
                    })
                }, 
                () => {
                    this.getLogs()
                })
            }
        })
    }  

    getLogs = () => {
        this.setState({
            dataLoading: true
        },
        () => {
            API.get('/connectwise/logs', {
                params: {
                    filter: this.state.filter,
                    search: this.state.search,
                    task: this.state.task
                }
            })
            .then(result => {
                if(result.data) {
                    this.setState({
                        dataLoading: false,
                        logs: result.data,
                    }) 
                }
            });
        })
    }
    
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption ? selectedOption.value : ''
        },
        () => this.getLogs());
    };

    render() {
        const { filter, dataLoading, filterOptions, isLoading, logs, search, searchOptions } = this.state;        
        return (
            <Grid container spacing={3}>                
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (  
                    <Grid item xs={12}>
                        <Grid container spacing={3}>     
                            <Grid item xs={12}> 
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={3}>
                                        <AutoCompleteSelect 
                                            options={searchOptions}
                                            placeholder='Company'
                                            onChange={this.handleSelectChange('search')}
                                            value={search}
                                            margin="none"
                                            variant="filled"
                                            adornment='filter'
                                        />
                                    </Grid>  
                                    <Grid item xs={3}>
                                        <AutoCompleteSelect 
                                            options={filterOptions}
                                            placeholder='Log Type'
                                            onChange={this.handleSelectChange('filter')}
                                            value={filter}
                                            variant="filled"
                                            adornment='filter'
                                        />
                                    </Grid>    
                                </Grid>  
                            </Grid>  
                            <Grid item xs={12}>  
                                <Paper>
                                    <DataTable  
                                        config={{
                                            key: 'cwlog_id',
                                            pagination: true,
                                            isLoading: dataLoading,
                                            rowsPerPage: 8,
                                            noHeader: true,
                                            noRowHover: true,
                                            title: 'Accounts Log',
                                        }}
                                        columns={[
                                            {
                                                field: rowData => (
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item />
                                                        <Grid item align='center'>                                        
                                                            <FAIcon 
                                                                type="thin"
                                                                size={30}
                                                                icon={rowData.cwlog_type === 'Success' ? 'check-circle' : (rowData.cwlog_type === 'Error' ? 'times-circle' : (rowData.cwlog_type === 'Warning' ? 'exclamation-triangle' : (rowData.cwlog_type === 'Notice' ? 'info-circle' : 'flag-alt')))}
                                                                style={{
                                                                    color: rowData.cwlog_type === 'Success' ? '#388E3C' : (rowData.cwlog_type === 'Error' ? '#d9534f' : (rowData.cwlog_type === 'Warning' ? '#EF6C00' : (rowData.cwlog_type === 'Notice' ? '#6A1B9A' : '#0288D1')))
                                                                }}  
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Typography variant="body2" className="fw-400" component="div">                                                           
                                                                <span style={{
                                                                    color: rowData.cwlog_type === 'Success' ? '#388E3C' : (rowData.cwlog_type === 'Error' ? '#d9534f' : (rowData.cwlog_type === 'Warning' ? '#EF6C00' : (rowData.cwlog_type === 'Notice' ? '#6A1B9A' : '#0288D1')))
                                                                }}>
                                                                    {rowData.cwlog_title.split(":")[0]}{rowData.cwlog_title.split(":").length > 1 && ':'}
                                                                </span> 
                                                                {rowData.cwlog_title.split(":")[1]}
                                                            </Typography>      
                                                            {!_.isEmpty(rowData.cwlog_text) && (
                                                                <Typography variant="caption" component="div">
                                                                    {rowData.cwlog_text}
                                                                </Typography>
                                                            )}
                                                            <Typography variant="caption" color="textSecondary">
                                                                {moment(rowData.cwlog_created_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                ),
                                            },             
                                        ]}
                                        rows={logs}
                                    />
                                </Paper>
                            </Grid>           
                        </Grid>      
                    </Grid>       
                )}
            </Grid>
        )
    }
}

export default AccountsLogs;