import * as NotificationActionTypes from 'Redux/Actions/Notifications/Types/Types';
import API from 'API';
import { loggedIn } from 'Functions/AuthFunctions';

export function handleIamActive(staffId) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.I_AM_ACTIVE,
            payload: staffId
        });
    }
}

export function handleLoadStaffStatus(status) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.LOAD_STAFF_STATUS,
            payload: status
        });
    }
}

export function handleNotifications(result) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.HANDLE_NOTIFICATIONS,
            payload: result
        });
    }
}

export function loadNotifications() {
    return (dispatch, getState) => {  

        if(loggedIn() && !document.hidden) { 

            API.get('/pulse',
            {
                props: {
                    noLoading: true,
                    handleNotifications: true,
                    ignoreErrors: true,
                    cancellation: true
                }
            })
            
        }
    }
}

export function loadStaffStatus() {
    return dispatch => {    
        API.get('/staff/status')
        .then(res => {
            if(res && res.data) {
                dispatch(handleLoadStaffStatus(res.data))
            }
        })
    }
}