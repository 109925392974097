import React  from 'react';
import moment from 'moment';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import UpdateStaffMfa from '../Staff/UpdateStaff/UpdateStaffMfa';
import ChangeStaffPasswordForm from '../Staff/UpdateStaff/ChangeStaffPasswordForm';
import UploadStaffProfilePhotoForm from '../Staff/UpdateStaff/UploadStaffProfilePhotoForm';
import ViewStaffAuditLog from '../Staff/ViewStaff/ViewStaffAuditLog';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { UserAvatar } from 'Components/Common/Badges/Avatar';
import { handleTabChange, toggleDialog } from '../../Functions/MiscFunctions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

const styles = theme => ({
    tab: {
        color: '#fff',
        opacity: 1
    },
    tabIndicator: {
        backgroundColor: '#fff'
    },
    card: {
        color: '#fff',
        height: '270px',
        padding: '20px'
    },
    cardContent: {
        padding: '0 16px 16px 16px'
    }
})

const initialState = {
    currentTab: 0,
    dialog: {
        updateAvatar: false,
        updatePassword: false,
    },
    staff: {},
    isLoading: true,
}

class MyAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleTabChange = handleTabChange.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
    }

    componentDidMount() {  
        /* Important for dialog callback function */
        this.loadComponentData()
        this.props.pageTitle([0, 'My Account']); 
    }
    
    loadComponentData = () => {
        API.get('/staff/my/profile', { params: { viewStaff: true }})
        .then(result => {
            this.setState({
                ...this.state,
                staff: result.data,
                isLoading: false
            });
        })
    }
    
    render() {
        const { classes, scrollToTop } = this.props;
        const { currentTab, dialog, isLoading, staff } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    <Grid item xs={12}>
                        {(isLoading && (
                            <LoadingCircle />
                        )) || (
                            <React.Fragment>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>     
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item>
                                                {UserAvatar(staff, {style: {width: 75, height: 75}})}
                                            </Grid>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h3" className="fw-300">
                                                            {staff.staff_first_name} {staff.staff_last_name}
                                                        </Typography>
                                                    </Grid>    
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="envelope"
                                                                    label={`Email: ${staff.staff_email}`}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="calendar-alt"
                                                                    label={`Created: ${staff.staff_created_datetime === '0000-00-00 00:00:00' ? 'Unknown' : moment(staff.staff_created_datetime).format('MMM D, YYYY')}`}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="check"
                                                                    label={`Last Login: ${staff.staff_last_login_datetime === '0000-00-00 00:00:00' ? 'Unknown' : moment(staff.staff_last_login_datetime).format('MMM D, YYYY')}`}
                                                                />
                                                            </Grid>
                                                            <Grid item xs />
                                                            <Grid item align='right'>
                                                                <Grid container spacing={1}>
                                                                    <Grid item>
                                                                        <Button
                                                                            variant="text"
                                                                            color="primary"
                                                                            size="small" 
                                                                            onClick={() => this.toggleDialog('updateAvatar')}
                                                                        >
                                                                            <FAIcon icon="square-user" size={15} button />
                                                                            Change Avatar
                                                                        </Button>
                                                                    </Grid>    
                                                                    {/* <Grid item>
                                                                        <Button
                                                                            variant="text"
                                                                            color="primary"
                                                                            size="small" 
                                                                            onClick={() => this.toggleDialog('updatePassword')}
                                                                        >
                                                                            <FAIcon icon="key" size={15} button />
                                                                            Change Password
                                                                        </Button>
                                                                    </Grid>     */}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>     
                                                </Grid> 
                                            </Grid>                                     
                                        </Grid>                                     
                                    </Grid>                                     
                                    <Grid item xs={12}>
                                        <Paper>
                                            <AppBar color="primary" position="static" elevation={0}>
                                                <Tabs classes={{indicator: classes.tabIndicator}} variant='scrollable' value={currentTab} onChange={this.handleTabChange}>
                                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Multi-Factor Authentication" />
                                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Login History" />
                                                </Tabs>   
                                            </AppBar>   
                                        </Paper>
                                    </Grid> 
                                    <Grid item xs={12}>  
                                        <TabPanel value={currentTab} index={0}>
                                            <UpdateStaffMfa myAccount={true} staff={staff} staffId={staff.staff_id} scrollToTop={scrollToTop} callback={() => this.componentDidMount()} />
                                        </TabPanel>
                                        <TabPanel value={currentTab} index={1}>
                                            <Paper>
                                                <ViewStaffAuditLog staff={staff} isLoading={isLoading} />
                                            </Paper>
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                                <SmallFormDialog
                                    open={dialog.updateAvatar}
                                    title="Change Avatar"
                                    content={<UploadStaffProfilePhotoForm staffId={staff.staff_id} toggleDialog={() => this.toggleDialog('updateAvatar')} />}
                                    maxWidth='xs'
                                />
                                <SmallFormDialog
                                    open={dialog.updatePassword}
                                    title="Change Password"
                                    content={<ChangeStaffPasswordForm staffId={staff.staff_id} myPassword={true} toggleDialog={() => this.toggleDialog('updatePassword')} />}
                                    maxWidth='xs'
                                />
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(MyAccount);