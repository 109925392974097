import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import StatusChip from 'Components/Common/Chips/StatusChip';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { 
    handleChange,
    handleSelectChange,
    handleUpdateChange,
    handleUpdateSelectChange,
    handleSnackbarClose,
    handleConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationSuccess,
    handleDialogClose
} from '../../../Functions/FormFunctions';

const initialState = {
    cwCompanyList: [],
    formData: {
        voipName: '',
        voipType: '',
        voipCustId: '',
        voipAddition: ''
    },
    updateData: {
        voipId: '',
        voipType: '',
        voipName: '',
        voipAddition: ''
    },
    formErrors:[],
    updateErrors: [],
    dialog: {
        update: false
    },
    rows: [],
    confirmation: {
        submit: false,
        update: false,
    },
    snackbar: {
        submit: false,
        update: false,
    },
    isLoading: true,
    updateLoading: true
}

class VoipCompanies extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleChange = handleChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleUpdateChange = handleUpdateChange.bind(this);
        this.handleUpdateSelectChange = handleUpdateSelectChange.bind(this);
        this.handleSnackbarClose = handleSnackbarClose.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
        this.handleDialogClose = handleDialogClose.bind(this);
    }

    componentDidMount = () => {
        this.props.pageTitle([1, 'VOIP and Connectivity', 'Companies']);
        this.getVoipCompanies();
    }

    submit = () => {
        API.post('/voip/companies', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbar: {
                        update: false,
                        submit: true
                    }
                });
                this.getVoipCompanies();
            }
            this.props.scrollToTop();
        });
    }

    update = () => {
        API.put('/voip/companies/'+this.state.updateData.voipId, this.state.updateData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    updateErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbar: {
                        update: true,
                        submit: false
                    }
                });
                this.getVoipCompanies();
            }
            this.props.scrollToTop();
        });
    }

    getVoipCompanies = () => {
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([
                API.get("/connectwise/additions"),
                API.get('/voip/companies')
            ])
            .then(([res, result]) => {
                let additionList = []
                let cwCompanyList = _.map(res.data, el => {
                    return _.assign({
                        label: el.cust_name,
                        value: el.cust_id,
                    });
                })
                res.data.forEach(comp => {
                    additionList = {
                        ...additionList,
                        [comp.cust_id]: [{
                            label: "BlueSync Actions",
                            options: [{
                                value: -1,
                                label: "Ignore"
                            }]
                        }]
                    }
                    if(!_.isEmpty(comp.agreements)) {
                        comp.agreements.forEach(agreement => {
                            if(!_.isEmpty(agreement.additions)) {
                                additionList = {
                                    ...additionList,
                                    [comp.cust_id]: [
                                        ...additionList[comp.cust_id],
                                        {
                                            label: agreement.cwa_cw_agreement_name,
                                            options: _.map(agreement.additions, el => {
                                                return _.assign({
                                                    value: el.cwaa_id,
                                                    label: el.cwaa_cw_product_identifier
                                                });
                                            }) 
                                        }
                                    ]
                                }
                            }
                        })
                    }
                })
                this.setState({
                    ...this.state,
                    additionList,
                    cwCompanyList,
                    rows: result.data,
                    isLoading: false
                });
            })
        })
    }
    
    getUpdateData = () => {
        API.get('/voip/companies/'+this.state.updateData.voipId)
        .then((result) => {
            if(result.data.errors) {
                this.handleDialogClose('update');
            } else {
                this.setState({
                    updateLoading: false,
                    updateData: {
                        ...this.state.updateData,
                        voipName: result.data.voip_name,
                        voipType: result.data.voip_type,
                        voipCustId: result.data.voip_cust_id,
                        voipAddition: (result.data.charges_addition && result.data.charges_addition[0] && result.data.charges_addition[0].cwaa_id) || -1,
                    }
                });
            }
        })
    }

    handleDeleteCompany = (id) => {
        API.put(`/voip/companies/${id}/delete`)
        .then(result => {
            if(result?.data) {
                this.getVoipCompanies();
                this.props?.scrollToTop?.();
                this.props?.deploySnackBar?.("success", "You have successfully deleted this VOIP company");
            }
        });
    }
    
    handleDialogOpen = (id) => {
        this.setState({
            dialog: {
                update: true
            },
            updateLoading: true,
            updateData: {
                ...initialState.updateData,
                voipId: id
            }
        }, () => this.getUpdateData());
    }

    render() {
        const { additionList, cwCompanyList, formData, formErrors, isLoading, updateData, updateErrors, updateLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            VOIP and Connectivity Companies
                        </Typography>
                        <DataTable  
                            config={{
                                key: 'voip_id',
                                pagination: true,
                                plainHeader: true,
                                plainPagination: true,
                                rowsPerPage: 50,
                                isLoading: isLoading,
                                options: {
                                    minimalPadding: true
                                }
                            }}
                            columns={[
                                {
                                    heading: 'VOIP Company',
                                    field: rowData => rowData.voip_name,
                                    main: true
                                },     
                                {
                                    heading: 'VOIP Company Type',
                                    field: rowData => <StatusChip status={rowData.voip_type} />,
                                },      
                                {
                                    heading: 'ConnectWise Company',
                                    field: rowData => rowData.voip_type === "Internal" ? "N/A" : rowData.connectwise_company.cust_name,
                                },   
                                {
                                    heading: 'Additional Charges Addition',
                                    field: rowData => rowData.voip_type === "Internal" ? "N/A" : (rowData.charges_addition && rowData.charges_addition[0] && rowData.charges_addition[0].cwaa_cw_product_identifier) || 'Not Billable',
                                },       
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil-alt',  onClick: () => this.handleDialogOpen(rowData.voip_id)},
                                            {name: 'Delete', icon: 'trash-alt',  onClick: () => this.props.deployConfirmation(`Are you sure you want to delete this company?`, () => this.handleDeleteCompany(rowData.voip_id))},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.rows}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Add Company
                        </Typography>
                        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <TextField
                                name="voipName"
                                label="VOIP Company Name *"
                                value={formData.voipName}
                                error={formErrors && formErrors['voipName'] && true}
                                helperText={formErrors && formErrors['voipName']}
                                onChange={this.handleChange}
                                margin="none"
                                fullWidth
                            />
                            <AutoCompleteSelect                                
                                options={[
                                    {
                                        label: "Customer",
                                        value: "Customer"
                                    }, 
                                    {
                                        label: "Internal",
                                        value: "Internal"
                                    }
                                ]}
                                label='VOIP Company Type *'
                                onChange={o => this.handleSelectChange('voipType', o)}
                                error={formErrors && formErrors['voipType'] && true}
                                errorText={formErrors && formErrors['voipType']}
                                value={formData.voipType}
                            />
                            {formData.voipType === "Customer" && (
                                <React.Fragment>
                                    <AutoCompleteSelect                                
                                        options={cwCompanyList}
                                        label='ConnectWise Company *'
                                        onChange={o => this.handleSelectChange('voipCustId', o)}
                                        error={formErrors && formErrors['voipCustId'] && true}
                                        errorText={formErrors && formErrors['voipCustId']}
                                        value={formData.voipCustId}
                                    />
                                    <AutoCompleteSelect                                
                                        options={formData.voipCustId === "" ? [] : additionList[formData.voipCustId]}
                                        label='Additional Charges Addition *'
                                        onChange={o => this.handleSelectChange('voipAddition', o)}
                                        error={formErrors && formErrors['voipAddition'] && true}
                                        errorText={formErrors && formErrors['voipAddition']}
                                        value={formData.voipAddition}
                                        disabled={formData.voipCustId === "" ? true : false}
                                    />
                                </React.Fragment>
                            )}
                            <div className='buttonRow'>
                                <Button 
                                    type='submit'
                                    onClick={() => this.handleConfirmationOpen('submit')}
                                    variant="contained" 
                                    color="primary" 
                                    disabled={!this.state.formData.voipName}
                                >
                                    <FAIcon icon="check" disabled={!this.state.formData.voipName} buttonPrimary size={15} />
                                    Add Company
                                </Button>
                            </div>
                        </form>
                        <Dialog open={this.state.dialog.update} onClose={() => this.handleDialogClose('update')} fullWidth={true} maxWidth="sm">
                            <DialogTitle id="form-dialog-title" color="secondary">Update VOIP Company</DialogTitle>
                            <DialogContent>
                                {(updateLoading && (
                                    <LoadingCircle />
                                )) || (
                                    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                        <TextField
                                            name="voipName"
                                            label="VOIP Company Name *"
                                            value={updateData.voipName}
                                            error={updateErrors && updateErrors['voipName'] && true}
                                            helperText={updateErrors && updateErrors['voipName']}
                                            onChange={this.handleUpdateChange}
                                            margin="none"
                                            fullWidth
                                        />
                                        <AutoCompleteSelect     
                                            isGrouped                           
                                            options={updateData.voipCustId === "" ? [] : additionList[updateData.voipCustId]}
                                            label='Additional Charges Addition *'
                                            onChange={o => this.handleUpdateSelectChange('voipAddition', o)}
                                            error={updateErrors && updateErrors['voipAddition'] && true}
                                            errorText={updateErrors && updateErrors['voipAddition']}
                                            value={updateData.voipAddition}
                                        />
                                    </form>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.handleDialogClose('update')} variant="text"><FAIcon icon="times" size={15} button />Cancel</Button>
                                <Button onClick={() => this.handleConfirmationOpen('update')} color="primary" autoFocus variant="contained"><FAIcon icon="check" size={15} buttonPrimary />Update</Button>
                            </DialogActions>
                        </Dialog>
                        <ConfirmationDialog 
                            open={this.state.confirmation.update} 
                            success={() => this.handleConfirmationSuccess('update')} 
                            close={() => this.handleConfirmationClose('update')} 
                            message="Are you sure you want to update this VOIP Company?"
                        />
                        <ConfirmationDialog 
                            open={this.state.confirmation.submit} 
                            success={() => this.handleConfirmationSuccess('submit')} 
                            close={() => this.handleConfirmationClose('submit')} 
                            message="Are you sure you want to add a new VOIP Company?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbar.update}
                            onClose={() => this.handleSnackbarClose('update')}
                            message='You have successfully updated the specified VOIP Company'
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbar.submit}
                            onClose={() => this.handleSnackbarClose('submit')}
                            message='You have successfully added a new VOIP Company'
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deployConfirmation: (message, callback) => dispatch(deployConfirmation(message, callback)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    }
}

export default connect(null, mapDispatchToProps)(VoipCompanies);