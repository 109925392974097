import React from 'react';
import moment from 'moment';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import InsightChip from 'Components/Common/Chips/InsightChip';
import StatusChip from 'Components/Common/Chips/StatusChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Tile from 'Components/Common/Dashboard/Tile';
import { COLOR_MAIN_VOIP, COLOR_TEXT_VOIP } from 'ColorSchemes';
import { handleTabChange } from '../../../Functions/MiscFunctions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

const styles = theme => ({
    tab: {
        color: '#fff',
        opacity: 1
    },
    tabIndicator: {
        backgroundColor: '#fff'
    },
})

const initialState = {
    currentTab: 0,
    service: {},
    isLoading: true
}

class VoipServicesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleTabChange = handleTabChange.bind(this);
    }

    componentDidMount = () => {
        this.props.pageTitle([1, 'VOIP and Connectivity', 'Services']);
        this.loadComponentData();
    }

    loadComponentData = () => {
        API.get(`/voip/services/${this.props.match.params.id}`)
        .then(result => {
            if(result.data) {
                this.setState({
                    service: result.data,
                    isLoading: false
                }, () => this.props.pageTitle([1, 'VOIP and Connectivity', 'Services', this.state.service.voip_service_identifier]))
            }
        });
    }

    tableServices = () => (
        <Paper>
            <DataTable  
                config={{
                    key: 'voip_sc_id',
                    pagination: true,
                    alternatingRowColours: true,
                    isLoading: this.state.isLoading,
                    responsiveImportance: true,
                    plainHeader: true, 
                    title: 'Service Charges',
                    showFullTotals: true
                }}
                columns={[
                    {
                        heading: 'Billing From',
                        field: rowData => rowData.voip_sc_billing_from,
                        fieldFormat: 'date',
                        important: true,
                    },
                    {
                        heading: 'Billing To',
                        field: rowData => rowData.voip_sc_billing_to,
                        fieldFormat: 'date',
                        important: true,
                    },
                    {
                        heading: 'Quantity',
                        field: rowData => rowData.voip_sc_quantity,
                        alignment: 'right',
                        showTotal: true,
                        important: true,
                    },
                    {
                        heading: 'Unit Cost',
                        field: rowData => `${rowData.voip_sc_unit_cost}`,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        important: true,
                        showTotal: true,
                    },
                    {
                        heading: 'Total Cost',
                        field: rowData => `${rowData.voip_sc_total_cost}`,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        important: true,
                        showTotal: true,
                    }
                ]}
                rows={this.state.service && this.state.service.service_charges}
            />
        </Paper>
    )

    tableCharges = () => (
        <Paper>
            <DataTable  
                config={{
                    key: 'voip_cc_id',
                    pagination: true,
                    alternatingRowColours: true,
                    isLoading: this.state.isLoading,
                    responsiveImportance: true,
                    plainHeader: true, 
                    title: 'Call Charges',
                    showFullTotals: true
                }}
                columns={[
                    {
                        heading: 'Dialled Number',
                        field: rowData => rowData.voip_cc_dialled_no,
                        important: true,
                    },
                    {
                        heading: 'Call Date',
                        field: rowData => rowData.voip_cc_call_date,
                        fieldFormat: 'date',
                        important: true,
                    },
                    {
                        heading: 'Call Time',
                        field: rowData => rowData.voip_cc_call_time,
                        important: true,
                    },
                    {
                        heading: 'Call Duration',
                        field: rowData => rowData.voip_cc_call_duration,
                        important: true,
                    },
                    {
                        heading: 'Call Charge Code',
                        field: rowData => rowData.voip_cc_charge_code,
                        important: true,
                    },
                    {
                        heading: 'Call Cost',
                        field: rowData => `${rowData.voip_cc_cost}`,
                        fieldFormat: 'currency3',
                        alignment: 'right',
                        important: true,
                        showTotal: true,
                    },
                ]}
                rows={this.state.service && this.state.service.call_charges}
            />
        </Paper>
    )

    render() {
        const { classes } = this.props;
        const { currentTab, service, isLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    <Grid item xs={12}>
                        {(isLoading && (
                            <LoadingCircle />
                        )) || (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>     
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <FAIcon type="thin" icon="phone" size={75} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h3" className="fw-300">
                                                                {service.voip_service_identifier}
                                                            </Typography>
                                                        </Grid>    
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="link-horizontal"
                                                                        label={`Company: ${service.company ? service.company.voip_name : 'Unassigned'}`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="box"
                                                                        label={`Service: ${service.voip_service_name}`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="calendar-alt"
                                                                        label={`Imported: ${service.voip_service_created_datetime === '0000-00-00 00:00:00' ? 'Unknown' : moment(service.voip_service_created_datetime).format('MMM D, YYYY')}`}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>     
                                                    </Grid> 
                                                </Grid>                                     
                                            </Grid>                                     
                                        </Grid> 
                                        <Grid item xs={12}>                                    
                                            <Paper>     
                                                <AppBar style={{backgroundColor: COLOR_MAIN_VOIP}} position="static" elevation={0}>
                                                    <Tabs value={currentTab} onChange={this.handleTabChange} classes={{indicator: classes.tabIndicator}} variant="scrollable">
                                                        <Tab disableRipple value={0} label={`Overview`} />
                                                        <Tab disableRipple value={1} label={`Service Charges`} />
                                                        <Tab disableRipple value={2} label={`Call Charges`} />
                                                    </Tabs>                                     
                                                </AppBar>
                                            </Paper>
                                        </Grid>     
                                        <Grid item xs={12}>  
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={9}>
                                                        <Paper>
                                                            <DataTable  
                                                                config={{
                                                                    key: 'vdi_id',
                                                                    pagination: true,
                                                                    alternatingRowColours: true,
                                                                    isLoading: this.state.isLoading,
                                                                    responsiveImportance: true,
                                                                    plainHeader: true, 
                                                                    title: 'Data Imports',
                                                                }}
                                                                columns={[
                                                                    {
                                                                        heading: 'Batch Identifier',
                                                                        field: rowData => rowData.vdi_ref,
                                                                        important: true,
                                                                    },
                                                                    {
                                                                        heading: 'Imported Service Charges',
                                                                        field: rowData => rowData.service_charges_count,
                                                                        alignment: 'right',
                                                                        important: true,
                                                                        sizeToContent: true,
                                                                    },
                                                                    {
                                                                        heading: 'Imported Call Charges',
                                                                        field: rowData => rowData.call_charges_count,
                                                                        alignment: 'right',
                                                                        important: true,
                                                                        sizeToContent: true,
                                                                    },
                                                                    {
                                                                        heading: 'Created',
                                                                        field: rowData => rowData.vdi_created_datetime,
                                                                        fieldFormat: 'datetime',
                                                                        important: true,
                                                                        sizeToContent: true,
                                                                    },
                                                                    {
                                                                        heading: 'Batch Status',
                                                                        field: rowData => <StatusChip status={rowData.vdi_status} />,
                                                                        important: true,
                                                                        sizeToContent: true,
                                                                    },
                                                                    {
                                                                        actions: rowData => {
                                                                            return [
                                                                                {name: "View Import", icon: 'search', link: '/voip/import/' + rowData.vdi_id, disabled: rowData.vdi_status === "Processing" ? true : false}
                                                                            ]
                                                                        }
                                                                    }
                                                                ]}
                                                                rows={this.state.service && this.state.service.imports}
                                                            />
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12} lg={3}>
                                                        <Grid container spacing={3}>
                                                            <Tile dashboard icon="traffic-light" backgroundColor={COLOR_TEXT_VOIP} data={service.voip_service_status} title="Status" />                                             
                                                            <Tile dashboard icon="exchange" backgroundColor={COLOR_TEXT_VOIP} data={service.service_charges.length} title="Imported Service Charges" />                                             
                                                            <Tile dashboard icon="exchange" backgroundColor={COLOR_TEXT_VOIP} data={service.call_charges.length} title="Imported Call Charges" />                                             
                                                            <Tile dashboard icon="sack-dollar" backgroundColor={COLOR_TEXT_VOIP} data={`£${service.service_charges_total}`} title="Total Service Charges" />                                             
                                                            <Tile dashboard icon="sack-dollar" backgroundColor={COLOR_TEXT_VOIP} data={`£${service.call_charges_total}`} title="Total Call Charges" />                                             
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                {this.tableServices()}
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                {this.tableCharges()}
                                            </TabPanel>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(VoipServicesView);