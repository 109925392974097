import React from 'react';
import { useSelector } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles'

import AdminMenu from 'Components/Layout/AdminLayout/AdminMenu';
// import AdminSmartSearch from 'Components/Layout/AdminLayout/AdminSmartSearch';
import AdminLoadingBar from 'Components/Layout/AdminLayout/AdminLoadingBar';
import AdminUser from 'Components/Layout/AdminLayout/AdminUser';
import AdminUserDetails from 'Components/Layout/AdminLayout/AdminUserDetails';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import logo from 'Assets/Images/logo_dark.png';
import { isTablet } from 'Functions/MiscFunctions';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'fixed',
        top: 0,
        webkitOverflowScrolling: 'touch',
        webkitTransform: 'translateZ(0)',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: '0 4px 2px -2px rgba(0,0,0,0.2)'
    },
    toolbar: {
        height: isTablet() ? 42.5 : 47.5,
        minHeight: isTablet() ? 42.5 : 47.5,
        paddingTop: 'env(safe-area-inset-top)',
        paddingRight: theme.spacing(3),
        backgroundColor: theme.palette.primary.main,
    },   
    logo:{
        width: 100,
        height:'auto',
        marginTop: 6,
        marginLeft: theme.spacing(1),
        marginRight: 87.5
    }, 
    iconContainer: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    searchContainer: {
        marginRight: theme.spacing(1)
    },
    menuButton: {
        marginLeft: 2
    },
    [theme.breakpoints.down('xs')]: {
        iconContainer: {
            display: 'none'
        },
        toolbar: {
            paddingRight: theme.spacing(1)
        },
        logo: {
            width: 182
        },
    }    
  }));

const AdminHeader = ({handleDialogOpen, toggleDrawer}) => {
    const   classes = useStyles(),
            device  = useSelector(state => state.ui?.device);
    return (
        <AppBar
            position="absolute"
            className={classes.appBar}
        >
            <Toolbar disableGutters={true} className={classes.toolbar}>
                <Grid container alignItems="center">
                    <Grid item>
                        <IconButton
                            color="inherit"
                            onClick={() => toggleDrawer('nav')}
                            className={`${classes.menuButton}`}
                        >
                            <FAIcon type="light" icon='bars' buttonPrimary size={device?.isTablet ? 17.5 : 20} noMargin />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <img alt="BlueSync" className={classes.logo} src={logo} style={{width: device?.isTablet ? 57.5 : undefined}} />
                    </Grid>
                    <Grid item xs align="right">
                        <Grid container alignItems="center">
                            {/* <Grid item align="right" className={classes.searchContainer}>
                                <AdminSmartSearch device={device} />
                            </Grid> */}
                            <Grid item xs />
                            <Grid item>
                                <IconButton onClick={() => handleDialogOpen('selection')}>
                                    <FAIcon icon="sync" noMargin buttonPrimary />
                                </IconButton>
                            </Grid>
                            <Grid item align="right" className={classes.iconContainer}>
                                <AdminUser device={device} />
                            </Grid>
                            <Grid item className={`${classes.iconContainer} ml-1`}>            
                                <AdminUserDetails device={device} />
                            </Grid>
                            <Grid item align="left" className={classes.iconContainer}>
                                <AdminMenu device={device} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> 
            </Toolbar>
            <AdminLoadingBar />
        </AppBar>
    )
}

export default AdminHeader;