import React from "react";
import _ from "lodash";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";

import API from "API";
import ActionBadge from "Components/Common/ActionBadge/ActionBadge";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import ConfirmationDialog from "Components/Common/Dialogs/ConfirmationDialog";
import DataTable from "Components/Common/DataTables/DataTable";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import SnackBar from "Components/Common/SnackBars/SnackBar";
import { formatValidationErrors } from "../../../../Helpers/ErrorHelper";

const initialState = {
    access: {
        logs: false,
        mapping: false,
    },
    confirmation: {
        dataSaved: false,
        importData: false,
    },
    customers: {},
    customerList: [],
    dataLoading: true,
    dataSave: false,
    formData: {},
    initialFormData: {},
    tenants: {},
    snackbar: {
        dataSaved: false,
    },
};

class MS365TenantMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.type = this.props.type;
    }

    componentDidMount = () => {
        this.loadComponentData();
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.type !== this.props.type) {
            this.type = this.props.type;
            this.loadComponentData();
        }
    };

    loadComponentData = () => {
        this.props.pageTitle([1, "Microsoft 365", "Tenant Mapping", this.type === "csp" ? "CSP" : "NCE"]);
        this.setState(
            {
                dataLoading: true,
            },
            () => {
                Promise.all([
                    API.get("/connectwise"),
                    API.get("/ms365/tenants", { params: { type: this.type } }),
                    API.access(`ms365-${this.type === "nce" ? "nce-" : ""}tenant-mapping`),
                    API.access("ms365-logs"),
                ]).then(([custRes, tenantsRes, mappingAccessRes, logsAccessRes]) => {
                    if (custRes.data && tenantsRes.data && mappingAccessRes.data && logsAccessRes.data) {
                        var assignedCustomers = [];
                        var initialFormData = {};
                        _.each(tenantsRes.data, (tenant) => {
                            initialFormData = this.mapFormData(tenant, assignedCustomers, initialFormData);
                        });
                        const customerList = _.map(custRes.data, (el) => {
                            return _.assign({
                                value: el.cust_id,
                                label: el.cust_name,
                                disabled: false, //assignedCustomers.includes(el.cust_id) ? true : false //Commented to allow duplicate tenant -> company mappings
                            });
                        });
                        this.setState({
                            ...this.state,
                            access: {
                                logs: (logsAccessRes.data && logsAccessRes.data.has_access) || false,
                                mapping: (mappingAccessRes.data && mappingAccessRes.data.has_access) || false,
                            },
                            customers: custRes.data,
                            customerList: customerList,
                            dataLoading: false,
                            formData: initialFormData,
                            initialFormData: initialFormData,
                            tenants: tenantsRes.data,
                        });
                    }
                });
            }
        );
    };

    mapFormData = (tenant, assignedCustomers, initialFormData) => {
        if (tenant.mso_company_cust_id !== 0) {
            //assignedCustomers.push(tenant.mso_company_cust_id); //Commented to allow duplicate tenant -> company mappings
        }
        initialFormData = {
            ...initialFormData,
            [tenant.mso_company_id]: {
                msoCompanyId: parseInt(tenant.mso_company_id),
                msoCompanyCustId: parseInt(tenant.mso_company_cust_id),
                msoCompanySync: parseInt(tenant.mso_company_sync),
            },
        };
        return initialFormData;
    };

    handleReset = () => {
        this.setState(
            {
                dataLoading: true,
            },
            () => this.loadComponentData()
        );
    };

    handleSubmit = () => {
        this.setState(
            {
                dataSave: true,
                initialFormData: this.state.formData,
            },
            () => {
                API.put("/ms365/tenants/mapping", { mappingData: this.state.formData }).then((result) => {
                    if (result.data.errors) {
                        this.setState(
                            {
                                dataSave: false,
                                formErrors: formatValidationErrors(result.data.errors),
                            },
                            () => this.props.scrollToTop()
                        );
                    } else {
                        this.setState(
                            {
                                dataLoading: true,
                                dataSave: false,
                                snackbar: {
                                    dataSaved: true,
                                },
                            },
                            () => {
                                this.props.scrollToTop();
                                this.loadComponentData();
                            }
                        );
                    }
                });
            }
        );
    };

    handleSyncToggle = (msoCompanyId) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [msoCompanyId]: {
                    ...this.state.formData[msoCompanyId],
                    msoCompanySync: this.state.formData[msoCompanyId].msoCompanySync === 1 ? 0 : 1,
                },
            },
        });
    };

    handleCustomerSelection = (msoCompanyId) => (selectedCustomer) => {
        const option = selectedCustomer === null ? 0 : selectedCustomer.value;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [msoCompanyId]: {
                        ...this.state.formData[msoCompanyId],
                        msoCompanyCustId: parseInt(option),
                        msoCompanySync: parseInt(option) === 0 ? 0 : 1,
                    },
                },
            },
            () => {
                var assignedCustomers = [];
                _.each(this.state.formData, (tenant) => {
                    if (tenant.msoCompanyCustId !== 0) assignedCustomers.push(tenant.msoCompanyCustId);
                });
                var customerList = _.map(this.state.customers, (el) => {
                    return _.assign({
                        value: el.cust_id,
                        label: el.cust_name,
                        disabled: false, //assignedCustomers.includes(el.cust_id) ? true : false //Commented to allow duplicate tenant -> company mappings
                    });
                });
                this.setState({
                    customerList: customerList,
                });
            }
        );
    };

    handleConfirmationOpen = (type) => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: true,
            },
        });
    };

    handleConfirmationClose = (type) => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false,
            },
        });
    };

    handleConfirmationSuccess = (type) => {
        this.setState(
            {
                confirmation: {
                    ...this.state.confirmation,
                    [type]: false,
                },
            },
            () => {
                this.handleSubmit();
            }
        );
    };

    handleSnackbarClose = (type) => {
        this.setState({
            snackbar: {
                ...this.state.snackbar,
                [type]: false,
            },
        });
    };

    render() {
        const { access, dataLoading, customerList, tenants, dataSave, formData, initialFormData, confirmation, snackbar } = this.state;
        return (
            <Grid container spacing={3}>
                {(dataLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Paper>
                                    <DataTable
                                        config={{
                                            key: "mso_company_id",
                                            pagination: true,
                                            alternatingRowColours: true,
                                            isLoading: dataLoading,
                                            rowsPerPage: 100,
                                            responsiveImportance: true,
                                            nesting: true,
                                            plainHeader: true,
                                            title: "Microsoft 365 Mapping",
                                            actions: (
                                                <React.Fragment>
                                                    <Button
                                                        variant="text"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => this.handleReset()}
                                                        style={{ marginLeft: 6 }}
                                                        disabled={dataLoading ? true : dataSave ? true : initialFormData === formData ? true : false}
                                                    >
                                                        <FAIcon
                                                            icon="trash"
                                                            style={{
                                                                color: dataLoading ? "#ddd" : dataSave ? "#ddd" : initialFormData === formData ? "#ddd" : "#375885",
                                                                height: "1em",
                                                            }}
                                                            heading
                                                            button
                                                        />
                                                        Discard
                                                    </Button>
                                                    <Button
                                                        variant="text"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => this.handleConfirmationOpen("dataSaved")}
                                                        style={{ marginLeft: 6 }}
                                                        disabled={dataLoading ? true : dataSave ? true : initialFormData === formData ? true : false}
                                                    >
                                                        <FAIcon
                                                            icon="save"
                                                            style={{
                                                                color: dataLoading ? "#ddd" : dataSave ? "#ddd" : initialFormData === formData ? "#ddd" : "#375885",
                                                            }}
                                                            size="small"
                                                            swapOpacity
                                                            button
                                                        />
                                                        Save
                                                    </Button>
                                                </React.Fragment>
                                            ),
                                        }}
                                        columns={[
                                            {
                                                heading: "Tenant",
                                                field: (rowData) => (
                                                    <React.Fragment>
                                                        {rowData.mso_company_name}
                                                        {formData[rowData.mso_company_id].msoCompanySync === 1 && formData[rowData.mso_company_id].msoCompanyCustId !== 0 && (
                                                            <React.Fragment>
                                                                <ActionBadge badgeContent={rowData.contact_actions_count} variant="warning" style={{ marginLeft: 8 }} />
                                                                <ActionBadge
                                                                    badgeContent={rowData.subscription_without_additions_actions_count}
                                                                    variant="error"
                                                                    style={{ paddingRight: 24 }}
                                                                />
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                ),
                                                main: true,
                                                important: true,
                                                nestingDropdown: true,
                                            },
                                            {
                                                heading: "Customer",
                                                field: (rowData) => (
                                                    <FormControl fullWidth style={{ minWidth: 350, maxWidth: 350 }}>
                                                        <AutoCompleteSelect
                                                            options={customerList}
                                                            placeholder="Not Mapped"
                                                            onChange={this.handleCustomerSelection(rowData.mso_company_id)}
                                                            value={formData[rowData.mso_company_id].msoCompanyCustId}
                                                            variant="filled"
                                                        />
                                                    </FormControl>
                                                ),
                                                important: true,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: "Enabled Licences",
                                                field: (rowData) => rowData.stats.licences.enabled,
                                                sizeToContent: true,
                                                alignment: "right",
                                            },
                                            {
                                                heading: "Unused Licences",
                                                field: (rowData) => (
                                                    <span
                                                        style={{
                                                            color:
                                                                formData[rowData.mso_company_id].msoCompanySync === 1 &&
                                                                formData[rowData.mso_company_id].msoCompanyCustId !== 0 &&
                                                                rowData.stats.licences.unused > 0 &&
                                                                "#d9534f",
                                                            fontWeight:
                                                                formData[rowData.mso_company_id].msoCompanySync === 1 &&
                                                                formData[rowData.mso_company_id].msoCompanyCustId !== 0 &&
                                                                rowData.stats.licences.unused > 0 &&
                                                                "bold",
                                                        }}
                                                    >
                                                        {rowData.stats.licences.unused}
                                                    </span>
                                                ),
                                                sizeToContent: true,
                                                alignment: "right",
                                            },
                                            {
                                                heading: "Enabled",
                                                field: (rowData) => (
                                                    <IconButton
                                                        disabled={(formData[rowData.mso_company_id].msoCompanyCustId === 0 && true) || false}
                                                        onClick={() => this.handleSyncToggle(rowData.mso_company_id)}
                                                        color="inherit"
                                                    >
                                                        <FAIcon
                                                            icon={
                                                                (formData[rowData.mso_company_id].msoCompanySync === 1 &&
                                                                    formData[rowData.mso_company_id].msoCompanyCustId !== 0 &&
                                                                    "check-circle") ||
                                                                (formData[rowData.mso_company_id].msoCompanySync === 0 &&
                                                                    formData[rowData.mso_company_id].msoCompanyCustId !== 0 &&
                                                                    "times-circle") ||
                                                                "times-circle"
                                                            }
                                                            style={{
                                                                color:
                                                                    (formData[rowData.mso_company_id].msoCompanySync === 1 &&
                                                                        formData[rowData.mso_company_id].msoCompanyCustId !== 0 &&
                                                                        "#388E3C") ||
                                                                    (formData[rowData.mso_company_id].msoCompanySync === 0 &&
                                                                        formData[rowData.mso_company_id].msoCompanyCustId !== 0 &&
                                                                        "#d9534f") ||
                                                                    "#BDBDBD",
                                                            }}
                                                            noMargin
                                                            button
                                                        />
                                                    </IconButton>
                                                ),
                                                important: true,
                                                sizeToContent: true,
                                            },
                                            {
                                                actions: (rowData) => {
                                                    return [
                                                        {
                                                            name: "Manage",
                                                            icon: "chevron-right",
                                                            link: `/ms365/${this.type}/mapping/subscriptions/${rowData.mso_company_id}`,
                                                            disabled: rowData.mso_company_cust_id === 0 ? true : !access.mapping ? true : false,
                                                        },
                                                    ];
                                                },
                                            },
                                        ]}
                                        rows={tenants}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        <ConfirmationDialog
                            open={confirmation.dataSaved}
                            success={() => this.handleConfirmationSuccess("dataSaved")}
                            close={() => this.handleConfirmationClose("dataSaved")}
                            title="Save Microsoft 365 Tenant Mapping?"
                            message="Are you sure you want to save these changes to the Microsoft 365 Tenant Mapping?"
                        />
                        <SnackBar
                            autoHideDuration={2000}
                            variant="success"
                            anchorOriginVertical="bottom"
                            anchorOriginHorizontal="right"
                            open={snackbar.dataSaved}
                            onClose={() => this.handleSnackbarClose("dataSaved")}
                            message="Microsoft 365 Tenant Mapping Saved Successfully"
                        />
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default MS365TenantMapping;
