import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';

const initialState = {
    formData: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
    },
    formErrors: {},
    confirmationOpen: false,
}

class AddStaffForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleSubmit = () => {
        API.post('/staff', this.state.formData)
        .then(result => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                }, () => {
                    this.props.deploySnackBar("success", "The system user was successfully created")
                    this.props.closeDialog()
                })
            }
        })
    }

    handleChange = (event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value
            }
        });
    }

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    }

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };

    render() {
        const { formData, formErrors } = this.state;
        return (
            <React.Fragment>
                <form onChange={this.handleChange} onSubmit={this.handleSubmit} noValidate autoComplete="off">
                    <TextField
                        name="firstName"
                        label="First Name"
                        margin="none" 
                        autoComplete="off"
                        error={formErrors && formErrors['firstName'] && true}
                        helperText={formErrors && formErrors['firstName']}
                        value={formData.firstName || ''}
                        fullWidth
                    />
                    <TextField
                        name="lastName"
                        label="Last Name"
                        margin="none"
                        autoComplete="off"
                        error={formErrors && formErrors['lastName'] && true}
                        helperText={formErrors && formErrors['lastName']}
                        value={formData.lastName || ''}
                        fullWidth
                    />
                    <TextField
                        name="email"
                        label="Email Address"
                        margin="none"
                        autoComplete="off"
                        error={formErrors && formErrors['email'] && true}
                        helperText={formErrors && formErrors['email']}
                        value={formData.email || ''}
                        fullWidth
                    />
                    <TextField
                        type="password"
                        name="password"
                        label="Password"
                        margin="none"
                        autoComplete="off"
                        error={formErrors && formErrors['password'] && true}
                        helperText={formErrors && formErrors['password']}
                        value={formData.password || ''}
                        fullWidth
                    />
                    <TextField
                        type="password"
                        name="confirmPassword"
                        label="Confirm Password"
                        margin="none"
                        autoComplete="off"
                        error={formErrors && formErrors['confirmPassword'] && true}
                        helperText={formErrors && formErrors['confirmPassword']}
                        value={formData.confirmPassword || ''}
                        fullWidth
                    />
                    <div className='buttonRow'>
                        <Grid container spacing={3}>
                            <Grid item xs />
                            <Grid item>
                                <Button onClick={() => this.props.closeDialog()}
                                        variant="outlined"
                                        color="primary"
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                        disabled={Object.values(formData).every(x => (x === null || x === ""))}>
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen} 
                        success={this.handleConfirmationSuccess} 
                        close={this.handleConfirmationClose} 
                        title="Add system user?" 
                        message="Are you sure you want to add this new system user?"
                    />
                </form>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog())
    };
}

export default connect(null, mapDispatchToProps)(AddStaffForm);