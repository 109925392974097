import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import Fade from '@material-ui/core/Fade';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import AdminLayout from 'Components/Layout/AdminLayout/AdminLayout';
import Login from 'Components/Login/Login';
import Theme from 'Components/Common/Theme/Theme';
import { loadNotifications } from 'Redux/Actions/Notifications/Notifications';
import { loggedIn } from 'Functions/AuthFunctions';

const Layout = () => {

    const   dispatch    = useDispatch();

    useEffect(() => {

        const getNotifications = () => {
            dispatch(loadNotifications())
        }

        let load = setTimeout(getNotifications, 500);
        let notifications = setInterval(getNotifications, 5000);

        return () => {
            if(load)
                clearTimeout(load);
            if(notifications)
                clearInterval(notifications);
        }

    }, [dispatch]);

    return (
        <BrowserRouter>
            <CssBaseline />
            <div className='displayFlex'>
                <Switch>
                    <Route 
                        exact 
                        path="/" 
                        render={
                            () => (
                                (loggedIn() && (
                                    <Redirect to="/dashboard" /> 
                                )) || (
                                    <Redirect to="/login" />
                                )
                            )
                        }
                    />
                    <Route 
                        exact 
                        path="/login"
                        render={
                            props => (
                                (loggedIn() && (
                                    <Redirect to="/dashboard" />
                                )) || (
                                    <Login {...props} /> 
                                )
                            )
                        }
                    />
                    <Route 
                        render={
                            props => (
                                (loggedIn() && (
                                    <ThemeProvider theme={Theme}>
                                        <SnackbarProvider 
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            maxSnack={5}
                                            preventDuplicate={false}
                                            TransitionComponent={Fade}
                                        >
                                            <AdminLayout {...props} />
                                        </SnackbarProvider>
                                    </ThemeProvider>
                                )) || (                                            
                                    <Redirect to="/login" />
                                )
                            )
                        }
                    />
                </Switch>
            </div>
        </BrowserRouter>
    )
}

export default Layout;