import React from "react";
import clsx from "clsx";
import moment from "moment";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import ActionBadge from "Components/Common/ActionBadge/ActionBadge";
import API from "API";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import ConfirmationDialog from "Components/Common/Dialogs/ConfirmationDialog";
import DataTable from "Components/Common/DataTables/DataTable";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import InsightChip from "Components/Common/Chips/InsightChip";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import LoadingScreen from "Components/Common/LoadingScreen/LoadingScreen";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import SnackBar from "Components/Common/SnackBars/SnackBar";
import Tile from "Components/Common/Dashboard/Tile";
import { formatValidationErrors } from "../../../../Helpers/ErrorHelper";
import { Table, TableHead, TableCell, TableBody, TableRow } from "@material-ui/core";
import { COLOR_MAIN_MS365, COLOR_TEXT_MS365 } from "ColorSchemes";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import Alert from "Components/Common/Alert/Alert";

const styles = (theme) => ({
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    warning: {
        backgroundColor: "#FB8C00",
        color: "#FFF",
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
            <Box>{children}</Box>
        </Typography>
    );
}

const initialState = {
    access: {
        logs: false,
    },
    activeMappings: {},
    additions: {},
    agreements: {},
    agreementsList: {},
    badge: {
        contactActions: 0,
        subscriptionsWithoutMapping: 0,
    },
    contacts: {},
    contactData: {},
    contactsList: [],
    contactsUnused: [],
    domains: [],
    confirmation: {
        mmSaved: false,
        dataSaved: false,
        formData: false,
        usersSaved: false,
    },
    currentTab: 0,
    dataLoading: true,
    dataSave: false,
    mmSave: false,
    errors: {
        subscriptionsWithoutMapping: [],
    },
    expanded: {},
    formData: {},
    tenant: {},
    initialFormData: {},
    initialUserMapping: {},
    initialUserData: {},
    logs: {},
    pendingTab: 0,
    userData: {},
    userDialog: {
        open: false,
        subscription: {},
    },
    userMapping: {},
    snackbar: {
        mmSaved: false,
        dataSaved: false,
        error: false,
        usersSaved: false,
        toggleContact: false,
    },
    userList: {},
    unassignedContacts: [],
    initialManualMappings: {},
    manualMappings: {},
};

class MS365SubscriptionMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.type = this.props.type;
    }

    componentDidMount = () => {
        this.loadComponentData();
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.type !== this.props.type) {
            this.type = this.props.type;
            this.loadComponentData();
        }
    };

    loadComponentData = () => {
        Promise.all([
            API.get("/ms365/tenants/" + this.props.match.params.id + "/subscriptionMapping", {
                params: {
                    type: this.type,
                },
            }),
            API.access("ms365-logs"),
        ]).then(([result, accessLogs]) => {
            if (result.data.errors) {
                this.props.history.push("/ms365/tenants");
            } else {
                /*
                 * Check tenant billing configuration
                 */
                if ((this.type === "nce" && result.data.tenant.mso_annual === 0) || (this.type === "csp" && result.data.tenant.mso_monthly === 0)) {
                    this.props.deploySnackBar("error", `Sorry, the ${result.data.tenant.mso_company_name} tenant is not enabled for ${this.type.toUpperCase()} billing`);
                    this.props.history.push(`/ms365/${this.type}/mapping/tenants`);
                    return;
                }

                /*
                 * Set Page Title
                 */
                this.props.pageTitle([1, "Microsoft 365", "Subscription Mapping", this.type === "nce" ? "NCE" : "CSP", result.data.tenant.mso_company_name]);

                /*
                 * Subscription Mapping
                 */
                let initialFormData = {};
                _.each(result.data.tenant.subscriptions, (subscription) => {
                    let users = {};
                    _.each(subscription.users, (user) => {
                        users = {
                            ...users,
                            [user.mso_user_id]: {
                                msoUserId: parseInt(user.mso_user_id),
                                msoUserSync: parseInt(user.pivot.msous_sync),
                            },
                        };
                    });
                    if (this.type === "nce") {
                        let additions = {
                            monthly: _.find(subscription.subscription_addition, (el) => el?.pivot?.msosa_month === 0),
                            annual: _.find(subscription.subscription_addition, (el) => el?.pivot?.msosa_month !== 0),
                        };
                        initialFormData = {
                            ...initialFormData,
                            [`${subscription.mso_subscription_id}-monthly`]: {
                                key: `${subscription.mso_subscription_id}-monthly`,
                                type: "monthly",
                                msoSubscriptionId: parseInt(subscription.mso_subscription_id),
                                subscriptionUsers: users,
                                cwAdditionId: parseInt(additions?.monthly?.cwaa_id ?? 0),
                                suppAdd: parseInt(additions?.monthly?.pivot?.msosa_supp_cwaa_id ?? 0),
                                suppMin: additions?.monthly?.pivot?.msosa_supp_min_qty ?? 0,
                                expiry: additions?.monthly?.pivot?.msosa_expiry === 0 ? "" : additions?.monthly?.pivot?.msosa_expiry ?? "",
                                month: additions?.monthly?.pivot?.msosa_month ?? 0,
                                renewal: additions?.monthly?.pivot?.msosa_renewal === 0 ? "" : additions?.monthly?.pivot?.msosa_renewal ?? "",
                                enabled: additions?.monthly?.pivot?.msosa_enabled ?? "",
                                active: additions?.monthly?.pivot?.msosa_active ?? "",
                                inactive: additions?.monthly?.pivot?.msosa_inactive ?? "",
                            },
                            [`${subscription.mso_subscription_id}-annual`]: {
                                key: `${subscription.mso_subscription_id}-annual`,
                                type: "annual",
                                msoSubscriptionId: parseInt(subscription.mso_subscription_id),
                                subscriptionUsers: users,
                                cwAdditionId: parseInt(additions?.annual?.cwaa_id ?? 0),
                                suppAdd: parseInt(additions?.annual?.pivot?.msosa_supp_cwaa_id ?? 0),
                                suppMin: additions?.annual?.pivot?.msosa_supp_min_qty ?? 0,
                                expiry: additions?.annual?.pivot?.msosa_expiry === 0 ? "" : additions?.annual?.pivot?.msosa_expiry ?? "",
                                month: additions?.annual?.pivot?.msosa_month === -1 ? "" : additions?.annual?.pivot?.msosa_month ?? "",
                                renewal: additions?.annual?.pivot?.msosa_renewal === 0 ? "" : additions?.annual?.pivot?.msosa_renewal ?? "",
                                enabled: additions?.annual?.pivot?.msosa_enabled ?? "",
                                active: additions?.annual?.pivot?.msosa_active ?? "",
                                inactive: additions?.annual?.pivot?.msosa_inactive ?? "",
                            },
                        };
                    } else {
                        initialFormData = {
                            ...initialFormData,
                            [subscription.mso_subscription_id]: {
                                key: subscription.mso_subscription_id,
                                msoSubscriptionId: parseInt(subscription.mso_subscription_id),
                                subscriptionUsers: users,
                                cwAdditionId: parseInt(subscription.subscription_addition.length > 0 ? subscription.subscription_addition[0].cwaa_id : 0),
                                suppAdd: parseInt(additions?.monthly?.pivot?.msosa_supp_cwaa_id ?? 0),
                                suppMin: additions?.monthly?.pivot?.msosa_supp_min ?? 0,
                                expiry: subscription.subscription_addition.length > 0 ? subscription.subscription_addition[0]?.pivot?.msosa_expiry : "",
                                month: subscription.subscription_addition.length > 0 ? subscription.subscription_addition[0]?.pivot?.msosa_month : "",
                                renewal: subscription.subscription_addition.length > 0 ? subscription.subscription_addition[0]?.pivot?.msosa_renewal : "",
                                enabled: subscription.subscription_addition.length > 0 ? subscription.subscription_addition[0]?.pivot?.msosa_enabled : "",
                                active: subscription.subscription_addition.length > 0 ? subscription.subscription_addition[0]?.pivot?.msosa_active : "",
                                inactive: subscription.subscription_addition.length > 0 ? subscription.subscription_addition[0]?.pivot?.msosa_inactive : "",
                            },
                        };
                    }
                });

                /*
                 * Manual Mappings
                 */
                let initialManualMappings = {};
                _.each(result.data.tenant.manual_mappings, (mm) => {
                    initialManualMappings = {
                        ...initialManualMappings,
                        [mm.mm_id]: {
                            mmId: parseInt(mm.mm_id),
                            mmName: mm.mm_name,
                            mmAdditionId: parseInt(mm.agreement_addition ? mm.agreement_addition.cwaa_id : 0),
                            mmUnits: mm.mm_units,
                            mmNew: false,
                            mmDel: false,
                        },
                    };
                });

                let assignedAdditions = [];

                _.each(initialFormData, (subscription) => {
                    if (subscription.cwAdditionId !== 0) assignedAdditions.push(subscription.cwAdditionId);
                });

                _.each(initialManualMappings, (manualMapping) => {
                    if (manualMapping.mmAdditionId !== 0) assignedAdditions.push(manualMapping.mmAdditionId);
                });

                let agreementsList = _.map(result.data.tenant.customer.agreements, (el) => {
                    return _.assign({
                        label:
                            el.cwa_parent_id === 0
                                ? el.cwa_cw_agreement_name
                                : `${
                                      _.find(result.data.tenant.customer.agreements, (parent) => {
                                          return parent.cwa_id === el.cwa_parent_id;
                                      }).cwa_cw_agreement_name
                                  } ⟶ ${el.cwa_cw_agreement_name}`,
                        options: _.map(el.additions, (_el) => {
                            return _.assign({
                                value: _el.cwaa_id,
                                label: _el.cwaa_cw_product_identifier,
                                disabled: assignedAdditions.includes(_el.cwaa_id) ? true : false,
                            });
                        }),
                    });
                });

                let allAdditions = _.map(result.data.tenant.customer.agreements, (el) => {
                    return _.map(el.additions, (_el) => {
                        return _el;
                    });
                });

                let combinedAllAdditions = Array.prototype.concat(...allAdditions);

                let additions = {};

                for (var i = 0, l = combinedAllAdditions.length; i < l; i++) {
                    var obj = combinedAllAdditions[i];
                    additions[obj.cwaa_id] = obj;
                    additions[obj.cwaa_id].cwaa_cw_data = JSON.parse(obj.cwaa_cw_data);
                }

                let activeMappings = _.filter(initialFormData, ({ cwAdditionId }) => {
                    return cwAdditionId > 0;
                });

                /*
                 * Contact Mapping
                 */
                let contacts = result.data.tenant.customer.contacts;
                let assignedContacts = [];
                let assignedUsers = [];
                let unassignedContacts = [];
                let unassignedUsers = [];
                let initialUserMapping = {};
                _.each(result.data.tenant.users, (user) => {
                    initialUserMapping = {
                        ...initialUserMapping,
                        [user.mso_user_id]: {
                            msoUserId: parseInt(user.mso_user_id),
                            msoUserDomainId: parseInt(user.mso_user_domain_id),
                            msoUserName: user.mso_user_name,
                            msoUserEmail: user.mso_user_email,
                            ccId: user.mso_user_cc_id === 0 ? (user.mso_user_cc_action === "Create" ? "create" : "ignore") : user.mso_user_cc_id,
                            domainDisabled: user.domain?.mso_domain_enabled ? false : true,
                        },
                    };
                    if (user.mso_user_cc_id !== 0) {
                        assignedContacts.push(user.mso_user_cc_id);
                        assignedUsers.push(user.mso_user_id);
                    } else {
                        unassignedUsers.push(user);
                    }
                });
                _.each(result.data.tenant.customer.contacts, (contact, idx) => {
                    if (!assignedContacts.includes(contact.cc_id) || contact.cc_sync_action === "Deactivate") {
                        unassignedContacts.push({
                            ...contact,
                            disabled: contact.cc_sync_action === "Deactivate",
                        });
                    }
                });

                let contactsList = [
                    {
                        label: "BlueSync Actions",
                        options: [
                            {
                                value: "create",
                                label: "Create ConnectWise Contact",
                            },
                            {
                                value: "ignore",
                                label: "Ignore",
                            },
                        ],
                    },
                    {
                        label: "ConnectWise Contacts",
                        options: _.map(result.data.tenant.customer.contacts, (el) => {
                            return _.assign({
                                value: el.cc_id,
                                label: `${(el.cc_first_name && el.cc_first_name !== null && `${el.cc_first_name} `) || ""}${
                                    (el.cc_last_name && el.cc_last_name !== null && `${el.cc_last_name} `) || ""
                                }${(el.cc_email && el.cc_email.length > 0 && `(${el.cc_email})`) || ""}`,
                                disabled: assignedContacts.includes(el.cc_id) ? true : false,
                            });
                        }),
                    },
                ];

                /*
                 * Domains
                 */
                let domains = [];
                _.each(result.data.tenant?.domains, (domain) => {
                    domains = {
                        ...domains,
                        [domain.mso_domain_id]: {
                            msoDomainId: parseInt(domain.mso_domain_id),
                            msoDomainValue: domain.mso_domain_value,
                            msoDomainEnabled: parseInt(domain.mso_domain_enabled),
                        },
                    };
                });

                let userList = _.map(unassignedUsers, (el) => {
                    return _.assign({
                        value: el.mso_user_id,
                        label: `${(el.mso_user_name && el.mso_user_name !== null && `${el.mso_user_name} `) || ""}${
                            (el.mso_user_email && el.mso_user_email !== "false" && `(${el.mso_user_email})`) || ""
                        }`,
                        disabled: assignedUsers.includes(el.mso_user_id) ? true : false,
                        domainEnabled: _.find(result.data.tenant?.domains, (_domain) => el.mso_user_domain_id === _domain.mso_domain_id)?.mso_domain_enabled ?? 0,
                    });
                });
                userList = userList.filter((user) => user.domainEnabled === 1);

                let initialUserData = [];
                _.each(unassignedContacts, (contact) => {
                    initialUserData.push({
                        ccId: contact.cc_id,
                        action: contact.cc_sync_action,
                    });
                });

                /*
                 * Mapping Overview > Manage Expansion State
                 */
                let initialExpanded = {};
                for (let i = 0, j = activeMappings.length; i < j; i++) {
                    initialExpanded = {
                        ...initialExpanded,
                        [i]: false,
                    };
                }

                /*
                 * User Subscriptions > Manage Expansion State
                 */
                let userSubscriptions = {};
                for (let i = 0, j = result.data.tenant.users.length; i < j; i++) {
                    userSubscriptions = {
                        ...userSubscriptions,
                        [i]: false,
                    };
                }

                /*
                 * Set State
                 */
                this.setState(
                    {
                        ...this.state,
                        access: {
                            logs: (accessLogs.data && accessLogs.data.has_access) || false,
                        },
                        activeMappings: activeMappings,
                        additions: additions,
                        agreements: result.data.tenant.customer.agreements,
                        agreementsList: agreementsList,
                        badge: {
                            contactActions: result.data.tenant.contact_actions_count,
                            subscriptionsWithoutMapping: result.data.tenant.subscription_without_additions_actions_count,
                        },
                        contacts: contacts,
                        contactsList: contactsList,
                        domains: domains,
                        dataLoading: false,
                        errors: {
                            // subscriptionsWithoutMapping: result.data.tenant.subscription_without_additions_actions
                        },
                        expanded: initialExpanded,
                        formData: initialFormData,
                        tenant: result.data.tenant,
                        initialFormData,
                        initialUserData,
                        initialUserMapping,
                        userData: initialUserData,
                        userMapping: initialUserMapping,
                        unassignedContacts: unassignedContacts,
                        userSubscriptions,
                        userList: userList,
                        initialManualMappings,
                        manualMappings: initialManualMappings,
                    },
                    () => {
                        /*
                         * Get MS365 Logs (subject to access)
                         */
                        if (this.state.access.logs) {
                            API.get("/ms365/tenants/" + this.props.match.params.id + "/logs").then((result) => {
                                if (result.data) {
                                    this.setState({
                                        logs: result.data,
                                    });
                                }
                            });
                        }
                        /*
                         * Sort additions (for default disabled due to CSP / NCE opposite mappings))
                         */
                        this.sortAgreementsList();
                    }
                );
            }
        });
    };

    handleAdditionSelection = (subscriptionKey) => (selectedAddition) => {
        const cwAdditionId = selectedAddition === null ? 0 : selectedAddition.value;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [subscriptionKey]: {
                        ...this.state.formData[subscriptionKey],
                        cwAdditionId: parseInt(cwAdditionId),
                    },
                },
            },
            () => {
                this.sortAgreementsList();
            }
        );
    };

    handleSupportAdditionSelection = (subscriptionKey) => (selectedAddition) => {
        const suppAdd = selectedAddition === null ? 0 : selectedAddition.value;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [subscriptionKey]: {
                        ...this.state.formData[subscriptionKey],
                        suppAdd: parseInt(suppAdd),
                    },
                },
            },
            () => {
                this.sortAgreementsList();
            }
        );
    };

    handleDomainToggle = (id) => {
        API.put(`/ms365/tenants/${this.props.match.params.id}/domains/${id}/toggle`).then((res) => {
            if (res?.data?.success) {
                this.loadComponentData();
                this.props?.deploySnackBar(`success`, `The domain status has been updated successfully`);
            }
        });
    };

    handleSubscriptionChange = (subscriptionKey, name, nv) => {
        let value = nv;

        const dayFields = ["expiry", "renewal"];
        const monthFields = ["month"];

        if (dayFields.includes(name)) {
            if (isNaN(value) || value < 0 || value > 31) {
                value = "";
            }
        }

        if (monthFields.includes(name)) {
            if (isNaN(value) || value < 0 || value > 12) {
                value = "";
            }
        }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [subscriptionKey]: {
                        ...this.state.formData[subscriptionKey],
                        [name]: value,
                    },
                },
            },
            () => {
                if (name === "expiry" && value !== "") {
                    let renewal = parseInt(value) + 1;
                    if (renewal > 31) {
                        renewal = 1;
                    }
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            [subscriptionKey]: {
                                ...this.state.formData[subscriptionKey],
                                renewal,
                            },
                        },
                    });
                }
            }
        );
    };

    handleConfirmationOpen = (type) => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: true,
            },
        });
    };

    handleConfirmationClose = (type) => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false,
            },
            pendingTab: type === "formData" ? 0 : this.state.pendingTab,
        });
    };

    handleConfirmationSuccess = (type) => {
        this.setState(
            {
                confirmation: {
                    ...this.state.confirmation,
                    [type]: false,
                },
            },
            () => {
                if (type === "formData") {
                    this.handleDiscardFormData();
                } else if (type === "usersSaved") {
                    this.handleUserMapping();
                } else if (type === "mmSaved") {
                    this.handleManualMappingSubmit();
                } else {
                    this.handleSubmit();
                }
            }
        );
    };

    handleContactSelection = (idType, id) => (selectedOption) => {
        const msoUserId = idType === "msoUserId" ? id : selectedOption === null ? "None" : selectedOption.value;
        const ccId = idType === "ccId" ? id : selectedOption === null ? "ignore" : selectedOption.value;

        const currCcId = msoUserId === "None" ? false : msoUserId === "Deactivate" ? false : msoUserId === "Activate" ? false : this.state.userMapping[msoUserId].ccId;

        if (currCcId) {
            this.setState(
                {
                    userMapping: {
                        ...this.state.userMapping,
                        [msoUserId]: {
                            ...this.state.userMapping[msoUserId],
                            ccId: ccId,
                        },
                    },
                },
                () => {
                    let userData = [...this.state.userData];

                    /* Remove old contact value from userData (if required) */
                    if (ccId !== "ignore" && ccId !== "create") {
                        let idx = _.findIndex(userData, function (el) {
                            return el.ccId === ccId;
                        });
                        if (idx !== -1) {
                            userData.splice(idx, 1);
                        }
                    }

                    let assignedContacts = [];
                    let assignedUsers = [];
                    let unassignedContacts = [];
                    let unassignedUsers = [];

                    _.each(this.state.userMapping, (user) => {
                        if (user.ccId !== "ignore" && user.ccId !== "create") {
                            assignedContacts.push(user.ccId);
                            assignedUsers.push(user.ccId);
                        } else {
                            unassignedUsers.push(user);
                        }
                    });

                    _.each(this.state.contacts, (contact) => {
                        if (!assignedContacts.includes(contact.cc_id)) {
                            unassignedContacts.push(contact);
                        }
                    });

                    /* Add old contact value to userData (if required) */
                    if (currCcId !== "ignore" && currCcId !== "create") {
                        let idx = _.findIndex(unassignedContacts, function (el) {
                            return el.cc_id === currCcId;
                        });
                        if (idx !== -1) {
                            const contact = unassignedContacts[idx];
                            userData.push({
                                ccId: contact.cc_id,
                                action: contact.cc_sync_action,
                            });
                        }
                    }

                    let contactsList = [
                        {
                            label: "BlueSync Actions",
                            options: [
                                {
                                    value: "create",
                                    label: "Create ConnectWise Contact",
                                },
                                {
                                    value: "ignore",
                                    label: "Ignore",
                                },
                            ],
                        },
                        {
                            label: "ConnectWise Contacts",
                            options: _.map(this.state.contacts, (el) => {
                                return _.assign({
                                    value: el.cc_id,
                                    label: `${(el.cc_first_name && el.cc_first_name !== null && `${el.cc_first_name} `) || ""}${
                                        (el.cc_last_name && el.cc_last_name !== null && `${el.cc_last_name} `) || ""
                                    }${(el.cc_email && el.cc_email.length > 0 && `(${el.cc_email})`) || ""}`,
                                    disabled: assignedContacts.includes(el.cc_id) ? true : false,
                                });
                            }),
                        },
                    ];

                    let userList = _.map(unassignedUsers, (el) => {
                        return _.assign({
                            value: el.msoUserId,
                            label: `${(el.msoUserName && el.msoUserName !== null && `${el.msoUserName} `) || ""}${
                                (el.msoUserEmail && el.msoUserEmail !== "false" && `(${el.msoUserEmail})`) || ""
                            }`,
                            disabled: assignedUsers.includes(el.msoUserId) ? true : false,
                            domainEnabled: _.find(this.state.domains, (_el) => el.msoUserDomainId === _el.msoDomainId)?.msoDomainEnabled ?? 0,
                        });
                    });
                    userList = userList.filter((user) => user.domainEnabled === 1);

                    this.setState({
                        contactsList: contactsList,
                        unassignedContacts: unassignedContacts,
                        userData: userData,
                        userList: userList,
                    });
                }
            );
        } else {
            let userData = [...this.state.userData];
            let idx = _.findIndex(userData, function (el) {
                return el.ccId === ccId;
            });
            if (idx !== -1) {
                userData[idx].action = msoUserId;
            }
            this.setState({
                userData: userData,
            });
        }
    };

    handleDiscardFormData = () => {
        this.setState(
            {
                formData: this.state.initialFormData,
            },
            () => this.handleTabChange(false, this.state.pendingTab)
        );
    };

    handleExpandActiveMapping = (idx) => {
        this.setState({
            expanded: {
                ...this.state.expanded,
                [idx]: !this.state.expanded[idx],
            },
        });
    };

    handleExpandUserSubscription = (idx) => {
        this.setState({
            userSubscriptions: {
                ...this.state.userSubscriptions,
                [idx]: !this.state.userSubscriptions[idx],
            },
        });
    };

    handleUserDialogClose = () => {
        this.setState({
            userDialog: {
                ...this.state.userDialog,
                open: false,
            },
        });
    };

    handleUserDialogOpen = (msoSubscriptionId) => {
        let subscription = this.state.tenant.subscriptions.filter((obj) => {
            return obj.mso_subscription_id === msoSubscriptionId;
        });
        this.setState({
            userDialog: {
                open: true,
                subscription: subscription[0],
            },
        });
    };

    handleUserMapping = () => {
        this.setState(
            {
                initialUserMapping: this.state.userMapping,
            },
            () => {
                API.put("/ms365/tenants/" + this.props.match.params.id + "/contactMapping", {
                    data: this.state.userMapping,
                    userData: this.state.userData,
                    company: this.state.tenant.mso_company_id,
                }).then((result) => {
                    if (result.data.errors) {
                        this.setState({
                            formErrors: formatValidationErrors(result.data.errors),
                        });
                    } else {
                        this.setState(
                            {
                                dataLoading: true,
                                snackbar: {
                                    usersSaved: true,
                                },
                            },
                            () => {
                                this.props.scrollToTop();
                                this.loadComponentData();
                            }
                        );
                    }
                });
            }
        );
    };

    handleReset = () => {
        this.setState(
            {
                dataLoading: true,
            },
            () => this.loadComponentData()
        );
    };

    handleSnackbarClose = (type) => {
        this.setState({
            snackbar: {
                ...this.state.snackbar,
                [type]: false,
            },
        });
    };

    handleSubmit = () => {
        this.setState(
            {
                dataSave: true,
                initialFormData: this.state.formData,
                type: this.type,
            },
            () => {
                API.put("/ms365/tenants/" + this.props.match.params.id + "/subscriptionMapping", {
                    data: this.state.formData,
                    type: this.type,
                }).then((result) => {
                    if (result.data.errors) {
                        this.setState({
                            dataSave: false,
                            formErrors: formatValidationErrors(result.data.errors),
                        });
                    } else {
                        this.setState(
                            {
                                dataLoading: true,
                                dataSave: false,
                                snackbar: {
                                    dataSaved: true,
                                },
                            },
                            () => {
                                this.props.scrollToTop();
                                this.loadComponentData();
                            }
                        );
                    }
                });
            }
        );
    };

    // handleSyncToggle = (msoSubscriptionId, msoUserId) => {
    //     this.setState({
    //         formData: {
    //             ...this.state.formData,
    //             [msoSubscriptionId]: {
    //                 ...this.state.formData[msoSubscriptionId],
    //                 subscriptionUsers: {
    //                     ...this.state.formData[msoSubscriptionId].subscriptionUsers,
    //                     [msoUserId]: {
    //                         ...this.state.formData[msoSubscriptionId].subscriptionUsers[msoUserId],
    //                         msoUserSync:
    //                             this.state.formData[msoSubscriptionId].subscriptionUsers[msoUserId].msoUserSync === 1
    //                                 ? 0
    //                                 : 1,
    //                     },
    //                 },
    //             },
    //         },
    //     });
    // };

    handleTabChange = (event, tabIndex) => {
        if (this.state.pendingTab === 0) {
            if (
                this.state.currentTab === 1 &&
                tabIndex !== 1 &&
                this.state.initialFormData !==
                    this.state.formData /* || (this.state.currentTab === 2 && tabIndex !== 2 && this.state.initialUserMapping !== this.state.userMapping)*/
            ) {
                this.setState({
                    confirmation: {
                        ...this.state.confirmation,
                        formData: true,
                    },
                    pendingTab: tabIndex,
                });
                return;
            }
        }
        this.setState({
            currentTab: tabIndex,
            pendingTab: 0,
        });
    };

    importLogs = () => {
        const { logs } = this.state;
        return (
            <Grid item xs={12}>
                <DataTable
                    config={{
                        key: "mso_log_id",
                        pagination: true,
                        rowsPerPage: 7,
                        noHeader: true,
                        noResultsText: "No Microsoft 365 Logs Exist",
                        title: "Microsoft 365 Import Log",
                    }}
                    columns={[
                        {
                            field: (rowData) => (
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item />
                                    <Grid item align="center">
                                        <FAIcon
                                            type="thin"
                                            size={30}
                                            icon={
                                                rowData.mso_log_type === "Success"
                                                    ? "check-circle"
                                                    : rowData.mso_log_type === "Error"
                                                    ? "times-circle"
                                                    : rowData.mso_log_type === "Warning"
                                                    ? "exclamation-triangle"
                                                    : rowData.mso_log_type === "Notice"
                                                    ? "info-circle"
                                                    : "flag-alt"
                                            }
                                            style={{
                                                color:
                                                    rowData.mso_log_type === "Success"
                                                        ? "#388E3C"
                                                        : rowData.mso_log_type === "Error"
                                                        ? "#d9534f"
                                                        : rowData.mso_log_type === "Warning"
                                                        ? "#EF6C00"
                                                        : rowData.mso_log_type === "Notice"
                                                        ? "#6A1B9A"
                                                        : "#0288D1",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2" className="fw-400" component="div">
                                            <span
                                                style={{
                                                    color:
                                                        rowData.mso_log_type === "Success"
                                                            ? "#388E3C"
                                                            : rowData.mso_log_type === "Error"
                                                            ? "#d9534f"
                                                            : rowData.mso_log_type === "Warning"
                                                            ? "#EF6C00"
                                                            : rowData.mso_log_type === "Notice"
                                                            ? "#6A1B9A"
                                                            : "#0288D1",
                                                }}
                                            >
                                                {rowData.mso_log_title.split(":")[0]}
                                                {rowData.mso_log_title.split(":").length > 1 && ":"}
                                            </span>
                                            {rowData.mso_log_title.split(":")[1]}
                                        </Typography>
                                        {!_.isEmpty(rowData.mso_log_text) && (
                                            <Typography variant="caption" component="div">
                                                {rowData.mso_log_text}
                                            </Typography>
                                        )}
                                        <Typography variant="caption" color="textSecondary">
                                            {moment(rowData.mso_log_created_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ),
                        },
                    ]}
                    rows={logs}
                />
            </Grid>
        );
    };

    mappingOverview = () => {
        const { classes } = this.props;
        const { manualMappings, activeMappings, additions, expanded } = this.state;
        let overallTotalCost = [];
        let overallTotalPrice = [];
        return (
            <Grid container spacing={3} direction="column-reverse">
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                            <PaddedPaper>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs>
                                        <Typography variant={"h6"}>Users</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {(_.size(this.state.tenant.users) > 0 &&
                                            _.map(this.state.tenant.users, (user, idx) => {
                                                return (
                                                    <Typography variant="caption" key={idx}>
                                                        <Box pt={0.25} pb={0.25}>
                                                            > {user.mso_user_name}{" "}
                                                            {user.mso_user_type === "Admin" && (
                                                                <FAIcon type="solid" icon="user-crown" size="small" style={{ color: "#FFD700" }} noMargin />
                                                            )}
                                                        </Box>
                                                    </Typography>
                                                );
                                            })) || (
                                            <Typography variant={"body2"} gutterBottom>
                                                <FAIcon icon="times-circle" size="small" color="error" />
                                                No users imported
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <PaddedPaper>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs>
                                        <Typography variant={"h6"}>Subscriptions</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {(_.size(this.state.tenant.subscriptions) > 0 &&
                                            _.map(this.state.tenant.subscriptions, (subscription, idx) => {
                                                return (
                                                    <Typography variant="caption" key={idx}>
                                                        <Box pt={0.25} pb={0.25}>
                                                            > {subscription.subscription_type.msost_name}
                                                        </Box>
                                                    </Typography>
                                                );
                                            })) || (
                                            <Typography variant={"body2"} gutterBottom>
                                                <FAIcon icon="times-circle" size="small" color="error" />
                                                No subscriptions imported
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>
                                                {(_.size(activeMappings) > 0 &&
                                                    _.map(activeMappings, (mapping, idx) => {
                                                        let subscription = _.filter(this.state.tenant.subscriptions, ({ mso_subscription_id }) => {
                                                            return mso_subscription_id === mapping.msoSubscriptionId;
                                                        })[0];
                                                        let addition = additions[mapping.cwAdditionId];
                                                        let usersActive = _.orderBy(
                                                            _.map(
                                                                _.filter(mapping.subscriptionUsers, ({ msoUserSync }) => {
                                                                    return msoUserSync === 1;
                                                                }),
                                                                (user) => {
                                                                    return _.filter(subscription.users, ({ mso_user_id }) => {
                                                                        return mso_user_id === user.msoUserId;
                                                                    })[0];
                                                                }
                                                            ),
                                                            "mso_user_name",
                                                            "asc"
                                                        );
                                                        let usersInactive = _.orderBy(
                                                            _.map(
                                                                _.filter(mapping.subscriptionUsers, ({ msoUserSync }) => {
                                                                    return msoUserSync === 0;
                                                                }),
                                                                (user) => {
                                                                    return _.filter(subscription.users, ({ mso_user_id }) => {
                                                                        return mso_user_id === user.msoUserId;
                                                                    })[0];
                                                                }
                                                            ),
                                                            "mso_user_name",
                                                            "asc"
                                                        );
                                                        let totalCost = parseFloat(addition.cwaa_cw_data.unitCost * mapping.enabled);
                                                        if (isNaN(totalCost)) totalCost = 0;
                                                        let totalPrice = parseFloat(addition.cwaa_cw_data.unitPrice * mapping.enabled);
                                                        if (isNaN(totalPrice)) totalPrice = 0;
                                                        overallTotalCost.push(parseFloat(totalCost.toFixed(2)));
                                                        overallTotalPrice.push(parseFloat(totalPrice.toFixed(2)));
                                                        return (
                                                            <Grid item xs={12} key={idx}>
                                                                <Paper>
                                                                    <CardHeader
                                                                        subheader={
                                                                            <Typography variant={"caption"} gutterBottom>
                                                                                {addition.cwaa_cw_product_identifier} | Quantity: {mapping.enabled} | Unit Cost: £
                                                                                {(addition.cwaa_cw_data.unitCost && addition.cwaa_cw_data.unitCost.toFixed(2)) || 0} | Unit
                                                                                Price: £{(addition.cwaa_cw_data.unitPrice && addition.cwaa_cw_data.unitPrice.toFixed(2)) || 0}{" "}
                                                                                | Total Price: £{(totalPrice && totalPrice.toFixed(2)) || 0}
                                                                            </Typography>
                                                                        }
                                                                        title={<Typography variant={"h6"}>{subscription.subscription_type.msost_name}</Typography>}
                                                                        action={
                                                                            <IconButton
                                                                                className={clsx(classes.expand, {
                                                                                    [classes.expandOpen]: expanded[idx],
                                                                                })}
                                                                                onClick={() => this.handleExpandActiveMapping(idx)}
                                                                            >
                                                                                <FAIcon icon="chevron-down" button noMargin />
                                                                            </IconButton>
                                                                        }
                                                                        disableTypography
                                                                    />
                                                                    <Collapse in={expanded[idx]} timeout="auto" unmountOnExit>
                                                                        <CardContent style={{ paddingTop: 0 }}>
                                                                            {(subscription.mso_subscription_enabled_count + usersInactive.length > 0 && (
                                                                                <React.Fragment>
                                                                                    <Table size="small">
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                <TableCell>
                                                                                                    <Typography
                                                                                                        variant="body2"
                                                                                                        color="primary"
                                                                                                        className="fw-400"
                                                                                                        gutterBottom
                                                                                                    >
                                                                                                        Active Users
                                                                                                    </Typography>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {_.map(usersActive, (user, idx) => {
                                                                                                return (
                                                                                                    <TableRow key={idx}>
                                                                                                        <TableCell>
                                                                                                            <Typography variant="caption">
                                                                                                                <Box pt={0.5} pb={0.5}>
                                                                                                                    > {user.mso_user_name}{" "}
                                                                                                                    {user.mso_user_type === "Admin" && (
                                                                                                                        <FAIcon
                                                                                                                            type="solid"
                                                                                                                            icon="user-crown"
                                                                                                                            size="small"
                                                                                                                            style={{
                                                                                                                                color: "#FFD700",
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    )}
                                                                                                                </Box>
                                                                                                            </Typography>
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                );
                                                                                            })}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                    {usersInactive.length > 0 && (
                                                                                        <React.Fragment>
                                                                                            <br />
                                                                                            <Table size="small">
                                                                                                <TableHead>
                                                                                                    <TableRow>
                                                                                                        <TableCell>
                                                                                                            <Typography
                                                                                                                variant="body2"
                                                                                                                color="primary"
                                                                                                                className="fw-400"
                                                                                                                gutterBottom
                                                                                                            >
                                                                                                                Inactive Users
                                                                                                            </Typography>
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                </TableHead>
                                                                                                <TableBody>
                                                                                                    {_.map(usersInactive, (user, idx) => {
                                                                                                        return (
                                                                                                            <TableRow key={idx}>
                                                                                                                <TableCell
                                                                                                                    style={{
                                                                                                                        width: "1%",
                                                                                                                        whiteSpace: "nowrap",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Typography variant="caption">
                                                                                                                        <Box pt={0.5} pb={0.5}>
                                                                                                                            > {user.mso_user_name}{" "}
                                                                                                                            {user.mso_user_type === "Admin" && (
                                                                                                                                <FAIcon
                                                                                                                                    type="solid"
                                                                                                                                    icon="user-crown"
                                                                                                                                    size="small"
                                                                                                                                    style={{
                                                                                                                                        color: "#FFD700",
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            )}
                                                                                                                        </Box>
                                                                                                                    </Typography>
                                                                                                                </TableCell>
                                                                                                            </TableRow>
                                                                                                        );
                                                                                                    })}
                                                                                                </TableBody>
                                                                                            </Table>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )) || (
                                                                                <Typography variant="caption">
                                                                                    <IconButton disabled>
                                                                                        <FAIcon icon="exclamation-circle" />
                                                                                    </IconButton>
                                                                                    This subscription has no users
                                                                                </Typography>
                                                                            )}
                                                                        </CardContent>
                                                                    </Collapse>
                                                                </Paper>
                                                            </Grid>
                                                        );
                                                    })) || (
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant={"body2"}>
                                                                <FAIcon icon="times-circle" size="small" color="error" />
                                                                No subscriptions are mapped within the {this.state.tenant.mso_company_name}
                                                            </Typography>
                                                        </PaddedPaper>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>
                                                {(_.size(manualMappings) > 0 &&
                                                    _.map(manualMappings, (mapping, idx) => {
                                                        if (!mapping.mmAdditionId || parseInt(mapping.mmAdditionId) < 1) {
                                                            return (
                                                                <Grid item xs={12} key={idx}>
                                                                    <Paper>
                                                                        <CardHeader
                                                                            subheader={
                                                                                <Typography variant={"caption"} gutterBottom>
                                                                                    Unmapped
                                                                                </Typography>
                                                                            }
                                                                            title={<Typography variant={"h6"}>{mapping.mmName}</Typography>}
                                                                            disableTypography
                                                                        />
                                                                    </Paper>
                                                                </Grid>
                                                            );
                                                        } else {
                                                            let addition = additions[mapping.mmAdditionId];
                                                            let totalCost = parseFloat(addition.cwaa_cw_data.unitCost * mapping.mmUnits);
                                                            if (isNaN(totalCost)) totalCost = 0;
                                                            let totalPrice = parseFloat(addition.cwaa_cw_data.unitPrice * mapping.mmUnits);
                                                            if (isNaN(totalPrice)) totalPrice = 0;
                                                            overallTotalCost.push(parseFloat(totalCost.toFixed(2)));
                                                            overallTotalPrice.push(parseFloat(totalPrice.toFixed(2)));
                                                            return (
                                                                <Grid item xs={12} key={idx}>
                                                                    <Paper>
                                                                        <CardHeader
                                                                            subheader={
                                                                                <Typography variant={"caption"} gutterBottom>
                                                                                    {addition.cwaa_cw_product_identifier} | Quantity: {mapping.mmUnits} | Unit Cost: £
                                                                                    {(addition.cwaa_cw_data.unitCost && addition.cwaa_cw_data.unitCost.toFixed(2)) || 0} | Unit
                                                                                    Price: £
                                                                                    {(addition.cwaa_cw_data.unitPrice && addition.cwaa_cw_data.unitPrice.toFixed(2)) || 0} |
                                                                                    Total Price: £{(totalPrice && totalPrice.toFixed(2)) || 0}
                                                                                </Typography>
                                                                            }
                                                                            title={<Typography variant={"h6"}>{mapping.mmName}</Typography>}
                                                                            disableTypography
                                                                        />
                                                                    </Paper>
                                                                </Grid>
                                                            );
                                                        }
                                                    })) || (
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant={"body2"}>
                                                                <FAIcon icon="times-circle" size="small" color="error" />
                                                                There are no Manual Mappings for {this.state.tenant.mso_company_name}
                                                            </Typography>
                                                        </PaddedPaper>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {_.size(activeMappings) > 0 && (
                    <Grid item xs={12} style={{ maxHeight: 135, paddingBottom: 0 }}>
                        <Grid container spacing={3}>
                            <Tile
                                grid={3}
                                icon="coins"
                                backgroundColor={COLOR_TEXT_MS365}
                                data={`£${overallTotalCost.reduce((a, b) => a + b, 0).toFixed(2)}`}
                                title="Service Cost"
                            />
                            <Tile
                                grid={3}
                                icon="file-invoice-dollar"
                                backgroundColor={COLOR_TEXT_MS365}
                                data={`£${overallTotalPrice.reduce((a, b) => a + b, 0).toFixed(2)}`}
                                title="Service Turnover"
                            />
                            <Tile
                                grid={3}
                                icon="sack-dollar"
                                backgroundColor={COLOR_TEXT_MS365}
                                data={`£${(overallTotalPrice.reduce((a, b) => a + b, 0).toFixed(2) - overallTotalCost.reduce((a, b) => a + b, 0).toFixed(2)).toFixed(2)}`}
                                title="Service Profit"
                            />
                            <Tile
                                grid={3}
                                icon="chart-line"
                                backgroundColor={COLOR_TEXT_MS365}
                                data={`${
                                    isNaN(
                                        (
                                            ((overallTotalPrice.reduce((a, b) => a + b, 0).toFixed(2) - overallTotalCost.reduce((a, b) => a + b, 0).toFixed(2)) /
                                                overallTotalPrice.reduce((a, b) => a + b, 0).toFixed(2)) *
                                            100
                                        ).toFixed(2)
                                    )
                                        ? 0
                                        : (
                                              ((overallTotalPrice.reduce((a, b) => a + b, 0).toFixed(2) - overallTotalCost.reduce((a, b) => a + b, 0).toFixed(2)) /
                                                  overallTotalPrice.reduce((a, b) => a + b, 0).toFixed(2)) *
                                              100
                                          ).toFixed(2)
                                }%`}
                                title="Service Margin"
                            />
                        </Grid>
                    </Grid>
                )}
                {(this.state.tenant.mso_annual === 1 && this.state.tenant.mso_monthly === 1 && (
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            <Typography variant="body2">
                                <strong>This tenant is enabled for CSP and NCE subscription billing</strong>
                            </Typography>
                            <Typography variant="caption">
                                The financials below only apply to {this.type.toUpperCase()} subscriptions - mapping counts across both mapping pages must equal the total
                                counts for each subscription
                            </Typography>
                        </Alert>
                    </Grid>
                )) || (
                    <Grid item xs={12}>
                        <Alert severity="success">
                            <Typography variant="body2">
                                <strong>This tenant is enabled for {this.type.toUpperCase()} subscription billing</strong>
                            </Typography>
                            <Typography variant="caption">
                                The financials below are the total for this tenant - mapping counts are not required to be manually entered
                            </Typography>
                        </Alert>
                    </Grid>
                )}
            </Grid>
        );
    };

    isQtyValid = (sub, type) => {
        const values = [],
            keys = [`${sub.mso_subscription_id}-annual`, `${sub.mso_subscription_id}-monthly`],
            enabled = parseInt(sub.mso_subscription_enabled_count),
            active = parseInt(sub.mso_subscription_active_count),
            inactive = parseInt(enabled - active),
            totals = {
                enabled,
                active,
                inactive,
            };

        _.each(keys, (key) => {
            if (this.state.formData?.[key]?.[type] !== "") {
                values.push(parseInt(this.state.formData?.[key]?.[type] ?? 0));
            }
        });

        return _.sum(values) === totals[type] ? "4CAF50" : "d9534f";
    };

    mappingTable = () => {
        const { dataLoading, agreementsList, formData, tenant } = this.state;
        let cols = [
            {
                heading: "Subscription Name",
                field: (rowData) => (
                    <>
                        {rowData.desc && (
                            <Typography variant="caption" className="fw-400">
                                {rowData.desc}
                            </Typography>
                        )}
                        <Typography variant="body2">{rowData.subscription_type.msost_name}</Typography>
                        {formData[rowData.key].suppAdd > 0 && parseInt(formData?.[rowData.key]?.suppMin ?? 0) > 0 ? (
                            <Box mt={1}>
                                <Typography variant="body2" color="error">
                                    <strong>Min Support Addition Qty - {formData?.[rowData.key]?.suppMin}</strong>
                                </Typography>
                                <Typography variant="body2" color="error">
                                    <strong>
                                        Expected Support Addition Qty -{" "}
                                        {parseInt(formData?.[rowData.key]?.enabled ?? 0) > parseInt(formData?.[rowData.key]?.suppMin)
                                            ? formData?.[rowData.key]?.enabled
                                            : formData?.[rowData.key]?.suppMin}
                                    </strong>
                                </Typography>
                            </Box>
                        ) : formData[rowData.key].suppAdd > 0 ? (
                            <Box mt={1}>
                                <Typography variant="body2" color="error">
                                    <strong>Expected Support Addition Qty - {formData?.[rowData.key]?.enabled}</strong>
                                </Typography>
                            </Box>
                        ) : null}
                    </>
                ),
                showTotalLabel: true,
            },
            {
                heading: "ConnectWise Additions",
                field: (rowData) => {
                    return (
                        <>
                            <Box maxWidth={300} minWidth={300}>
                                <FormControl fullWidth>
                                    <AutoCompleteSelect
                                        options={[
                                            {
                                                label: "BlueSync Actions",
                                                options: [
                                                    {
                                                        label:
                                                            ((formData[rowData.key].cwAdditionId === 0 || formData[rowData.key].cwAdditionId === -1) &&
                                                                rowData.mso_subscription_enabled_count > 0 &&
                                                                "None (Ignore Error)") ||
                                                            "None",
                                                        value: -1,
                                                    },
                                                ],
                                            },
                                            ...agreementsList,
                                        ]}
                                        isGrouped={true}
                                        placeholder="Not Mapped"
                                        onChange={this.handleAdditionSelection(rowData.key)}
                                        error={formData[rowData.key].cwAdditionId === 0 && rowData.mso_subscription_enabled_count > 0 ? true : false}
                                        value={formData[rowData.key].cwAdditionId}
                                        variant="filled"
                                    />
                                </FormControl>
                            </Box>
                            <Box maxWidth={300} minWidth={300}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs>
                                        <FormControl fullWidth>
                                            <AutoCompleteSelect
                                                options={[
                                                    {
                                                        label: "BlueSync Actions",
                                                        options: [
                                                            {
                                                                label:
                                                                    ((formData[rowData.key].suppAdd === 0 || formData[rowData.key].suppAdd === -1) &&
                                                                        rowData.mso_subscription_enabled_count > 0 &&
                                                                        "None (Ignore Error)") ||
                                                                    "None",
                                                                value: -1,
                                                            },
                                                        ],
                                                    },
                                                    ...agreementsList,
                                                ]}
                                                isGrouped={true}
                                                placeholder="No Support Addition"
                                                onChange={this.handleSupportAdditionSelection(rowData.key)}
                                                value={formData[rowData.key].suppAdd}
                                                variant="filled"
                                                disabled={formData[rowData.key].cwAdditionId === 0 || formData[rowData.key].cwAdditionId === -1}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Box maxWidth={50}>
                                            <TextField
                                                type="number"
                                                margin="none"
                                                autoComplete="off"
                                                value={formData[rowData.key].suppMin}
                                                onChange={(e) => this.handleSubscriptionChange(rowData.key, "suppMin", e?.target?.value)}
                                                variant="filled"
                                                disabled={formData[rowData.key].suppAdd === 0}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    );
                },
                sizeToContent: true,
            },
        ];

        const isNce = this.type === "nce";

        if (isNce) {
            cols.push(
                {
                    heading: "Enabled *",
                    field: (rowData) => {
                        return (
                            <Box maxWidth={50} minWidth={50} border={`1px solid #${this.isQtyValid(rowData, "enabled")}`}>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        margin="none"
                                        autoComplete="off"
                                        value={formData[rowData.key].enabled}
                                        onChange={(e) => this.handleSubscriptionChange(rowData.key, "enabled", e?.target?.value)}
                                        fullWidth
                                        variant="filled"
                                    />
                                </FormControl>
                            </Box>
                        );
                    },
                    sizeToContent: true,
                },
                {
                    heading: "Active *",
                    field: (rowData) => {
                        return (
                            <Box maxWidth={50} minWidth={50} border={`1px solid #${this.isQtyValid(rowData, "active")}`}>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        margin="none"
                                        autoComplete="off"
                                        value={formData[rowData.key].active}
                                        onChange={(e) => this.handleSubscriptionChange(rowData.key, "active", e?.target?.value)}
                                        fullWidth
                                        variant="filled"
                                    />
                                </FormControl>
                            </Box>
                        );
                    },
                    sizeToContent: true,
                },
                {
                    heading: "Inactive *",
                    field: (rowData) => {
                        return (
                            <Box maxWidth={50} minWidth={50} border={`1px solid #${this.isQtyValid(rowData, "inactive")}`}>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        margin="none"
                                        autoComplete="off"
                                        value={formData[rowData.key].inactive}
                                        onChange={(e) => this.handleSubscriptionChange(rowData.key, "inactive", e?.target?.value)}
                                        fullWidth
                                        variant="filled"
                                    />
                                </FormControl>
                            </Box>
                        );
                    },
                    sizeToContent: true,
                }
            );
        }

        cols.push(
            {
                heading: `${isNce ? `Total ` : ``}Enabled`,
                field: (rowData) => rowData.mso_subscription_enabled_count,
                sizeToContent: true,
                showTotal: true,
            },
            {
                heading: `${isNce ? `Total ` : ``}Active`,
                field: (rowData) => rowData.mso_subscription_active_count,
                sizeToContent: true,
                showTotal: true,
            },
            {
                heading: `${isNce ? `Total ` : ``}Inactive`,
                field: (rowData) => {
                    const inactiveCount = rowData.mso_subscription_enabled_count - rowData.mso_subscription_active_count;
                    return (
                        <span
                            style={{
                                color: formData[rowData.key].cwAdditionId !== -1 && inactiveCount > 0 && "#d9534f",
                                fontWeight: formData[rowData.key].cwAdditionId !== -1 && inactiveCount > 0 && "bold",
                            }}
                        >
                            {inactiveCount}
                        </span>
                    );
                },
                totalField: (rowData) => rowData.mso_subscription_enabled_count - rowData.mso_subscription_active_count,
                sizeToContent: true,
                showTotal: true,
            }
        );

        if (isNce) {
            cols.push(
                {
                    heading: "Expiry Day *",
                    field: (rowData) => {
                        return (
                            <Box maxWidth={50} minWidth={50}>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        margin="none"
                                        autoComplete="off"
                                        value={formData[rowData.key].expiry}
                                        onChange={(e) => this.handleSubscriptionChange(rowData.key, "expiry", e?.target?.value)}
                                        fullWidth
                                        variant="filled"
                                    />
                                </FormControl>
                            </Box>
                        );
                    },
                    sizeToContent: true,
                },
                {
                    heading: "Expiry Month *",
                    field: (rowData) => {
                        return (
                            (formData[rowData.key]?.type === "annual" && (
                                <Box maxWidth={50} minWidth={50}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type="number"
                                            margin="none"
                                            autoComplete="off"
                                            value={formData[rowData.key].month}
                                            onChange={(e) => this.handleSubscriptionChange(rowData.key, "month", e?.target?.value)}
                                            fullWidth
                                            variant="filled"
                                        />
                                    </FormControl>
                                </Box>
                            )) ||
                            `-`
                        );
                    },
                    sizeToContent: true,
                },
                {
                    heading: "Billing Day *",
                    field: (rowData) => {
                        return (
                            <Box maxWidth={50} minWidth={50}>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        margin="none"
                                        autoComplete="off"
                                        value={formData[rowData.key].renewal}
                                        onChange={(e) => this.handleSubscriptionChange(rowData.key, "renewal", e?.target?.value)}
                                        fullWidth
                                        variant="filled"
                                    />
                                </FormControl>
                            </Box>
                        );
                    },
                    sizeToContent: true,
                }
            );
        }

        cols.push(
            {
                heading: "View Users",
                field: (rowData) => (
                    <IconButton
                        onClick={() => {
                            this.handleUserDialogOpen(rowData.mso_subscription_id);
                        }}
                    >
                        <FAIcon icon="users" button noMargin />
                    </IconButton>
                ),
                sizeToContent: true,
            },
            {
                heading: "Unit Price",
                field: (rowData) =>
                    formData[rowData.key].cwAdditionId === 0 || formData[rowData.key].cwAdditionId === -1
                        ? "-"
                        : this.state.additions[formData[rowData.key].cwAdditionId].cwaa_cw_data.unitPrice,
                fieldFormat: "currency",
                sizeToContent: true,
            },
            {
                heading: "Total Price",
                field: (rowData) =>
                    formData[rowData.key].cwAdditionId === 0 || formData[rowData.key].cwAdditionId === -1
                        ? "-"
                        : this.state.additions[formData[rowData.key].cwAdditionId].cwaa_cw_data.unitPrice * rowData.mso_subscription_enabled_count,
                totalField: (rowData) =>
                    formData[rowData.key].cwAdditionId === 0 || formData[rowData.key].cwAdditionId === -1
                        ? 0
                        : this.state.additions[formData[rowData.key].cwAdditionId].cwaa_cw_data.unitPrice * rowData.mso_subscription_enabled_count,
                fieldFormat: "currency",
                sizeToContent: true,
                showTotal: true,
            }
        );

        return (
            <DataTable
                config={{
                    key: "mso_subscription_id",
                    pagination: true,
                    alternatingRowColours: true,
                    isLoading: dataLoading,
                    rowsPerPage: 100,
                    plainHeader: true,
                    showTotals: true,
                    title: "Subscription Mapping",
                    actions: (
                        <React.Fragment>
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                onClick={() => this.handleReset()}
                                style={{ marginLeft: 6 }}
                                disabled={this.state.dataLoading ? true : this.state.dataSave ? true : this.state.initialFormData === this.state.formData ? true : false}
                            >
                                <FAIcon
                                    icon="trash"
                                    style={{
                                        color: this.state.dataLoading
                                            ? "#ddd"
                                            : this.state.dataSave
                                            ? "#ddd"
                                            : this.state.initialFormData === this.state.formData
                                            ? "#ddd"
                                            : "#375885",
                                        height: "1em",
                                    }}
                                    heading
                                    button
                                />
                                Discard
                            </Button>
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                onClick={() => this.handleConfirmationOpen("dataSaved")}
                                style={{ marginLeft: 6 }}
                                disabled={
                                    this.state.dataLoading ||
                                    this.state.dataSave ||
                                    this.state.initialFormData === this.state.formData ||
                                    _.some(
                                        this.state.formData,
                                        (el) =>
                                            this.type === "nce" &&
                                            el.cwAdditionId > 0 &&
                                            (el.expiry === "" || el.renewal === "" || el.month === "" || parseInt(el.expiry) === 0 || parseInt(el.renewal) === 0)
                                    )
                                }
                            >
                                <FAIcon
                                    icon="save"
                                    disabled={
                                        this.state.dataLoading ||
                                        this.state.dataSave ||
                                        this.state.initialFormData === this.state.formData ||
                                        _.some(
                                            this.state.formData,
                                            (el) =>
                                                this.type === "nce" &&
                                                el.cwAdditionId > 0 &&
                                                (el.expiry === "" || el.renewal === "" || el.month === "" || parseInt(el.expiry === 0) || parseInt(el.renewal) === 0)
                                        )
                                    }
                                    swapOpacity
                                    button
                                />
                                Save
                            </Button>
                        </React.Fragment>
                    ),
                }}
                columns={cols}
                rows={_.flatMap(tenant.subscriptions, (sub) => {
                    let rows = [];
                    if (isNce) {
                        rows.push({
                            ...sub,
                            key: `${sub.mso_subscription_id}-annual`,
                            desc: "Annual / Annual",
                        });
                        rows.push({
                            ...sub,
                            key: `${sub.mso_subscription_id}-monthly`,
                            desc: "Monthly / Monthly",
                        });
                    } else {
                        rows.push({
                            ...sub,
                            key: sub.mso_subscription_id,
                        });
                    }
                    return rows;
                })}
            />
        );
    };

    handleUnassignedOptions(contact) {
        const bsOptions = [
            {
                value: "None",
                label: "None",
            },
        ];
        if (contact.cc_active === "Y") {
            bsOptions.push({
                value: "Deactivate",
                label: "Deactivate",
            });
        } else {
            bsOptions.push({
                value: "Activate",
                label: "Activate",
            });
        }
        return [
            {
                label: "BlueSync Actions",
                options: bsOptions,
            },
            {
                label: "Microsoft 365 Users",
                options: this.state.userList,
            },
        ];
    }

    usersTable = () => {
        const { contacts, contactsList, dataLoading, tenant, userData, userMapping, unassignedContacts } = this.state;
        let countAdActivate = 0;
        _.each(userMapping, (map) => {
            if (map.ccId !== "ignore" && map.ccId !== "create") {
                let idx = _.findIndex(contacts, function (el) {
                    return el.cc_id === map.ccId;
                });
                const usr = _.find(tenant.users, (user) => user.mso_user_id === map.msoUserId);
                if (contacts[idx] && contacts[idx].cc_active === "N" && !_.isEmpty(usr?.subscriptions ?? [])) {
                    countAdActivate++;
                }
            }
        });
        let countCreate = _.size(
            _.filter(this.state.userMapping, function (el) {
                return el.ccId === "create";
            })
        );
        let countActivate =
            _.size(
                _.filter(this.state.userData, function (el) {
                    return el.action === "Activate";
                })
            ) + countAdActivate;
        let countDeactivate = _.size(
            _.filter(this.state.userData, function (el) {
                return el.action === "Deactivate";
            })
        );
        let btnDisabled = this.state.dataLoading
            ? true
            : this.state.dataSave
            ? true
            : this.state.initialUserMapping === this.state.userMapping && this.state.initialUserData === this.state.userData
            ? true
            : false;
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} align="right">
                        <Grid container>
                            <Grid item xs align="right">
                                <Button variant="text" color="primary" size="small" onClick={() => this.handleReset()} style={{ marginLeft: 6 }} disabled={btnDisabled}>
                                    <FAIcon icon="trash" style={{ color: btnDisabled ? "#ddd" : "#375885", height: "1em" }} heading button />
                                    Discard
                                </Button>
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    onClick={() => this.handleConfirmationOpen("usersSaved")}
                                    style={{ marginLeft: 6 }}
                                    disabled={btnDisabled}
                                >
                                    <FAIcon icon="save" style={{ color: btnDisabled ? "#ddd" : "#375885", height: "1em" }} swapOpacity button />
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Paper>
                            <DataTable
                                config={{
                                    key: "mso_user_id",
                                    pagination: true,
                                    alternatingRowColours: true,
                                    isLoading: dataLoading,
                                    rowsPerPage: 100,
                                    plainHeader: true,
                                    title: "Microsoft 365 Users",
                                }}
                                columns={[
                                    {
                                        field: (rowData) => {
                                            let contactIdx = _.findIndex(contacts, function (el) {
                                                return el.cc_id === userMapping[rowData.mso_user_id].ccId;
                                            });
                                            return (
                                                (userMapping[rowData.mso_user_id] &&
                                                    ((userMapping[rowData.mso_user_id].ccId === "create" && (
                                                        <FAIcon icon="exclamation-triangle" color="warning" style={{ margin: "0 0 0 10px" }} />
                                                    )) ||
                                                        (userMapping[rowData.mso_user_id].ccId === "ignore" && <React.Fragment />) ||
                                                        (contacts[contactIdx] &&
                                                            contacts[contactIdx].cc_active === "N" &&
                                                            contacts[contactIdx].cc_sync_action === "Activate" && (
                                                                <FAIcon icon="exclamation-triangle" color="warning" style={{ margin: "0 0 0 10px" }} />
                                                            )))) || <React.Fragment />
                                            );
                                        },
                                        alignment: "right",
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: "Microsoft 365 User",
                                        field: (rowData) => (
                                            <React.Fragment>
                                                <Typography
                                                    variant="body2"
                                                    className="fw-400"
                                                    style={{ textDecoration: _.isEmpty(rowData.subscriptions) ? `line-through` : undefined }}
                                                >
                                                    {rowData.mso_user_type === "Admin" && <FAIcon type="solid" icon="user-crown" size="small" style={{ color: "#FFD700" }} />}
                                                    {`${(rowData.mso_user_name && rowData.mso_user_name !== "false" && `${rowData.mso_user_name}`) || "-"}`.replace(
                                                        /(^.{30}).*$/,
                                                        "$1..."
                                                    )}
                                                </Typography>
                                                <Typography variant="caption" style={{ textDecoration: _.isEmpty(rowData.subscriptions) ? `line-through` : undefined }}>
                                                    {`${(rowData.mso_user_email && rowData.mso_user_email !== "false" && `${rowData.mso_user_email}`) || "-"}`}
                                                </Typography>
                                            </React.Fragment>
                                        ),
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: "ConnectWise Contact",
                                        field: (rowData) => {
                                            let contactIdx = _.findIndex(contacts, function (el) {
                                                return el.cc_id === userMapping[rowData.mso_user_id].ccId;
                                            });
                                            return (
                                                <Box minWidth={350} maxWidth={350}>
                                                    <FormControl fullWidth>
                                                        <AutoCompleteSelect
                                                            options={contactsList}
                                                            disabled={
                                                                _.isEmpty(rowData.subscriptions) ||
                                                                (_.find(this.state.domains, (el) => el.msoDomainId === rowData.mso_user_domain_id)?.msoDomainEnabled ?? 0) ===
                                                                    0
                                                            }
                                                            isGrouped={true}
                                                            placeholder={
                                                                (_.find(this.state.domains, (el) => el.msoDomainId === rowData.mso_user_domain_id)?.msoDomainEnabled ??
                                                                    parseInt(contacts?.[contactIdx]?.cc_default ?? 0)) === 0
                                                                    ? `Domain Disabled`
                                                                    : _.isEmpty(rowData.subscriptions)
                                                                    ? `Unlicensed`
                                                                    : (userMapping[rowData.mso_user_id] &&
                                                                          ((userMapping[rowData.mso_user_id].ccId === "create" && "Pending Action (Contact Creation):") ||
                                                                              (userMapping[rowData.mso_user_id].ccId === "ignore" && "Mapped Contact:") ||
                                                                              (contacts[contactIdx] &&
                                                                                  contacts[contactIdx].cc_active === "N" &&
                                                                                  "Pending Action (Contact Activation):"))) ||
                                                                      "Mapped Contact:"
                                                            }
                                                            warning={
                                                                (userMapping[rowData.mso_user_id] &&
                                                                    ((userMapping[rowData.mso_user_id].ccId === "create" && true) ||
                                                                        (userMapping[rowData.mso_user_id].ccId === "ignore" && false) ||
                                                                        (contacts[contactIdx] && contacts[contactIdx].cc_active === "N" && true))) ||
                                                                false
                                                            }
                                                            onChange={this.handleContactSelection("msoUserId", rowData.mso_user_id)}
                                                            value={
                                                                _.isEmpty(rowData.subscriptions) ||
                                                                (userMapping[rowData.mso_user_id].ccId === "ignore" &&
                                                                    (_.find(this.state.domains, (el) => el.msoDomainId === rowData.mso_user_domain_id)?.msoDomainEnabled ??
                                                                        0) === 0)
                                                                    ? null
                                                                    : userMapping[rowData.mso_user_id].ccId
                                                            }
                                                            noClear
                                                            variant="filled"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            );
                                        },
                                    },
                                ]}
                                rows={_.orderBy(tenant.users, [(el) => (_.isEmpty(el.subscriptions) ? 1 : 0), (el) => el.mso_user_name], ["asc", "asc"])}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper>
                                    <DataTable
                                        config={{
                                            key: "cc_id",
                                            pagination: true,
                                            alternatingRowColours: true,
                                            isLoading: dataLoading,
                                            rowsPerPage: 100,
                                            plainHeader: true,
                                            title: "Unassigned ConnectWise Contacts",
                                            noResultsText: "All ConnectWise contacts are assigned to a user",
                                        }}
                                        columns={[
                                            {
                                                field: (rowData) => {
                                                    let idx = _.findIndex(userData, function (el) {
                                                        return el.ccId === rowData.cc_id;
                                                    });
                                                    return (
                                                        (userData[idx] &&
                                                            ((userData[idx].action === "Activate" && (
                                                                <FAIcon icon="exclamation-triangle" color="warning" style={{ margin: "0 0 0 10px" }} />
                                                            )) ||
                                                                (userData[idx].action === "Deactivate" && (
                                                                    <FAIcon icon="exclamation-triangle" color="warning" style={{ margin: "0 0 0 10px" }} />
                                                                )))) || <React.Fragment />
                                                    );
                                                },
                                                alignment: "right",
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: "ConnectWise Contact",
                                                field: (rowData) => (
                                                    <React.Fragment>
                                                        <Typography
                                                            variant="body2"
                                                            className="fw-400"
                                                            style={{ textDecoration: rowData.cc_active === "N" ? "line-through" : "" }}
                                                        >
                                                            {`${(rowData.cc_first_name && rowData.cc_first_name !== null && `${rowData.cc_first_name} `) || ""}${
                                                                (rowData.cc_last_name && rowData.cc_last_name !== null && `${rowData.cc_last_name} `) || ""
                                                            }`}
                                                        </Typography>
                                                        <Typography variant="caption" style={{ textDecoration: rowData.cc_active === "N" ? "line-through" : "" }}>
                                                            {(rowData.cc_email && rowData.cc_email.length > 0 && `${rowData.cc_email}`) || "-"}
                                                        </Typography>
                                                    </React.Fragment>
                                                ),
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: "Active",
                                                field: (rowData) => rowData.cc_active,
                                                fieldFormat: "boolean",
                                                alignment: "center",
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: "Microsoft 365 User",
                                                field: (rowData) => {
                                                    let idx = _.findIndex(this.state.userData, function (el) {
                                                        return el.ccId === rowData.cc_id;
                                                    });
                                                    return (
                                                        <Box minWidth={350} maxWidth={350}>
                                                            <FormControl fullWidth>
                                                                <AutoCompleteSelect
                                                                    options={this.handleUnassignedOptions(rowData)}
                                                                    disabled={rowData.disabled}
                                                                    isGrouped={true}
                                                                    placeholder={
                                                                        (this.state.userData[idx] &&
                                                                            ((this.state.userData[idx].action === "Activate" && "Pending Action (Contact Activation): ") ||
                                                                                (this.state.userData[idx].action === "Deactivate" &&
                                                                                    "Pending Action (Contact Deactivation): "))) ||
                                                                        "Sync Action"
                                                                    }
                                                                    warning={
                                                                        (this.state.userData[idx] &&
                                                                            ((this.state.userData[idx].action === "Activate" && true) ||
                                                                                (this.state.userData[idx].action === "Deactivate" && true))) ||
                                                                        false
                                                                    }
                                                                    onChange={this.handleContactSelection("ccId", rowData.cc_id)}
                                                                    value={(this.state.userData[idx] && this.state.userData[idx].action) || "None"}
                                                                    noClear
                                                                    variant="filled"
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                    );
                                                },
                                            },
                                        ]}
                                        rows={_.orderBy(unassignedContacts, [(el) => (el.cc_active === "Y" ? 0 : 1), (el) => el.cc_first_name], ["asc", "asc"])}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <DataTable
                                        config={{
                                            key: "id",
                                            plainHeader: true,
                                            title: "Actions Pending Microsoft 365 Synchronization",
                                        }}
                                        columns={[
                                            {
                                                field: (rowData) => <React.Fragment />,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: "Action",
                                                field: (rowData) => rowData.action,
                                                main: true,
                                            },
                                            {
                                                heading: "Count",
                                                field: (rowData) => rowData.count,
                                                sizeToContent: true,
                                            },
                                        ]}
                                        rows={[
                                            {
                                                id: 1,
                                                action: "Create ConnectWise Contact",
                                                count: countCreate,
                                            },
                                            {
                                                id: 2,
                                                action: "Activate ConnectWise Contact",
                                                count: countActivate,
                                            },
                                            {
                                                id: 3,
                                                action: "Deactivate ConnectWise Contact",
                                                count: countDeactivate,
                                            },
                                        ]}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    userDialog = () => {
        const { userDialog } = this.state;
        return (
            <Dialog open={userDialog.open} onClose={this.handleUserDialogClose} maxWidth="md" scroll="body">
                <DialogTitle>
                    Users of {userDialog.subscription.subscription_type.msost_name}
                    <br />
                    <span style={{ fontSize: "12px" }}>{userDialog.subscription.adg_dn}</span>
                </DialogTitle>
                <Typography component={DialogContent} variant="body2">
                    <DataTable
                        config={{
                            key: "name",
                            plainHeader: true,
                        }}
                        columns={[
                            {
                                field: (rowData) => (
                                    <React.Fragment>
                                        {rowData.mso_user_type === "Admin" && <FAIcon type="solid" icon="user-crown" size="small" style={{ color: "#FFD700" }} noMargin />}
                                    </React.Fragment>
                                ),
                                alignment: "center",
                            },
                            {
                                heading: "Name",
                                field: (rowData) => rowData.mso_user_name,
                            },
                            {
                                heading: "Email Address",
                                field: (rowData) => rowData.mso_user_email,
                            },
                        ]}
                        rows={userDialog.subscription.users}
                    />
                </Typography>
            </Dialog>
        );
    };

    userSubscriptions = () => {
        const { classes } = this.props;
        const { userSubscriptions } = this.state;
        return (
            <Grid container spacing={3}>
                {_.map(_.orderBy(this.state.tenant.users, [(el) => _.size(el.subscriptions)], ["desc"]), (user, idx) => (
                    <Grid item xs={12} lg={3} key={idx}>
                        <Paper>
                            <CardHeader
                                title={
                                    <Typography variant={"h6"} style={{ textDecoration: _.isEmpty(user.subscriptions) ? `line-through` : undefined }}>
                                        {user.mso_user_type === "Admin" && <FAIcon type="solid" icon="user-crown" style={{ color: "#FFD700" }} />}
                                        {user.mso_user_name}
                                    </Typography>
                                }
                                subheader={
                                    <Typography variant={"caption"} gutterBottom>
                                        {user.subscriptions.length} Subscriptions
                                    </Typography>
                                }
                                action={
                                    <IconButton
                                        className={clsx(classes.expand, {
                                            [classes.expandOpen]: userSubscriptions[idx],
                                        })}
                                        disabled={!user.subscriptions.length}
                                        onClick={() => this.handleExpandUserSubscription(idx)}
                                    >
                                        <FAIcon disabled={!user.subscriptions.length} icon="chevron-down" button noMargin />
                                    </IconButton>
                                }
                                disableTypography
                            />
                            <Collapse in={userSubscriptions[idx]} timeout="auto" unmountOnExit>
                                <CardContent style={{ paddingTop: 0 }}>
                                    {(!_.isEmpty(user.subscriptions) && (
                                        <Table size="small" padding="none">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2" color="primary" className="fw-400" gutterBottom>
                                                            Microsoft 365 Subscriptions
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {_.map(user.subscriptions, (subscription, idx) => {
                                                    return (
                                                        <TableRow key={idx}>
                                                            <TableCell>
                                                                <Typography variant="caption">
                                                                    <Box pt={1} pb={1}>
                                                                        > {subscription.subscription_type.msost_name}
                                                                    </Box>
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    )) || (
                                        <Typography variant="caption">
                                            <IconButton disabled>
                                                <FAIcon icon="exclamation-circle" />
                                            </IconButton>
                                            {user.mso_user_name} is not associated to any Microsoft 365 Subscriptions
                                        </Typography>
                                    )}
                                </CardContent>
                            </Collapse>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        );
    };

    manualMappings = () => {
        const { manualMappings, agreementsList } = this.state;
        return (
            <Grid item xs={12}>
                <Paper style={{ padding: 18 }}>
                    <DataTable
                        config={{
                            key: "mmId",
                            pagination: true,
                            alternatingRowColours: true,
                            isLoading: this.state.dataLoading,
                            rowsPerPage: 100,
                            plainHeader: true,
                            showTotals: true,
                            title: "Manual Mappings",
                            rowProps: (rowData) => {
                                return {
                                    style: {
                                        backgroundColor: rowData.mmDel && "#ff9994",
                                    },
                                };
                            },
                            actions: (
                                <React.Fragment>
                                    <Button variant="text" color="primary" size="small" onClick={this.addManualMapping} style={{ marginLeft: 6 }}>
                                        <FAIcon
                                            icon="plus"
                                            style={{
                                                color: this.state.dataLoading ? "#ddd" : this.state.dataSave ? "#ddd" : "#375885",
                                                height: "1em",
                                            }}
                                            heading
                                            button
                                        />
                                        New Mapping
                                    </Button>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        size="small"
                                        onClick={() => this.handleReset()}
                                        style={{ marginLeft: 6 }}
                                        disabled={
                                            this.state.dataLoading
                                                ? true
                                                : this.state.dataSave
                                                ? true
                                                : this.state.initialManualMappings === this.state.manualMappings
                                                ? true
                                                : false
                                        }
                                    >
                                        <FAIcon
                                            icon="trash"
                                            style={{
                                                color: this.state.dataLoading
                                                    ? "#ddd"
                                                    : this.state.dataSave
                                                    ? "#ddd"
                                                    : this.state.initialManualMappings === this.state.manualMappings
                                                    ? "#ddd"
                                                    : "#375885",
                                                height: "1em",
                                            }}
                                            heading
                                            button
                                        />
                                        Discard
                                    </Button>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        size="small"
                                        onClick={() => this.handleConfirmationOpen("mmSaved")}
                                        style={{ marginLeft: 6 }}
                                        disabled={
                                            this.state.dataLoading
                                                ? true
                                                : this.state.dataSave
                                                ? true
                                                : this.state.initialManualMappings === this.state.manualMappings
                                                ? true
                                                : false
                                        }
                                    >
                                        <FAIcon
                                            icon="save"
                                            style={{
                                                color: this.state.dataLoading
                                                    ? "#ddd"
                                                    : this.state.dataSave
                                                    ? "#ddd"
                                                    : this.state.initialManualMappings === this.state.manualMappings
                                                    ? "#ddd"
                                                    : "#375885",
                                                height: "1em",
                                            }}
                                            swapOpacity
                                            button
                                        />
                                        Save
                                    </Button>
                                </React.Fragment>
                            ),
                        }}
                        columns={[
                            {
                                heading: "Name",
                                field: (rowData) => {
                                    return rowData.mmDel ? (
                                        <React.Fragment>
                                            <em>{rowData.mmName}</em>
                                        </React.Fragment>
                                    ) : (
                                        <TextField
                                            margin="none"
                                            autoComplete="off"
                                            value={rowData.mmName}
                                            error={!rowData.mmName}
                                            onChange={this.handleManualMappingNameChange(rowData.mmId)}
                                            style={{ width: 500 }}
                                            fullWidth
                                        />
                                    );
                                },
                                showTotalLabel: true,
                            },
                            {
                                heading: "CW Addition",
                                field: (rowData) => {
                                    return rowData.mmDel ? (
                                        <React.Fragment>
                                            <em>Pending Deletion</em>
                                        </React.Fragment>
                                    ) : (
                                        <Box maxWidth={350} minWidth={350}>
                                            <FormControl fullWidth>
                                                <AutoCompleteSelect
                                                    options={[
                                                        {
                                                            label: "BlueSync Actions",
                                                            options: [
                                                                {
                                                                    label:
                                                                        ((rowData.mmAdditionId === 0 || rowData.mmAdditionId === -1) &&
                                                                            rowData.mmUnits > 0 &&
                                                                            "None (Ignore Error)") ||
                                                                        "None",
                                                                    value: -1,
                                                                },
                                                            ],
                                                        },
                                                        ...agreementsList,
                                                    ]}
                                                    isGrouped={true}
                                                    placeholder="Not Mapped"
                                                    onChange={this.handleManualMappingAdditionSelection(rowData.mmId)}
                                                    error={rowData.mmAdditionId === 0 && rowData.mmUnits > 0 ? true : false}
                                                    value={rowData.mmAdditionId}
                                                    variant="filled"
                                                />
                                            </FormControl>
                                        </Box>
                                    );
                                },
                                sizeToContent: true,
                            },
                            {
                                heading: "Units",
                                field: (rowData) => {
                                    return rowData.mmDel ? (
                                        <React.Fragment></React.Fragment>
                                    ) : (
                                        <TextField
                                            type="number"
                                            margin="none"
                                            autoComplete="off"
                                            value={rowData.mmUnits}
                                            onChange={this.handleManualMappingUnitsChange(rowData.mmId)}
                                            style={{ width: 100 }}
                                            fullWidth
                                        />
                                    );
                                },
                                totalField: (rowData) => rowData.mmUnits,
                                showTotal: true,
                                sizeToContent: true,
                            },
                            {
                                heading: "Unit Price",
                                field: (rowData) =>
                                    rowData.mmAdditionId === 0 || rowData.mmAdditionId === -1 ? "-" : this.state.additions[rowData.mmAdditionId].cwaa_cw_data.unitPrice,
                                fieldFormat: "currency",
                                sizeToContent: true,
                            },
                            {
                                heading: "Total Price",
                                field: (rowData) =>
                                    rowData.mmAdditionId === 0 || rowData.mmAdditionId === -1
                                        ? "-"
                                        : this.state.additions[rowData.mmAdditionId].cwaa_cw_data.unitPrice * rowData.mmUnits,
                                totalField: (rowData) =>
                                    rowData.mmAdditionId === 0 || rowData.mmAdditionId === -1
                                        ? 0
                                        : this.state.additions[rowData.mmAdditionId].cwaa_cw_data.unitPrice * rowData.mmUnits,
                                fieldFormat: "currency",
                                sizeToContent: true,
                                showTotal: true,
                            },
                            {
                                actions: (rowData) => {
                                    return [
                                        {
                                            name: rowData.mmDel ? "Restore" : "Delete",
                                            icon: rowData.mmDel ? "trash-undo-alt" : "trash-alt",
                                            onClick: () => {
                                                this.handleManualMappingDeleteToggle(rowData.mmId);
                                            },
                                            disabled: false,
                                        },
                                    ];
                                },
                            },
                        ]}
                        rows={manualMappings}
                    />
                </Paper>
            </Grid>
        );
    };

    addManualMapping = () => {
        const tempId = uuidv4();
        let manualMappings = this.state.manualMappings;
        manualMappings[tempId] = {
            mmId: tempId,
            mmAdditionId: 0,
            mmName: "",
            mmUnits: 0,
            mmNew: true,
            mmDel: false,
        };
        this.setState({ manualMappings });
    };

    handleManualMappingAdditionSelection = (mmId) => (selectedAddition) => {
        const cwAdditionId = selectedAddition === null ? 0 : selectedAddition.value;
        this.setState(
            {
                manualMappings: {
                    ...this.state.manualMappings,
                    [mmId]: {
                        ...this.state.manualMappings[mmId],
                        mmAdditionId: parseInt(cwAdditionId),
                    },
                },
            },
            () => {
                this.sortAgreementsList();
            }
        );
    };

    handleManualMappingNameChange = (mmId) => (el) => {
        this.setState({
            manualMappings: {
                ...this.state.manualMappings,
                [mmId]: {
                    ...this.state.manualMappings[mmId],
                    mmName: el.target.value,
                },
            },
        });
    };

    handleManualMappingUnitsChange = (mmId) => (el) => {
        let units = parseInt(el.target.value);
        if (units < 0 || !units) {
            units = 0;
        }
        this.setState({
            manualMappings: {
                ...this.state.manualMappings,
                [mmId]: {
                    ...this.state.manualMappings[mmId],
                    mmUnits: units,
                },
            },
        });
    };

    handleManualMappingDeleteToggle = (mmId) => {
        let mappings = { ...this.state.manualMappings };
        if (mappings[mmId].mmDel) {
            mappings[mmId].mmDel = false;
        } else {
            if (mappings[mmId].mmNew) {
                delete mappings[mmId];
            } else {
                mappings[mmId].mmDel = true;
            }
        }
        this.setState({
            manualMappings: mappings,
        });
    };

    handleManualMappingSubmit = () => {
        this.setState(
            {
                mmSave: true,
                initialManualMappings: this.state.manualMappings,
                type: this.type,
            },
            () => {
                API.put("/ms365/tenants/" + this.props.match.params.id + "/manualMapping", {
                    data: this.state.manualMappings,
                }).then((result) => {
                    if (result.data.errors) {
                        this.setState({
                            mmSave: false,
                        });
                    } else {
                        this.setState(
                            {
                                dataLoading: true,
                                mmSave: false,
                                snackbar: {
                                    mmSaved: true,
                                },
                            },
                            () => {
                                this.props.scrollToTop();
                                this.loadComponentData();
                            }
                        );
                    }
                });
            }
        );
    };

    sortAgreementsList = () => {
        let assignedAdditions = [];
        _.each(this.state.formData, (subscription) => {
            if (subscription.cwAdditionId !== 0) assignedAdditions.push(subscription.cwAdditionId);
        });
        _.each(this.state.manualMappings, (mm) => {
            if (mm.mmAdditionId !== 0) assignedAdditions.push(mm.mmAdditionId);
        });
        _.each(this.state.tenant.subscriptions, (subscription) => {
            _.each(subscription.mapped, (el) => {
                assignedAdditions.push(el);
            });
        });
        let agreementsList = _.map(this.state.agreements, (el) => {
            return _.assign({
                label: el.cwa_cw_agreement_name,
                options: _.map(el.additions, (_el) => {
                    return _.assign({
                        value: _el.cwaa_id,
                        label: _el.cwaa_cw_product_identifier,
                        disabled: assignedAdditions.includes(_el.cwaa_id) ? true : false,
                    });
                }),
            });
        });
        this.setState({
            agreementsList,
        });
    };

    domainsTable = () => {
        const { dataLoading, domains } = this.state;
        return (
            <>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <DataTable
                                    config={{
                                        key: "msoDomainId",
                                        pagination: true,
                                        alternatingRowColours: true,
                                        isLoading: dataLoading,
                                        rowsPerPage: 100,
                                        plainHeader: true,
                                        title: "Tenant Domains",
                                        noResultsText: "All ConnectWise contacts are assigned to a user",
                                    }}
                                    columns={[
                                        {
                                            heading: "Domain",
                                            field: (rowData) => (
                                                <React.Fragment>
                                                    <Typography variant="body2" className="fw-400">
                                                        {rowData.msoDomainValue}
                                                    </Typography>
                                                </React.Fragment>
                                            ),
                                        },
                                        {
                                            heading: "Enabled",
                                            field: (rowData) => (
                                                <IconButton onClick={() => this.handleDomainToggle(rowData.msoDomainId)} color="inherit">
                                                    <FAIcon
                                                        icon={(rowData.msoDomainEnabled && "check-circle") || "times-circle"}
                                                        style={{
                                                            color: (rowData.msoDomainEnabled && "#388E3C") || "#d9534f",
                                                        }}
                                                        noMargin
                                                        button
                                                    />
                                                </IconButton>
                                            ),
                                            important: true,
                                            sizeToContent: true,
                                        },
                                    ]}
                                    rows={domains}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };

    render() {
        const { access, currentTab, dataLoading, dataSave, confirmation, snackbar } = this.state;
        return (
            <Grid container spacing={1}>
                {(dataLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <FAIcon type="brand" icon="microsoft" size={85} style={{ color: COLOR_MAIN_MS365 }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs={12}>
                                                    <Typography variant="h3" className="fw-300">
                                                        {this.state.tenant.mso_company_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <InsightChip icon="link-horizontal" label={`Mapped: ${this.state.tenant?.customer?.cust_name}`} />
                                                        </Grid>
                                                        <Grid item>
                                                            <InsightChip
                                                                icon="calendar-alt"
                                                                label={`Created: ${
                                                                    this.state.tenant?.mso_company_created_datetime === "0000-00-00 00:00:00"
                                                                        ? "Unknown"
                                                                        : moment(this.state.tenant?.mso_company_created_datetime).format("MMM D, YYYY")
                                                                }`}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InsightChip icon="check" label={`Sync: ${this.state.tenant?.mso_company_sync === 1 ? "Enabled" : "Disabled"}`} />
                                                        </Grid>
                                                        <Grid item>
                                                            <InsightChip icon="user" label={`Tenant: ${this.state.tenant?.mso_company_tenant_uuid}`} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <AppBar style={{ backgroundColor: COLOR_MAIN_MS365 }} position="static" elevation={0}>
                                            <Tabs
                                                value={currentTab}
                                                onChange={this.handleTabChange}
                                                variant="scrollable"
                                                TabIndicatorProps={{ style: { backgroundColor: "#fff" } }}
                                            >
                                                <Tab disableRipple value={0} label="Tenant Overview" />
                                                <Tab
                                                    disableRipple
                                                    value={1}
                                                    label={
                                                        <ActionBadge badgeContent={this.state.badge.subscriptionsWithoutMapping} variant="error">
                                                            {this.type.toUpperCase()} Subscription Mapping
                                                        </ActionBadge>
                                                    }
                                                />
                                                <Tab disableRipple value={5} label="Manual Mapping" />
                                                <Tab disableRipple value={4} label="User Subscription Overview" />
                                                <Tab
                                                    disableRipple
                                                    value={3}
                                                    label={
                                                        <ActionBadge badgeContent={this.state.badge.contactActions} variant="warning">
                                                            Contact Mapping
                                                        </ActionBadge>
                                                    }
                                                />
                                                <Tab disableRipple value={6} label="Domains" />
                                                {access.logs && <Tab disableRipple value={2} label="Import Log" />}
                                            </Tabs>
                                        </AppBar>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <TabPanel value={currentTab} index={0}>
                                        {this.mappingOverview()}
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={1}>
                                        <Paper>{this.mappingTable()}</Paper>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={3}>
                                        {this.usersTable()}
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={4}>
                                        {this.userSubscriptions()}
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={5}>
                                        {this.manualMappings()}
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={6}>
                                        {this.domainsTable()}
                                    </TabPanel>
                                    {access.logs && (
                                        <TabPanel value={currentTab} index={2}>
                                            <Paper>{this.importLogs()}</Paper>
                                        </TabPanel>
                                    )}
                                    {this.state.userDialog.open && this.userDialog()}
                                    <LoadingScreen open={dataSave} title="Please Wait" message="BlueSync is updating your Microsoft 365 Subscription Mapping" />
                                    <ConfirmationDialog
                                        open={confirmation.mmSaved}
                                        success={() => this.handleConfirmationSuccess("mmSaved")}
                                        close={() => this.handleConfirmationClose("mmSaved")}
                                        title="Save Manual Mapping?"
                                        message="Are you sure you want to save these changes?"
                                    />
                                    <ConfirmationDialog
                                        open={confirmation.dataSaved}
                                        success={() => this.handleConfirmationSuccess("dataSaved")}
                                        close={() => this.handleConfirmationClose("dataSaved")}
                                        title="Save Microsoft 365 Subscription Mapping?"
                                        message="Are you sure you want to save these changes?"
                                    />
                                    <ConfirmationDialog
                                        open={confirmation.usersSaved}
                                        success={() => this.handleConfirmationSuccess("usersSaved")}
                                        close={() => this.handleConfirmationClose("usersSaved")}
                                        title="Save Contact Mapping?"
                                        message="Are you sure you want to save these changes?"
                                    />
                                    <ConfirmationDialog
                                        open={confirmation.formData}
                                        success={() => this.handleConfirmationSuccess("formData")}
                                        close={() => this.handleConfirmationClose("formData")}
                                        title="You have not saved your configuration!"
                                        message="Are you sure you want to leave the tab and discard your changes?"
                                    />
                                    <SnackBar
                                        autoHideDuration={2000}
                                        variant="success"
                                        anchorOriginVertical="bottom"
                                        anchorOriginHorizontal="right"
                                        open={snackbar.usersSaved}
                                        onClose={() => this.handleSnackbarClose("usersSaved")}
                                        message="Microsoft 365 User Contact Mapping Updated Successfully"
                                    />
                                    <SnackBar
                                        autoHideDuration={2000}
                                        variant="success"
                                        anchorOriginVertical="bottom"
                                        anchorOriginHorizontal="right"
                                        open={snackbar.dataSaved}
                                        onClose={() => this.handleSnackbarClose("dataSaved")}
                                        message="Microsoft 365 Subscription Mapping Updated Successfully"
                                    />
                                    <SnackBar
                                        autoHideDuration={2000}
                                        variant="success"
                                        anchorOriginVertical="bottom"
                                        anchorOriginHorizontal="right"
                                        open={snackbar.mmSaved}
                                        onClose={() => this.handleSnackbarClose("mmSaved")}
                                        message="Manual Mappings Updated Successfully"
                                    />
                                    <SnackBar
                                        autoHideDuration={2000}
                                        variant="success"
                                        anchorOriginVertical="bottom"
                                        anchorOriginHorizontal="right"
                                        open={snackbar.toggleContact}
                                        onClose={() => this.handleSnackbarClose("toggleContact")}
                                        message="ConnectWise Contact Status Toggled"
                                    />
                                    <SnackBar
                                        autoHideDuration={2000}
                                        variant="error"
                                        anchorOriginVertical="bottom"
                                        anchorOriginHorizontal="right"
                                        open={snackbar.error}
                                        onClose={() => this.handleSnackbarClose("error")}
                                        message="Something went wrong, please try again!"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    deploySnackBar: (message, variant) => dispatch(deploySnackBar(message, variant)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(MS365SubscriptionMapping));
