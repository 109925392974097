import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MaterialLink from '@material-ui/core/Link';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { COLOR_MAIN_VOIP } from 'ColorSchemes';
import { currencyFormat, handleTabChange } from '../../../Functions/MiscFunctions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

const styles = theme => ({
    tab: {
        color: '#fff',
        opacity: 1
    },
    tabIndicator: {
        backgroundColor: '#fff'
    },
})

const initialState = {
    currentTab: 0,
    batch: {},
    batchTotal: 0.00,
    billing: {},
    companies: 0,
    journal: {},
    isLoading: true
}

class VoipBillingView extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleTabChange = handleTabChange.bind(this);
    }

    componentDidMount = () => {
        this.props.pageTitle([1, 'VOIP and Connectivity', 'Billing']);
        this.loadComponentData();
    }

    loadComponentData = () => {
        API.get(`/voip/billing/${this.props.match.params.id}`)
        .then(result => {
            if(result.data) {
                let statements = {};
                _.each(result.data.batch.billing_statements, statement => {
                    statements[statement.vbs_voip_id] = statement.vbs_url || null;
                });
                this.setState({
                    batch: result.data.batch,
                    batchTotal: result.data.batchTotal,
                    billing: result.data.billing,
                    companies: result.data.companies,
                    journal: result.data.journal,
                    statements,
                    isLoading: false
                }, () => this.props.pageTitle([1, 'VOIP and Connectivity', 'Billing', this.state.batch.vs_ref]))
            }
        });
    }

    additions = () => {
        return (
            <Paper>
                <DataTable  
                    config={{
                        key: 'vsa_id',
                        responsiveImportance: true,
                        plainHeader: true,
                        pagination: true,
                        rowsPerPage: 100,
                        title: 'Mapping Overview',
                    }}
                    columns={[
                        {
                            heading: 'Company',
                            field: rowData => (rowData.addition && rowData.addition.agreement && rowData.addition.agreement.customer && rowData.addition.agreement.customer.cust_name) || '-',
                            important: true,
                        },
                        {
                            heading: 'Agreement',
                            field: rowData => (rowData.addition && rowData.addition.agreement && rowData.addition.agreement.cwa_cw_agreement_name) || '-',
                            important: true,
                        },
                        {
                            heading: 'Addition',
                            field: rowData => (rowData.addition && rowData.addition.cwaa_cw_product_identifier) || '-',
                            important: true,
                        },
                        {
                            heading: 'Transaction',
                            field: rowData => rowData.vsa_type === "services" ? "Service Charges" : "Call Charges",
                            important: true,
                        },
                        {
                            heading: 'Previous Quantity',
                            field: rowData => (rowData.lastSync && rowData.lastSync.vsa_quantity) || '-',
                            alignment: 'right',
                            important: true,
                        },
                        {
                            heading: 'New Quantity',
                            field: rowData => rowData.vsa_quantity,
                            alignment: 'right',
                            important: true,
                        },
                        {
                            heading: 'Previous Price',
                            field: rowData => (rowData.lastSync && rowData.lastSync.vsa_price && (rowData.lastSync.vsa_price === "0.00" ? "N/A" : currencyFormat.format(rowData.lastSync.vsa_price))) || '-',
                            alignment: 'right',
                            important: true,
                        },
                        {
                            heading: 'New Price',
                            field: rowData => rowData.vsa_price === "0.00" ? "N/A" : currencyFormat.format(rowData.vsa_price),
                            alignment: 'right',
                            important: true,
                        },
                    ]}
                    rows={_.sortBy(this.state.batch.additions, 'addition.agreement.customer.cust_name', 'addition.agreement.cwa_cw_agreement_name', 'addition.cwaa_cw_product_identifier')}
                />
            </Paper>
        )
    }

    companyBill = id => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item xs={8}>
                    <Typography variant="h6" style={{color: COLOR_MAIN_VOIP}}>
                        {this.state.billing[id].company} ({currencyFormat.format(this.state.billing[id].company_total)})
                    </Typography>
                </Grid>
                <Grid item xs={4} align='right'>
                    {(this.state.statements[id] && (
                        <MaterialLink href={this.state.statements[id]} target='_blank' style={{textDecoration: 'none'}} key={`downloadBill${id}`}>
                            <Button 
                                color="primary" 
                                size="small"
                                variant="text"
                            >   
                                <FAIcon icon="file-pdf" color="error" size="small" button />
                                Download Statement
                            </Button>
                        </MaterialLink>
                    )) || (
                        <Typography variant="body2" color="textSecondary">
                            No Statement Available
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {_.map(this.state.billing[id].services, (s, idx) => (
                        (!_.isEmpty(s.services) || !_.isEmpty(s.calls)) && (
                            <ExpansionPanel key={idx} TransitionProps={{ mountOnEnter: true }}>
                                <ExpansionPanelSummary
                                    expandIcon={<FAIcon icon="chevron-down" noMargin button />}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Typography variant="h6" style={{color: COLOR_MAIN_VOIP}}>
                                                {s.identifier}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} align="right">
                                            <Typography variant="h6" style={{color: COLOR_MAIN_VOIP}}>
                                                {currencyFormat.format(s.total)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={3}>
                                        {!_.isEmpty(s.services) && (
                                            <React.Fragment>
                                                <Grid item xs={8}>
                                                    <Typography variant="h6">
                                                        Service Charges
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} align="right">
                                                    <Typography variant="h6">
                                                        {currencyFormat.format(s.services_total)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <DataTable  
                                                        config={{
                                                            key: 'voip_sc_id',
                                                            responsiveImportance: true,
                                                            plainHeader: true, 
                                                        }}
                                                        columns={[
                                                            {
                                                                heading: 'Subscription',
                                                                field: rowData => rowData.service && rowData.service.voip_service_name,
                                                                important: true,
                                                            },
                                                            {
                                                                heading: 'Billing From',
                                                                field: rowData => rowData.voip_sc_billing_from,
                                                                fieldFormat: 'date',
                                                                important: true,
                                                            },
                                                            {
                                                                heading: 'Billing To',
                                                                field: rowData => rowData.voip_sc_billing_to,
                                                                fieldFormat: 'date',
                                                                important: true,
                                                            },
                                                            {
                                                                heading: 'Automated Billing',
                                                                field: rowData => rowData.voip_sc_type === "Automated" ? true : false,
                                                                fieldFormat: 'boolean',
                                                                alignment: 'center',
                                                                important: true,
                                                            },
                                                            {
                                                                heading: 'Quantity',
                                                                field: rowData => rowData.voip_sc_quantity,
                                                                alignment: 'right',
                                                                important: true,
                                                            },
                                                            {
                                                                heading: 'Total Price',
                                                                field: rowData => `${rowData.voip_sc_total_price}`,
                                                                fieldFormat: 'currency',
                                                                alignment: 'right',
                                                                important: true,
                                                            }
                                                        ]}
                                                        rows={s.services}
                                                    />
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                        {!_.isEmpty(s.calls) && (
                                            <React.Fragment>
                                                <Grid item xs={8}>
                                                    <Typography variant="h6">
                                                        Call Charges
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} align="right">
                                                    <Typography variant="h6">
                                                        {currencyFormat.format(s.calls_total)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <DataTable  
                                                        config={{
                                                            key: 'voip_cc_id',
                                                            alternatingRowColours: true,
                                                            responsiveImportance: true,
                                                            plainHeader: true, 
                                                        }}
                                                        columns={[
                                                            {
                                                                heading: 'Dialled Number',
                                                                field: rowData => rowData.voip_cc_dialled_no,
                                                                important: true,
                                                            },
                                                            {
                                                                heading: 'Call Date',
                                                                field: rowData => rowData.voip_cc_call_date,
                                                                fieldFormat: 'date',
                                                                important: true,
                                                            },
                                                            {
                                                                heading: 'Call Time',
                                                                field: rowData => rowData.voip_cc_call_time,
                                                                important: true,
                                                            },
                                                            {
                                                                heading: 'Call Duration',
                                                                field: rowData => rowData.voip_cc_call_duration,
                                                                important: true,
                                                            },
                                                            {
                                                                heading: 'Call Charge Code',
                                                                field: rowData => rowData.voip_cc_charge_code,
                                                                important: true,
                                                            },
                                                            {
                                                                heading: 'Call Cost',
                                                                field: rowData => `£${rowData.voip_cc_cost}`,
                                                                alignment: 'right',
                                                                important: true,
                                                            },
                                                        ]}
                                                        rows={s.calls}
                                                    />
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    ))}
                    {!_.isEmpty(this.state.billing[id].misc_calls) && (
                        <ExpansionPanel TransitionProps={{ mountOnEnter: true }}>
                            <ExpansionPanelSummary
                                expandIcon={<FAIcon icon="chevron-down" noMargin button />}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={8}>
                                        <Typography variant="h6" style={{color: COLOR_MAIN_VOIP}}>
                                            Miscellaneous Charges
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} align="right">
                                        <Typography variant="h6" style={{color: COLOR_MAIN_VOIP}}>
                                            {currencyFormat.format(this.state.billing[id].misc_total)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <DataTable  
                                            config={{
                                                key: 'voip_cc_id',
                                                alternatingRowColours: true,
                                                responsiveImportance: true,
                                                plainHeader: true, 
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Service Identifier',
                                                    field: rowData => rowData.voip_cc_service_identifier,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Dialled Number',
                                                    field: rowData => rowData.voip_cc_dialled_no,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Call Date',
                                                    field: rowData => rowData.voip_cc_call_date,
                                                    fieldFormat: 'date',
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Call Time',
                                                    field: rowData => rowData.voip_cc_call_time,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Call Duration',
                                                    field: rowData => rowData.voip_cc_call_duration,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Call Charge Code',
                                                    field: rowData => rowData.voip_cc_charge_code,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Call Cost',
                                                    field: rowData => `${rowData.voip_cc_cost}`,
                                                    fieldFormat: 'currency3',
                                                    alignment: 'right',
                                                    important: true,
                                                },
                                            ]}
                                            rows={this.state.billing[id].misc_calls}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )}
                </Grid>
            </Grid>
        );
    }

    render() {
        const { classes } = this.props;
        const { currentTab, batch, batchTotal, billing, journal, statements, isLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    <Grid item xs={12}>
                        {(isLoading && (
                            <LoadingCircle />
                        )) || (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>     
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <FAIcon type="thin" icon="file-invoice" size={70} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h3" className="fw-300">
                                                                {batch.vs_ref}
                                                            </Typography>
                                                        </Grid>    
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="arrow-right"
                                                                        label={`Billing Overview`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="check"
                                                                        label={`Status: ${batch.vs_status}`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="gbp"
                                                                        label={`Total: ${currencyFormat.format(batchTotal)}`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="calendar-alt"
                                                                        label={`Processed: ${batch.vbs_created_datetime === '0000-00-00 00:00:00' ? 'Unknown' : moment(batch.vbs_created_datetime).format('MMM D, YYYY')}`}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>     
                                                    </Grid> 
                                                </Grid>                                     
                                            </Grid>                                     
                                        </Grid> 

                                        <Grid item xs={12}>                                    
                                            <Paper>     
                                                <AppBar style={{backgroundColor: COLOR_MAIN_VOIP}} position="static" elevation={0}>   
                                                    <Tabs value={currentTab} onChange={this.handleTabChange} classes={{indicator: classes.tabIndicator}} variant="scrollable">
                                                        <Tab disableRipple value={0} label={`Overview`} />
                                                        <Tab disableRipple value={1} label={`Journal`} />
                                                        <Tab disableRipple value={2} label={`Mapping Overview (${_.size(batch.additions)})`} />
                                                        {_.map(billing, (comp, idx) => (
                                                            <Tab disableRipple key={idx} value={(idx+3)} label={`${comp.company} (${currencyFormat.format(comp.company_total)})`} />
                                                        ))}
                                                    </Tabs>                                     
                                                </AppBar>
                                            </Paper>
                                        </Grid>     
                                        <Grid item xs={12}>  
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={12}>
                                                        <Paper>
                                                            <DataTable  
                                                                config={{
                                                                    key: 'id',
                                                                    alternatingRowColours: true,
                                                                    responsiveImportance: true,
                                                                    plainHeader: true, 
                                                                    title: 'Company Statements',
                                                                    showFullTotals: true
                                                                }}
                                                                columns={[
                                                                    {
                                                                        heading: 'Company',
                                                                        field: rowData => rowData.company,
                                                                        important: true,
                                                                        showTotalLabel: true
                                                                    },
                                                                    {
                                                                        heading: 'Total',
                                                                        field: rowData => rowData.company_total,
                                                                        fieldFormat: 'currency',
                                                                        alignment: 'right',
                                                                        important: true,
                                                                        showTotal: true
                                                                    },
                                                                    {
                                                                        actions: rowData => {
                                                                            return [
                                                                                {name: "Download Statement", icon: 'file-pdf', color: statements?.[rowData?.id] && 'error', linkExternal: statements[rowData.id], disabled: !statements[rowData.id]}
                                                                            ]
                                                                        }
                                                                    }
                                                                ]}
                                                                rows={this.state.billing}
                                                            />
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="body2" className="fw-400">
                                                                <FAIcon icon="exclamation-circle" style={{color: COLOR_MAIN_VOIP}} size="small" />
                                                                The journal figures do not include service and call charges that are ignored at a company level
                                                            </Typography>
                                                        </PaddedPaper>
                                                    </Grid>
                                                    <Grid item xs={12} lg={4}>
                                                        <Paper>
                                                            <DataTable  
                                                                config={{
                                                                    key: 'id',
                                                                    responsiveImportance: true,
                                                                    plainHeader: true, 
                                                                    title: 'Service Charges',
                                                                    showFullTotals: true
                                                                }}
                                                                columns={[
                                                                    {
                                                                        heading: 'Nominal Code',
                                                                        field: rowData => `${rowData.nominalCode} - ${rowData.nominalDesc}`,
                                                                        important: true,
                                                                        showTotalLabel: true
                                                                    },
                                                                    {
                                                                        heading: 'Total',
                                                                        field: rowData => rowData.total,
                                                                        fieldFormat: 'currency',
                                                                        alignment: 'right',
                                                                        important: true,
                                                                        showTotal: true
                                                                    },
                                                                ]}
                                                                rows={[
                                                                    {
                                                                        id: 0,
                                                                        nominalCode: 5007,
                                                                        nominalDesc: 'Connectivity Purchases',
                                                                        total: journal['5007']['services']
                                                                    },
                                                                    {
                                                                        id: 1,
                                                                        nominalCode: 7270,
                                                                        nominalDesc: 'Internal Telephone and Mobile',
                                                                        total: journal['7270']['services']
                                                                    }
                                                                ]}
                                                            />
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12} lg={4}>
                                                        <Paper>
                                                            <DataTable  
                                                                config={{
                                                                    key: 'id',
                                                                    responsiveImportance: true,
                                                                    plainHeader: true, 
                                                                    title: 'Call Charges',
                                                                    showFullTotals: true
                                                                }}
                                                                columns={[
                                                                    {
                                                                        heading: 'Nominal Code',
                                                                        field: rowData => `${rowData.nominalCode} - ${rowData.nominalDesc}`,
                                                                        important: true,
                                                                        showTotalLabel: true
                                                                    },
                                                                    {
                                                                        heading: 'Total',
                                                                        field: rowData => rowData.total,
                                                                        fieldFormat: 'currency',
                                                                        alignment: 'right',
                                                                        important: true,
                                                                        showTotal: true
                                                                    },
                                                                ]}
                                                                rows={[
                                                                    {
                                                                        id: 0,
                                                                        nominalCode: 5007,
                                                                        nominalDesc: 'Connectivity Purchases',
                                                                        total: journal['5007']['calls']
                                                                    },
                                                                    {
                                                                        id: 1,
                                                                        nominalCode: 7270,
                                                                        nominalDesc: 'Internal Telephone and Mobile',
                                                                        total: journal['7270']['calls']
                                                                    }
                                                                ]}
                                                            />
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12} lg={4}>
                                                        <Paper>
                                                            <DataTable  
                                                                config={{
                                                                    key: 'id',
                                                                    responsiveImportance: true,
                                                                    plainHeader: true, 
                                                                    title: 'Hardware',
                                                                    showFullTotals: true
                                                                }}
                                                                columns={[
                                                                    {
                                                                        heading: 'Nominal Code',
                                                                        field: rowData => `${rowData.nominalCode} - ${rowData.nominalDesc}`,
                                                                        important: true,
                                                                        showTotalLabel: true
                                                                    },
                                                                    {
                                                                        heading: 'Total',
                                                                        field: rowData => rowData.total,
                                                                        fieldFormat: 'currency',
                                                                        alignment: 'right',
                                                                        important: true,
                                                                        showTotal: true
                                                                    },
                                                                ]}
                                                                rows={[
                                                                    {
                                                                        id: 0,
                                                                        nominalCode: 5000,
                                                                        nominalDesc: 'Purchases',
                                                                        total: journal['5000']['services']
                                                                    },
                                                                ]}
                                                            />
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                {this.additions()}
                                            </TabPanel>
                                            {_.map(billing, (comp, idx) => (
                                                <TabPanel key={idx} value={currentTab} index={(idx + 3)}>
                                                    {this.companyBill(comp.id)}
                                                </TabPanel>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(VoipBillingView);