import React from 'react';
import MaterialAlert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

const initialState = {
    open: true
}

export default class Alert extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleClose = () => {
        this.setState({open: false})
    }

    render() {
        return (
            <Collapse in={this.state.open}>
                <MaterialAlert 
                    severity={this.props.severity}
                    variant={this.props.variant}
                    onClose={this.props.closeable ? () => this.handleClose() : false}
                    style={{marginBottom: this.props.margin}}
                >
                    {this.props.children}
                </MaterialAlert>
            </Collapse>
        )
    }
}