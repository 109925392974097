export const COLOR_MAIN_DASHBOARD = "#638abe";
export const COLOR_MAIN_ACCOUNTS = "#4CAF50";
export const COLOR_MAIN_ACTION_CENTER = "#ff1744";
export const COLOR_MAIN_CONNECTWISE = "#6a1b9a";
export const COLOR_MAIN_MS365 = "#DC3E15";
export const COLOR_MAIN_MYCLOUD = "#0288D1";
export const COLOR_MAIN_SETTINGS = "#00ACC1";
export const COLOR_MAIN_SUPPORT_CONTRACTS = "#546E7A";
export const COLOR_MAIN_VOIP = "#6a1b9a";

export const COLOR_TEXT_ACCOUNTS = "#fff";
export const COLOR_TEXT_CONNECTWISE = "#fff";
export const COLOR_TEXT_MS365 = "#fff";
export const COLOR_TEXT_MYCLOUD = "#fff";
export const COLOR_TEXT_SUPPORT_CONTRACTS = "#fff";
export const COLOR_TEXT_VOIP = "#fff";
