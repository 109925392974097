const adminRouteAccessReferences = {
    /* Dashboard */
    "/dashboard": true,
    /* Accounts */
    "/accounts/batches/expenses/view": "accounts-batches-expenses-view",
    "/accounts/batches/expenses": "accounts-batches-expenses",
    "/accounts/batches/invoices/view": "accounts-batches-invoices-view",
    "/accounts/batches/invoices": "accounts-batches-invoices",
    "/accounts/logs": "accounts-logs",
    "/accounts/sage/auth": "sage-auth-history",
    "/accounts": "accounts-dashboard",
    /* Action Center */
    "/action-center": "action-center",
    /* ConnectWise */
    "/connectwise/companies/view": "connectwise-view",
    "/connectwise/companies": "connectwise-search",
    "/connectwise/logs": "connectwise-logs",
    "/connectwise/history": "connectwise-history",
    "/connectwise": "connectwise-dashboard",
    /* Microsoft 365 */
    "/ms365/csp/mapping/subscriptions": "ms365-subscription-mapping",
    "/ms365/csp/mapping/tenants": "ms365-tenant-mapping",
    "/ms365/nce/mapping/subscriptions": "ms365-nce-subscription-mapping",
    "/ms365/nce/mapping/tenants": "ms365-nce-tenant-mapping",
    "/ms365/subscription-types": "ms365-subscription-types",
    "/ms365/renewal-emails": "ms365-renewals",
    "/ms365/logs": "ms365-logs",
    "/ms365": "ms365-dashboard",
    /* MyCloud */
    "/mycloud/mapping/groups": "mycloud-group-mapping",
    "/mycloud/mapping/ou": "mycloud-ou-mapping",
    "/mycloud/logs": "mycloud-logs",
    "/mycloud": "mycloud-dashboard",
    /* Settings */
    "/settings/users/": "view-staff",
    "/settings/users": "staff-search",
    /* Support Contracts */
    "/support-contracts/companies": "sc-companies",
    "/support-contracts/logs": "sc-logs",
    "/support-contracts/mapping": "sc-mapping",
    "/support-contracts/": "sc-mapping-management",
    "/support-contracts": "sc-dashboard",
    /* VOIP and Connectivity */
    "/voip/billing/": "voip-billing-view",
    "/voip/billing": "voip-billing",
    "/voip/services/": "voip-services-view",
    "/voip/services": "voip-services",
    "/voip/companies": "voip-companies",
    "/voip/import/new": "voip-import",
    "/voip/import/": "voip-import-view",
    "/voip/import": "voip-import-search",
    "/voip/logs": "voip-logs",
    "/voip": "voip-dashboard",
    /*
     * Automatic access
     */
    "/my-account/": true,
    "/my-account": true,
};
export default adminRouteAccessReferences;
