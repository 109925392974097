import React from "react";
import { connect } from "react-redux";

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import FAIcon from "../Icons/FontAwesome/FAIcon";
import { handleAppError } from "Redux/Actions/UI/UI";
import { Box, Divider, Table, TableBody, TableCell, TableRow } from "@material-ui/core";

const styles = (theme) => ({
    dropzone: {
        height: 125,
        backgroundColor: "#fff",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "center",
        padding: "8px",
        zIndex: 9999999,
    },
    dropzoneArea: {
        backgroundColor: "#375885",
        borderRadius: 7.5,
        color: "#fff",
    },
    dropzoneDisabled: {
        backgroundColor: "rgba(187, 186, 186, 0.1)",
        color: "rgba(0,0,0,0.5)",
        cursor: "default",
    },
    dropzoneHover: {
        color: "#fff",
        backgroundColor: "#8fc3ea!important",
    },
    fileInput: {
        display: "none",
    },
});

const initialState = {
    fileKey: 1,
    dropzoneHover: false,
};

class DragFileInputMultiple extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.fileInputRef = React.createRef();
    }

    componentDidMount = () => {
        this.setState({
            fileKey: Math.random().toString(36),
        });
    };

    handleChange = (drop, name, event) => {
        if (drop) event.preventDefault();
        if (this.props.disabled) return;

        const files = drop === true ? event.dataTransfer.files : event.target.files;
        let err = false;
        const filesArray = Array.from(files);
        filesArray.forEach((file) => {
            if (this.props.primary) {
                if (file.name.substring(file.name.lastIndexOf(".") + 1) !== this.props.primary) {
                    err = true;
                }
            }
        });

        if (!err) {
            this.props.onChange(drop, name, files);
        } else {
            this.props.handleAppError("UPLOAD_FILE_INVALID", ".csv");
        }

        this.setState({
            dropzoneHover: false,
        });
    };

    handleClearFile = (fileIdx, cancelOnClick) => {
        this.setState(
            {
                fileKey: Math.random().toString(36),
            },
            () => this.props.cancelOnClick(fileIdx)
        );
    };

    handleDragOver = (event) => {
        event.preventDefault();
        if (this.props.disabled) return;
        this.setState({
            dropzoneHover: true,
        });
    };

    handleDragLeave = () => {
        this.setState({
            dropzoneHover: false,
        });
    };

    handleOpenDialog = () => {
        if (this.props.disabled) return;
        this.fileInputRef.current.click();
    };

    render() {
        const { fileKey, dropzoneHover } = this.state;
        const { classes, primary } = this.props;
        return (
            <FormControl error={!!this.props.errorText} fullWidth key={`file-${fileKey}`} margin="normal">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="body2">{this.props.label}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div
                            className={`${classes.dropzone} ${classes.dropzoneArea} ${dropzoneHover ? classes.dropzoneHover : ""} ${
                                this.props.disabled ? classes.dropzoneDisabled : ""
                            }`}
                            onDragOver={this.handleDragOver}
                            onDragLeave={this.handleDragLeave}
                            onDrop={(event) => this.handleChange(true, this.props.name, event)}
                        >
                            <div onClick={this.handleOpenDialog} style={{ cursor: "pointer", height: "100%", paddingTop: 24 }}>
                                <input
                                    className={`${classes.fileInput}`}
                                    disabled={this.props.disabled}
                                    file={this.props.file}
                                    id={this.props.id}
                                    name={this.props.name}
                                    onChange={(event) => this.handleChange(false, this.props.name, event)}
                                    ref={this.fileInputRef}
                                    type="file"
                                    multiple
                                />
                                {(dropzoneHover && <FAIcon icon="cloud-upload" size="large" buttonPrimary noMargin />) ||
                                    (primary && (
                                        <Grid container spacing={1} justify="center" alignItems="center">
                                            <Grid item>
                                                <FAIcon icon={`file-${primary}`} size="large" buttonPrimary noMargin />
                                            </Grid>
                                        </Grid>
                                    )) || (
                                        <Grid container spacing={1} justify="center" alignItems="center">
                                            <Grid item>
                                                <FAIcon icon="file-excel" buttonPrimary noMargin />
                                            </Grid>
                                            <Grid item>
                                                <FAIcon icon="file-video" style={{ width: 27.5, height: 27.5 }} buttonPrimary noMargin />
                                            </Grid>
                                            <Grid item>
                                                <FAIcon icon="file-image" size="large" buttonPrimary noMargin />
                                            </Grid>
                                            <Grid item>
                                                <FAIcon icon="file-pdf" style={{ width: 27.5, height: 27.5 }} buttonPrimary noMargin />
                                            </Grid>
                                            <Grid item>
                                                <FAIcon icon="file-csv" buttonPrimary noMargin />
                                            </Grid>
                                        </Grid>
                                    )}
                                {(dropzoneHover && <Typography variant="subtitle1">Drop file to upload it</Typography>) || (
                                    <Typography variant="subtitle1">Choose a file or drag it here</Typography>
                                )}
                            </div>
                        </div>
                    </Grid>
                </Grid>
                {this.props.errorText && (
                    <Grid item xs={12}>
                        <FormHelperText>{this.props.errorText}</FormHelperText>
                    </Grid>
                )}
                {(this.props.file && this.props.file.length > 0 && (
                    <Box mt={2}>
                        <Divider style={{ margin: "0 0" }} />
                        <Table>
                            <TableBody>
                                {Array.from(this.props.file).map((file, fileIdx) => (
                                    <TableRow>
                                        <TableCell>{file.name}</TableCell>
                                        <TableCell style={{ width: 1 }}>
                                            <span key={fileIdx} onClick={() => this.handleClearFile(fileIdx)}>
                                                <FAIcon type="thin" icon="times-circle" size="small" style={{ marginRight: 5 }} button />
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                )) || <React.Fragment />}
            </FormControl>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message)),
    };
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(DragFileInputMultiple));
