import React from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
/* Dashboard */
import Dashboard from "../../Dashboard/Dashboard";
/* Accounts */
import AccountsExpenseBatches from "../../Accounts/Batches/Expenses/Search/Search";
import AccountsExpenseBatchView from "../../Accounts/Batches/Expenses/View/View";
import AccountsInvoiceBatches from "../../Accounts/Batches/Invoices/Search/Search";
import AccountsInvoiceBatchView from "../../Accounts/Batches/Invoices/View/View";
import AccountsLogs from "../../Accounts/Logs/Logs";
import AccountsDashboard from "../../Accounts/Dashboard/Dashboard";
import SageCloudAuth from "../../Accounts/SageCloudAuth/SageCloudAuth";
/* Action Center */
import ActionCenter from "../../ActionCenter/ActionCenter";
/* ConnectWise */
import ConnectWiseDashboard from "../../ConnectWise/Dashboard/Dashboard";
import ConnectWiseLogs from "../../ConnectWise/Logs/Logs";
import ConnectWiseSearch from "../../ConnectWise/Search/Search";
import ConnectWiseView from "../../ConnectWise/View/View";
import CWHistory from "Components/ConnectWise/History/History";
/* MyAccount */
import MyAccount from "../../MyAccount/MyAccount";
/* Microsoft 365 */
import MS365Dashboard from "../../Microsoft365/Dashboard/Dashboard";
import MS365Logs from "../../Microsoft365/Logs/Logs";
import MS365RenewalEmails from "../../Microsoft365/RenewalEmails/RenewalEmails";
import MS365SubscriptionTypes from "../../Microsoft365/SubscriptionTypes/SubscriptionTypes";
import MS365Tenants from "../../Microsoft365/Tenants/Tenants";
import MS365TenantMapping from "../../Microsoft365/Mapping/Tenant/Tenant";
import MS365SubscriptionMapping from "../../Microsoft365/Mapping/Subscription/Subscription";
/* MyCloud */
import MyCloudDashboard from "../../MyCloud/Dashboard/Dashboard";
import MyCloudLogs from "../../MyCloud/Logs/Logs";
import MyCloudOUMapping from "../../MyCloud/Mapping/OU/OU";
import MyCloudGroupMapping from "../../MyCloud/Mapping/Group/Group";
/* Staff */
import StaffSearch from "../../Staff/StaffSearch/StaffSearch";
import ViewStaff from "../../Staff/ViewStaff/ViewStaff";
/* Support Contracts */
import ScDashboard from "../../SupportContracts/Dashboard/Dashboard";
import ScCompanies from "../../SupportContracts/Companies/Companies";
import ScLogs from "../../SupportContracts/Logs/Logs";
import ScMapping from "../../SupportContracts/Mapping/Mapping";
import ScMappingManagement from "../../SupportContracts/MappingManagement/MappingManagement";
/* VOIP and Connectivity */
import VoipDashboard from "../../Voip/Dashboard/Dashboard";
import VoipLogs from "../../Voip/Logs/Logs";
import VoipCompanies from "../../Voip/Companies/Companies";
import VoipBillingSearch from "../../Voip/Billing/Search";
import VoipBillingView from "../../Voip/Billing/View";
import VoipServicesSearch from "../../Voip/Services/Search";
import VoipServicesView from "../../Voip/Services/View";
import VoipImportSearch from "../../Voip/Import/Search";
import VoipImportView from "../../Voip/Import/View";
import VoipImport from "../../Voip/Import/Import";
/* Error Handling */
import Error404 from "Components/Common/ErrorHandling/Error404";
import { handleAppError } from "Redux/Actions/UI/UI";

class AdminRouteSwitcher extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.changePath(this.props.location.pathname);
        }
    }

    render() {
        const { pageTitle, scrollToTop } = this.props;
        return (
            <Switch>
                {/* Dashboard */}
                <Route
                    exact
                    path="/"
                    render={(props) => <Dashboard {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/dashboard"
                    render={(props) => <Dashboard {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />

                {/* Accounts */}
                <Route
                    exact
                    path="/accounts/batches/expenses/view/:id"
                    render={(props) => (
                        <AccountsExpenseBatchView {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />
                    )}
                />
                <Route
                    exact
                    path="/accounts/batches/expenses"
                    render={(props) => (
                        <AccountsExpenseBatches {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />
                    )}
                />
                <Route
                    exact
                    path="/accounts/batches/invoices/view/:id"
                    render={(props) => (
                        <AccountsInvoiceBatchView {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />
                    )}
                />
                <Route
                    exact
                    path="/accounts/batches/invoices"
                    render={(props) => (
                        <AccountsInvoiceBatches {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />
                    )}
                />
                <Route
                    exact
                    path="/accounts/logs"
                    render={(props) => <AccountsLogs {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/accounts/sage/auth"
                    render={(props) => <SageCloudAuth {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/accounts"
                    render={(props) => <AccountsDashboard {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />

                {/* Action Center */}
                <Route
                    exact
                    path="/action-center"
                    render={(props) => <ActionCenter {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />

                {/* ConnectWise */}
                <Route
                    exact
                    path="/connectwise/companies/view/:id"
                    render={(props) => <ConnectWiseView {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/connectwise/companies"
                    render={(props) => <ConnectWiseSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/connectwise/logs"
                    render={(props) => <ConnectWiseLogs {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/connectwise/history"
                    render={(props) => <CWHistory {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/connectwise"
                    render={(props) => (
                        <ConnectWiseDashboard {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />
                    )}
                />

                {/* MyAccount */}
                <Route
                    exact
                    path="/my-account"
                    render={(props) => <MyAccount {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />

                {/* Microsoft 365 */}
                <Route
                    exact
                    path="/ms365/tenants"
                    render={(props) => <MS365Tenants {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/ms365/csp/mapping/subscriptions/:id"
                    render={(props) => (
                        <MS365SubscriptionMapping
                            type="csp"
                            {...props}
                            pageTitle={pageTitle}
                            scrollToTop={scrollToTop}
                        />
                    )}
                />
                <Route
                    exact
                    path="/ms365/csp/mapping/tenants"
                    render={(props) => (
                        <MS365TenantMapping type="csp" {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />
                    )}
                />
                <Route
                    exact
                    path="/ms365/nce/mapping/subscriptions/:id"
                    render={(props) => (
                        <MS365SubscriptionMapping
                            type="nce"
                            {...props}
                            pageTitle={pageTitle}
                            scrollToTop={scrollToTop}
                        />
                    )}
                />
                <Route
                    exact
                    path="/ms365/nce/mapping/tenants"
                    render={(props) => (
                        <MS365TenantMapping type="nce" {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />
                    )}
                />
                <Route
                    exact
                    path="/ms365/logs/:id"
                    render={(props) => <MS365Logs {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/ms365/logs"
                    render={(props) => <MS365Logs {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/ms365/renewal-emails/:id"
                    render={(props) => (
                        <MS365RenewalEmails {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />
                    )}
                />
                <Route
                    exact
                    path="/ms365/renewal-emails"
                    render={(props) => (
                        <MS365RenewalEmails {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />
                    )}
                />
                <Route
                    exact
                    path="/ms365/subscription-types"
                    render={(props) => (
                        <MS365SubscriptionTypes {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />
                    )}
                />
                <Route
                    exact
                    path="/ms365"
                    render={(props) => <MS365Dashboard {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />

                {/* MyCloud */}
                <Route
                    exact
                    path="/mycloud/mapping/groups/:id"
                    render={(props) => (
                        <MyCloudGroupMapping {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />
                    )}
                />
                <Route
                    exact
                    path="/mycloud/mapping/ou"
                    render={(props) => <MyCloudOUMapping {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/mycloud/logs/:id"
                    render={(props) => <MyCloudLogs {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/mycloud/logs"
                    render={(props) => <MyCloudLogs {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/mycloud"
                    render={(props) => <MyCloudDashboard {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />

                {/* Settings */}
                <Route
                    exact
                    path="/settings/users/:id"
                    render={(props) => <ViewStaff {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/settings/users"
                    render={(props) => <StaffSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />

                {/* Support Contracts */}
                <Route
                    exact
                    path="/support-contracts/companies"
                    render={(props) => <ScCompanies {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/support-contracts/mapping/:id"
                    render={(props) => (
                        <ScMappingManagement {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />
                    )}
                />
                <Route
                    exact
                    path="/support-contracts/mapping"
                    render={(props) => <ScMapping {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/support-contracts/logs"
                    render={(props) => <ScLogs {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/support-contracts"
                    render={(props) => <ScDashboard {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />

                {/* VOIP and Connectivity */}
                <Route
                    exact
                    path="/voip/billing/:id"
                    render={(props) => <VoipBillingView {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/voip/billing"
                    render={(props) => <VoipBillingSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/voip/services/:id"
                    render={(props) => <VoipServicesView {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/voip/services"
                    render={(props) => (
                        <VoipServicesSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />
                    )}
                />
                <Route
                    exact
                    path="/voip/companies"
                    render={(props) => <VoipCompanies {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/voip/import/new"
                    render={(props) => <VoipImport {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/voip/import/:id"
                    render={(props) => <VoipImportView {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/voip/import"
                    render={(props) => <VoipImportSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/voip/logs"
                    render={(props) => <VoipLogs {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />
                <Route
                    exact
                    path="/voip"
                    render={(props) => <VoipDashboard {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />}
                />

                {/* 404 - Page Not Found */}
                <Route render={(props) => <Error404 {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
            </Switch>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message)),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(AdminRouteSwitcher));
