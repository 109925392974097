import React from "react";
import InsightChip from "Components/Common/Chips/InsightChip";
import { hex2rgba } from "Functions/MiscFunctions";

const StatusChip = ({ invert, label, status }) => {
    const config = {
            active: {
                icon: "check",
                color: "#4CAF50",
            },
            authorised: {
                icon: "check",
                color: "#4CAF50",
            },
            completed: {
                icon: "check",
                color: "#4CAF50",
            },
            resolved: {
                icon: "check",
                color: "#4CAF50",
            },
            outstanding: {
                icon: "times",
                color: "#d9534f",
            },
            complete: {
                icon: "check",
                color: "#4CAF50",
            },
            read: {
                icon: "check",
                color: "#4CAF50",
            },
            internal: {
                icon: "check",
                color: "#375885",
            },
            "awaiting review": {
                icon: "exclamation-triangle",
                color: "#d9534f",
            },
            "ready to bill": {
                icon: "check",
                color: "#1565C0",
            },
            ignored: {
                icon: "pause",
                color: "#1565C0",
            },
            skipped: {
                icon: "pause",
                color: "#1565C0",
            },
            billed: {
                icon: "check",
                color: "#4CAF50",
            },
            success: {
                icon: "check",
                color: "#4CAF50",
            },
            posted: {
                icon: "check",
                color: "#4CAF50",
            },
            posting: {
                icon: "times",
                color: "#F57C00",
            },
            missing: {
                icon: "exclamation-circle",
                color: "#F57C00",
            },
            raised: {
                icon: "check",
                color: "#d9534f",
            },
            customer: {
                icon: "exclamation-circle",
                color: "#4CAF50",
            },
            new: {
                icon: "exclamation-circle",
                color: "#F57C00",
            },
            pending: {
                icon: "hourglass",
                color: "#F57C00",
            },
            processing: {
                icon: "hourglass",
                color: "#F57C00",
            },
            queued: {
                icon: "hourglass-start",
                color: "#1565C0",
            },
            error: {
                icon: "exclamation-triangle",
                color: "#d9534f",
            },
            "on hold": {
                icon: "exclamation-triangle",
                color: "#d9534f",
            },
            "pending deletion": {
                icon: "times",
                color: "#d9534f",
            },
            terminated: {
                icon: "times",
                color: "#d9534f",
            },
            inactive: {
                icon: "times",
                color: "#d9534f",
            },
            finished: {
                icon: "times",
                color: "#d9534f",
            },
            sent: {
                icon: "paper-plane",
                color: "#F57C00",
            },
            timeout: {
                icon: "times",
                color: "#d9534f",
            },
            expired: {
                icon: "times",
                color: "#d9534f",
            },
            required_fields: {
                icon: "times",
                color: "#d9534f",
            },
            required_captcha: {
                icon: "times",
                color: "#d9534f",
            },
            required_mfa: {
                icon: "times",
                color: "#d9534f",
            },
            replay_mfa: {
                icon: "exclamation-triangle",
                color: "#d9534f",
            },
            incorrect_mfa: {
                icon: "times",
                color: "#d9534f",
            },
            incorrect_captcha: {
                icon: "times",
                color: "#d9534f",
            },
            incorrect_login: {
                icon: "times",
                color: "#d9534f",
            },
            ip_blocked: {
                icon: "exclamation-triangle",
                color: "#d9534f",
            },
            "in progress": {
                icon: "check",
                color: "#F57C00",
            },
            generated: {
                icon: "check",
                color: "#F57C00",
            },
            invoiced: {
                icon: "check",
                color: "#4CAF50",
            },
            enabled: {
                icon: "check",
                color: "#4CAF50",
            },
            valid: {
                icon: "check",
                color: "#4CAF50",
            },
            disabled: {
                icon: "check",
                color: "#d9534f",
            },
            closed: {
                icon: "check",
                color: "#d9534f",
            },
            ready: {
                icon: "check",
                color: "#F57C00",
            },
            cancelled: {
                icon: "times",
                color: "#d9534f",
            },
            resent: {
                icon: "exclamation-circle",
                color: "#03A9F4",
            },
            "sync blocked": {
                icon: "exclamation-triangle",
                color: "#d9534f",
            },
        },
        color = status ? config?.[status.toLowerCase?.() ?? -1]?.color ?? "#607D8B" : "#607D8B",
        icon = status ? config?.[status.toLowerCase?.() ?? -1]?.icon ?? "question" : "question";

    return (
        <InsightChip
            background={invert ? undefined : hex2rgba(color, 0.9)}
            color={invert ? hex2rgba(color, 0.9) : "#fff"}
            textDefault={invert ? true : false}
            icon={icon}
            type="solid"
            label={`${label ?? ""}${status}`}
            paddingLeft={8}
            paddingRight={4}
        />
    );
};

export default StatusChip;
