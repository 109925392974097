import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const styles = theme => ({
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    actionArea: {
        alignSelf: 'center',
        marginTop: 0,
        marginRight: 0,
    },
})

const initialState = {
    actions: {},
    actionResolutions: [],
    confirmation: {
        resolveErrors: false
    },
    totals: {},
    expanded: {},
    filterActions: null,
    formData: {},
    initialFormData: {},
    isLoading: true,
    isResolving: false,
    snackbar: {
        resolve: false,
    },
}

class ActionCenter extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Action Center']);
        this.loadComponentData();
    }

    loadComponentData = () => {
        API.get('/actionCenter')
        .then(result => {
            if(result.data) {
                let initialFormData = {}
                let initialExpanded = {}
                _.each(result.data.actions, service => {
                    _.each(service, action => {
                        initialFormData = {
                            ...initialFormData,
                            [action.action_id]: ''
                        }
                        initialExpanded = {
                            ...initialExpanded,
                            [action.action_id]: true
                        }
                    })
                });
                this.setState({
                    actions: result.data.actions,
                    formData: initialFormData,
                    initialFormData,
                    expanded: initialExpanded,
                    totals: result.data.totals,
                    isLoading: false
                }) 
            }
        });
    }  

    handleReload = () => {
        this.setState({
            isLoading: true
        }, () => this.loadComponentData())      
    }

    handleFilterActions = selectedService => {
        this.setState({
            filterActions: (selectedService && selectedService.value) || null
        })        
    }

    handleActionResolutionSelect = (actionId, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [actionId]: this.state.formData[actionId] === value ? "" : value
            }
        })
    }
    
    handleSnackbarClose = type => {
        this.setState({ 
            snackbar: {
                ...this.state.snackbar,
                [type]: false
            }
        })
    };

    handleExpandAction = id => {
        this.setState({
            expanded: {
                ...this.state.expanded,
                [id]: !this.state.expanded[id]
            }
        })
    }

    handleConfirmationOpen = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: true
            },
        });
    };

    handleConfirmationClose = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false
            },
        });
    };

    handleConfirmationSuccess = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false
            },
        }, () => this.handleSubmit())
    }

    
    renderAgreement = (allAgreements, agreement, idx, level) => {
        const children = _.filter(allAgreements, child => { return child.cwa_parent_id === agreement.cwa_id });
        return (
            <React.Fragment key={idx}>
                <Grid item xs={12} key={idx}>   
                    <Typography style={{textDecoration: agreement.cwa_status !== 'Active' ? 'line-through' : 'none'}} variant="body2" className="fw-400" gutterBottom>
                        {idx + 1}) {agreement.cwa_cw_agreement_name}
                    </Typography>           
                    {(_.size(agreement.additions) > 0 && (                                                                                                        
                        _.map(_.filter(agreement.additions, el => el.mappingCount > 0), (addition, idx) => (              
                            <Grid item xs={12} key={idx}>  
                                <Typography variant="caption" style={{textDecoration: addition.cwaa_status !== 'Active' ? 'line-through' : 'none'}} >  
                                    > {addition.cwaa_cw_product_identifier} {(addition.mappingCount > 0 && <em>(Mapped to {addition.mappingCount} products/services in BlueSync)</em>) || <em>(Not mapped in BlueSync)</em>}
                                </Typography>
                            </Grid>    
                        ))
                    )) || (
                        <Grid item xs={12} key={idx}>  
                            <Typography variant="caption" paragraph style={{textDecoration: agreement.cwa_status !== 'Active' ? 'line-through' : 'none'}} >
                                This agreement has no additions
                            </Typography>
                        </Grid>   
                    )}   
                </Grid> 
                {_.size(children) > 0 && ( _.map(children, (childAgreement, cidx) => (this.renderAgreement(allAgreements, childAgreement, cidx, (level+1)))))}
            </React.Fragment>
        )                                                      
    }

    handleSubmit = () => {
        this.setState({
            isResolving: true
        },
        () => {
            API.post('/actionCenter', { actions: this.state.formData })
            .then(result => {
                if(result.data) {
                    this.setState({
                        isResolving: false,
                        snackbar: {
                            ...this.state.snackbar,
                            resolve: true
                        }
                    }, () => {
                        this.loadComponentData();
                    })
                }
            })
        })
    }

    renderActionCard = (action, idx) => {
        const { classes } = this.props;
        const { expanded, formData } = this.state;
        return (
            <Grid item xs={12} key={idx}>
                <Card
                >
                    <CardHeader
                        title={<Typography variant="h6" className="fw-300" style={{color: action.action_type === 'Success' ? '#1565C0' : (action.action_type === 'Notice' ? '#F57C00' : '#d9534f')}}>{action.action_title}</Typography>}
                        subheader={<Typography component="div" variant="caption" color="textSecondary" paragraph>{moment(action.action_created_datetime).format("DD/MM/YYYY HH:mm")}</Typography>}
                        // title={<Typography variant="h6" className="fw-300" style={{color: action.action_type === 'Success' ? '#1565C0' : (action.action_type === 'Notice' ? '#F57C00' : '#d9534f')}}>{moment(action.action_created_datetime).format("DD/MM/YYYY HH:mm")} - {action.action_title}</Typography>}
                        disableTypography
                        // style={{backgroundColor: action.action_type === 'Success' ? '#1565C0' : (action.action_type === 'Notice' ? '#F57C00' : '#d9534f'), padding: '0 0 0 15px'}}
                        classes={{action: classes.actionArea}}
                        style={{paddingBottom: expanded[action.action_id] ? 0 : undefined}}
                        action={
                            <Grid container>
                                <Grid item>
                                    <FormControl fullWidth>                                        
                                        <FormGroup style={{flexDirection: 'row-reverse'}}>
                                            {action.action_recommended !== 'None' && (
                                                <FormControlLabel
                                                    value={action.action_recommended}
                                                    control={<Checkbox color="primary" onChange={(e) => this.handleActionResolutionSelect(action.action_id, action.action_recommended)} checked={formData[action.action_id] === action.action_recommended ? true : false} />}
                                                    label={<Typography variant="caption" className="fw-400">{action.action_recommended}</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}                    
                                            {(action.action_module === 'ConnectWise' && (                   
                                                <FormControlLabel
                                                    value='Resolved'                                                    
                                                    control={<Checkbox color="primary" onChange={(e) => this.handleActionResolutionSelect(action.action_id, 'Resolved')} checked={formData[action.action_id] === 'Resolved' ? true : false} />}
                                                    label={<Typography variant="caption" className="fw-400">Resolved in ConnectWise</Typography>}
                                                    labelPlacement="end"
                                                    style={{marginRight: 48}}
                                                />                  
                                            ))}   
                                            <FormControlLabel
                                                value='None'
                                                control={<Checkbox color="primary" onChange={(e) => this.handleActionResolutionSelect(action.action_id, 'None')} checked={formData[action.action_id] === 'None' ? true : false} />}
                                                label={<Typography variant="caption" className="fw-400">Dismiss</Typography>}
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                    </FormControl>   
                                </Grid>
                                <Grid item>
                                    <Tooltip title={expanded[action.action_id] ? 'Hide' : 'Show More'}>                   
                                        <IconButton
                                            className={clsx(classes.expand, {
                                                [classes.expandOpen]: expanded[action.action_id],
                                            })}
                                            onClick={() => this.handleExpandAction(action.action_id)}
                                        >
                                            <FAIcon icon="chevron-down" noMargin button />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        }
                    />
                    <Collapse in={expanded[action.action_id]} timeout="auto" unmountOnExit>
                        <CardContent style={{paddingTop: 0}}>          
                            <Typography variant="subtitle1" className="fw-400" gutterBottom>
                                {action.action_type === 'Success' ? 'Information' : 'Issue'}
                            </Typography>
                            <Typography variant="caption">
                                {action.action_text.split('\n').map((item, i) => (
                                    <div key={i}>{item}</div>
                                ))}
                            </Typography>                    
                            {(action.company && action.company !== null && (
                                <React.Fragment>
                                    <Typography variant="subtitle1" className="fw-400" style={{paddingTop:15}} gutterBottom>
                                        Company
                                    </Typography>
                                    <Typography variant="caption">
                                        {action.company.cust_name}
                                    </Typography>
                                    {action.action_title === "Company Pending Deletion" && !_.isEmpty(action.company?.agreements) && (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" className="fw-400" style={{paddingTop:15}} gutterBottom>
                                                    Mapped Agreements
                                                </Typography>
                                            </Grid>
                                            {_.map(action.company?.agreements, (agreement, idx) => {     
                                                if(agreement.cwa_parent_id === 0) {     
                                                    return this.renderAgreement(action.company?.agreements, agreement, idx, 0);
                                                }                                                
                                            })}
                                        </Grid>
                                    )}
                                </React.Fragment>
                            ))}                                                          
                            {(action.agreement && action.agreement !== null && ( 
                                <React.Fragment>
                                    <Typography variant="subtitle1" className="fw-400" style={{paddingTop:15}} gutterBottom>
                                        ConnectWise
                                    </Typography>
                                    <Typography variant="caption" paragraph>
                                        {action.agreement && action.agreement !== null && (
                                            <React.Fragment>
                                                <strong>Agreement:</strong> {action.agreement.cwa_cw_agreement_name}
                                            </React.Fragment>
                                        )}
                                        {action.addition && action.addition !== null && (
                                            <React.Fragment>
                                                <br />
                                                <strong>Addition</strong> {action.addition.cwaa_cw_product_identifier}
                                            </React.Fragment>
                                        )}
                                    </Typography>
                                </React.Fragment>
                            ))}
                            {((action.my_cloud_group && action.my_cloud_group !== null) || (action.my_cloud_adou && action.my_cloud_adou.adou_name !== null)) && (   
                                <React.Fragment>      
                                    <Typography variant="subtitle1" className="fw-400" style={{paddingTop:15}} gutterBottom>
                                        MyCloud Details
                                    </Typography>
                                    <Typography variant="caption">
                                        {action.my_cloud_adou && action.my_cloud_adou.adou_name !== null && (
                                            <React.Fragment>
                                                <strong>MyCloud OU:</strong> {action.my_cloud_adou.adou_name}<br />
                                            </React.Fragment>
                                        )}
                                        {action.my_cloud_group && action.my_cloud_group !== null && (
                                            <React.Fragment>
                                                <strong>MyCloud Group:</strong> {action.my_cloud_group.adg_name}
                                            </React.Fragment>
                                        )}
                                    </Typography>
                                </React.Fragment>
                            )}
                            {((action.office365_subscription && action.office365_subscription !== null) || (action.office365_company && action.office365_company.mso_company_name !== null)) && (
                                <React.Fragment>      
                                    <Typography variant="subtitle1" className="fw-400" style={{paddingTop:15}} gutterBottom>
                                        Microsoft 365 Details
                                    </Typography>
                                    <Typography variant="caption">
                                        {action.office365_company && action.office365_company.mso_company_name !== null && (
                                            <React.Fragment>
                                                <strong>Microsoft 365 Tenant:</strong> {action.office365_company.mso_company_name}<br />
                                            </React.Fragment>
                                        )}
                                        {action.office365_subscription && action.office365_subscription !== null && (
                                            <React.Fragment>
                                                <strong>Microsoft 365 Subscription:</strong> {action.office365_subscription.subscription_type.msost_name}
                                            </React.Fragment>
                                        )}
                                    </Typography>
                                </React.Fragment>
                            )}
                            {(action.sc_site || (action.sc_company && action.sc_company.sc_comp_name !== null)) && (
                                <React.Fragment>      
                                    <Typography variant="subtitle1" className="fw-400" style={{paddingTop:15}} gutterBottom>
                                        Support Contract Details
                                    </Typography>
                                    <Typography variant="caption">
                                        {action.sc_company && action.sc_company.sc_comp_name !== null && (
                                            <React.Fragment>
                                                <strong>Company:</strong> {action.sc_company.sc_comp_name}<br />
                                            </React.Fragment>
                                        )}
                                        {action.sc_site && action.sc_site !== null && (
                                            <React.Fragment>
                                                <strong>Site:</strong> {action.sc_site.sc_site_name}
                                            </React.Fragment>
                                        )}
                                    </Typography>
                                </React.Fragment>
                            )}
                        </CardContent>
                    </Collapse>
                </Card>
            </Grid>
        )
    }

    renderNoActions = service => (        
        <Grid item xs={12}>
            <Box pl={1}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                        <FAIcon icon="check-circle" size={25} style={{color: '#388E3C'}} />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h5" className="fw-300">
                            There are no issues with {service}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
    
    render() {
        const { actions, confirmation, filterActions, formData, initialFormData, isLoading, isResolving, snackbar, totals } = this.state;    
        return (
            <Grid container spacing={3}>                
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (    
                    <React.Fragment>                       
                        <Grid item xs={12} lg={8}>
                            <Grid container spacing={5}> 
                                <Grid item xs={12}>
                                    <Box maxWidth={320}>
                                        <AutoCompleteSelect
                                            options={[                                                        
                                                {value: 'Accounting', label: 'Accounting'},              
                                                {value: 'ConnectWise', label: 'ConnectWise'},
                                                {value: 'MyCloud', label: 'MyCloud'},
                                                {value: 'VOIP', label: 'VOIP & Connectivity'},
                                                {value: 'SC', label: 'Support Contracts'},
                                                {value: 'MS365', label: 'Microsoft 365'}
                                            ]}
                                            onChange={this.handleFilterActions}
                                            value={filterActions}
                                            placeholder='Filter:'
                                            adornment='filter'
                                            variant='filled'
                                        />
                                    </Box>
                                </Grid> 
                                {(filterActions === 'Accounting' || filterActions === null) && (
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item>
                                                <FAIcon type="thin" icon="coins" size="large" noMargin/>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h4">
                                                    Accounts
                                                </Typography>
                                            </Grid>
                                            {(totals.accounting > 0 && (
                                                _.map(actions.accounting, (action, idx) => (
                                                    this.renderActionCard(action, idx)
                                                ))
                                            )) || (    
                                                this.renderNoActions('Accounts')
                                            )}
                                        </Grid>
                                    </Grid>        
                                )}   
                                {(filterActions === 'ConnectWise' || filterActions === null) && (
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item>
                                                <FAIcon type="thin" icon="users" size="large" noMargin/>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h4">
                                                    ConnectWise
                                                </Typography>
                                            </Grid>
                                            {(totals.connectwise > 0 && (
                                                _.map(actions.connectwise, (action, idx) => (
                                                    this.renderActionCard(action, idx)
                                                ))
                                            )) || (    
                                                this.renderNoActions('ConnectWise')
                                            )}
                                        </Grid>
                                    </Grid>        
                                )}
                                {(filterActions === 'MyCloud' || filterActions === null) && (    
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item>
                                                <FAIcon type="thin" icon="cloud" size="large" noMargin/>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h4">
                                                    MyCloud
                                                </Typography>
                                            </Grid>
                                            {(totals.mycloud > 0 && (
                                                _.map(actions.mycloud, (action, idx) => (
                                                    this.renderActionCard(action, idx)
                                                ))
                                            )) || (
                                                this.renderNoActions('MyCloud')
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                                {(filterActions === 'MS365' || filterActions === null) && (    
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item>
                                                <FAIcon type="thin" icon="file-word" size="large" noMargin/>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h4">
                                                    Microsoft 365
                                                </Typography>
                                            </Grid>
                                            {(totals.ms365 > 0 && (
                                                _.map(actions.ms365, (action, idx) => (
                                                    this.renderActionCard(action, idx)
                                                ))
                                            )) || (
                                                this.renderNoActions('Microsoft 365 Actions')
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                                {(filterActions === 'VOIP' || filterActions === null) && (    
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item>
                                                <FAIcon type="thin" icon="phone-office" size="large" noMargin/>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h4">
                                                    VOIP &amp; Connectivity
                                                </Typography>
                                            </Grid>
                                            {(totals.voip > 0 && (
                                                _.map(actions.voip, (action, idx) => (
                                                    this.renderActionCard(action, idx)
                                                ))
                                            )) || (
                                                this.renderNoActions('VOIP & Connectivity')
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                                {(filterActions === 'SC' || filterActions === null) && (    
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item>
                                                <FAIcon type="thin" icon="desktop" size="large" noMargin/>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h4">
                                                    Support Contracts
                                                </Typography>
                                            </Grid>
                                            {(totals.sc > 0 && (
                                                _.map(actions.sc, (action, idx) => (
                                                    this.renderActionCard(action, idx)
                                                ))
                                            )) || (
                                                this.renderNoActions('Support Contracts')
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid item xs={12} align='right'>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.handleConfirmationOpen('resolveErrors')}
                                        disabled={isResolving ? true : (totals.all > 0 && initialFormData !== formData ? false : true)}
                                        size="large"
                                    >   
                                        <FAIcon disabled={isResolving ? true : (totals.all > 0 && initialFormData !== formData ? false : true)} icon="tools" size="small" buttonPrimary />
                                        Resolve Actions
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                   </React.Fragment>        
                )}
                <ConfirmationDialog 
                    open={confirmation.resolveErrors} 
                    success={() => this.handleConfirmationSuccess('resolveErrors')} 
                    close={() => this.handleConfirmationClose('resolveErrors')} 
                    title="Resolve actions?" 
                    message="This will resolve all actions based on your chosen resolution"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={snackbar.resolve}
                    onClose={() => this.handleSnackbarClose('resolve')}
                    message='The actions you requested have been completed'
                />
            </Grid>
        )
    }
}

export default withStyles(styles)(ActionCenter);