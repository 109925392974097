import React from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import API from "API";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import Tile from "Components/Common/Dashboard/Tile";
import { COLOR_MAIN_ACCOUNTS } from "ColorSchemes";

const initialState = {
    isLoading: true,
    sageCloudConnected: true,
    charts: {
        batchInvoices: {
            invoices: [],
            invoiceTotals: [],
        },
        batchExpenses: {
            expenses: [],
            expensesTotals: [],
        },
    },
    insight: {},
};

class AccountsDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.props.pageTitle([1, "Accounts", "Dashboard"]);
        this.loadComponentData();
    };

    loadComponentData = () => {
        Promise.all([API.get("/bluesync/accounts/batches/insight"), API.get("/bluesync/accounts/batches/insight/charts"), API.get("/bluesync/accounts/status")]).then(
            ([insight, charts, sageCloudConnected]) => {
                let labels = [];
                let biInvoices = [];
                let biInvoicesTotal = [];
                let beExpenses = [];
                let beExpensesTotal = [];

                for (let i = 0, j = 11; i <= j; i++) {
                    biInvoices.push(parseInt(charts.data[i].invoices.invoices));
                    biInvoicesTotal.push(parseFloat(charts.data[i].invoices.total));
                    beExpenses.push(parseInt(charts.data[i].expenses.expenses));
                    beExpensesTotal.push(parseFloat(charts.data[i].expenses.total));
                    labels.push(charts.data[i].label);
                }

                const chartBiInvoices = {
                    labels: labels,
                    datasets: [
                        {
                            lineTension: 0.1,
                            backgroundColor: "rgba(38,166,154,0.5)",
                            borderColor: "#26A69A",
                            borderCapStyle: "butt",
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: "miter",
                            pointBorderColor: "#26A69A",
                            pointBackgroundColor: "#fff",
                            pointBorderWidth: 4,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: "#fff",
                            pointHoverBorderColor: "#26A69A",
                            pointHoverBorderWidth: 2,
                            pointRadius: 2,
                            pointHitRadius: 10,
                            fill: true,
                            data: biInvoices,
                        },
                    ],
                };

                const chartBiTotal = {
                    labels: labels,
                    datasets: [
                        {
                            lineTension: 0.1,
                            backgroundColor: "rgba(212,225,87,0.5)",
                            borderColor: "#D4E157",
                            borderCapStyle: "butt",
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: "miter",
                            pointBorderColor: "#D4E157",
                            pointBackgroundColor: "#fff",
                            pointBorderWidth: 4,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: "#fff",
                            pointHoverBorderColor: "#D4E157",
                            pointHoverBorderWidth: 2,
                            pointRadius: 2,
                            pointHitRadius: 10,
                            fill: true,
                            data: biInvoicesTotal,
                        },
                    ],
                };

                const chartBeExpenses = {
                    labels: labels,
                    datasets: [
                        {
                            lineTension: 0.1,
                            backgroundColor: "rgba(239,83,80,0.5)",
                            borderColor: "#ef5350",
                            borderCapStyle: "butt",
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: "miter",
                            pointBorderColor: "#ef5350",
                            pointBackgroundColor: "#fff",
                            pointBorderWidth: 4,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: "#fff",
                            pointHoverBorderColor: "#ef5350",
                            pointHoverBorderWidth: 2,
                            pointRadius: 2,
                            pointHitRadius: 10,
                            fill: true,
                            data: beExpenses,
                        },
                    ],
                };

                const chartBeTotal = {
                    labels: labels,
                    datasets: [
                        {
                            lineTension: 0.1,
                            backgroundColor: "rgba(236,64,122,0.5)",
                            borderColor: "#EC407A",
                            borderCapStyle: "butt",
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: "miter",
                            pointBorderColor: "#EC407A",
                            pointBackgroundColor: "#fff",
                            pointBorderWidth: 4,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: "#fff",
                            pointHoverBorderColor: "#EC407A",
                            pointHoverBorderWidth: 2,
                            pointRadius: 2,
                            pointHitRadius: 10,
                            fill: true,
                            data: beExpensesTotal,
                        },
                    ],
                };

                this.setState({
                    isLoading: false,
                    charts: {
                        batchInvoices: {
                            invoices: chartBiInvoices,
                            invoiceTotals: chartBiTotal,
                        },
                        batchExpenses: {
                            expenses: chartBeExpenses,
                            expensesTotals: chartBeTotal,
                        },
                    },
                    sageCloudConnected: sageCloudConnected?.data?.status === 1,
                    insight: insight.data.insight,
                });
            }
        );
    };

    render() {
        const { charts, sageCloudConnected, isLoading, insight } = this.state;
        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={8} xl={9}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Invoice Net Totals
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.batchInvoices.invoiceTotals}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                    callback: (value, index, values) => {
                                                                                        return value.toLocaleString("en-GB", {
                                                                                            style: "currency",
                                                                                            currency: "GBP",
                                                                                        });
                                                                                    },
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Expenses Net Totals
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.batchExpenses.expensesTotals}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                    callback: (value, index, values) => {
                                                                                        return value.toLocaleString("en-GB", {
                                                                                            style: "currency",
                                                                                            currency: "GBP",
                                                                                        });
                                                                                    },
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Invoices Posted
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.batchInvoices.invoices}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                    userCallback: function (label, index, labels) {
                                                                                        if (Math.floor(label) === label) {
                                                                                            return label;
                                                                                        }
                                                                                    },
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Expenses Posted
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.batchExpenses.expenses}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                    userCallback: function (label, index, labels) {
                                                                                        if (Math.floor(label) === label) {
                                                                                            return label;
                                                                                        }
                                                                                    },
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={4} xl={3}>
                                    <Grid container spacing={3}>
                                        <Tile
                                            dashboard
                                            icon="thermometer-half"
                                            color={sageCloudConnected ? COLOR_MAIN_ACCOUNTS : "#e53935"}
                                            backgroundColor={"#fff"}
                                            data={sageCloudConnected ? "Connected" : "Unauthorised"}
                                            title="Sage Business Cloud Accounting Status"
                                        />
                                        <Tile
                                            dashboard
                                            icon="file-invoice-dollar"
                                            color={COLOR_MAIN_ACCOUNTS}
                                            backgroundColor={"#fff"}
                                            data={insight ? insight.invoiceBatches : 0}
                                            title="Invoice Batches"
                                        />
                                        <Tile
                                            dashboard
                                            icon="receipt"
                                            color={COLOR_MAIN_ACCOUNTS}
                                            backgroundColor={"#fff"}
                                            data={insight ? insight.expenseBatches : 0}
                                            title="Expenses Batches"
                                        />
                                        <Tile
                                            dashboard
                                            icon="file-invoice-dollar"
                                            color={COLOR_MAIN_ACCOUNTS}
                                            backgroundColor={"#fff"}
                                            data={insight ? insight.totalInvoices : 0}
                                            title="Invoices Processed"
                                        />
                                        <Tile
                                            dashboard
                                            icon="receipt"
                                            color={COLOR_MAIN_ACCOUNTS}
                                            backgroundColor={"#fff"}
                                            data={insight ? insight.totalExpenses : 0}
                                            title="Expenses Processed"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

export default AccountsDashboard;
