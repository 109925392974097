import React  from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import { COLOR_MAIN_VOIP, COLOR_TEXT_VOIP } from 'ColorSchemes';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import InsightChip from 'Components/Common/Chips/InsightChip';
import Tile from 'Components/Common/Dashboard/Tile';
import { currencyFormat, handleTabChange } from '../../../Functions/MiscFunctions';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { CLOUDFRONT_URL } from 'Constants';

function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

const styles = theme => ({
    tab: {
        color: '#fff',
        opacity: 1
    },
    tabIndicator: {
        backgroundColor: '#fff'
    },
})

const initialState = {
    access: {
        reviewImport: false,
    }, 
    confirmation: {
        accept: false,
        discard: false,
    },
    currentTab: 0,
    dataImport: {},
    dialog: false,
    dialogAdditionId: 0,
    importId: 0,
    isLoading: true,
}

class VoipImportView extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleTabChange = handleTabChange.bind(this);
    }

    componentDidMount = () => {
        let importId = this.props.id ? this.props.id : this.props.match.params.id
        this.setState({
            importId
        }, 
        ()=> {
            this.loadComponentData();
        });
    }

    acceptBatch = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.put('/voip/import/' + this.state.importId, { action: 'accept', insight: this.state.dataImport.import_insight })
            .then(res => {
                if(res.data) {
                    this.loadComponentData();
                    this.props.deploySnackBar("success", `This Data Import Batch has been successfully accepted and is ready to bill`);
                }
            })
        })
    }

    discardBatch = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.put('/voip/import/' + this.state.importId, { action: 'discard' })
            .then(res => {
                if(res.data) {
                    this.props.deploySnackBar("success", `This Data Import Batch has been successfully discarded`);
                    this.props.history.push('/voip/import');
                }
            })
        })
    }

    loadComponentData = () => {
        Promise.all([
            API.get('/voip/import/' + this.state.importId),
            API.access('voip-import-review'),
        ])
        .then(([result, reviewImport]) => {  
            if(result.data.errors) {
                this.props.history.push('/voip/import')
            } else {
                this.setState({
                    ...this.state,
                    access: {
                        reviewImport: (reviewImport.data && reviewImport.data.has_access) || false,
                    },
                    dataImport: result.data,
                    isLoading: false,
                }, () => this.props.pageTitle([1, 'VOIP and Connectivity', `${this.state.dataImport.vdi_ref}`]));
            }
        })
    }

    handleConfirmationOpen = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: true
            },
        });
    }

    handleConfirmationClose = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false
            },
        });
    }

    handleConfirmationSuccess = type => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false
            },
        }, () => {
            if (type === 'accept') {
                this.acceptBatch();
            } else {
                this.discardBatch();
            }
        })
    }

    handleServiceChargesDialog = (dialog, dialogAdditionId) => {
        this.setState({
            dialog,
            dialogAdditionId
        })
    }

    additions = () => {
        return (
            <Paper>
                <DataTable  
                    config={{
                        key: 'id',
                        responsiveImportance: true,
                        plainHeader: true, 
                        pagination: true,
                        rowsPerPage: 100,
                        title: 'Mapping Overview',
                    }}
                    columns={[
                        {
                            heading: 'Company',
                            field: rowData => rowData.company,
                            important: true,
                        },
                        {
                            heading: 'Agreement',
                            field: rowData => rowData.agreement,
                            important: true,
                        },
                        {
                            heading: 'Addition',
                            field: rowData => rowData.addition,
                            important: true,
                        },
                        {
                            heading: 'Transaction',
                            field: rowData => rowData.type === "services" ? "Service Charges" : "Call Charges",
                            important: true,
                        },
                        {
                            heading: 'Previous Quantity',
                            field: rowData => (rowData.lastSync && rowData.lastSync.vsa_quantity) || '-',
                            alignment: 'right',
                            important: true,
                        },
                        {
                            heading: 'New Quantity',
                            field: rowData => rowData.qty,
                            alignment: 'right',
                            important: true,
                        },
                        {
                            heading: 'Previous Price',
                            field: rowData => (rowData.lastSync && rowData.lastSync.vsa_price && (rowData.lastSync.vsa_price === "0.00" ? "N/A" : currencyFormat.format(rowData.lastSync.vsa_price))) || '-',
                            alignment: 'right',
                            important: true,
                        },
                        {
                            heading: 'New Price',
                            field: rowData => rowData.total === 0 ? "N/A" : currencyFormat.format(rowData.total),
                            alignment: 'right',
                            important: true,
                        },
                        {
                            actions: rowData => {
                                return [
                                    
                                    {name: 'View', icon: 'search', onClick: () => this.handleServiceChargesDialog(true, (rowData.rowNumber - 1))}
                                ]
                            }
                        }
                    ]}
                    rows={_.sortBy(this.state.dataImport.additions, 'company', 'agreement', 'addition')}
                />
            </Paper>
        )
    }

    companyOverview = () => (
        <Paper>
            <DataTable  
                config={{
                    key: 'voip_id',
                    pagination: true,
                    alternatingRowColours: true,
                    isLoading: this.state.isLoading,
                    responsiveImportance: true,
                    plainHeader: true, 
                    title: 'Company Billing Overview',
                    showFullTotals: true
                }}
                columns={[
                    {
                        heading: 'VOIP Company',
                        field: rowData => rowData.voip_name,
                        important: true,
                        showTotalLabel: true,
                        cellProps: rowData => {
                            return ({
                                style: {
                                    fontStyle: (rowData.voip_name === 'Ignored' && 'italic')
                                }
                            });
                        },
                    },
                    {
                        heading: 'Additional Calls Addition',
                        field: rowData => rowData.addition,
                        important: true,
                        cellProps: rowData => {
                            return ({
                                style: {
                                    fontStyle: (rowData.voip_name === 'Ignored' && 'italic')
                                }
                            });
                        },
                    },
                    {
                        heading: 'Total Services Cost',
                        field: rowData => `${rowData.services_cost}`,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        important: true,
                        showTotal: true,
                        cellProps: rowData => {
                            return ({
                                style: {
                                    fontStyle: (rowData.voip_name === 'Ignored' && 'italic')
                                }
                            });
                        },
                    },
                    {
                        heading: 'Total Calls Cost',
                        field: rowData => `${rowData.calls_cost}`,
                        fieldFormat: 'currency3',
                        alignment: 'right',
                        important: true,
                        showTotal: true,
                        cellProps: rowData => {
                            return ({
                                style: {
                                    fontStyle: (rowData.voip_name === 'Ignored' && 'italic')
                                }
                            });
                        },
                    },
                    {
                        heading: 'Total Cost',
                        field: rowData => `${rowData.total_cost}`,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        important: true,
                        showTotal: true,
                        cellProps: rowData => {
                            return ({
                                style: {
                                    fontStyle: (rowData.voip_name === 'Ignored' && 'italic')
                                }
                            });
                        },
                    },
                    {
                        heading: 'Estimated Bill',
                        field: rowData => `${rowData.total_price}`,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        important: true,
                        cellProps: rowData => {
                            return ({
                                style: {
                                    color: (rowData.total_price < 0 && '#d9534f') || '#4CAF50',
                                    fontStyle: (rowData.voip_name === 'Ignored' && 'italic')
                                }
                            });
                        },
                        showTotal: true
                    },
                    {
                        heading: 'Profit',
                        field: rowData => `${(rowData.services_price - rowData.services_cost).toFixed(2)}`,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        important: true,
                        cellProps: rowData => {
                            let markup = (rowData.services_price - rowData.services_cost).toFixed(2)
                            return ({
                                style: {
                                    color: (markup < 0 && '#d9534f') || '#4CAF50',
                                    fontStyle: (rowData.voip_name === 'Ignored' && 'italic')
                                }
                            });
                        },
                        showTotal: true
                    },
                ]}
                rows={this.state.dataImport.companies}
            />
        </Paper>
    )

    serviceOverview = () => (
        <Paper>
            <DataTable  
                config={{
                    key: 'voip_service_id',
                    pagination: true,
                    alternatingRowColours: true,
                    isLoading: this.state.isLoading,
                    responsiveImportance: true,
                    plainHeader: true, 
                    title: 'Service Billing Overview',
                    showFullTotals: true
                }}
                columns={[
                    {
                        heading: 'Service Identifier',
                        field: rowData => rowData.voip_service_identifier,
                        important: true,
                        showTotalLabel: true
                    },
                    {
                        heading: 'VOIP Company',
                        field: rowData => (rowData.company && rowData.company.voip_name) || '-',
                        important: true,
                    },
                    {
                        heading: 'Subscription',
                        field: rowData => rowData.voip_service_name,
                        important: true,
                    },
                    {
                        heading: 'Service Cost',
                        field: rowData => `${rowData.services_cost}`,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        important: true,
                        showTotal: true,
                    },
                    {
                        heading: 'Calls Cost',
                        field: rowData => `${rowData.calls_cost}`,
                        fieldFormat: 'currency3',
                        alignment: 'right',
                        important: true,
                        showTotal: true,
                    },
                    {
                        heading: 'Total Cost',
                        field: rowData => `${rowData.total_cost}`,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        important: true,
                        showTotal: true,
                    },
                    {
                        heading: 'Estimated Bill',
                        field: rowData => `${rowData.total_price}`,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        important: true,
                        cellProps: rowData => {
                            return ({
                                style: {
                                    color: (rowData.total_price < 0 && '#d9534f') || '#4CAF50'
                                }
                            });
                        },
                        showTotal: true,
                    },
                    {
                        heading: 'Profit',
                        field: rowData => `${(rowData.services_price - rowData.services_cost).toFixed(2)}`,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        important: true,
                        cellProps: rowData => {
                            let markup = (rowData.services_price - rowData.services_cost).toFixed(2)
                            return ({
                                style: {
                                    color: (markup < 0 && '#d9534f') || '#4CAF50'
                                }
                            });
                        },
                        showTotal: true,
                    },
                    
                ]}
                rows={this.state.dataImport.services}
            />
        </Paper>
    )

    tableServices = (dataSet, dataTitle) => (
        <Paper>
            <DataTable  
                config={{
                    key: 'voip_sc_id',
                    pagination: true,
                    alternatingRowColours: true,
                    isLoading: this.state.isLoading,
                    responsiveImportance: true,
                    plainHeader: true, 
                    title: dataTitle,
                    showFullTotals: true
                }}
                columns={[
                    {
                        heading: 'Service Identifier',
                        field: rowData => rowData.voip_service_identifier,
                        important: true,
                        showTotalLabel: true,
                        cellProps: rowData => ({
                            style: {
                                backgroundColor: rowData.voip_sc_type === "Manual" && '#FFF59D'
                            }
                        })
                    },
                    {
                        heading: 'Billing From',
                        field: rowData => rowData.voip_sc_billing_from,
                        fieldFormat: 'date',
                        important: true,
                        cellProps: rowData => ({
                            style: {
                                backgroundColor: rowData.voip_sc_type === "Manual" && '#FFF59D'
                            }
                        })
                    },
                    {
                        heading: 'Billing To',
                        field: rowData => rowData.voip_sc_billing_to,
                        fieldFormat: 'date',
                        important: true,
                        cellProps: rowData => ({
                            style: {
                                backgroundColor: rowData.voip_sc_type === "Manual" && '#FFF59D'
                            }
                        })
                    },
                    {
                        heading: 'Subscription',
                        field: rowData => rowData.voip_service_name,
                        important: true,
                        cellProps: rowData => ({
                            style: {
                                backgroundColor: rowData.voip_sc_type === "Manual" && '#FFF59D'
                            }
                        })
                    },
                    {
                        heading: `VOIP Company`,
                        field: rowData => rowData.voip_service_company,
                        important: true,
                        cellProps: rowData => {
                            let company = rowData.voip_service_company;
                            return ({
                                style: {
                                    color: (company === "-" || company === "Unassigned" ? 'rgb(106, 27, 154)' : '#4CAF50'),
                                    backgroundColor: rowData.voip_sc_type === "Manual" && '#FFF59D'
                                }
                            });
                        },
                    },
                    {
                        heading: 'Addition',
                        field: rowData => rowData.voip_service_addition,
                        important: true,
                        cellProps: rowData => {
                            let addition = rowData.voip_service_addition;
                            return ({
                                style: {
                                    color: (addition === "-") ? '#d9534f' : (addition === "Ignored"  || addition === "Not Billable" ? 'rgb(106, 27, 154)' : '#4CAF50'),
                                    backgroundColor: rowData.voip_sc_type === "Manual" && '#FFF59D'
                                }
                            });
                        },
                    },
                    {
                        heading: 'Quantity',
                        field: rowData => rowData.voip_sc_quantity,
                        alignment: 'right',
                        showTotal: true,
                        important: true,
                        cellProps: rowData => ({
                            style: {
                                backgroundColor: rowData.voip_sc_type === "Manual" && '#FFF59D'
                            }
                        })
                    },
                    {
                        heading: 'Unit Cost',
                        field: rowData => `${rowData.voip_sc_unit_cost}`,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        important: true,
                        showTotal: true,
                        cellProps: rowData => ({
                            style: {
                                backgroundColor: rowData.voip_sc_type === "Manual" && '#FFF59D'
                            }
                        })
                    },
                    {
                        heading: 'Total Cost',
                        field: rowData => `${rowData.voip_sc_total_cost}`,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        important: true,
                        showTotal: true,
                        cellProps: rowData => ({
                            style: {
                                backgroundColor: rowData.voip_sc_type === "Manual" && '#FFF59D'
                            }
                        })
                    }
                ]}
                rows={this.state.dataImport[dataSet]}
            />
        </Paper>
    )

    tableCharges = (dataSet, dataTitle) => (
        <Paper>
            <DataTable  
                config={{
                    key: 'voip_cc_id',
                    pagination: true,
                    alternatingRowColours: true,
                    isLoading: this.state.isLoading,
                    responsiveImportance: true,
                    plainHeader: true, 
                    title: dataTitle,
                    showFullTotals: true
                }}
                columns={[
                    {
                        heading: 'Service Identifier',
                        field: rowData => rowData.voip_cc_service_identifier,
                        important: true,
                        showTotalLabel: true
                    },
                    {
                        heading: 'Dialled Number',
                        field: rowData => rowData.voip_cc_dialled_no,
                        important: true,
                    },
                    {
                        heading: 'Call Date',
                        field: rowData => rowData.voip_cc_call_date,
                        fieldFormat: 'date',
                        important: true,
                    },
                    {
                        heading: 'Call Time',
                        field: rowData => rowData.voip_cc_call_time,
                        important: true,
                    },
                    {
                        heading: 'Call Duration',
                        field: rowData => rowData.voip_cc_call_duration,
                        important: true,
                    },
                    {
                        heading: 'Call Charge Code',
                        field: rowData => rowData.voip_cc_charge_code,
                        important: true,
                    },
                    {
                        heading: `VOIP Company`,
                        field: rowData => rowData.company,
                        important: true,
                        cellProps: rowData => {
                            let { company } = rowData;
                            return ({
                                style: {
                                    color:                                      
                                        (company === "-" || company === "Unassigned") ? 'rgb(106, 27, 154)' : '#4CAF50'
                                }
                            });
                        },
                    },
                    {
                        heading: 'Addition',
                        field: rowData => rowData.addition,
                        important: true,
                        cellProps: rowData => {
                            let { addition } = rowData;
                            return ({
                                style: {
                                    color: 
                                        (addition === "-") ? '#d9534f' : (addition === "Ignored" || addition === "Not Billable" ? 'rgb(106, 27, 154)' : '#4CAF50')
                                }
                            });
                        },
                    },
                    {
                        heading: 'Call Cost',
                        field: rowData => `${rowData.voip_cc_cost}`,
                        fieldFormat: 'currency3',
                        alignment: 'right',
                        important: true,
                        showTotal: true,
                    },
                ]}
                rows={this.state.dataImport[dataSet]}
            />
        </Paper>
    )

    importOverview = () => (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={9} align='center'>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <PaddedPaper style={{paddingTop: 50, paddingBottom: 50}}>
                            {(this.state.dataImport.vdi_status === "Awaiting Review" && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FAIcon icon="exclamation-circle" style={{color: COLOR_MAIN_VOIP, width: 100, height: 100}} type="thin" noMargin />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" className="fw-300">
                                            Outstanding Billing Review
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" className="fw-300" paragraph>
                                            Please review the billing data and then Accept or Discard it to finalise this import
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button 
                                            color="primary"
                                            className='btn btn-success'
                                            onClick={() => this.handleConfirmationOpen('accept')}
                                            variant="contained"
                                            style={{marginRight: 24}}
                                            size="large"
                                        >
                                            <FAIcon icon="check" buttonPrimary />
                                            Accept
                                        </Button>
                                        <Button 
                                            color="error"      
                                            className='btn btn-error'                                      
                                            onClick={() => this.handleConfirmationOpen('discard')}
                                            variant="contained"
                                            size="large"
                                        >
                                            <FAIcon icon="times" buttonPrimary />
                                            Discard 
                                        </Button>
                                    </Grid>
                                </Grid>
                            )) || (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FAIcon icon="check-circle" style={{color: '#4CAF50', width: 100, height: 100}} type="thin" noMargin />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" className="fw-300">
                                            Nothing To Do
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" className="fw-300">
                                            This VOIP and Connectivity Data Import has been finalised
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </PaddedPaper>
                    </Grid>
                    {(this.state.dataImport.vdi_status === "Ready To Bill" || this.state.dataImport.vdi_status === "Billed") && (
                        <React.Fragment>
                            <Grid item xs={6}>
                                <PaddedPaper>
                                    <Grid container alignItems='center' spacing={2} align='left'>
                                        <Grid item>
                                            <FAIcon type="thin" icon="file-csv" size="large" color="success" noMargin button />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                {this.state.dataImport.vdi_filekey_services.split("/").reverse()[0]}
                                            </Typography>
                                            <Typography variant="caption" component="div">
                                                Services Import File
                                            </Typography>
                                        </Grid>
                                        <Grid item xs align="right">
                                            <Tooltip title="Download">
                                                <IconButton onClick={() => window.open(`${CLOUDFRONT_URL}/voip/imports/${this.state.dataImport.vdi_filekey_services}`)}>
                                                    <FAIcon icon="download" button noMargin />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={6}>
                                <PaddedPaper>
                                    <Grid container alignItems='center' spacing={2} align='left'>
                                        <Grid item>
                                            <FAIcon type="thin" icon="file-csv" size="large" color="success" noMargin button />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                {this.state.dataImport.vdi_filekey_calls.split("/").reverse()[0]}
                                            </Typography>
                                            <Typography variant="caption" component="div">
                                                Call Charges Import File
                                            </Typography>
                                        </Grid>
                                        <Grid item xs align="right">
                                            <Tooltip title="Download">
                                                <IconButton onClick={() => window.open(`${CLOUDFRONT_URL}/voip/imports/${this.state.dataImport.vdi_filekey_calls}`)}>
                                                    <FAIcon icon="download" button noMargin />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} lg={3}>
                <Grid container spacing={3}>
                    {/* <Tile icon="traffic-light" backgroundColor={COLOR_TEXT_VOIP} data={this.state.dataImport.vdi_status} title="Import Status" />                                              */}
                    <Tile dashboard icon="sack-dollar" backgroundColor={COLOR_TEXT_VOIP} data={`${this.state.dataImport.import_insight && currencyFormat.format(this.state.dataImport.import_insight.total_price)}`} title="Estimated Revenue" />                                             
                    <Tile dashboard icon="balance-scale" backgroundColor={COLOR_TEXT_VOIP} data={`${this.state.dataImport.import_insight && currencyFormat.format(this.state.dataImport.import_insight.total_estimated_profit)}`} title="Estimated Profit" />                                             
                    <Tile dashboard icon="receipt" backgroundColor={COLOR_TEXT_VOIP} data={`${this.state.dataImport.import_insight && currencyFormat.format(this.state.dataImport.import_insight.total_cost)}`} title="Total Cost" />                                             
                </Grid>
            </Grid>
        </Grid>
    )

    render() {
        const { classes } = this.props;
        const { confirmation, currentTab, dataImport, dialog, dialogAdditionId, isLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    <Grid item xs={12}>
                        {(isLoading && (
                            <LoadingCircle />
                        )) || (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>     
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <FAIcon type="thin" icon="file-invoice" size={70} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h3" className="fw-300">
                                                                {moment(dataImport.vdi_created_datetime).format("MMMM YYYY")}
                                                            </Typography>
                                                        </Grid>    
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="hashtag"
                                                                        label={`Batch: ${dataImport.vdi_ref}`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="calendar-alt"
                                                                        label={`Created: ${dataImport.vdi_created_datetime === '0000-00-00 00:00:00' ? 'Unknown' : moment(dataImport.vdi_created_datetime).format('MMM D, YYYY')}`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="check"
                                                                        label={`Status: ${dataImport.vdi_status}`}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>     
                                                    </Grid> 
                                                </Grid>                                     
                                            </Grid>                                     
                                        </Grid> 
                                        <Grid item xs={12}>                                    
                                            <Paper>     
                                                <AppBar style={{backgroundColor: COLOR_MAIN_VOIP}} position="static" elevation={0}>  
                                                    <Tabs value={currentTab} onChange={this.handleTabChange} classes={{indicator: classes.tabIndicator}} variant="scrollable">
                                                        <Tab disableRipple value={0} label={`Import Overview`} />
                                                        <Tab disableRipple value={6} label={`Company Billing Overview (${dataImport.companies_count})`} />
                                                        <Tab disableRipple value={7} label={`Mapping Overview (${_.size(dataImport.additions)})`} />
                                                        <Tab disableRipple value={1} label={`Service Charges (${dataImport.service_charges_count})`} />
                                                        <Tab disableRipple value={2} label={`Call Charges (${dataImport.call_charges_count})`} />                                            
                                                        <Tab disableRipple value={3} label={`Manual Call Charges (${_.size(dataImport.unassigned_call_charges)})`} />                                            
                                                        <Tab disableRipple value={4} label={`Ignored Service Charges (${_.size(dataImport.ignored_service_charges)})`} />                                            
                                                        <Tab disableRipple value={5} label={`Ignored Call Charges (${_.size(dataImport.ignored_call_charges)})`} />                                            
                                                    </Tabs>                                     
                                                </AppBar>
                                               </Paper>
                                            </Grid>     
                                            <Grid item xs={12}>  
                                                <TabPanel value={currentTab} index={0}>
                                                    {this.importOverview()}
                                                </TabPanel>
                                                <TabPanel value={currentTab} index={1}>
                                                    {this.tableServices('service_charges', 'Service Charges')}
                                                </TabPanel>
                                                <TabPanel value={currentTab} index={2}>                                                    
                                                    {this.tableCharges('call_charges', 'Call Charges')}
                                                </TabPanel>
                                                <TabPanel value={currentTab} index={3}>
                                                    {this.tableCharges('unassigned_call_charges', 'Manual Call Charges')}
                                                </TabPanel>
                                                <TabPanel value={currentTab} index={4}>
                                                    {this.tableServices('ignored_service_charges', 'Ignored Service Charges')}
                                                </TabPanel>
                                                <TabPanel value={currentTab} index={5}>
                                                    {this.tableCharges('ignored_call_charges', 'Ignored Call Charges')}
                                                </TabPanel>
                                                <TabPanel value={currentTab} index={6}>
                                                    {this.companyOverview()}
                                                </TabPanel>
                                                <TabPanel value={currentTab} index={7}>
                                                    {this.additions()}
                                                </TabPanel>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <ConfirmationDialog 
                                        open={confirmation.accept} 
                                        success={() => this.handleConfirmationSuccess('accept')} 
                                        close={() => this.handleConfirmationClose('accept')} 
                                        message="Are you sure you want to accept this VOIP & Connectivity Import?"
                                    />
                                    <ConfirmationDialog 
                                        open={confirmation.discard} 
                                        success={() => this.handleConfirmationSuccess('discard')} 
                                        close={() => this.handleConfirmationClose('discard')} 
                                        message="Are you sure you want to discard this VOIP & Connectivity Import?"
                                    />
                                    {dialog && (
                                        <Dialog 
                                            open={true}
                                            onClose={() => this.handleServiceChargesDialog(false, 0)}
                                            maxWidth='md'
                                            scroll='body'
                                        >
                                            <DialogTitle>
                                                Addition Mapping<br />
                                                <span style={{fontSize: '12px'}}>Company: {dataImport.additions[dialogAdditionId].company}<br />Agreement: {dataImport.additions[dialogAdditionId].agreement}<br />Addition: {dataImport.additions[dialogAdditionId].addition}<br />Quantity: {dataImport.additions[dialogAdditionId].qty}</span>
                                            </DialogTitle>
                                            <Typography component={DialogContent} variant="body2">
                                                <DataTable  
                                                    config={{
                                                        key: 'rowNumber',
                                                    }}
                                                    columns={[
                                                        {
                                                            heading: 'Service Identifier',
                                                            field: rowData => rowData.voip_service_identifier
                                                        },
                                                        {
                                                            heading: 'Service Name',
                                                            field: rowData => rowData.voip_service_name
                                                        },
                                                        {
                                                            heading: 'Billing From',
                                                            field: rowData => rowData.voip_sc_billing_from
                                                        },
                                                        {
                                                            heading: 'Billing To',
                                                            field: rowData => rowData.voip_sc_billing_to
                                                        },
                                                        {
                                                            heading: 'Quantity',
                                                            field: rowData => rowData.voip_sc_quantity,
                                                            alignment: 'right'
                                                        },
                                                        {
                                                            heading: 'Unit Cost',
                                                            field: rowData => rowData.voip_sc_unit_cost,
                                                            alignment: 'right',
                                                            fieldFormat: 'currency'
                                                        },
                                                        {
                                                            heading: 'Total Cost',
                                                            field: rowData => rowData.voip_sc_total_cost,
                                                            alignment: 'right',
                                                            fieldFormat: 'currency'
                                                        },
                                                    ]}
                                                    rows={dataImport.additions[dialogAdditionId].service_charges}
                                                />
                                            </Typography>
                                        </Dialog>      
                                    )}   
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(VoipImportView));