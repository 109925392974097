import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";

import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";

const StaffChip = ({ staff, label, size, style }) => {
    if (staff?.name || staff?.staff_first_name) {
        return (
            <Chip
                avatar={
                    <Avatar
                        alt={staff.name ?? `${staff.staff_first_name} ${staff.staff_last_name}`}
                        src={`${staff.picture ?? staff.staff_profile_photo}`}
                    />
                }
                label={`${label ? label : ""}${staff.name ?? `${staff.staff_first_name} ${staff.staff_last_name}`}`}
                size={size ?? "small"}
                variant="outlined"
                style={{ border: 0, fontWeight: 400, ...style }}
            />
        );
    } else {
        return (
            <Chip
                avatar={
                    <Avatar alt={`System`}>
                        <FAIcon icon="cog" size={10} type="solid" color="white" noMargin />
                    </Avatar>
                }
                label={`System`}
                size={size ?? "small"}
                variant="outlined"
                style={{ border: 0, fontWeight: 400, ...style }}
            />
        );
    }
};

export default StaffChip;
