import React from "react";
import moment from "moment";
import _ from "lodash";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import API from "API";
import DataTable from "Components/Common/DataTables/DataTable";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import StatusChip from "Components/Common/Chips/StatusChip";
import InsightChip from "Components/Common/Chips/InsightChip";
import { handleTabChange } from "../../../Functions/MiscFunctions";
import { COLOR_MAIN_CONNECTWISE } from "ColorSchemes";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
            <Box>{children}</Box>
        </Typography>
    );
}

const styles = (theme) => ({
    tab: {
        color: "#fff",
        opacity: 1,
    },
    tabIndicator: {
        backgroundColor: "#fff",
    },
});

const initialState = {
    company: {},
    currentTab: 0,
    errors: {},
    isLoading: true,
    isSyncing: false,
    snackbar: {
        importError: false,
        importSuccess: false,
        syncError: false,
        syncSuccess: false,
    },
    snackbarMessage: {
        importError: "ConnectWise Data Import Failed",
    },
    syncBlocked: false,
};

class ConnectWiseView extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleTabChange = handleTabChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData();
    };

    loadComponentData = () => {
        API.get("/connectwise/" + this.props.match.params.id).then((result) => {
            if (result.data) {
                this.setState(
                    {
                        company: result.data,
                        syncBlocked: !!result.data.cust_sync_blocked,
                        isLoading: false,
                    },
                    () => {
                        this.props.pageTitle([1, "ConnectWise", this.state.company.cust_name, "View"]);
                    }
                );
            }
        });
    };

    handleSnackbarClose = (type) => {
        this.setState({
            snackbar: {
                ...this.state.snackbar,
                [type]: false,
            },
        });
    };

    renderAgreement = (agreement, idx, level) => {
        const { company } = this.state;
        const children = _.filter(company.agreements, (child) => {
            return child.cwa_parent_id === agreement.cwa_id;
        });
        return (
            <React.Fragment key={idx}>
                <Grid item xs={12} key={idx}>
                    <PaddedPaper>
                        <Typography
                            style={{ textDecoration: agreement.cwa_status !== "Active" ? "line-through" : "none" }}
                            variant="h6"
                            gutterBottom
                        >
                            {agreement.cwa_cw_agreement_name}
                        </Typography>
                        {(_.size(agreement.additions) > 0 &&
                            _.map(agreement.additions, (addition, idx) => (
                                <Grid item xs={12} key={idx}>
                                    <Typography
                                        variant="caption"
                                        style={{
                                            textDecoration: addition.cwaa_status !== "Active" ? "line-through" : "none",
                                        }}
                                    >
                                        > {addition.cwaa_cw_product_identifier}{" "}
                                        {(addition.mappingCount > 0 && (
                                            <em>(Mapped to {addition.mappingCount} products/services in BlueSync)</em>
                                        )) || <em>(Not mapped in BlueSync)</em>}
                                    </Typography>
                                </Grid>
                            ))) || (
                            <Grid item xs={12} key={idx}>
                                <Typography
                                    variant="caption"
                                    paragraph
                                    style={{
                                        textDecoration: agreement.cwa_status !== "Active" ? "line-through" : "none",
                                    }}
                                >
                                    This agreement has no additions
                                </Typography>
                            </Grid>
                        )}
                    </PaddedPaper>
                </Grid>
                {_.size(children) > 0 &&
                    _.map(children, (childAgreement, cidx) => this.renderAgreement(childAgreement, cidx, level + 1))}
            </React.Fragment>
        );
    };

    render() {
        const { classes } = this.props;
        const { company, currentTab, isLoading, syncBlocked } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    <Grid item xs={12}>
                        {(isLoading && <LoadingCircle />) || (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <FAIcon type="thin" icon="user" size={70} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Typography variant="h3" className="fw-300">
                                                                {company.cust_name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid item>
                                                                    <InsightChip
                                                                        icon="location"
                                                                        label={
                                                                            (company.info && (
                                                                                <React.Fragment>
                                                                                    {company.info.addressLine1},{" "}
                                                                                    {company.info.zip},{" "}
                                                                                    {company.info.country &&
                                                                                        company.info.country.name}
                                                                                </React.Fragment>
                                                                            )) ||
                                                                            `No address details imported`
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip
                                                                        icon="hashtag"
                                                                        label={`Account No: ${company.cust_account_number}`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <StatusChip
                                                                        invert
                                                                        status={syncBlocked ? "Sync Blocked" : "Active"}
                                                                        label="Status: "
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip
                                                                        icon="calendar-alt"
                                                                        label={`Last Import: ${
                                                                            company.cust_last_import_datetime ===
                                                                            "0000-00-00 00:00:00"
                                                                                ? "Never"
                                                                                : moment(
                                                                                      company.cust_last_import_datetime
                                                                                  ).format("MMM D, YYYY")
                                                                        }`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip
                                                                        icon="calendar-alt"
                                                                        label={`Last Sync: ${
                                                                            company.cust_last_sync_datetime ===
                                                                            "0000-00-00 00:00:00"
                                                                                ? "Never"
                                                                                : moment(
                                                                                      company.cust_last_sync_datetime
                                                                                  ).format("MMM D, YYYY")
                                                                        }`}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper>
                                                <AppBar
                                                    style={{ backgroundColor: COLOR_MAIN_CONNECTWISE }}
                                                    position="static"
                                                    elevation={0}
                                                >
                                                    <Tabs
                                                        value={currentTab}
                                                        onChange={this.handleTabChange}
                                                        classes={{ indicator: classes.tabIndicator }}
                                                        variant="scrollable"
                                                    >
                                                        <Tab
                                                            disableRipple
                                                            value={0}
                                                            label={`Agreements and Additions`}
                                                        />
                                                        <Tab disableRipple value={1} label={`Company Contacts`} />
                                                        <Tab disableRipple value={2} label={`ConnectWise Log`} />
                                                    </Tabs>
                                                </AppBar>
                                            </Paper>
                                        </Grid>
                                        {syncBlocked && (
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item>
                                                        <FAIcon
                                                            icon="exclamation-triangle"
                                                            size={50}
                                                            type="thin"
                                                            color="error"
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant="h6" className="fw-400">
                                                            Synchronization for {company.cust_name} is currently
                                                            disabled
                                                        </Typography>
                                                        <Typography variant="body2" className="fw-400">
                                                            Please check the Action Center for more details; or if you
                                                            have resolved all actions then please re-import your
                                                            ConnectWise data to enable synchronization.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            className="btn btn-error"
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                            style={{ marginTop: 6 }}
                                                            onClick={() => this.props.history.push("/action-center")}
                                                        >
                                                            <FAIcon icon="bell" buttonPrimary />
                                                            Action Center
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    {(_.size(company.agreements) > 0 &&
                                                        _.map(company.agreements, (agreement, idx) => {
                                                            if (agreement.cwa_parent_id === 0) {
                                                                return this.renderAgreement(agreement, idx, 0);
                                                            }
                                                        })) ||
                                                        (company.cust_last_import_datetime ===
                                                            "0000-00-00 00:00:00" && (
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Typography variant="caption" paragraph>
                                                                        <FAIcon
                                                                            icon="exclamation-triangle"
                                                                            size="small"
                                                                        />
                                                                        BlueSync will show agreements and additions once
                                                                        ConnectWise data is imported
                                                                    </Typography>
                                                                </PaddedPaper>
                                                            </Grid>
                                                        )) || (
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Typography variant="caption" paragraph>
                                                                        <FAIcon
                                                                            icon="exclamation-triangle"
                                                                            size="small"
                                                                        />
                                                                        No active agreements exist for{" "}
                                                                        {company.cust_name}
                                                                    </Typography>
                                                                </PaddedPaper>
                                                            </Grid>
                                                        )}
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <Grid container spacing={3}>
                                                    {(_.size(company.contacts) > 0 &&
                                                        _.map(
                                                            _.orderBy(company.contacts, ["cc_default"], ["desc"]),
                                                            (contact, idx) => (
                                                                <Grid key={idx} item xs={12} lg={3}>
                                                                    <PaddedPaper>
                                                                        <Typography
                                                                            variant="h6"
                                                                            style={{
                                                                                textDecoration:
                                                                                    contact.cc_active === "N"
                                                                                        ? "line-through"
                                                                                        : "none",
                                                                            }}
                                                                        >
                                                                            <FAIcon icon="chevron-right" size="small" />
                                                                            {contact.cc_default === 1 && (
                                                                                <FAIcon
                                                                                    icon="star"
                                                                                    className="mr-1 textWarning"
                                                                                    size={20}
                                                                                />
                                                                            )}
                                                                            {contact.cc_first_name &&
                                                                                contact.cc_first_name.length > 0 &&
                                                                                `${contact.cc_first_name} `}
                                                                            {contact.cc_last_name &&
                                                                                contact.cc_last_name.length > 0 &&
                                                                                `${contact.cc_last_name} `}
                                                                        </Typography>
                                                                        {(contact.cc_email &&
                                                                            contact.cc_email.length > 0 && (
                                                                                <Typography
                                                                                    variant="caption"
                                                                                    style={{
                                                                                        marginLeft: 24,
                                                                                        textDecoration:
                                                                                            contact.cc_active === "N"
                                                                                                ? "line-through"
                                                                                                : "none",
                                                                                    }}
                                                                                >
                                                                                    {contact.cc_email}
                                                                                </Typography>
                                                                            )) || (
                                                                            <Typography
                                                                                variant="caption"
                                                                                style={{
                                                                                    marginLeft: 24,
                                                                                    textDecoration:
                                                                                        contact.cc_active === "N"
                                                                                            ? "line-through"
                                                                                            : "none",
                                                                                }}
                                                                            >
                                                                                No e-mail address
                                                                            </Typography>
                                                                        )}
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            )
                                                        )) ||
                                                        (company.cust_last_import_datetime ===
                                                            "0000-00-00 00:00:00" && (
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Typography paragraph>
                                                                        <FAIcon
                                                                            icon="exclamation-triangle"
                                                                            size="small"
                                                                        />
                                                                        BlueSync will show contact once ConnectWise data
                                                                        is imported
                                                                    </Typography>
                                                                </PaddedPaper>
                                                            </Grid>
                                                        )) || (
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Typography paragraph>
                                                                        <FAIcon
                                                                            icon="exclamation-triangle"
                                                                            size="small"
                                                                        />
                                                                        No contacts exist for {company.cust_name}
                                                                    </Typography>
                                                                </PaddedPaper>
                                                            </Grid>
                                                        )}
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                <Paper>
                                                    <DataTable
                                                        config={{
                                                            key: "cwlog_id",
                                                            pagination: true,
                                                            alternatingRowColours: true,
                                                            plainPagination: true,
                                                            isLoading: isLoading,
                                                            rowsPerPage: 8,
                                                            noHeader: true,
                                                            title: "ConnectWise Logs",
                                                            noRowHover: true,
                                                            noResultsText: "No logs exist for this company",
                                                        }}
                                                        style={{ width: "100%", maxWidth: "100%" }}
                                                        columns={[
                                                            {
                                                                field: (rowData) => (
                                                                    <Grid container spacing={1} alignItems="center">
                                                                        <Grid item />
                                                                        <Grid item align="center">
                                                                            <FAIcon
                                                                                type="thin"
                                                                                size={30}
                                                                                icon={
                                                                                    rowData.cwlog_type === "Success"
                                                                                        ? "check-circle"
                                                                                        : rowData.cwlog_type === "Error"
                                                                                        ? "times-circle"
                                                                                        : rowData.cwlog_type ===
                                                                                          "Warning"
                                                                                        ? "exclamation-triangle"
                                                                                        : rowData.cwlog_type ===
                                                                                          "Notice"
                                                                                        ? "info-circle"
                                                                                        : "flag-alt"
                                                                                }
                                                                                style={{
                                                                                    color:
                                                                                        rowData.cwlog_type === "Success"
                                                                                            ? "#388E3C"
                                                                                            : rowData.cwlog_type ===
                                                                                              "Error"
                                                                                            ? "#d9534f"
                                                                                            : rowData.cwlog_type ===
                                                                                              "Warning"
                                                                                            ? "#EF6C00"
                                                                                            : rowData.cwlog_type ===
                                                                                              "Notice"
                                                                                            ? "#6A1B9A"
                                                                                            : "#0288D1",
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            <Typography
                                                                                variant="body2"
                                                                                className="fw-400"
                                                                                component="div"
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            rowData.cwlog_type ===
                                                                                            "Success"
                                                                                                ? "#388E3C"
                                                                                                : rowData.cwlog_type ===
                                                                                                  "Error"
                                                                                                ? "#d9534f"
                                                                                                : rowData.cwlog_type ===
                                                                                                  "Warning"
                                                                                                ? "#EF6C00"
                                                                                                : rowData.cwlog_type ===
                                                                                                  "Notice"
                                                                                                ? "#6A1B9A"
                                                                                                : "#0288D1",
                                                                                    }}
                                                                                >
                                                                                    {rowData.cwlog_title.split(":")[0]}
                                                                                    {rowData.cwlog_title.split(":")
                                                                                        .length > 1 && ":"}
                                                                                </span>
                                                                                {rowData.cwlog_title.split(":")[1]}
                                                                            </Typography>
                                                                            {!_.isEmpty(rowData.cwlog_text) && (
                                                                                <Typography
                                                                                    variant="caption"
                                                                                    component="div"
                                                                                >
                                                                                    {rowData.cwlog_text}
                                                                                </Typography>
                                                                            )}
                                                                            <Typography
                                                                                variant="caption"
                                                                                color="textSecondary"
                                                                            >
                                                                                {moment(
                                                                                    rowData.cwlog_created_datetime,
                                                                                    "YYYY-MM-DD HH:mm:ss"
                                                                                ).format("DD/MM/YYYY HH:mm")}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                ),
                                                            },
                                                        ]}
                                                        rows={company.logs}
                                                    />
                                                </Paper>
                                            </TabPanel>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ConnectWiseView);
