import _ from 'lodash';
import * as NotificationActionTypes from 'Redux/Actions/Notifications/Types/Types';

const initialState = () => ({
    badges: {},
    status: {},
    staffList: []
});

function NotificationsReducer(state = initialState(), action) {
    switch (action.type) {
        case NotificationActionTypes.CLEAR_USER_DATA:
            return initialState();
        case NotificationActionTypes.LOAD_STAFF_STATUS:
            return {
                ...state,                   
                status: action.payload,
                staffList: _.map(staff => (
                    _.assign({
                        label: staff.name,
                        value: staff.id,
                    })
                ))
            }
        case NotificationActionTypes.I_AM_ACTIVE:
            if(state.status[action.payload]?.status !== 2) {
                return {
                    ...state,                   
                    status: {
                        ...state.status,
                        [action.payload]: {
                            ...state.status[action.payload],
                            status: 2
                        }
                    } 
                }
            } else {
                return state
            }
        case NotificationActionTypes.HANDLE_NOTIFICATIONS:
            if(JSON.stringify(action.payload) !== JSON.stringify(state)) {
                if(JSON.stringify(action.payload.badges) !== JSON.stringify(state.badges)) {
                    state = {
                        ...state,
                        badges: action.payload.badges
                    }
                }
                Object.keys(action.payload.status).forEach((staffId) => {
                    if(state.status?.[staffId]?.status !== action.payload?.status?.[staffId]) {
                        state = {
                            ...state,
                            status: {
                                ...state.status,
                                [staffId]: {
                                    ...state.status[staffId],
                                    status: action.payload.status[staffId]
                                }
                            }
                        }
                    }
                });
            }
            return state;
        default:
            return state;
    }
}

 export default NotificationsReducer;