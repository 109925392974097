import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = {
    dataLoading: true,
    filter: '',
    filterOptions: _.map(['Info','Success', 'Warning','Error','Notice'], el => {
        return _.assign({
            value: el,
            label: el
        });
    }),
    logs: {},
}
 
class ScLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {        
        this.props.pageTitle([1, 'Support Contracts', 'Logs'])  
        this.getLogs()
    }

    getLogs = () => {
        this.setState({
            dataLoading: true
        },
        () => {
            API.get('/supportContracts/logs', {
                params: {
                    filter: this.state.filter
                }
            })
            .then(result => {
                if(result && result.data) {
                    this.setState({
                        dataLoading: false,
                        logs: result.data,
                    }) 
                }
            });
        })
    }
    
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption ? selectedOption.value : ''
        },
        () => this.getLogs());
    };

    render() {
        const { filter, dataLoading, filterOptions, logs } = this.state;        
        return (
            <Grid container spacing={3}>                
                <Grid item xs={12}>
                    <Grid container spacing={3}>     
                        <Grid item xs={12}> 
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item xs={3}>
                                    <AutoCompleteSelect 
                                        options={filterOptions}
                                        placeholder='Log Type'
                                        onChange={this.handleSelectChange('filter')}
                                        value={filter}
                                        variant="filled"
                                        adornment='filter'
                                    />
                                </Grid>      
                            </Grid>  
                        </Grid>  
                        <Grid item xs={12}>  
                            <Paper>
                                <DataTable  
                                    config={{
                                        key: 'log_id',
                                        pagination: true,
                                        isLoading: dataLoading,
                                        rowsPerPage: 8,
                                        noHeader: true,
                                        noRowHover: true,
                                        title: 'Support Contracts Logs',
                                    }}
                                    columns={[
                                        {
                                            field: rowData => (
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item />
                                                    <Grid item align='center'>                                        
                                                        <FAIcon 
                                                            type="thin"
                                                            size={30}
                                                            icon={rowData.log_type === 'Success' ? 'check-circle' : (rowData.log_type === 'Error' ? 'times-circle' : (rowData.log_type === 'Warning' ? 'exclamation-triangle' : (rowData.log_type === 'Notice' ? 'info-circle' : 'flag-alt')))}
                                                            style={{
                                                                color: rowData.log_type === 'Success' ? '#388E3C' : (rowData.log_type === 'Error' ? '#d9534f' : (rowData.log_type === 'Warning' ? '#EF6C00' : (rowData.log_type === 'Notice' ? '#6A1B9A' : '#0288D1')))
                                                            }}  
                                                        />
                                                    </Grid>
                                                    <Grid item xs>                                             
                                                        <Typography variant="body2" className="fw-400" component="div">                                                        
                                                            <span style={{
                                                                color: rowData.log_type === 'Success' ? '#388E3C' : (rowData.log_type === 'Error' ? '#d9534f' : (rowData.log_type === 'Warning' ? '#EF6C00' : (rowData.log_type === 'Notice' ? '#6A1B9A' : '#0288D1')))
                                                            }}>
                                                                {rowData.log_title.split(":")[0]}{rowData.log_title.split(":").length > 1 && ':'}
                                                            </span> 
                                                            {rowData.log_title.split(":")[1]}
                                                        </Typography>      
                                                        {!_.isEmpty(rowData.log_text) && (
                                                            <Typography variant="caption" component="div">
                                                                {rowData.log_text}
                                                            </Typography>
                                                        )}
                                                        <Typography variant="caption" color="textSecondary">
                                                            {moment(rowData.log_created_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            ),
                                        },             
                                    ]}
                                    rows={logs}
                                />
                            </Paper>
                        </Grid>           
                    </Grid>      
                </Grid> 
            </Grid>
        )
    }
}

export default ScLogs;