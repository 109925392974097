import React from 'react';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StatusChip from 'Components/Common/Chips/StatusChip';

const initialState = {
    searchResults: {},
    searchString: '',
    access: {
        viewBills: false
    },
    dataLoading: true
}

class VoipBillingSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    getAccess = () => {
        Promise.all([
            API.access('voip-billing-view'), 
        ])
        .then(([viewBills]) =>  {
            this.setState({
                ...this.state,
                access: {
                    viewBills: (viewBills.data && viewBills.data.has_access) || false
                }
            });
        })
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'VOIP and Connectivity', 'Billing']);
        this.getSearchData();
        this.getAccess();
    }

    getSearchData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/voip/billing', { params: { searchString: this.state.searchString } })
            .then(result => {
                this.setState({
                    dataLoading: false
                });
                if(result.data) {
                    this.setState({
                        searchResults: result.data
                    });
                }
            });
        });
    }
    
    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        }, () => {
            this.getSearchData();
        });
    }

    resetSearch = () => {
        this.setState({
            searchString: ''
        }, () => {
            this.getSearchData();
        });
    }

    render() {
        const { access, dataLoading } = this.state;
        return (
            <React.Fragment>
                <Grid container spacing={1}>      
                    <Grid container item xs={12} alignItems='center'>
                        <Grid item xs={3} style={{marginBottom: 12}}>
                            <TextField 
                                fullWidth
                                onChange={this.onSearchStringChange}
                                placeholder='Reference:'
                                value={this.state.searchString} 
                                variant="filled"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><FAIcon icon="search" size="small" /></InputAdornment>
                                }}
                            /> 
                        </Grid>   
                    </Grid>   
                    <Grid item xs={12}>
                        <Paper>
                            <DataTable  
                                config={{
                                    key: 'vdi_id',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    isLoading: dataLoading,
                                    responsiveImportance: true,
                                    plainHeader: true, 
                                    title: 'VOIP and Connectivity Billing Batches',
                                }}
                                columns={[
                                    {
                                        heading: 'Reference',
                                        field: rowData => rowData.vs_ref,
                                        important: true,
                                    },
                                    {
                                        heading: 'Billed Service Charges',
                                        field: rowData => rowData.service_charges_count, 
                                        alignment: 'right',
                                        sizeToContent: true,    
                                    },
                                    {
                                        heading: 'Billed Call Charges',
                                        field: rowData => rowData.call_charges_count, 
                                        alignment: 'right',
                                        sizeToContent: true,    
                                    },
                                    {
                                        heading: 'Affected Additions',
                                        field: rowData => rowData.additions_count, 
                                        alignment: 'right',
                                        sizeToContent: true,    
                                    },
                                    {
                                        heading: 'Status',
                                        field: rowData => <StatusChip status={rowData.vs_status} />,  
                                        sizeToContent: true,   
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: "View Batch", icon: 'search', link: '/voip/billing/' + rowData.vs_id, disabled: rowData.vs_status === "Processing" ? true : (access.viewBills ? false : true)}
                                            ]
                                        }
                                    }
                                ]}
                                rows={this.state.searchResults}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
    }

export default VoipBillingSearch;