import * as UIActionTypes from 'Redux/Actions/UI/Types/Types';
import { isPortrait, isTablet } from 'Functions/MiscFunctions';

 const initialState = {
    appError: {
        state: false,
        message: "",
        unauthorized: false,
        unauthorizedPage: ""
    },
    device: {
        isMobile: window.innerWidth < 960,
        isPDA: navigator.userAgent.indexOf("PDA") > -1,
        isPortrait: isPortrait(),
        isTablet: isTablet() || window.innerWidth < 1400,
        width: window.innerWidth,
        height: window.innerHeight,
    },
    updateNotification: false
};

 function UIReducer(state = initialState, action) {
    switch (action.type) {
        case UIActionTypes.CHANGE_MODE:
            if(state.mode !== action.payload) {
                return {
                    ...state,                   
                    mode: action.payload
                };
            } else {
                return state
            }
        case UIActionTypes.WINDOW_RESIZE:
            return {
                ...state,
                device: {
                    ...state.device,
                    isMobile: action.payload[0] < 960,
                    isPortrait: isPortrait(),
                    isTablet: isTablet() || action.payload[0] < 1400,
                    width: action.payload[0],
                    height: action.payload[1],
                }
            };
        case UIActionTypes.UPDATE_NOTIFICATION:
            if(state.updateNotification !== action.payload) {
                return {
                    ...state,                   
                    updateNotification: action.payload,
                };
            } else {
                return state
            }
        case UIActionTypes.HANDLE_ERROR:
            if(state.appError.state !== action.payload[0]) {
                return {
                    ...state,                   
                    appError: {
                        state: action.payload[0],
                        detail: action.payload[1]
                    }
                };
            } else {
                return state
            }
        case UIActionTypes.HANDLE_UNAUTHORIZED:
            if(state.appError.unauthorizedPage !== action.payload) {
                return {
                    ...state,   
                    unauthorized: action.payload === false ? false : true,               
                    unauthorizedPage: action.payload
                };
            } else {
                return state
            }
        default:
            return state;
    }
}

 export default UIReducer;