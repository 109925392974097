import * as authActionTypes from 'Redux/Actions/Auth/Types/Types';

const initialState = {
    access: {},
    staff: {
        token: '',
        exp: 0,
        id: 0,
        email: '',
        firstName: '',
        lastName: '',
        name: '',
        picture: '',
        teams: []
    }
};

function authReducer(state = initialState, action) {
    switch(action.type) {
        case authActionTypes.ACCESS:
            return {
                ...state,
                access: action.payload
            };
        case authActionTypes.EXTRACT_TOKEN:
            return {
                ...state,
                staff: action.payload
            };
        case authActionTypes.UPDATE_PICTURE:
            return {
                ...state,
                staff: {
                    ...state.staff,
                    picture: action.payload
                }
            };
        default:
            return state;
    }
}

export default authReducer;