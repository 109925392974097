import React from 'react';
import { useDispatch } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    heading: {
        textTransform: 'capitalize'
    },
    content : {
        padding: '0 24px 24px 24px', 
    },
    container: {
        marginTop: 88,
    },
    dialogPaper: {
        backgroundColor: '#fafafa'
    },
    dialogTitleSuccess: {
        backgroundColor: '#4CAF50',
        color: 'white',
        fontWeight: 'bold',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    dialogTitleWarning: {
        backgroundColor: '#F57C00',
        color: 'white',
        fontWeight: 'bold',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    dialogTitleError: {
        backgroundColor: '#d9534f',
        color: 'white',
        fontWeight: 'bold',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: '#fff'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = ({dialogOpen, disableDialogContent, dialogContent, dialogDisableExit, dialogFullScreen, dialogTitle, dialogVariant, dialogSize, handleDialogClose}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const close = () => {
        dispatch(closeDialog())
    }

    const generateDialogTitleClassName = () => {

        let className = '';

        switch(dialogVariant) {

            case 'success':
                className = classes.dialogTitleSuccess;
            break;

            case 'warning':
                className = classes.dialogTitleWarning;
            break;

            case 'error':
                className = classes.dialogTitleError;
            break;

            default:
            break;
        }

        return className;
    }

    const Element = disableDialogContent ? React.Fragment : DialogContent;

    return (
        <MuiDialog
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            open={dialogOpen}
            TransitionComponent={Transition}
            onClose={handleDialogClose}
            maxWidth={dialogSize || 'md'}
            scroll="body"
            fullWidth
            fullScreen={dialogFullScreen}
            PaperProps={{
                className: dialogFullScreen && classes.dialogPaper,
                style: {
                    position: 'relative'
                }
            }}
        >
            {(dialogFullScreen && (
                <>
                    <AppBar>
                        <Toolbar>
                            <Tooltip title="Cancel">
                                <IconButton edge="start" color="inherit" onClick={close}>
                                    <FAIcon type="light" icon='times' buttonPrimary noMargin />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h6" className={classes.title}>
                                {dialogTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Container maxWidth="xl" className={classes.container}>
                        {dialogContent}
                    </Container>
                </>
            )) || (
                <>
                    {(dialogTitle && (
                        <DialogTitle disableTypography className={generateDialogTitleClassName()} style={{cursor: 'move'}} id="draggable-control">
                            <Grid container alignItems='center'>
                                <Grid item xs>
                                    <Typography variant="h6" className={dialogVariant === "standard" ? 'textDefault' : 'textLight'}>
                                        {dialogTitle}
                                    </Typography>
                                </Grid>
                                {!dialogDisableExit && (
                                    <Grid item>
                                        <Tooltip title="Close">
                                            <IconButton                                            
                                                onTouchStart={close}
                                                onClick={close}
                                            >
                                                <FAIcon icon="times" button noMargin />    
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                )}
                            </Grid>
                        </DialogTitle>
                    )) || (
                        <DialogTitle disableTypography className={generateDialogTitleClassName()} style={{cursor: 'move', width: '100%', position: 'absolute', left: 0, top: 0}} id="draggable-control">
                            <Grid container alignItems='center'>
                                <Grid item xs />
                                {!dialogDisableExit && (
                                    <Grid item>
                                        <Tooltip title="Close">
                                            <IconButton
                                                onClick={close}
                                                onTouchStart={close}
                                            >
                                                <FAIcon icon="times" button size={20} noMargin />    
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                )}
                            </Grid>
                        </DialogTitle>
                    )}
                    <Element className={classes.content} id={!dialogTitle ? 'draggable-control' : undefined}>
                        {dialogContent}
                    </Element>
                </>
            )}
        </MuiDialog>
    )
}

export default Dialog;