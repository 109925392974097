import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import AppStoreLogo from '../../../Assets/Logos/app-store.svg';
import GooglePlayLogo from '../../../Assets/Logos/google-play.svg';
import ViewStaffMfaStatus from '../ViewStaff/ViewStaffMfaStatus';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';

const initialState = {
    action: 'status',
    isLoading: true,
    mfaStatus: '',
    mfaActivationDatetime: '',
    mfaActivationQr: '',
    mfaActivationSecret: '',
    formData: {
        mfaCodeInd: {
            0: ' ',
            1: ' ',
            2: ' ',
            3: ' ',
            4: ' ',
            5: ' ',
        },
        mfaCode: '',
    },
    formErrors: [],
    confirmation: {
        revoke: false,
        submit: false,
    },
    snackbar: {
        revoke: false,
        submit: false,
    }
}

class UpdateStaffMfa extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    
    componentDidMount(){        
        this.mfaCode0 = React.createRef();
        this.mfaCode1 = React.createRef();
        this.mfaCode2 = React.createRef();
        this.mfaCode3 = React.createRef();
        this.mfaCode4 = React.createRef();
        this.mfaCode5 = React.createRef();
        
        this.getMfaStatus();
    }

    getMfaStatus = () => {
        API.get('/staff/' + this.props.staffId).then(result => {
            this.setState({
                isLoading: false,
                mfaStatus: result.data.staff_mfa,
                mfaActivationDatetime: result.data.staff_mfa_activation_datetime
            })
        });
    }

    handleActivateMfa = () => {
        if(!this.props.myAccount) {
            API.get('/staff/' + this.props.staffId + '/mfa').then(result => {
                if(result.data){
                    this.setState({
                        action: 'activate',
                        mfaActivationQr: result.data.mfa_qr,
                        mfaActivationSecret: result.data.mfa_secret
                    }, () => this.mfaCode0.current.focus());              
                }
            });
        }
    }

    handleRevoke = () => {
        if(!this.props.myAccount) {
            API.get('/staff/' + this.props.staffId + '/mfa/revoke').then(result => {
                if(result.data.success){
                    this.setState({
                        action: 'status',
                        snackbar: {
                            ...this.state.snackbar,
                            revoke: true
                        },
                        formErrors: [],
                        formData: {
                            ...this.state.formData,
                            mfaCode: '',
                            mfaCodeInd: {
                                0: ' ',
                                1: ' ',
                                2: ' ',
                                3: ' ',
                                4: ' ',
                                5: ' '
                            }
                        }
                    }, () => {
                        this.getMfaStatus()
                        if(this.props.callback)
                            this.props.callback()
                    });                
                }
            });
        }
    }

    handleVerifyMfa = () => { 
        if(!this.props.myAccount) {      
            const mfaCode = this.state.formData.mfaCodeInd;
            this.setState({
                formData: {
                    ...this.state.formData,
                    mfaCode: mfaCode[0]+mfaCode[1]+mfaCode[2]+mfaCode[3]+mfaCode[4]+mfaCode[5]
                }            
            }, () => this.handleVerifyMfaSubmit());
        }
    }

    handleVerifyMfaSubmit = () => {   
        if(!this.props.myAccount) {
            API.post('/staff/' + this.props.staffId + '/mfa/verify', this.state.formData)
            .then(result => {
                if(result.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),                    
                        formData: {
                            ...this.state.formData,
                            mfaCode: '',
                            mfaCodeInd: {
                                0: ' ',
                                1: ' ',
                                2: ' ',
                                3: ' ',
                                4: ' ',
                                5: ' '
                            }
                        }
                    }, () => {
                        if(this.state.formErrors['mfaCode']){
                            this.mfaCode0.current.focus();
                        }
                    });
                } else {
                    this.setState({
                        ...initialState,
                        action: 'complete',
                        isLoading: false,
                        snackbar: {
                            submit: true
                        }
                    }, () => {
                        if(this.props.callback)
                            this.props.callback()
                    });
                }
            });
        }
    }

    handleMfaChange = (id, e) => {
        if(id === 0 && e.target.value.length >= 1) {
            this.mfaCode1.current.focus();
        } else if(id > 0 && id < 5 && e.target.value.length >= 1) {
            this['mfaCode'+(id+1)].current.focus();            
        } else if(id > 0) {            
            this['mfaCode'+(id-1)].current.focus();  
        }
        this.setState({
            formData: {
                ...this.state.formData,
                mfaCodeInd: {
                    ...this.state.formData.mfaCodeInd,
                    [id]: e.target.value.length > 0 ? (e.target.value.trim().length === 1 ? e.target.value.trim() : e.target.value.trim()[1]) : ' '
                },
            }
        }, () => {
            if(id === 5 && this.state.formData.mfaCodeInd[5].length > 0) { 
                this.handleVerifyMfa(false);
            }
        });
    }

    handleSnackbarClose = (type) => {
        this.setState({
            snackbar: {
                ...this.state.snackbar,
                [type]: false
            }
        });
    };

    handleConfirmationOpen = (type) => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: true,
            }
        });
    };

    handleConfirmationClose = (type) => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false,
            }
        });
    };

    handleConfirmationSuccess = (type) => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false,
            }
        }, () => {
            if(type === 'revoke') {
                this.handleRevoke();
            } else {
                this.handleActivateMfa();
            }
        });
    }

    render() {
        const { myAccount, staff } = this.props;
        const { action, mfaStatus, formData, formErrors, isLoading, mfaActivationDatetime, mfaActivationQr, mfaActivationSecret, confirmation, snackbar } = this.state;
        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <React.Fragment>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                    {(action === 'complete' && (
                                        <Grid container spacing={3}>
                                            <Grid item align='center'>
                                                <FAIcon icon='shield-check' style={{width: 75, height: 75, color: '#4CAF50'}} />
                                            </Grid>
                                            <Grid item xs>          
                                                <Typography variant="subtitle1">
                                                    Multi-Factor Authentication Activated
                                                </Typography>       
                                                <Typography variant="caption">
                                                    You have successfully actived Multi-Factor Authentication for {(staff && `${staff.staff_first_name} ${staff.staff_last_name}`) || 'this staff member'}!<br />
                                                    Next time they login, they'll have to start using their authenticator app to get a code.
                                                </Typography>
                                            </Grid>
                                        </Grid>      
                                    )) || (action === 'status' && (
                                        <Grid container spacing={3} alignItems='center'>
                                            <Grid item xs>
                                                <ViewStaffMfaStatus status={[mfaStatus,mfaActivationDatetime]} elevation={0} style={{padding: 0}} />
                                            </Grid>
                                            {!myAccount && (
                                                <Grid item>
                                                    {(mfaStatus && (
                                                        <Button 
                                                            variant="contained" 
                                                            color="primary" 
                                                            onClick={() => this.handleConfirmationOpen('revoke')}
                                                        > 
                                                            Revoke
                                                        </Button>
                                                    )) || (                          
                                                        <Button 
                                                            variant="contained" 
                                                            color="primary" 
                                                            onClick={() => this.handleConfirmationOpen('submit')}
                                                        > 
                                                            Activate 
                                                        </Button>
                                                    )}
                                                </Grid>
                                            )}
                                        </Grid>
                                    )) || (
                                        <React.Fragment>
                                            <Grid container spacing={3}>
                                                <Grid item align='center'>
                                                    <img src={mfaActivationQr} width='150' height='150' alt='Mfa Qr Code' /><br />
                                                    {mfaActivationSecret}
                                                </Grid>
                                                <Grid item xs>          
                                                    <Typography variant="subtitle1">
                                                        Multi-Factor Authentication Activation
                                                    </Typography>       
                                                    <Typography variant="caption">
                                                        Please scan the QR code or enter the code into your authenticator app and enter a one time code below to complete Multi-Factor Authentication activation.
                                                    </Typography>
                                                    {this.state.formErrors.mfaCode && 
                                                        <Typography variant="body2" color="error">
                                                            {this.state.formErrors.mfaCode}
                                                        </Typography>
                                                    }    
                                                    <Grid item container xs={12} spacing={window.innerWidth < 600 ? 1 : 3} style={{marginTop: 12, marginBottom: 12}} align='center'>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="mfa-0"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                autoComplete="off"
                                                                value={formData.mfaCodeInd[0]}
                                                                error={formErrors && formErrors['mfaCode'] && true}
                                                                onChange={(e) => this.handleMfaChange(0, e)} 
                                                                className='mfaInput'
                                                                inputProps={{
                                                                    ref:this.mfaCode0,
                                                                    size:2,
                                                                    maxLength:2,
                                                                    style: {
                                                                        textAlign: 'center'
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="mfa-1"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                autoComplete="off"
                                                                className='mfaInput'
                                                                value={formData.mfaCodeInd[1]}
                                                                error={formErrors && formErrors['mfaCode'] && true}
                                                                onChange={(e) => this.handleMfaChange(1, e)} 
                                                                inputProps={{
                                                                    ref:this.mfaCode1,
                                                                    size:2,
                                                                    maxLength:2,
                                                                    style: {
                                                                        textAlign: 'center'
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="mfa-2"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                name="mfaCode3"
                                                                autoComplete="off"
                                                                className='mfaInput'
                                                                value={formData.mfaCodeInd[2]}
                                                                error={formErrors && formErrors['mfaCode'] && true}
                                                                onChange={(e) => this.handleMfaChange(2, e)} 
                                                                inputProps={{
                                                                    ref:this.mfaCode2,
                                                                    size:2,
                                                                    maxLength:2,
                                                                    style: {
                                                                        textAlign: 'center'
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="mfa-3"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                autoComplete="off"
                                                                className='mfaInput'
                                                                value={formData.mfaCodeInd[3]}
                                                                error={formErrors && formErrors['mfaCode'] && true}
                                                                onChange={(e) => this.handleMfaChange(3, e)} 
                                                                inputProps={{                                                                        
                                                                    ref:this.mfaCode3,
                                                                    size:2,
                                                                    maxLength:2,
                                                                    style: {
                                                                        textAlign: 'center'
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="mfa-4"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                autoComplete="off"
                                                                className='mfaInput'
                                                                value={formData.mfaCodeInd[4]}
                                                                error={formErrors && formErrors['mfaCode'] && true}
                                                                onChange={(e) => this.handleMfaChange(4, e)} 
                                                                inputProps={{
                                                                    ref:this.mfaCode4,
                                                                    size:2,
                                                                    maxLength:2,
                                                                    style: {
                                                                        textAlign: 'center'
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="mfa-5"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                autoComplete="off"
                                                                className='mfaInput'
                                                                value={formData.mfaCodeInd[5]}
                                                                error={formErrors && formErrors['mfaCode'] && true}
                                                                onChange={(e) => this.handleMfaChange(5, e)} 
                                                                inputProps={{
                                                                    ref:this.mfaCode5,
                                                                    size:2,
                                                                    maxLength:2,
                                                                    style: {
                                                                        textAlign: 'center'
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <div className="buttonRow">
                                                        <Button 
                                                            variant="outlined" 
                                                            color="secondary" 
                                                            onClick={() => this.handleRevoke()}
                                                        > 
                                                            Cancel
                                                        </Button>
                                                        <Button 
                                                            variant="contained" 
                                                            color="primary" 
                                                            onClick={() => this.handleVerify()}
                                                        > 
                                                            Confirm
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>                        
                                        </React.Fragment>
                                    )}
                                    <ConfirmationDialog 
                                        open={confirmation['revoke']} 
                                        success={() => this.handleConfirmationSuccess('revoke')} 
                                        close={() => this.handleConfirmationClose('revoke')} 
                                        title="Revoke Multi-Factor Authentication?" 
                                        message="Are you sure you want to revoke Multi-Factor Authentication for this system user?"
                                    />                    
                                    <ConfirmationDialog 
                                        open={confirmation['submit']} 
                                        success={() => this.handleConfirmationSuccess('submit')} 
                                        close={() => this.handleConfirmationClose('submit')} 
                                        title="Activate Multi-Factor Authentication?" 
                                        message="Are you sure you want to activate Multi-Factor Authentication for this system user?"
                                    />                    
                                    <SnackBar
                                        variant="success"
                                        anchorOriginVertical='bottom'
                                        anchorOriginHorizontal='right'
                                        open={snackbar['revoke']}
                                        onClose={() => this.handleSnackbarClose('revoke')}
                                        message="The system user's Multi-Factor Authentication has been revoked"
                                    />
                                    <SnackBar
                                        variant="success"
                                        anchorOriginVertical='bottom'
                                        anchorOriginHorizontal='right'
                                        open={snackbar['submit']}
                                        onClose={() => this.handleSnackbarClose('submit')}
                                        message="The system user's Multi-Factor Authentcation has been enabled"
                                    />
                                </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" gutterBottom>
                                                    Authy
                                                </Typography>
                                                <Typography variant="caption" component="div" color="primary">
                                                    Compatible with BlueSync Multi-Factor Authentication
                                                </Typography>
                                                <Typography variant="caption">
                                                    Authy brings the future of strong authentication to the convenience of your Android device.
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <a href="https://apps.apple.com/gb/app/authy/id494168017" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                                    <img src={AppStoreLogo} alt="App Store" width={115} height={33}/>
                                                </a>
                                            </Grid>
                                            <Grid item>
                                                <a href="https://play.google.com/store/apps/details?id=com.authy.authy" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                                    <img src={GooglePlayLogo} alt="Google Play" width={115} height={33}/>
                                                </a>
                                            </Grid>
                                            <Grid item xs />
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" gutterBottom>
                                                    Google Authenticator
                                                </Typography>
                                                <Typography variant="caption" component="div" color="primary">
                                                    Compatible with BlueSync Multi-Factor Authentication
                                                </Typography>
                                                <Typography variant="caption">
                                                    Google Authenticator generates 2-Step Verification codes on your phone.
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <a href="https://apps.apple.com/gb/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                                    <img src={AppStoreLogo} alt="App Store" width={115} height={33}/>
                                                </a>
                                            </Grid>
                                            <Grid item>
                                                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                                    <img src={GooglePlayLogo} alt="Google Play" width={115} height={33}/>
                                                </a>
                                            </Grid>
                                            <Grid item xs />
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" gutterBottom>
                                            What is Multi-Factor Authentication?
                                        </Typography>
                                        <Typography variant="caption">
                                            Multi-Factor Authentication is an additional layer of security that requires staff to enter a one-time code from a supported authenticator app when they login to BlueSync.
                                        </Typography>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" gutterBottom>
                                            Why use Multi-Factor Authentication?
                                        </Typography>
                                        <Typography variant="caption">
                                            If a user's password is compromised then Multi-Factor Authentication can help to keep the data stored in BlueSync secure; as no one can login and access BlueSync without having a one time code that is generated from a device that only the staff member has access to physyically. Plus, the one-time code changes every 30 seconds, can only be used once and is relatively impossible to guess!
                                        </Typography>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}> 
                                    <PaddedPaper style={{paddingBottom: 25}}>
                                        <Typography variant="h6" gutterBottom>
                                            What if the system user loses their device?
                                        </Typography>
                                        <Typography variant="caption">
                                            You can revoke and re-activate Multi-Factor Authentication from this page at any time; once Multi-Factor Authentication has been revoked no one will be able to use the old authenticator app to generate a code to login to BlueSync.
                                        </Typography>
                                    </PaddedPaper>
                                </Grid> 
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        )
    }
}

export default UpdateStaffMfa;