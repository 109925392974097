import React from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const StatusIndicator = ({status, size, children}) => {
    const Online = withStyles(theme => ({
        badge: {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            }
        }
    }))(Badge);
    
    const Idle = withStyles(theme => ({
        badge: {
            backgroundColor: '#F57C00',
            color: '#F57C00',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            }
        }
    }))(Badge);

    const Offline = withStyles(theme => ({
        badge: {
            backgroundColor: '#d9534f',
            color: '#d9534f',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
        }
    }))(Badge);

    const El = isNaN(status) && status !== 0 ? (status === true ? Online : Offline) : (status === 2 ? Online : (status === 1 ? Idle : Offline));

    return(
        <El
            overlap="circle"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            variant="dot"
        >
            {children}
        </El>
    );
}

export default StatusIndicator;