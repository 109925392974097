import React  from 'react';

import Typography from '@material-ui/core/Typography';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core';

export const BasicDialog = props => (
    <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth={(props.maxWidth && props.maxWidth) || 'sm'}
        open={props.open}
        fullWidth={true}
        scroll="body"
    >
        <DialogTitle disableTypography>
            <Typography variant="h6" align="center">
                {props.title}
            </Typography>
        </DialogTitle>
        <DialogContent>
            {props.content}
        </DialogContent>
        <DialogActions>
            {props.onClose && (
                <Button onClick={props.onClose} color="primary">
                    CLOSE
                </Button>
            )}
        </DialogActions>
    </Dialog>
)