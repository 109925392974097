import React from 'react';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

const DateSelect = ({onChange, value}) => (
    <AutoCompleteSelect
        adornment="calendar-alt"
        onChange={onChange}
        options={[
            {
                label: 'Today',
                value: 'today'
            },
            {
                label: 'This Week',
                value: 'thisWeek'
            },
            {
                label: 'Last Week',
                value: 'lastWeek'
            },
            {
                label: 'This Month',
                value: 'thisMonth'
            },
            {
                label: '--------------------',
                disabled: true
            },
            {
                label: 'One Month',
                value: 'oneMonth'
            },
            {
                label: 'Three Months',
                value: 'threeMonths'
            },
            {
                label: 'Six Months',
                value: 'sixMonths'
            },
            {
                label: 'One Year',
                value: 'oneYear'
            },
            {
                label: 'All Time',
                value: 'allTime'
            }
        ]}
        noClear
        noDefaultSort
        placeholder='Display:'
        value={value} 
        variant="filled"
    /> 
)

export default DateSelect;