import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

class ViewStaffMfaStatus extends React.Component {
    render() {
        const { status, isloading } = this.props;
        return (
            <React.Fragment>
                {(isloading && (
                    <LoadingCircle />
                )) || (              
                    <Card style={{padding: 6, height: '100%'}} {...this.props}>
                        <CardHeader
                            avatar={<FAIcon icon='shield-alt' style={{width: 45, height: 45, color: status[0] === 1 ? '#8BC34A' : '#f44336'}} noMargin />}
                            title={<Typography variant='h5' className="fw-400">{(status[0] === 1 && ( `Activated` )) || ( `Inactive` )}</Typography>}
                            subheader={<Typography variant="caption">Multi-Factor Authentication</Typography>}
                            disableTypography
                            {...this.props}
                        />
                    </Card>
                )}
            </React.Fragment>
        )
    }
}

export default ViewStaffMfaStatus;