import React from "react";
import _ from "lodash";
import moment from "moment";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import API from "API";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import Tile from "Components/Common/Dashboard/Tile";
import { COLOR_MAIN_MYCLOUD } from "ColorSchemes";

const initialState = {
    dataLoading: true,
    charts: {
        contacts: [],
        people: [],
        sales: [],
    },
    contacts: {},
    contactsLegend: [],
    insight: {},
};

class MyCloudDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.props.pageTitle([1, "MyCloud", "Dashboard"]);
        this.loadComponentData();
    };

    loadComponentData = () => {
        Promise.all([API.get("/mycloud/insight"), API.get("/mycloud/insight/charts")]).then(([insightLatest, insight]) => {
            let historySales = [];
            let historyPeople = [];
            let historyGWoAdditions = [];
            let historyLabels = [];

            for (let i = 0, j = 11; i <= j; i++) {
                historySales.push(insight.data[i].sales.total);
                historyPeople.push(parseInt(insight.data[i].myc.people));
                historyGWoAdditions.push(parseInt(insight.data[i].myc.gWoAdditions));
                historyLabels.push(insight.data[i].label);
            }

            const chartPeople = {
                labels: historyLabels,
                datasets: [
                    {
                        lineTension: 0.1,
                        backgroundColor: "rgba(244,67,54,0.5)",
                        borderColor: "#f44336",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#f44336",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#f44336",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        fill: true,
                        data: historyPeople,
                    },
                ],
            };

            const chartContacts = {
                labels: ["User Mapped", "User Ignored", "Pending Creation", "Unassigned"],
                datasets: [
                    {
                        data: [
                            insightLatest.data.contacts.mapped,
                            insightLatest.data.contacts.ignore,
                            insightLatest.data.contacts.create,
                            insightLatest.data.contacts.unassigned,
                        ],
                        backgroundColor: ["rgba(76,175,80,0.5)", "rgba(255,64,129,0.5)", "rgba(239,108,0,0.5)", "rgba(30,136,229,0.5)"],
                    },
                ],
            };

            const contactsLegend = [
                {
                    name: "User Mapped",
                    color: "rgba(76,175,80,0.5)",
                    count: insightLatest.data.contacts.mapped,
                },
                {
                    name: "User Ignored",
                    color: "rgba(255,64,129,0.5)",
                    count: insightLatest.data.contacts.ignore,
                },
                {
                    name: "Pending Creation",
                    color: "rgba(239,108,0,0.5)",
                    count: insightLatest.data.contacts.create,
                },
                {
                    name: "Unassigned",
                    color: "rgba(30,136,229,0.5)",
                    count: insightLatest.data.contacts.unassigned,
                },
            ];

            const chartSales = {
                labels: historyLabels,
                datasets: [
                    {
                        lineTension: 0.1,
                        backgroundColor: "#81C784",
                        borderColor: "#4CAF50",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "#4CAF50",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#4CAF50",
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        fill: true,
                        data: historySales,
                    },
                ],
            };

            this.setState({
                dataLoading: false,
                charts: {
                    contacts: chartContacts,
                    people: chartPeople,
                    sales: chartSales,
                },
                contacts: insightLatest.data.contacts,
                contactsLegend: contactsLegend,
                insight: insightLatest.data.insight,
            });
        });
    };

    render() {
        const { charts, contacts, contactsLegend, dataLoading, insight } = this.state;
        return (
            <Grid container spacing={3}>
                {(dataLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={8} xl={9}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Service Sales
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.sales}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Total Users
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Chart
                                                                type="line"
                                                                data={charts.people}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        xAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                            },
                                                                        ],
                                                                        yAxes: [
                                                                            {
                                                                                gridLines: {
                                                                                    color: "rgba(0,0,0,.03)",
                                                                                },
                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                    suggestedMax: insight && insight.imc_count_people * 1.5,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant={"h4"} paragraph>
                                                        Contact Mapping Status
                                                    </Typography>
                                                    <Paper elevation={0} style={{ border: "1px solid #ddd" }}>
                                                        <Box p={3}>
                                                            <Grid container spacing={3} alignItems="center">
                                                                <Grid item xs={12} lg={6}>
                                                                    <Chart
                                                                        type="doughnut"
                                                                        data={charts.contacts}
                                                                        width={50}
                                                                        height={50}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: {
                                                                                    display: false,
                                                                                },
                                                                            },
                                                                            legend: {
                                                                                display: false,
                                                                            },
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <Grid container spacing={1}>
                                                                        {_.map(contactsLegend, (legend, idx) => (
                                                                            <React.Fragment key={idx}>
                                                                                <Grid item xs={2} align="center">
                                                                                    <FAIcon icon="circle" style={{ color: legend.color }} />
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <Typography variant="caption">{legend.name}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={2} align="center">
                                                                                    <Typography variant="caption">{legend.count}</Typography>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        ))}
                                                                        <Grid item xs={2} style={{ borderTop: "1px solid #ddd" }} />
                                                                        <Grid item xs={8} style={{ borderTop: "1px solid #ddd" }}>
                                                                            <Typography variant="caption" color="textSecondary">
                                                                                Total
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={2} align="center" style={{ borderTop: "1px solid #ddd" }}>
                                                                            <Typography variant="caption" color="textSecondary">
                                                                                {contacts.total}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={4} xl={3}>
                                    <Grid container spacing={3}>
                                        <Tile
                                            dashboard
                                            icon="sync"
                                            color={COLOR_MAIN_MYCLOUD}
                                            backgroundColor={"#fff"}
                                            data={insight && insight.imc_created_datetime ? moment(insight.imc_created_datetime).format("DD/MM/YYYY") : "Never"}
                                            title="Last MyCloud Data Import"
                                        />
                                        <Tile
                                            dashboard
                                            icon="users"
                                            color={COLOR_MAIN_MYCLOUD}
                                            backgroundColor={"#fff"}
                                            data={insight ? insight.imc_count_people : 0}
                                            title="Users"
                                        />
                                        <Tile
                                            dashboard
                                            icon={insight && insight.imc_count_gWoAdditions > 0 ? "exclamation-triangle" : "link"}
                                            color={COLOR_MAIN_MYCLOUD}
                                            backgroundColor={"#fff"}
                                            data={insight ? insight.imc_count_gWoAdditions : 0}
                                            title="Generic Groups Without Mapping"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

export default MyCloudDashboard;
